import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "reducers";
import { List, ListItem, ListItemText, ListItemSecondaryAction, Button, Divider } from "@material-ui/core";
import { AdminAction, getUsers, impersonate } from "actions/admin";
import { User } from "reducers/user";

type Props = {
  impersonate: AdminAction;
  getUsers: AdminAction;
  users: User[];
} & RouteComponentProps;

const AdminImpersonateList: React.FC<Props> = ({ impersonate, getUsers, users }: Props) => {
  React.useEffect(() => {
    if (!users.length) getUsers();
  }, [users]);

  const userList = users.map((user) => {
    return (
      <div key={user.id}>
        <ListItem>
          <ListItemText primary={user.email} secondary={user.organization.title} />
          <ListItemSecondaryAction>
            <Button onClick={(): void => impersonate(user.id, history)} variant="contained">
              Impersonate
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </div>
    );
  });

  return (
    <List className="admin__usersList" style={{ maxWidth: "50%", alignContent: "center", margin: "auto" }}>
      {userList}
    </List>
  );
};

export default connect((state: RootStore) => ({ users: state.admin.users }), { getUsers, impersonate })(
  AdminImpersonateList
);

import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
  fetchMarketingSources,
  fetchCampaigns,
  fetchLeadDocketMarketingSources,
  fetchLitifyMarketingSources,
  MarketingSource,
  Campaign,
  LeadDocketMarketingSource,
  LitifyMarketingSource,
} from "actions/marketingSources";
import {
  removeMarketingSource as removeMarketingSourceRequest,
  removeCampaign as removeCampaignRequest,
} from "lib/marketingSources";
import { RootStore } from "reducers";
import connect from "app/connect";
import { hasLeaddocketPlugin, hasLitifyPlugin } from "selectors/plugins";
import MarketingSourceModal from "app/components/settings/addMarketingSources/MarketingSourceModal";
import { Icon, IconButton } from "@material-ui/core";
import EditIcon from "assets/icons/ui/edit.inline.svg";
import Remove from "assets/icons/ui/x.inline.svg";
import { fetchPlugins, PluginSource } from "actions/plugins";
import { createPlugin, redirect_uri_for_plugin_source } from "lib/plugins";
import { getQueryParam } from "lib/utilities/url";

type Props = {
  fetchPlugins: Function;
  fetchMarketingSources: Function;
  fetchLeadDocketMarketingSources: Function;
  fetchLitifyMarketingSources: Function;
  marketingSources: MarketingSource[];
  hasLeaddocketPlugin?: boolean;
  hasLitifyPlugin?: boolean;
  leaddocket_marketing_sources: LeadDocketMarketingSource[];
  litify_marketing_sources: LitifyMarketingSource[];
  pluginSources: PluginSource[];
} & RouteComponentProps;

const MarketingSources: React.FC<Props> = ({
  fetchPlugins,
  fetchMarketingSources,
  fetchLeadDocketMarketingSources,
  fetchLitifyMarketingSources,
  marketingSources,
  hasLeaddocketPlugin,
  hasLitifyPlugin,
  toggleSnackbar,
  leaddocket_marketing_sources,
  litify_marketing_sources,
  pluginSources,
  match
}: Props) => {
  const [showNewCreateModal, setShowNewCreateModal] = React.useState(false);
  const [isMarketingSource, setIsMarketingSource] = React.useState(false);
  const [sourceId, setSourceId] = React.useState("");
  const [marketingSource, setMarketingSource] = React.useState<MarketingSource>(null);
  const [marketingCampaign, setMarketingCampaign] = React.useState<Campaign>(null);

  const addNewMarketingCampaign = (source: MarketingSource, visible?): void => {
    setShowNewCreateModal(visible || !showNewCreateModal);
    setSourceId(String(source.id));
    setIsMarketingSource(false);
  };

  const addNewMarketingSource = (visible?): void => {
    setShowNewCreateModal(visible || !showNewCreateModal);
    setIsMarketingSource(true);
  };

  const handleCloseModal = (visible?): void => {
    setShowNewCreateModal(false);
    setMarketingSource(null);
    setMarketingCampaign(null);
    setSourceId("");
  };

  const savePluginSourceFactory = (sourceIdOrIdentifier: string | number) => async (
    // param: LeadDocketInput | LitifyDomainInput | Partial<CreatePluginPayload>
    param: { [key: string]: any }
  ): Promise<void> => {
    let sourceId;
    let pluginSource;
    if (typeof sourceIdOrIdentifier === "string") {
      pluginSource = pluginSources?.find((p) => p.identifier === sourceIdOrIdentifier);
      if (!pluginSource) {
        toggleSnackbar(`Could not find plugin source for ${sourceIdOrIdentifier}`);
        return;
      }
      sourceId = pluginSource.id;
    } else {
      sourceId = sourceIdOrIdentifier;
    }

    try {
      const payload = {
        plugin_source_id: sourceId,
        redirect_uri: redirect_uri_for_plugin_source(sourceId),
        ...param,
      };

      await createPlugin(payload);
      await fetchPlugins();
    } catch (err) {
      await toggleSnackbar("We're running into an issue saving this plugin. Please contact us for help!");
    }
  };

  React.useEffect(() => {
    /*console.log("hasLeaddocketPlugin: ", hasLeaddocketPlugin);
    console.log("leaddocket_marketing_sources: ", leaddocket_marketing_sources);
    console.log("pluginSources: ", pluginSources)*/
    
    fetchPlugins();
    const plugin_source_id = getQueryParam(window.location.href, "plugin_source_id") || match.params.pluginSourceId;
    const authorization_code = getQueryParam(window.location.href, "code");
    const oauthState = getQueryParam(window.location.href, "state");
    
    if (plugin_source_id && authorization_code) {
      savePluginSourceFactory(plugin_source_id)({ authorization_code, oauthState });
    }

    fetchMarketingSources();
    if (hasLeaddocketPlugin && !leaddocket_marketing_sources.length) {
      fetchLeadDocketMarketingSources();
    }
    if(hasLitifyPlugin && !litify_marketing_sources.length) {
      fetchLitifyMarketingSources();
    }
  }, [hasLeaddocketPlugin, leaddocket_marketing_sources, hasLitifyPlugin, litify_marketing_sources]);

  const onEditMarketingSource = (marketing_source: MarketingSource): void => {
    setMarketingSource(marketing_source);
    setShowNewCreateModal(true);
    setIsMarketingSource(true);
  };

  const removeMarketingSource = (source: MarketingSource): void => {
    removeMarketingSourceRequest(source.id)
      .then(() => fetchMarketingSources())
      .catch(() => toggleSnackbar("We ran into an issue removing this source! Please try again later."));
  };

  const onEditCampaign = (campaign: Campaign): void => {
    setMarketingCampaign(campaign);
    setShowNewCreateModal(true);
    setIsMarketingSource(false);
  };

  const removeCampaign = (campaign: Campaign): void => {
    removeCampaignRequest(campaign.id)
      .then(() => fetchMarketingSources())
      .catch(() => toggleSnackbar("We ran into an issue removing this campaign! Please try again later."));
  };

  return (
    <div className="marketing">
      <div className="marketing__header">
        <button className="marketing__add__button" onClick={(): void => addNewMarketingSource()}>
          + New Marketing Source
        </button>

        <h3 className="type-24-bold">Marketing Sources</h3>
      </div>

      <div className="marketing__list">
        {marketingSources.map((source: MarketingSource, i: number) => {
          return (
            <div key={i} className="marketing__list__item">
              <div className="marketing__list__item__header">
                <h2>{source.name}</h2>
                <div className="marketing__list__item__header__actions">
                  <IconButton className="source__button-update" onClick={(): void => onEditMarketingSource(source)}>
                    <EditIcon className="edit" />
                  </IconButton>
                  <IconButton onClick={(): void => removeMarketingSource(source)}>
                    <Remove className="remove" />
                  </IconButton>
                </div>
              </div>
              <div className="marketing__list__item__content">
                <div>
                  <h3>Campaigns</h3>
                  <button className="marketing__list__item__add" onClick={(): void => addNewMarketingCampaign(source)}>
                    New Campaign
                  </button>
                </div>
                <ul>
                  {source.marketing_campaigns.map((campaign: Campaign, i: number) => {
                    return (
                      <li key={i}>
                        <div>{campaign.name} </div>

                        <div className="campaign__actions">
                          <IconButton
                            className="campaign__button-update"
                            onClick={(): void => onEditCampaign(campaign)}
                          >
                            <EditIcon className="edit" />
                          </IconButton>
                          <IconButton onClick={(): void => removeCampaign(campaign)}>
                            <Remove className="remove" />
                          </IconButton>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      <MarketingSourceModal
        setVisible={(visible) => handleCloseModal(visible)}
        visible={showNewCreateModal}
        isMarketingSource={isMarketingSource}
        marketingSourceId={sourceId}
        marketingSource={marketingSource}
        marketingCampaign={marketingCampaign}
      />
    </div>
  );
};

export default connect(
  (state: RootStore) => ({
    marketingSources: state.marketingSources.marketing_sources,
    leaddocket_marketing_sources: state.marketingSources.leaddocket_marketing_sources,
    litify_marketing_sources: state.marketingSources.litify_marketing_sources,
    hasLeaddocketPlugin: hasLeaddocketPlugin(state),
    hasLitifyPlugin: hasLitifyPlugin(state),
    plugins: state.plugins.plugins,
    pluginSources: state.plugins.plugin_sources
  }),
  { fetchMarketingSources, fetchCampaigns, fetchLeadDocketMarketingSources, fetchPlugins, fetchLitifyMarketingSources }
)(MarketingSources);

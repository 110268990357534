import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import ReactMarkdown from "react-markdown";
import { getPresignedLogoS3Url } from "lib/user";
import axios from "axios";

type Props = {
  className?: string;
  onChange: Function;
  body: string;
  disabled?: boolean;
  updateNode: Function;
  placeholder: string;
} & RouteComponentProps;

const MarkDownInput: React.FC<Props> = ({
  className,
  onChange,
  body,
  disabled,
  updateNode,
  placeholder,
  user,
}: Props) => {
  // let mde: EasyMDE;
  const [mde, setMDE] = React.useState<{ mde: EasyMDE }>({ mde: undefined });

  React.useEffect(() => {
    // this focus is required. Was having an issue with the page jumping to the top
    // when first focused and cursor out of view. This resolves.
    if (mde && mde.mde) {
      mde.mde.codemirror.focus();
    }
  }, [mde]);

  const onImageUpload = (image, onSuccess, onError): void => {
    let s3URL = undefined;
    getPresignedLogoS3Url(user.organization_id, image.type)
      .then((res) => {
        s3URL = res.data.presigned_logo_url.split("?")[0];
        return axios.put(res.data.presigned_logo_url, image, { headers: { "Content-Type": image.type } });
      })
      .then((res) => {
        onSuccess(s3URL);
      })
      .catch((err) => {
        onError(err);
      });
  };

  // no way to set SimpleMDE to disabled, so just render message when disabled
  return disabled ? (
    <ReactMarkdown source={body} />
  ) : (
    // contentEditable prevents drag and enables the children to be focusable
    <div contentEditable={true} suppressContentEditableWarning={true}>
      <SimpleMDEReact
        value={body}
        getMdeInstance={(instance): void => {
          setMDE({ mde: instance });
        }}
        onChange={(value): void => onChange(value)}
        className={className}
        options={{
          placeholder: placeholder,
          spellChecker: false,
          status: ["upload-image"],
          hideIcons: ["fullscreen", "side-by-side"],
          uploadImage: true,
          imageUploadFunction: onImageUpload,
        }}
        events={{
          blur: (e, event): void => {
            if (event) {
              event.preventDefault();
            }
            // updateNode();
          },
        }}
      />
    </div>
  );
};

export default connect()(MarkDownInput);

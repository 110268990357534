import api from "lib/api";
import { AxiosResponse } from "axios";
import { Organization, User } from "reducers/user";

export type CreateOrganizationParams = {
  user: {
    name: string;
    email: string;
    password: string;
    password_confirm: string;
  };
  organization: {
    title: string;
    plan: string;
  };
};

export type CreateOrgResponse = {
  jwt: string;
  user: User;
  organization: Organization;
  sessionId: string;
};

export const createOrganization = (params: CreateOrganizationParams): Promise<AxiosResponse<CreateOrgResponse>> => {
  return api.post("/organizations", params);
};

import api from "lib/api";
import { Order } from "reducers/leads";

export const leadLimit = 25;

export type assignLeadParams = {
    lead_id: number;
    user_id: number;
}

export type updateLeadParams = {
    id: number;
    name: string;
    email: string;
    phone_number: string;
    address: string;
}
export type leadDocParams = {
    lead_id: number
}

export const fetchLeads = (offset = null, sort, order, filter) => {
    let endpoint = `/leads?limit=${leadLimit}&offset=${offset}`
    if (sort != null) endpoint += `&sort_by=${sort}`
    if (order != null) endpoint += `&order_by=${order}`
    if (filter != null) endpoint += `&filter[field]=${filter.field}&filter[value]=${filter.value}`

    return api.get(endpoint, null)
}

export const assignLead = (params: assignLeadParams) => {
    return api.post(`/leads/assign`, params);
}

export const unassignLead = (leadId) => {
    return api.post(`/leads/unassign`, { lead_id: leadId })
}

export const updateLead = (params: updateLeadParams) => {
    return api.put(`/leads/${params.id}`, params);
}

export const fetchLead = (leadId) => {
    let endpoint = `/leads/${leadId}`;
    return api.get(endpoint, null)
}

export const countLeads = () => {
    const endpoint = `/leads/count`;
    return api.get(endpoint, null)
}

export const fetchMessages = (conversationId: number, page: number) => {
  const endpoint = `/conversations/messages?conversation_id=${conversationId}&limit=20&page=${page}`;
  return api.get(endpoint, null);
};

export const fetchConversations = (leadId: number, page:number, orderBy: Order, limit: number) => {
  const endpoint = `/conversations?lead_id=${leadId}&limit=${limit}&page=${page}&order_by=${orderBy}`;
  return api.get(endpoint, null);
};

export const archiveLead = (leadId: number) => {
    return api.put(`/leads/archive`, { lead_id: leadId });
}

//gets all the conversation documents (doccassemble) for the given conversation
export const fetchConversationDocuments = (conversation_id: number) => {
    const endpoint = `/conversations/documents?conversation_id=${conversation_id}`;
    return api.get(endpoint, null)

};

//checks if conversation document link has expired or not, will genereate new link
export const checkConversationDocuments = (check_link: any) => {
    const endpoint = `/conversations/documents/check?check_link=${check_link}`;
    return api.get(endpoint, null)

};

export const fetchLeadsConversationDocuments= (leadId: any) => {
    const endpoint = `/lead/conversations/documents?lead_id=${leadId}`;
    return api.get(endpoint, null)

}

export const fetchPresignedDocument = (url: any) => {
    const endpoint = `/lead/document?url=${url}`
    return api.get(endpoint, null)
}

export const exportLeads= (sort: any, order: any, filter: any) => {
    let endpoint = `/leads/export`
    if (sort != null && order != null && filter.value != null){
        endpoint += `?sort_by=${sort}`
        endpoint += `&order_by=${order}`
        endpoint += `&filter={field:${filter.field},value:${filter.value}}`
       // endpoint += `&filter[field]=${filter.field}&filter[value]=${filter.value}`
    } else if (sort != null && order != null){
        endpoint += `?sort_by=${sort}`
        endpoint += `&order_by=${order}`

    } else if ( sort == null && filter.value != null){
        endpoint += `?filter={field:${filter.field},value:${filter.value}}`
        //endpoint += `?filter[field]=${filter.field}&filter[value]=${filter.value}`
    }
    return api.get(endpoint, null);

}

export const fetchLeadAppointments= (lead_id: any) => {
    const endpoint = `/lead/appointment?lead_id=${lead_id}`;
    return api.get(endpoint, null)
}

export const fetchConversationTranscripts = (leadId: any) => {
    return api.get(`/conversations/logs?lead_id=${leadId}`, null);
}

export const fetchPDF = (lead_id: number, conversation_id: number) => {
    return api.getBlob(`/conversations/pdf?lead_id=${lead_id}&conversation_id=${conversation_id}`, null);
}

export const fetchCSV = (lead_id: number, conversation_id: number) => {
    return api.getBlob(`/conversations/csv?lead_id=${lead_id}&conversation_id=${conversation_id}`, null);
}
import * as React from "react";
import { requestPasswordReset } from "lib/user";
import { Link } from "react-router-dom";
import connect from "app/connect";

// Cmps.
import FormControl from "app/components/global/formControl";

type Props = {
    requestPasswordReset: Function; // TODO; type this
    toggleSnackbar: Function;
}

type State = {
    loading: Boolean;
    email: String;
    success: String;
}

class LoginForm extends React.Component<Props, State> {
    state = {
        loading: false,
        email: '',
        success: '',
    };


    handleResetRequest = e => {
        e.preventDefault();

        this.setState({ loading: true });
        requestPasswordReset(this.state.email)
        .then(response => {
            this.setState({
                loading: false,
                success: `Password reset link emailed to ${this.state.email}.`
            })
        })
        .catch(err => {
            this.setState({ loading: false });
            this.props.toggleSnackbar("Unable to find User with that email.", true, 'error');
        })
    }

    render() {
        return (
            <div className="requestResetForm window-form">
                <h2>Forgot password</h2>

                <h4>Enter your information to recover your password</h4>

                <form onSubmit={this.handleResetRequest}>
                    <FormControl 
                        type="text" 
                        value={this.state.email} 
                        title
                        placeholder="Email"
                        disabled={this.state.loading || this.state.success.length > 0}
                        onChange={ (e) => this.setState({ email: e.target.value }) } 
                    />

                    { this.state.success.length > 0 && !this.state.loading ? 
                        <h4 className="password-reset-request__success">{this.state.success}</h4>
                        : <button onClick={(e) => this.handleResetRequest(e)}>Request Password Reset</button>
                    }
                </form>

                <span>
                    <Link to='/signup' className="light-blue create-account">Create account</Link>

                    &nbsp;or&nbsp;

                    <Link to='/' className="light-blue align-right">Sign in</Link>
                </span>
            </div>
        )
    }
}

export default connect()(LoginForm);
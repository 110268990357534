import * as React from "react";
import connect from "app/connect";
import { Lead, setLead } from "actions/leads";
import { getAttorneys } from "actions/attorneys";
import { AttorneyStore } from "reducers/attorneys";
import { RootStore } from "reducers";
import { assignLead, unassignLead } from "lib/leads";

import "sass/components/userListItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import UserListItem from "app/components/global/attorneyListItem";

type Props = {
  visible: boolean;
  lead: Lead;
  getAttorneys: Function;
  attorneys: AttorneyStore;
  toggleSnackbar: Function;
  updateAssignee: Function;
  setLead: Function;
};

/**
 * Hover Menu for assigning lead
 */
class AssignMenu extends React.Component<Props> {
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.visible && this.props.visible) {
      this.props.getAttorneys();
    }
  }

  selected = (member) => {
    assignLead({ lead_id: this.props.lead.id, user_id: member.id })
      .then((res) => {
        this.props.toggleSnackbar("Lead successfully asssigned!");
        this.props.setLead(res.data);
      })
      .catch((err) => {
        this.props.toggleSnackbar("We ran into an issue assigning this lead! Please try again later.");
      });
  };

  unassign = (lead) => {
    unassignLead(lead.id).then((res) => {
      this.props.toggleSnackbar("Lead unassigned.");
      this.props.setLead(res.data);
    });
  };

  render() {
    return (
      <div className={`lead__item__assignMenu ${this.props.visible ? "visible" : ""}`}>
        <ul>
          {this.props.attorneys.data.map((m, i) => (
            <UserListItem
              userData={m}
              key={i}
              secondary={false}
              isClicked={true}
              clickHandler={(e) => this.selected(m)}
            />
          ))}
          <ListItem onClick={(e) => this.unassign(this.props.lead)} className="user_list__item">
            <ListItemText primary="Unassign" />
          </ListItem>
        </ul>
      </div>
    );
  }
}

export default connect((state: RootStore) => ({ attorneys: state.attorneys }), {
  getAttorneys,
  setLead,
})(AssignMenu);

import { fetchAttorneys } from "lib/attorneys";
import { User } from "reducers/user";
import { SET_ATTORNEY } from "./attorney";
import { toggleSnackbar } from "actions/ui";
import { getAvailabilities, getPluginEvents } from "actions/availabilities";

// Action types
export const FETCH_ATTORNEYS = 'FETCH_ATTORNEYS';
export const SET_ATTORNEYS = 'SET_ATTORNEYS';

export const getAttorneys = () => dispatch => {
    // Tell reducer we're fetching
    dispatch({
        type: FETCH_ATTORNEYS
    });

    // Actually fetch attorneys
    fetchAttorneys()
    .then(res => {
        dispatch({
            type: SET_ATTORNEYS,
            attorneys: res.users,
            seats_remaining: res.seats_remaining,
        })
        return res;
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your organization's users! Please try again", true)(dispatch);
    })
}

export type HandoffAction =
    | {
          type: "FETCH_ATTORNEYS";
          loading: Boolean
      } 
    | {
        type: "SET_ATTORNEYS";
        attorneys: User[]
        seats_remaining: number
    }
    | {
        type: "SET_ATTORNEY";
        selectedAttorney: User
    };
/**
 * This file just exists to avoid a circular dependency
 * Used in SortingArrows and SortingLabel to denote which direction the field
 * is being sorted in
 */

export enum SortDirection {
    ASC,
    DESC,
    OFF
}

/**
 * For converting nicely between SortDirection (number representation) and Order
 * (string representation)
 * @param dir - SortDirection
 * @returns a string representing the order of the sort ("ASC" or "DESC" or 
 * null for no sort)
 */
export const sortDirectionToString = (dir: SortDirection) => {
    switch(dir) {
        case SortDirection.ASC:
            return "ASC"
        case SortDirection.DESC:
            return "DESC"
        case SortDirection.OFF:
        default:
            return null
    }
}
import { NODE_TYPES, VALIDATION_TYPES } from "lib/nodes";
import { Node } from "actions/bot";


export enum CueType {
  MESSAGE,
  OPEN_RESPONSE,
  NUMBER_RESPONSE,
  FILE_UPLOAD,
  MULTIPLE_CHOICE,
  DATE,
  HANDOFF,
  CONTACT,
  DIALOG,
  DISCLAIMER,
  REPEAT,
  ADDRESS,
  UNKNOWN,
  DIALOG_LINK,

}

// Returns correspondign cue type as a string
export const CueTypeString = {
  [CueType.MESSAGE]: "message",
  [CueType.OPEN_RESPONSE]: "open_response",
  [CueType.NUMBER_RESPONSE]: "number_response",
  [CueType.FILE_UPLOAD]: "file_upload",
  [CueType.MULTIPLE_CHOICE]: "option",
  [CueType.DATE]: "date",
  [CueType.HANDOFF]: "handoff",
  [CueType.CONTACT]: "contact",
  [CueType.DIALOG]: "dialog",
  [CueType.DIALOG_LINK]: 'dialog_link',
  [CueType.DISCLAIMER]: "disclaimer",
  [CueType.REPEAT]: "repeat",
  [CueType.ADDRESS]: "address",
  [CueType.UNKNOWN]: "unknown",
};

/**
 * Cue Interface
 * A cue is really just a node but with a
 * more detailed type
 */
export type Cue = {
  id: number;
  type: CueType;
  message: string;
  title: string;
  meta: Record<string, any>;
  validation?: string;
};

/**
 * Pattern Icon Text
 *
 */

export type OperatorDetails = {
  value: string;
  label: string;
  iconText: any;
  initialText: string;
  joinedText: string;
  type: string;
};

export const cueTitle = (type: CueType): string => {
  switch (type) {
    case CueType.MESSAGE:
      return "Message";
    case CueType.OPEN_RESPONSE:
      return "Open Response";
    case CueType.CONTACT:
      return "Contact Info";
    case CueType.NUMBER_RESPONSE:
      return "Number Response";
    case CueType.FILE_UPLOAD:
      return "Upload File";
    case CueType.MULTIPLE_CHOICE:
      return "Multiple Choice";
    case CueType.DATE:
      return "Date Time";
    case CueType.HANDOFF:
      return "Completion Message";
    case CueType.DISCLAIMER:
      return "Disclaimer";
    case CueType.DIALOG:
      return "Dialog";
    case CueType.REPEAT:
      return "Repeat";
    case CueType.ADDRESS:
      return "Address"
  }
  console.warn("Cue Type Unknown.");
  return "Unknown";
};

/*
 * Creates Cue display object from the server-side Node representation
 */
export const nodeToCue = (node: Node): Cue => {
  const { id, title, message, node_type, validation } = node;
  const ensuredTitle: string = title || "";
  const ensuredMessage: string = message || "";

  return {
    id,
    title: ensuredTitle,
    message: ensuredMessage,
    validation: node.validation,
    meta: node.meta,
    ...nodeSpecsToCueType(node_type, validation),
  };
};

export const defaultCueValues = (type: CueType): {} => {
  switch (type) {
    case CueType.CONTACT:
      return {
        title: cueTitle(type),
        validation: "email",
      };
    case CueType.DIALOG:
      return {
        title: cueTitle(type),
      };
  }
  return { title: cueTitle(type) };
};

/*
 * Given a Node's type and validation, returns the corresponding
 * Cue type wrapped in an object
 */
export const nodeSpecsToCueType = (node_type: string, validation?: string): { type: CueType } => {
  switch (node_type) {
    case NODE_TYPES.OPEN:
      if (validation == VALIDATION_TYPES.NUMBER) {
        return { type: CueType.NUMBER_RESPONSE };
      } else {
        return { type: CueType.OPEN_RESPONSE };
      }
    case NODE_TYPES.CONTACT:
      return { type: CueType.CONTACT };
    case NODE_TYPES.PROMPT:
      return { type: CueType.MESSAGE };
    case NODE_TYPES.FILE:
      return { type: CueType.FILE_UPLOAD };
    case NODE_TYPES.OPTION:
      return { type: CueType.MULTIPLE_CHOICE };
    case NODE_TYPES.DATE:
      return { type: CueType.DATE };
    case NODE_TYPES.HANDOFF:
      return { type: CueType.HANDOFF };
    case NODE_TYPES.DIALOG_LINK:
      return { type: CueType.DIALOG };
    case NODE_TYPES.DISCLAIMER:
      return { type: CueType.DISCLAIMER };
    case NODE_TYPES.REPEAT:
      return { type: CueType.REPEAT };
    case NODE_TYPES.ADDRESS:
      return {type: CueType.ADDRESS}
  }
  return { type: CueType.UNKNOWN };
};

/*
 * Given cue type, returns the corresponding
 * Node validation and node_type wrapped in an object
 */
export const cueTypeToNodeSpecs = (type: CueType): { node_type: string; validation?: string } => {
  switch (type) {
    case CueType.MESSAGE:
      return { node_type: NODE_TYPES.PROMPT };
    case CueType.OPEN_RESPONSE:
      return { node_type: NODE_TYPES.OPEN };
    case CueType.CONTACT:
      return {
        node_type: NODE_TYPES.CONTACT,
      };
    case CueType.NUMBER_RESPONSE:
      return {
        node_type: NODE_TYPES.OPEN,
        validation: VALIDATION_TYPES.NUMBER,
      };
    case CueType.FILE_UPLOAD:
      return { node_type: NODE_TYPES.FILE };
    case CueType.MULTIPLE_CHOICE:
      return { node_type: NODE_TYPES.OPTION };
    case CueType.DATE:
      return { node_type: NODE_TYPES.DATE };
    case CueType.HANDOFF:
      return { node_type: NODE_TYPES.HANDOFF };
    case CueType.DIALOG:
      return { node_type: NODE_TYPES.DIALOG_LINK };
    case CueType.DISCLAIMER:
      return { node_type: NODE_TYPES.DISCLAIMER };
    case CueType.REPEAT:
      return { node_type: NODE_TYPES.REPEAT };
    case CueType.ADDRESS:
      return { node_type: NODE_TYPES.ADDRESS };
  }
  console.warn("Cue Type Unknown.");
  return { node_type: NODE_TYPES.PROMPT };
};

export const dateAttributes = {
  TIME: "time",
  RANGE: "range",
};

export const dateOptions = {
  DATE: "date",
  DATETIME: "datetime",
  DATE_RANGE: "date-range",
  DATETIME_RANGE: "datetime-range",
};

export const dateAttributesToOptions = (attributes: string[] = []) => {
  const { TIME, RANGE } = dateAttributes;
  if (attributes.indexOf(TIME) >= 0 && attributes.indexOf(RANGE) >= 0) {
    return dateOptions.DATETIME_RANGE;
  } else if (attributes.indexOf(TIME) >= 0) {
    return dateOptions.DATETIME;
  } else if (attributes.indexOf(RANGE) >= 0) {
    return dateOptions.DATE_RANGE;
  } else {
    return dateOptions.DATE;
  }
};

export const dateOptionsToAttributes = (option: string) => {
  const { TIME, RANGE } = dateAttributes;
  switch (option) {
    case dateOptions.DATE:
      return [];
    case dateOptions.DATETIME:
      return [TIME];
    case dateOptions.DATE_RANGE:
      return [RANGE];
    case dateOptions.DATETIME_RANGE:
      return [TIME, RANGE];
  }
  return [];
};

/**
 * Pattern related operators which are allowed for each node type
 */

export const nodeTypeToAllowedPattern = (type: CueType): { allowed_pattern: Array<OperatorDetails> } => {
  switch (type) {
    case CueType.OPEN_RESPONSE:
      return {
        allowed_pattern: [
          { value: "", label: "Contains", iconText: "Aa", initialText: '"', joinedText: "", type: "text" },
        ],
      };
    case CueType.NUMBER_RESPONSE:
      return {
        allowed_pattern: [
          { value: ">", label: "Above", iconText: "[", initialText: "start from", joinedText: "", type: "number" },
          { value: "<", label: "Below", iconText: "]", initialText: "less then", joinedText: "", type: "number" },
          { value: "=", label: "Equal To", iconText: "=", initialText: "equals to", joinedText: "", type: "number" },
          { value: "[]", label: "Between", iconText: "[]", initialText: "Between", joinedText: "and", type: "number" },
        ],
      };
    case CueType.FILE_UPLOAD:
      return {
        allowed_pattern: [
          {
            value: "submitted",
            label: "Submitted",
            iconText: "Submitted",
            initialText: "Docs Submitted",
            joinedText: "",
            type: "file",
          },
          { value: "skip", label: "Skipped", iconText: "Skipped", initialText: "Skip", joinedText: "", type: "file" },
        ],
      };
    case CueType.DATE:
      return {
        allowed_pattern: [
          { value: ">=", label: "After", iconText: "[", initialText: "start from", joinedText: "", type: "date" },
          { value: "<=", label: "Before", iconText: "]", initialText: "less than", joinedText: "", type: "date" },
          { value: "=", label: "Exactly", iconText: "=", initialText: "equals to", joinedText: "", type: "date" },
          { value: "[]", label: "Between", iconText: "[]", initialText: "Between", joinedText: "and", type: "date" },
        ],
      };
  }
  return {
    allowed_pattern: [
      { value: "[]", label: "contains", iconText: "Aa", initialText: "", joinedText: "", type: "text" },
    ],
  };
};

import * as React from "react";
import App from "app/components/app";
import Nav from "app/components/navbar";
import Container from "app/components/global/container";
import { getStripeCheckoutSession } from "lib/stripe";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StripeError } from "@stripe/stripe-js";
import { getQueryParam } from "lib/utilities/url";
import connect from "app/connect";
import { User } from "reducers/user";
import { axiosErr } from "lib/api";
import { redirectToStripeCheckout } from "lib/utilities/stripe";

type Props = {
  location: Location;
  user: User;
  toggleSnackbar: Function;
} & RouteComponentProps;

const redirectToCheckout = async (
  priceId: string,
  seats: number,
  email: string,
  toggleSnackbar: Function
): Promise<StripeError | null> => {
  try {
    const response = await getStripeCheckoutSession(priceId, seats, email);
    const { sessionId } = response.data;

    const { error } = await redirectToStripeCheckout(sessionId);

    return error;
  } catch (err) {
    const error = axiosErr(err);
    toggleSnackbar(error.error, true, "error");
  }
};

const Checkout: React.FunctionComponent<Props> = ({ location, user, toggleSnackbar }: Props) => {
  const priceId = getQueryParam(location.href, "price_id");
  const seats = getQueryParam(location.href, "seats");

  React.useEffect(() => {
    if (user.email) {
      redirectToCheckout(priceId, seats, user.email, toggleSnackbar);
    }
  }, [user.email]);

  return (
    <App className="public">
      <Nav />
      <Container name="lander">
        <h1>Redirecting to Checkout...</h1>
      </Container>
    </App>
  );
};

export default withRouter(connect()(Checkout));

import * as React from "react";
import { Invite } from "reducers/invites";

// Cmps
import InviteItem from "./inviteItem";

type State = {
    activeInviteItem: String;
}

type Props = {
    invites: Invite[];
    title?: string;
    loading: boolean;
    overflow: boolean;
    fetchMore: Function;
};
/**
 * Invite List
 */

class InviteList extends React.Component<Props, State> {

    static defaultProps = {
        overflow: false
    }

    constructor(props:Props){
        super(props);
        this.state = {
            activeInviteItem: ''
        }
    }

    render() {
        const { overflow, invites , loading } = this.props;

        return (

            <div className="InviteList">
                <h3 className="type-16-nexa InviteList__title">Invites</h3>
                <li className="invite__item user__item--first"> 
                    <div className="invite__item__title">Name</div>
                    <div className="invite__item__email">Email</div>
                    <div className="invite__item__options">Options</div>
                </li>

                <ul ref="userList">
                    {invites.map((invite, i) => {
                        return (<InviteItem 
                            key={i}
                            invite={invite} 
                            isActive={this.state.activeInviteItem === invite.id}
                            makeInviteItemActive={() => this.setState({activeInviteItem: invite.id})}
                        />)
                    })}
                </ul>
            </div>
        );
    }
}

export default InviteList;

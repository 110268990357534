import { 
    fetchClassifiers, 
    factoryCreateClassifier, 
    factoryDeleteClassifier, 
    factoryUpdateClassifier 
} from "lib/classifiers";
import { toggleSnackbar } from "actions/ui";

// Action types
export const FETCH_CLASSIFIERS = 'FETCH_CLASSIFIERS';
export const SET_CLASSIFIERS = 'SET_CLASSIFIERS';

export type Classifier = {
    id: number;
    title: string;
    classifier_users: ClassifierUser[];
    organization_id: number;
    tags: Tag[]
    active?: boolean;
    appointment_type_id?: number;
}

export type ClassifierUser = {
    user_id: number;
    classifier_id: number;
    enabled: boolean;
    notifications: boolean;
    appointment_type_id: number;
    esign: boolean;
}

export type Tag = {
    name: string;
    node_id: number;
    node_connection_id: number;
    id: number;
}

export const getClassifiers = () => dispatch => {
    // Tell reducer we're fetching
    dispatch({
        type: FETCH_CLASSIFIERS
    });

    // Actually fetch classifiers
    fetchClassifiers()
    .then(res => {
        dispatch({
            type: SET_CLASSIFIERS,
            classifiers: res.classifiers,
            page: res.page,
        })
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your classifications! Please try again", true)(dispatch);
    })
}

export const updateClassifier = (params, withRefresh = true) => dispatch => {
    factoryUpdateClassifier(params)
    .then(res => {
        if (withRefresh) getClassifiers()(dispatch)
    })
    .catch(err => {
        toggleSnackbar("We ran into an error updating this classifier!", true)(dispatch);
    })
}

export const createClassifier = (params) => dispatch => {
    factoryCreateClassifier(params)
    .then(res => {
        getClassifiers()(dispatch)
    })
    .catch(err => {
        toggleSnackbar("We ran into an error creating a new classifier!", true)(dispatch);
    })
}

export const deleteClassifier = (classifierId) => dispatch => {
    factoryDeleteClassifier(classifierId)
    .then(res => {
        getClassifiers()(dispatch)
    })
    .catch(err => {
        toggleSnackbar("We ran into an error deleting this classifier!", true)(dispatch);
    })
}

export type ClassifiersAction =
    | {
          type: "FETCH_CLASSIFIERS";
          loading: Boolean
      }
    | {
          type: "SET_CLASSIFIERS";
          classifiers: Classifier[],
          page: number
      };
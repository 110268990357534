import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";

type Props = {} & RouteComponentProps;

const initialState = {
  copySuccess: false,
};

const EmbedCode: React.FC<Props> = ({}: Props) => {
  const [state, setState] = React.useState(initialState);
  let codeEl: HTMLTextAreaElement;

  const copyEmbedCodeToClipboard = (): void => {
    const el = codeEl;
    el.select();
    document.execCommand("copy");
    setState({ copySuccess: true });
  };

  const embedCode = `
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAX-nQG-eqjgDruecE120sG45BWNaxKCwo&libraries=places&callback=Function.prototype"></script><script>!function(){var t=document.createElement("script");t.src="https://d2pq0k5qg2gmsf.cloudfront.net/latest/botchat.js";var e=document.createElement("link");e.setAttribute("rel","stylesheet"),e.setAttribute("type","text/css"),e.setAttribute("href","https://d2pq0k5qg2gmsf.cloudfront.net/latest/botchat.css"),document.getElementsByTagName("head")[0].appendChild(e);var d=document.createElement("div");d.setAttribute("id","bot"),document.body.appendChild(d),t.onload=t=>{BotChat.App({directLine:{secret:"cMapY5eOo1s.Vgr8CiFkLAZrggzP93eCGzkf7x59pPQphXiMcdtkv7o"},gid:"https://api-aws.gideon.legal"},document.getElementById("bot"))},document.getElementsByTagName("head")[0].appendChild(t)}();</script>
  `;

  return (
    <div className="embed__code">
      <div className="embed__code__header">
        <h3 className="type-24-bold">Embed Code</h3>
      </div>
      <div className="embed__code__container">
        <span className="embed__code__description">
          {"Copy and paste this code in the <footer> section of your website:"}
        </span>
        <textarea
          className="embed__code__code"
          ref={(ref): HTMLTextAreaElement => (codeEl = ref)}
          rows={25}
          cols={6}
          readOnly
          value={embedCode}
        ></textarea>
        <button
          className="embed__code__button__copy"
          onClick={(): void => copyEmbedCodeToClipboard()}
          defaultValue="COPY"
        >
          COPY
        </button>
        {state.copySuccess && <span className="embed__code__copied">COPIED!</span>}
      </div>
    </div>
  );
}

export default connect()(EmbedCode);

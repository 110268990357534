import * as React from "react";
import { fetchPluginSources, PluginSource, Plugin } from "actions/plugins";
import connect from "app/connect";
import { RootStore } from "reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Dialog from "app/components/global/dialog";

type Props = {
  fetchPluginSources: Function;
  onAddPluginSource: Function;
  plugin_sources: PluginSource[];
  plugins: Plugin[];
  visible: boolean;
  setVisible: (v: boolean) => void;
} & RouteComponentProps;

/**
 */
const PluginSources: React.FC<Props> = ({
  plugin_sources,
  plugins,
  setVisible,
  visible,
  fetchPluginSources,
  onAddPluginSource,
}: Props) => {
  React.useEffect(() => {
    if (plugin_sources.length === 0) fetchPluginSources();
  }, [plugin_sources]);

  return (
    <Dialog className="plugin_sources" setVisible={setVisible} visible={visible}>
      <h3 className="type-30-bold">Add Plugin</h3>

      <ul className="plugin_sources__list">
        {plugin_sources.map((plugin_source, i) => {
          const installed = plugins.some((p) => p.plugin_source_id === plugin_source.id);

          return (
            <li key={i}>
              <div>
                <span>{plugin_source.title}</span>
                <img src={plugin_source.image} />
              </div>

              <p>{plugin_source.description}</p>

              <button
                className={installed ? "installed" : ""}
                onClick={(): void => !installed && onAddPluginSource(plugin_source)}
              >
                {installed ? "Installed" : "Add"}
              </button>
            </li>
          );
        })}
      </ul>
    </Dialog>
  );
}

export default withRouter(
  connect(
    (state: RootStore) => ({
      plugin_sources: state.plugins.plugin_sources,
      plugins: state.plugins.plugins,
    }),
    { fetchPluginSources }
  )(PluginSources)
);

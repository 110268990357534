import * as React from "react";
// import "sass/components/userListItem";

// Cmps
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "app/components/global/avatar";
import { User } from "reducers/user";

type Props = {
  clickHandler: Function;
  isClicked: boolean;
  secondary?: boolean;
  userData: User;
};

/**
 * Attorney List Item Component
 * Showing the user detail
 */
const UserListItem: React.FC<Props> = ({ clickHandler, isClicked, secondary, userData }: Props) => {
  return (
    <ListItem onClick={(e): void => clickHandler(e)} className={`user_list__item ${isClicked ? "active" : ""}`}>
      <Avatar image={userData.avatar} name={userData.name} />

      <ListItemText
        primary={userData ? userData.name : ""}
        secondary={userData && userData.organization && secondary ? userData.organization.title : ""}
        style={{ paddingLeft: "1rem" }}
      />
    </ListItem>
  );
};

export default UserListItem;

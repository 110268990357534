import { combineReducers } from "redux";
import admin, { AdminStore } from "reducers/admin";
import user, { UserStore } from "reducers/user";
import redirect, { RedirectStore } from "reducers/redirect";
import bots, { BotsStore } from "reducers/bots";
import plugins, { PluginsStore } from "reducers/plugins";
import detailBot, { BotStore } from "reducers/detailBot";
import attorneys, { AttorneyStore } from "reducers/attorneys";
import classifiers, { ClassifiersStore } from "reducers/classifiers";
import appointmentTypes, { AppointmentTypesStore } from "reducers/appointmentTypes";
import leads, { LeadsStore } from "./leads";
import invites, { InviteStore } from "./invites";
import ui from "reducers/ui";
import dashboard, { DashboardStore } from "reducers/dashboard";
import botCustomizations, { BotCustomizationsStore } from "reducers/botCustomizations";
import marketingSources, { MarketingSourcesStore } from "reducers/marketingSources";

export type RootStore = {
  ui: any; // TODO: Type this correctly
  admin: AdminStore;
  user: UserStore;
  redirect: RedirectStore;
  bots: BotsStore;
  detailBot: BotStore;
  plugins: PluginsStore;
  attorneys: AttorneyStore;
  classifiers: ClassifiersStore;
  leads: LeadsStore;
  invites: InviteStore;
  dashboard: DashboardStore;
  botCustomizations: BotCustomizationsStore;
  appointmentTypes: AppointmentTypesStore;
  marketingSources: MarketingSourcesStore
};

/**
 * The root reducer
 */
// @ts-ignore: Our actions are not compatible with Redux's AnyAction
export default combineReducers<RootStore>({
  ui,
  admin,
  user,
  redirect,
  detailBot,
  plugins,
  bots,
  attorneys,
  classifiers,
  leads,
  invites,
  dashboard,
  botCustomizations,
  appointmentTypes,
  marketingSources,
});

import { isLoggedIn } from "./utilities/user";

const axios = require("axios");

let override: string | null = null;
// override = "http://localhost:3000/api/v1";

// Create Axios instance with base paramaters
const instance = axios.create({
  baseURL: override !== null ? override : process.env.REACT_APP_API_URL,
  timeout: 6000,
});

const baseHeadersForRequest = (): { Authorization: string } | null => {
  return {
    Authorization: isLoggedIn() ? `Bearer ${localStorage.getItem("token")}` : null,
  };
};

type Error = {
  status: number;
  error: string;
  code: string;
  stack: any;
};

export const massArbCaseType = process.env.REACT_APP_MASS_ARB_CASE_TYPE_ID
/**
 * Format error from Axios request for us
 * @param err Axios error object
 */
export const axiosErr = (err): Error | null => {
  if (err.response && err.response.data) {
    return err.response.data;
  }

  return null;
};

export const get = async (url: string, params?: Record<string, any> | null) => {
  return instance({
    url,
    method: "GET",
    data: params,
    headers: baseHeadersForRequest(),
  }).then((res) => res.data);
};

export const getBlob = async (url: string, params?: Record<string, any> | null) => {
  return instance({
    url,
    method: "GET",
    data: params,
    headers: baseHeadersForRequest(),
    responseType: 'blob'
  }).then((res) => res.data);
};

export const post = async (url: string, params: Record<string, any>) => {
  return instance({
    url,
    method: "POST",
    data: params,
    headers: baseHeadersForRequest(),
  });
};

export const put = async (url: string, params: Record<string, any>) => {
  return instance({
    url,
    method: "PUT",
    data: params,
    headers: baseHeadersForRequest(),
  });
};

export const deleteReq = async (url: string, params: Record<string, any> | null) => {
  return instance({
    url,
    method: "DELETE",
    data: params,
    headers: baseHeadersForRequest(),
  });
};

const client = {
  get,
  post,
  put,
  deleteReq,
  getBlob,
};

export default client;

import {
  BotAction,
  Bot,
  Dialog,
  Node,
  FETCH_BOT,
  SET_BOT,
  SELECT_NODE,
  SET_NODE,
  ADD_NODE,
  SELECT_DIALOG,
  ADD_DIALOG,
  SET_DIALOG,
  FETCH_DIALOGS,
  SET_DIALOGS,
  DELETE_DIALOG,
  FETCH_LEADDOCKET_FIELDS,
  SET_LEADDOCKET_FIELDS,
  FETCH_LEADDOCKET_CASE_TYPES,
  SET_LEADDOCKET_CASE_TYPES
} from "actions/bot";
import { LeadDocketCaseType, LeadDocketField } from "lib/plugins";

export type BotStore = {
  bot: Bot | null;
  dialogsById?: any;
  loading: boolean;
  selectedNode: Node | null;
  selectedDialogId: string | null;
  leadDocketFieldsPerCaseType: Record<number, LeadDocketField[]>;
  leadDocketFieldsLoading: boolean;
  leadDocketCaseTypes: LeadDocketCaseType[];
  leadDocketCaseTypesLoading: boolean;
};

const initialState = {
  bot: null,
  loading: true,
  dialogsById: undefined,
  selectedNode: null,
  selectedDialogId: null,
  leadDocketFieldsPerCaseType: {},
  leadDocketFieldsLoading: false,
  leadDocketCaseTypes: [],
  leadDocketCaseTypesLoading: false
};

export default function (state: BotStore = initialState, action: BotAction): BotStore {
  switch (action.type) {
    case FETCH_DIALOGS:
    case FETCH_BOT: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_LEADDOCKET_FIELDS: {
      return {
        ...state,
        leadDocketFieldsLoading: action.loading,
      };
    }
    case FETCH_LEADDOCKET_CASE_TYPES: {
      return {
        ...state,
        leadDocketCaseTypesLoading: action.loading,
      }
    }
    case SET_LEADDOCKET_FIELDS: {
      return {
        ...state,
        leadDocketFieldsPerCaseType: {
          ...state.leadDocketFieldsPerCaseType,
          [action.caseTypeId]: action.leaddocketFields,
        }
      };
    }
    case SET_LEADDOCKET_CASE_TYPES: {
      return {
        ...state,
        leadDocketCaseTypes: action.leaddocketCaseTypes
      }
    }
    case SET_BOT: {
      const selectedDialogId = state.bot && state.bot.id !== action.bot.id ? null : state.selectedDialogId;
      return {
        ...state,
        loading: false,
        bot: action.bot,
        dialogsById: convertDialogArrayToHash(action.bot.dialogs),
        selectedDialogId,
      };
    }
    case SET_DIALOGS: {
      return {
        ...state,
        loading: false,
        dialogsById: convertDialogArrayToHash(action.dialogs),
      };
    }

    case DELETE_DIALOG: {
      const { [action.dialogId]: _d, ...newDialogsById } = state.dialogsById;
      return {
        ...state,
        loading: false,
        dialogsById: newDialogsById,
      };
    }

    case SET_NODE: {
      if (!state.dialogsById || !state.dialogsById[action.node.dialog_id]) return state;

      const dialog = state.dialogsById[action.node.dialog_id];

      return {
        ...state,
        dialogsById: {
          ...state.dialogsById,
          [dialog.id]: {
            ...dialog,
            nodesById: {
              ...dialog["nodesById"],
              [action.node.id]: action.node,
            },
          },
        },
      };
    }
    case ADD_NODE: {
      return state;
    }
    case SELECT_NODE: {
      return {
        ...state,
        selectedNode: action.node,
      };
    }
    case SELECT_DIALOG: {
      return {
        ...state,
        selectedDialogId: action.dialogId,
      };
    }
    case ADD_DIALOG || SET_DIALOG: {
      return {
        ...state,
        dialogsById: {
          ...state.dialogsById,
          [action.dialog.id]: action.dialog,
        },
      };
    }
    default: {
      return state;
    }
  }
}

const convertDialogArrayToHash = (dialogs) => {
  // Create map of Dialogs by their ID so we can references easily
  const dialogsById = dialogs.reduce((acc, dialog: Dialog) => {
    // Create map of Nodes by their ID so we can references easily
    const nodesById = dialog.nodes.reduce(
      (node_accumulator, node: Node) => ({
        ...node_accumulator,
        [node.id]: node,
      }),
      {}
    );

    return {
      ...acc,
      [dialog.id]: {
        ...dialog,
        nodesById,
      },
    };
  }, {});

  return dialogsById;
};

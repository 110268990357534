import { 
    Classifier, 
    FETCH_CLASSIFIERS, 
    SET_CLASSIFIERS, 
    ClassifiersAction 
} from "actions/classifiers";

export type ClassifiersStore = {
    classifiers: Classifier[],
    page?: number;
    loading: Boolean
};

const initialState = {
    classifiers: [],
    number: null,
    loading: false
};

export default function(
    state: ClassifiersStore = initialState,
    action: ClassifiersAction,
): ClassifiersStore {
    switch (action.type) {
        case FETCH_CLASSIFIERS: {
            return {
                ...state,
                loading: true,
            };
        }
        case SET_CLASSIFIERS: {
            return {
                ...state,
                loading: false,
                classifiers: action.classifiers,
                page: action.page
            };
        }
        default: {
            return state;
        }
    }
}

import { getPluginSources, getPlugins, createClioMatter as postClioMatter } from "lib/plugins";
import { toggleSnackbar } from "actions/ui";
import { Lead } from "./leads";

export type PluginSource = {
  id: number;
  description: string;
  title: string;
  image: string;
  authorization_url: string;
  identifier: string;
};

export type Plugin = {
  id: number;
  plugin_source_id: number;
  plugin_source: PluginSource;
  token: string;
  organization_id: string;
  refresh_token: string;
  meta: any;
  active: boolean;
};

export type ClioMapping = {
  id: number;
  node_id: number;
  active: boolean;
  clio_field: string;
};

// Action types
export const FETCH_PLUGIN_SOURCES = "FETCH_PLUGIN_SOURCES";
export const SET_PLUGIN_SOURCES = "SET_PLUGIN_SOURCES";
export const FETCH_PLUGINS = "FETCH_PLUGINS";
export const SET_PLUGINS = "SET_PLUGINS";

export const POST_CLIO_MATTER = "POST_CLIO_MATTER";

export const fetchPluginSources = () => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_PLUGIN_SOURCES,
  });

  // Actually fetch bots
  getPluginSources()
    .then((res) => {
      dispatch({
        type: SET_PLUGIN_SOURCES,
        plugin_sources: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch any plugin sources!", true)(dispatch);
    });
};

export const fetchPlugins = () => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_PLUGINS,
  });

  // Actually fetch bots
  getPlugins()
    .then((res) => {
      dispatch({
        type: SET_PLUGINS,
        plugins: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch your plugins!", true)(dispatch);
    });
};

export const createClioMatter = (
  lead: Lead,
  plugin: Plugin,
  createNewMatter: boolean,
  practiceArea: number,
  description: string,
  onSuccess: Function,
  onConflict: Function
) => (dispatch) => {
  dispatch({
    type: POST_CLIO_MATTER,
  });

  postClioMatter({
    lead_id: lead.id,
    plugin_id: plugin.id,
    practice_area: practiceArea,
    description: description,
    create_new_matter: createNewMatter,
  })
    .then((res) => {
      dispatch({
        type: "SET_DETAIL_LEAD",
        lead: res.data,
      });
      onSuccess();
    })
    .catch((err) => {
      if (err.response.status === 409) {
        onConflict();
      } else {
        toggleSnackbar("Unable to Sync Clio Contact and Matter", true)(dispatch);
      }
    });
};

export type PluginActions =
  | {
      type: "FETCH_PLUGIN_SOURCES";
      loading_plugins_sources: boolean;
    }
  | {
      type: "FETCH_PLUGINS";
      loading_plugins: boolean;
    }
  | {
      type: "SET_PLUGIN_SOURCES";
      plugin_sources: PluginSource[];
    }
  | {
      type: "SET_PLUGINS";
      plugins: Plugin[];
    };

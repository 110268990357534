import api from "lib/api";

export type createTagParams = {
    node_connection_id?: Number;
    option_id?: Number;
    name: String;
}

export type updateTagParams = {
    id: Number;
    name: String;
}

export type deleteTagParams = {
    node_connection_id?: Number;
    option_id?: Number;
}

export const fetchSuggestions = (query: string) => {
    return api.get('/tags/search?query=' + query)
}

export const createTag = (params: createTagParams) => {
    return api.post('/tags', params)
}

export const editTag = (params: updateTagParams) => {
    return api.put(`/tags/${params.id}`, params)
}

export const deleteTag = (id:Number, params: deleteTagParams) => {
    return api.deleteReq(`/tags/${id}`, params);
}

import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import Input from "@material-ui/core/Input";
import { createMarkdownDocument, CreateMarkdownDocumentParams } from "lib/markdownDocuments";
import { toggleSnackbar } from "actions/ui";
import Dialog from "app/components/global/dialog";
import { TextField } from "@material-ui/core";

type Props = {
  createMarkdownDocument: Function;
  documentCreated: Function;
  setVisible: Function;
  visible: boolean;
} & RouteComponentProps;

const initialState = {
  bot_id: null,
  title: "",
};

const CreateMarkdownDocument: React.FC<Props> = ({ selectedBot, documentCreated, visible, setVisible }: Props) => {
  const [state, setState] = React.useState<CreateMarkdownDocumentParams>(initialState);

  React.useEffect(() => {
    if (selectedBot && selectedBot.id && state.bot_id !== selectedBot.id) {
      setState({
        ...state,
        bot_id: selectedBot.id,
      });
    }
  }, [selectedBot]);

  const handleTitleChange = (event): void => {
    setState({
      ...state,
      title: event.target.value,
    });
  };

  const createDocument = (): void => {
    createMarkdownDocument(state)
      .then((res) => {
        documentCreated(res.data);
      })
      .catch((err) => {
        toggleSnackbar("We couldn't create this document! Please try again");
      });
  };

  return (
    <Dialog className="create__markdown__document" setVisible={setVisible} visible={visible}>
      <h3 className="type-30-bold">Create Document</h3>
      <div className={`create__markdown__document__title`}>Document Title</div>
      <TextField className="create__markdown__document__input" variant="outlined" onChange={(event): void => handleTitleChange(event)} />
      <button onClick={(): void => createDocument()}>Create Document</button>
    </Dialog>
  );
};

export default connect()(CreateMarkdownDocument);

import * as React from "react";
import { toggleSnackbar } from "actions/ui";
import {
    createTag,
    deleteTag
} from "lib/tags";
import { Tag } from "actions/classifiers";
import TagSvg from "assets/icons/ui/tag.inline.svg";
import { userIsAdmin } from "lib/utilities/global";
import { RouteComponentProps } from "react-router";

// Sass
import "sass/components/tags.scss";
import it from "date-fns/esm/locale/it/index.js";

type Props = {
    tagData: { option_id?: Number, node_connection_id?: Number };
    tags: Tag[];
    className?: string;
    selected: boolean;
    option: any;
    editing: boolean;
    updateTags: (tag: any, label: string, remove: false) => {};
} & RouteComponentProps;

type State = {
    suggestions: Tag[],
    inputTag: string,
    prevInputTag?: string|null
}

/**
 * Tags that appears in below each node connection component
 */
class NodeTags extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            suggestions: [],
            inputTag: '',
            prevInputTag: null
        }
    }

    clearSuggestions = () => {
        this.setState({
            inputTag: '',
            prevInputTag: null,
            suggestions: []
        })
    }

    /**
     * To remove the tags
     */
    removeTag = (tag: Tag) => {
        const { tags, tagData } = this.props
        if(tagData.node_connection_id) {
            tag["node_connection_id"] = tagData.node_connection_id
        }
        if(tagData.option_id) {
            tag['option_id'] = tagData.option_id
        }
                    
        this.props.updateTags(tag, "", true);
        
    }

    /**
     * Checking which key pressed by user and taking the related action
     */
    onKeyUp = (e) => {
        const { tags, tagData } = this.props;
        //const existingTags = [...this.state.currentTags]
        if(e.key === "Enter") {
            this.props.updateTags(tagData, e.target.value, false)
        }
    }

    render() {
        const { tags, editing } = this.props;
        if(editing) {
            
        }
        return (
            <div className="nodeTags">
                <span className={`nodeTags__indicator icon-tag ${userIsAdmin(this.props.user) ? '' : 'disabled'}`}>
                    <TagSvg />
                </span>

                <span className="nodeTags__indicator tag-text">Tags:</span>

                {tags.map((tag, i) => (
                    <span
                        className={`nodeTags__tag ${userIsAdmin(this.props.user) ? '' : 'disabled'}`}
                        key={i}
                        onClick={() => {
                            if(editing)
                                this.removeTag(tag)
                        }}
                    >{tag.name}</span>
                ))}

                {editing && tags.length < 1 && <input
                    type="text"
                    onKeyUp={this.onKeyUp}
                    placeholder="type to add new tag"
                    disabled={!userIsAdmin(this.props.user)}
                />}
            </div>
        )
    }
}

export default NodeTags;
import * as React from 'react'; 
import { User } from 'reducers/user';
import { RouteComponentProps } from 'react-router';
import { RootStore } from 'reducers';
import { updateUser } from 'actions/user';
import connect from "app/connect";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox
} from "@material-ui/core"

type Props = {
  user: User;
  updateUser: Function;
} & RouteComponentProps;

type State = {
  send_email_on_active_classification: boolean;
}

class Notifications extends React.Component<Props, State> {

  state = {
    send_email_on_active_classification: this.props.user.send_email_on_active_classification
  }

  render() {
    const { user, updateUser } = this.props;
    return (
      <div className="notifications">
        <div className="notifications__header">
          <h3 className="type-24-bold">
            Notifications
          </h3>
        </div>
        <div className="notifications__preferences">
          <ul>
            <FormControl component={"fieldset" as "div"}>
            <FormLabel>User Notification Preferences</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.send_email_on_active_classification}
                    onChange={ (e) => {
                      this.setState(
                        { send_email_on_active_classification: e.target.checked },
                        () =>  { 
                          updateUser({
                            id: user.id,
                            send_email_on_active_classification: this.state.send_email_on_active_classification
                          });
                        }
                      );
                    }}
                  />
                }
                label="Send email when active classifications occur"
              />
              </FormGroup>
            </FormControl>
          </ul>
        </div>         
      </div>
    )
  }
}

export default connect((state: RootStore) => ({
  user: state.user
}),
{
  updateUser
})(Notifications);
import * as React from "react";

type Props = {
  children: JSX.Element[] | JSX.Element;
  name: string;
  className: string;
};

class Container extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
  };

  render(): JSX.Element {
    return (
      <div className={`${this.props.name}-container`}>
        <div className={`${this.props.name} ${this.props.className}`}>{this.props.children}</div>
      </div>
    );
  }
}

export default Container;

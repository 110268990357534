import * as React from "react";
import { RouteComponentProps } from "react-router";
import Search from "assets/icons/ui/search.inline.svg";
import HotInput from "../global/hotInput";

const fields = {
    name: {
        title: 'Name',
        placeholder: "Enter name here..."
    },
    classifier : {
        title: 'Classification',
        placeholder: "Enter classification title here..."
    }
    
}

type State = {
    selected: string;
    visible: boolean;
}

type Props = {
    updateFilter: Function;
}

/**
 * Lead Search Bar
 */
class LeadSearchBar extends React.Component<Props, State> {

    state = {
        selected: 'name',
        visible: false
    }

    onChange = (inputValue) => {
        this.props.updateFilter(this.state.selected, inputValue);
    }

    render() {
        const { selected, visible } = this.state;

        return (
            <div className="leadSearchBar">
                <div
                    className="leadSearchBar__dropdown"
                    onClick={() => this.setState({ visible: !visible})}
                >
                    <span
                        className="leadSearchBar__dropdown__selection"
                    >{fields[this.state.selected]['title']}</span>

                    <ul className={`leadSearchBar__dropdown ${visible && 'visible'}`}>
                        {Object.keys(fields).filter(f => f !== selected).map((field, i) => (
                            <li
                                key={i}
                                onClick={() => this.setState({ selected: field})}
                            >{fields[field]['title']}</li>
                        ))}
                    </ul>
                </div>

                <HotInput
                    placeholder="Search"
                    initialValue=""
                    className="leadSearchBar__input"
                    type="text"
                    onChange={this.onChange}
                />
                <Search />
            </div>
        );
    }
}

export default LeadSearchBar;
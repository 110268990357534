import * as React from "react";
import { Route, Redirect, Link, Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import { PLUGINS } from "app/router";

// Cmps
import Billing from "app/components/settings/billing";
import Plugins from "app/components/settings/plugins";
import BotCustomization from "app/components/settings/bot_customization";
import Users from "app/components/settings/users";
import EmbedCode from "app/components/settings/embed_code";
import MarketingSources from "app/components/settings/marketing_sources";
import Layout from "app/components/global/layout";
import MarkdownDocuments from "app/components/settings/markdown_documents";

// Sass
import "sass/pages/settings.scss";
import Notifications from "app/components/settings/notifications";

type Props = {} & RouteComponentProps;

const Settings: React.FC = ({ match, user }: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState("");
  const redirectTo = selectedTab.length === 0 ? PLUGINS : selectedTab;
  const authUserIsAdmin = user.role && (user.role === "admin" || user.role === "superadmin");
  const authUserOrgHasStripeAcct = user.organization?.has_stripe_account;
  const documentGenerationEnabled =
    user.organization?.document_generation_enabled && user.role && (user.role === "superadmin" || user.role === "admin");

  if (window.location.pathname === "/settings") {
    return <Redirect to={match.url + redirectTo} />;
  }

  return (
    <Layout appClassName="platform" desktopOnly mobileTitle="Settings">
      <div className="settings">
        <div className="settings__menu">
          <h3 className="settings__menu__header type-24-bold-nexa">Settings</h3>
          <ul className="settings__menu__list">
            <ListLinkItem text="Plug-Ins" link={[match.url + PLUGINS]} setSelectedTab={() => setSelectedTab(PLUGINS)} />
            <ListLinkItem
              text="Bot Customization"
              link={[match.url + "/bot_customization"]}
              setSelectedTab={() => setSelectedTab("/bot_customization")}
            />
            {authUserIsAdmin && (
              <ListLinkItem
                text="Users"
                link={[match.url + "/users"]}
                setSelectedTab={() => setSelectedTab("/users")}
              />
            )}
            <ListLinkItem
              text="Notifications"
              link={[match.url + "/notifications"]}
              setSelectedTab={() => setSelectedTab("/notifications")}
            />
            <ListLinkItem
              text="Embed Code"
              link={[match.url + "/embed_code"]}
              setSelectedTab={() => setSelectedTab("/embed_code")}
            />
            {documentGenerationEnabled && (
              <ListLinkItem
                text="Markdown Documents"
                link={[match.url + "/markdown_documents"]}
                setSelectedTab={() => setSelectedTab("/markdown_documents")}
              />
            )}
            <ListLinkItem
              text="Marketing Sources"
              link={[match.url + "/marketing_sources"]}
              setSelectedTab={() => setSelectedTab("/marketing_sources")}
            />
            {authUserIsAdmin && authUserOrgHasStripeAcct && (
              <ListLinkItem
                text="Billing"
                link={[match.url + "/billing"]}
                setSelectedTab={(): void => setSelectedTab("/billing")}
              />
            )}
          </ul>
        </div>
        <div className="settings__panel">
          <Switch>
            <Route path={match.url + "/bot_customization"} exact component={BotCustomization} />
            <Route path={match.url + "/users"} exact component={Users} />

            <Route path={match.url + "/plugins"} exact component={Plugins} />

            <Route path={match.url + "/plugins/:pluginSourceId"} component={Plugins} />
            <Route path={match.url + "/notifications"} exact component={Notifications} />
            <Route path={match.url + "/embed_code"} exact component={EmbedCode} />
            <Route path={match.url + "/marketing_sources"} exact component={MarketingSources} />
            {documentGenerationEnabled && (
              <Route path={match.url + "/markdown_documents"} exact component={MarkdownDocuments} />
            )}
            <Route path={match.url + "/billing"} exact component={Billing} />
          </Switch>
        </div>
      </div>
    </Layout>
  );
};

type ListLinkItemProps = {
  text: string;
  link: string[];
  setSelectedTab: Function;
};

const ListLinkItem = ({ text, link, setSelectedTab }: ListLinkItemProps): JSX.Element => {
  const isCurrent = link.some((l) => window.location.pathname.indexOf(l) > -1);

  return (
    <Link to={link[0]}>
      <li className={isCurrent ? "current" : ""} onClick={() => setSelectedTab()}>
        <span>{text}</span>
      </li>
    </Link>
  );
};

export default connect()(Settings);

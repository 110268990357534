import {
  MarketingSource,
  Campaign,
  LeadDocketMarketingSource,
  FETCH_MARKETING_SOURCES,
  SET_MARKETING_SOURCES,
  FETCH_CAMPAINGS,
  SET_CAMPAIGNS,
  FETCH_LEADDOCKET_MARKETING_SOURCES,
  SET_LEADDOCKET_MARKETING_SOURCES,
  FETCH_LITIFY_MARKETING_SOURCES,
  SET_LITIFY_MARKETING_SOURCES,
  MarketingSourcesActions,
  LitifyMarketingSource,
} from "actions/marketingSources";

export type MarketingSourcesStore = {
  marketing_sources: MarketingSource[];
  campaigns: Campaign[];
  leaddocket_marketing_sources: LeadDocketMarketingSource[];
  litify_marketing_sources: LitifyMarketingSource[];
  loading_campaigns: boolean;
  loading_marketing_sources: boolean;
  loading_leaddocket_marketing_sources: boolean;
  loading_litify_marketing_sources: boolean;
};

const initialState = {
  marketing_sources: [],
  campaigns: [],
  leaddocket_marketing_sources: [],
  litify_marketing_sources: [],
  loading_marketing_sources: false,
  loading_campaigns: false,
  loading_leaddocket_marketing_sources: false,
  loading_litify_marketing_sources: false,
};

export default function (state: MarketingSourcesStore = initialState, action: MarketingSourcesActions): MarketingSourcesStore {
  switch (action.type) {
    case FETCH_MARKETING_SOURCES: {
      return {
        ...state,
        loading_marketing_sources: true,
      };
    }
    case FETCH_CAMPAINGS: {
      return {
        ...state,
        loading_campaigns: true,
      };
    }
    case SET_MARKETING_SOURCES: {
      return {
        ...state,
        loading_marketing_sources: false,
        marketing_sources: action.marketing_sources,
      };
    }
    case SET_CAMPAIGNS: {
      return {
        ...state,
        loading_campaigns: false,
        campaigns: action.campaigns,
      };
    }
    case FETCH_LEADDOCKET_MARKETING_SOURCES: {
      return {
        ...state,
        loading_leaddocket_marketing_sources: true,
      };
    }
    case SET_LEADDOCKET_MARKETING_SOURCES: {
      return {
        ...state,
        loading_leaddocket_marketing_sources: false,
        leaddocket_marketing_sources: action.leaddocket_marketing_sources,
      };
    }
    case SET_LITIFY_MARKETING_SOURCES: {
      return {
        ...state,
        loading_litify_marketing_sources: false,
        litify_marketing_sources: action.litify_marketing_sources,
      }
    }
    case FETCH_LITIFY_MARKETING_SOURCES: {
      return {
        ...state,
        loading_leaddocket_marketing_sources: true,
      }
    }
    default: {
      return state;
    }
  }
}

import * as React from "react"

type Props = {
    saveChanges: React.MouseEventHandler;
    discardChanges: React.MouseEventHandler;
}

class DialogNodeFooter extends React.Component<Props, {}> {
    render() {
        return (
            <div className="node__footer" style={{display: "flex", justifyContent: "right"}}>
                <button className="discard__button" onClick={this.props.discardChanges}>Discard</button>
                <button className="save__button" onClick={this.props.saveChanges}>Save</button>
            </div>
        )
    }
}

export default DialogNodeFooter
import { getMarketingSources, getCampaigns, getLeadDocketMarketingSources, getLitifyMarketingSources } from "lib/marketingSources";
import { toggleSnackbar } from "actions/ui";

export type MarketingSource = {
  id: number;
  name: string;
  utm_source: string;
  meta: any;
  organization_id: number;
  marketing_campaigns: Campaign[];
};

export type Campaign = {
  id: number;
  name: string;
  utm_campaign: string;
  meta: any;
  marketing_source_id: number;
  created_at: string;
  updated_at: string;
};

export type LeadDocketMarketingSource = {
  Id: number;
  SourceName: string;
  IsContactSource: boolean;
  IsMarketingSource: boolean;
};

export type LitifyMarketingSource = {
  Name: string;
}

// Action types
export const FETCH_MARKETING_SOURCES = "FETCH_MARKETING_SOURCES";
export const SET_MARKETING_SOURCES = "SET_MARKETING_SOURCES";
export const FETCH_CAMPAINGS = "FETCH_CAMPAINGS";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const FETCH_LEADDOCKET_MARKETING_SOURCES = "FETCH_LEADDOCKET_MARKETING_SOURCES";
export const SET_LEADDOCKET_MARKETING_SOURCES = "SET_LEADDOCKET_MARKETING_SOURCES";
export const FETCH_LITIFY_MARKETING_SOURCES = "FETCH_LITIFY_MARKETING_SOURCES"
export const SET_LITIFY_MARKETING_SOURCES = "SET_LITIFY_MARKETING_SOURCES"

export const fetchMarketingSources = () => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_MARKETING_SOURCES,
  });

  // Actually fetch marketing sources
  getMarketingSources()
    .then((res) => {
      dispatch({
        type: SET_MARKETING_SOURCES,
        marketing_sources: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch any marketing sources!", true)(dispatch);
    });
};

export const fetchCampaigns = (marketing_source_id:number = 1) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_CAMPAINGS,
  });

  // Actually fetch campaigns
  getCampaigns(marketing_source_id)
    .then((res) => {
      dispatch({
        type: SET_CAMPAIGNS,
        campaigns: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch your campaigns!", true)(dispatch);
    });
};

export const fetchLeadDocketMarketingSources = () => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_LEADDOCKET_MARKETING_SOURCES,
  });

  // Actually fetch marketing sources
  getLeadDocketMarketingSources()
    .then((res) => {
      dispatch({
        type: SET_LEADDOCKET_MARKETING_SOURCES,
        leaddocket_marketing_sources: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_LEADDOCKET_MARKETING_SOURCES,
        leaddocket_marketing_sources: [],
      });
      toggleSnackbar("We couldn't fetch any leaddocket marketing sources!", true)(dispatch);
    });
};

export const fetchLitifyMarketingSources = () => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_LITIFY_MARKETING_SOURCES,
  });

  //actually fetch litify sources
  getLitifyMarketingSources()
    .then((res) => {
      dispatch({
        type: SET_LITIFY_MARKETING_SOURCES,
        litify_marketing_sources: res,
      })
    })
    .catch((err) => {
      dispatch({
        type: SET_LITIFY_MARKETING_SOURCES,
        litify_marketing_sources: [],
      });
      toggleSnackbar("We couldn't fetch any litify marketing sources!", true)(dispatch);
    })
}

export type MarketingSourcesActions =
  | {
      type: "FETCH_MARKETING_SOURCES";
      loading_marketing_sources: boolean;
    }
  | {
      type: "FETCH_CAMPAINGS";
      loading_campaigns: boolean;
    }
  | {
      type: "SET_MARKETING_SOURCES";
      marketing_sources: MarketingSource[];
    }
  | {
      type: "SET_CAMPAIGNS";
      campaigns: Campaign[];
    }
  | {
      type: "FETCH_LEADDOCKET_MARKETING_SOURCES";
      loading_leaddocket_marketing_sources: boolean;
    }
  | {
      type: "SET_LEADDOCKET_MARKETING_SOURCES";
      leaddocket_marketing_sources: LeadDocketMarketingSource[];
    }
  | {
      type: "FETCH_LITIFY_MARKETING_SOURCES";
      litify_marketing_sources: boolean;
    }
  | {
      type: "SET_LITIFY_MARKETING_SOURCES";
      litify_marketing_sources: LitifyMarketingSource[];
    };

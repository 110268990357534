import api from "lib/api";

export type CreateMarkdownDocumentParams = {
  title: string;
  active?: boolean;
  bot_id?: number;
  document_url?: string;
};

export type TemplateDocassembleParams = {
  base64: string;
  doc_name: string;
  bot_name: string;
}

export type UpdateMarkdownDocumentParams = {
  id: number;
  title?: string;
  text?: string;
  bot_id?: number;
  active?: boolean;
  document_url?: string;
  conditional_variables?: string[];
};

export type GenerateMarkdownDocumentsParams = {
  lead_id: number;
};

export const createMarkdownDocument = (params: CreateMarkdownDocumentParams) => {
  return api.post("/markdown_documents", params);
};

export const updateMarkdownDocument = (params: UpdateMarkdownDocumentParams) => {
  return api.put(`/markdown_documents/${params.id}`, params);
};

export const fetchMarkdownDocuments = () => {
  return api.get("/markdown_documents", null);
};

export const deleteMarkdownDocument = (id:number) => {
  return api.deleteReq(`/markdown_documents/${id}`, null);
};

export const previewDocuments = (params: GenerateMarkdownDocumentsParams) => {
  return api.get(`/markdown_documents/preview_documents?lead_id=${params.lead_id}`, params);
};

export const generateDocuments = (params: GenerateMarkdownDocumentsParams) => {
  return api.get(`/markdown_documents/generate_documents?lead_id=${params.lead_id}`, params);
};

export const getPresignedUrlForMarkdownDocument = (content_type: string) => {
  return api.put(`/markdown_documents/presigned_s3_url_for_markdown_document`, { content_type });
};
import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import Dialog from "app/components/global/dialog";

import "sass/components/github-markdown.scss";

type Props = {
  body: string;
  visible: boolean;
  setVisible: Function;
} & RouteComponentProps;

const PreviewMarkdownDocument: React.FC<Props> = ({ body, visible, setVisible }: Props) => {
  return (
    <Dialog className="preview__markdown__document" visible={visible} setVisible={setVisible}>
      <div className="markdown-body" dangerouslySetInnerHTML={{ __html: body }}></div>
    </Dialog>
  );
};

export default connect()(PreviewMarkdownDocument);

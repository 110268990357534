import api from "lib/api"

export type createAppointmentTypeParams = {
  title: string;
  description: string;
  duration: string;
  buffer: number;
}

export type updateAppointmentTypeParams = {
  id: number;
  title?: string;
  description?: string;
  duration?: string;
  buffer?: number;
  email_customizations?: JSON;
}

export const createAppointmentType = (params: createAppointmentTypeParams) => {
  return api.post('/appointment_types', params);
}

export const updateAppointmentType = (params: updateAppointmentTypeParams) => {
  return api.put(`/appointment_types/${params.id}`, params)
}

export const fetchAppointmentTypes = () => {
  return api.get('/appointment_types', null);
}

export const deleteAppointmentType = (id:Number) => {
  return api.deleteReq(`/appointment_types/${id}`, null);
}

import * as React from "react";
import connect from "app/connect";
import { BotStore } from "reducers/detailBot";
import { RootStore } from "reducers";
import { selectedDialogId } from "actions/bot";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { Dialog } from "actions/bot";


type Props = {
    detailBot: BotStore;
    onClickHandler?: Function;
    dialogId: Function;
    className?: string;
} & RouteComponentProps;

type State = {

}

/** Showing the listing of the dialogs in navigator */

class DialogList extends React.Component<Props, State> {
    render() {
        const {
            className,
            detailBot,
            match,
            selectedDialogId
        } = this.props;
        
        const activeDialogId = detailBot.selectedDialogId ? detailBot.selectedDialogId : detailBot.bot.initial_dialog_id;

        return (
            <ul className={`dialogs`}>
                {detailBot.bot && (
                    detailBot.bot.dialogs.map((dialog: Dialog, index) => (
                        <li
                            key={dialog.id}
                            className={`${className} ${parseInt(activeDialogId) === dialog.id ? 'active' : ''}`}
                            onClick={() => selectedDialogId(dialog.id)}
                        >
                            <Link to={`${match.url}/dialog/${dialog.id}`}>
                                {dialog.title}
                            </Link>
                        </li>
                    ))
                )}
            </ul>
        )
    }
}

export default withRouter(connect(
    (state: RootStore) => ({ detailBot: state.detailBot }),
    { selectedDialogId }
)(DialogList));


import { 
    BotDisplayOptions,
    BotCustomizationsAction,
    FETCH_BOT_CUSTOMIZATIONS,
    SET_BOT_CUSTOMIZATIONS
} from "actions/botCustomizations";

export type BotCustomizationsStore = {
    loading: Boolean;
    botDisplayOptions?: BotDisplayOptions;
};

const initialState = {
    loading: false,
    botDisplayOptions: {}
};

export default function(
    state: BotCustomizationsStore = initialState,
    action: BotCustomizationsAction,
): BotCustomizationsStore {
    switch (action.type) {
        case FETCH_BOT_CUSTOMIZATIONS: {
            return {
                ...state,
                loading: true,
            };
        }
        case SET_BOT_CUSTOMIZATIONS: {
            return {
                ...state,
                loading: false,
                botDisplayOptions: action.displayOptions,
            };
        }
        default: {
            return state;
        }
    }
}
import api from "lib/api";


export const fetchme = () => {
    return api.get('/users/me', null)
}

export const fetchAttorneys = () => {
    return api.get('/organizations/users', null)
}



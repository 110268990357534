import { RootStore } from "reducers";

export const hasLeadDocketConfigured = (state: RootStore) =>
  state.detailBot?.bot?.leaddocket_sync_enabled && state.detailBot?.bot?.leaddocket_case_type_id != null;

export const isLDMulti = (state: RootStore) =>
  state.detailBot?.bot?.leaddocket_case_type_id == 0

export const leadDocketCaseTypeId = (state: RootStore) =>
  state.detailBot?.bot?.leaddocket_sync_enabled ? state.detailBot?.bot?.leaddocket_case_type_id : null;

export const leadDocketFieldsPerCaseType = (state: RootStore) => state.detailBot?.leadDocketFieldsPerCaseType ?? {};
export const leadDocketFieldsLoading = (state: RootStore) => state.detailBot?.leadDocketFieldsLoading;
export const leadDocketCaseTypes = (state: RootStore) => state.detailBot?.leadDocketCaseTypes ?? {};
export const leadDocketCaseTypesLoading = (state: RootStore) => state.detailBot?.leadDocketCaseTypesLoading;

/**
 * Wrapper for the 3 views of the right panel in the lead profile page
 * Exists to ensure uniformity in styling and structure
 */

import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import * as React from "react";

type Props = {
    title: string;  // title in the header
    rightHeader: any;
    body: any;
    backFunction?: React.MouseEventHandler; // run when the back arrow is clicked (if not provided, arrow won't display)
    footerIcon?: any;
    footerAction?: React.MouseEventHandler; // run when the footer is clicked (if not provided, footer won't display)
    footerTitle?: string;
    onScroll?: Function;
}

class ConversationsWrapper extends React.Component<Props, {}> {

    render() {
        return (
            <div className="lead__conversations">
                <div className="lead__conversations__header">
                    <div className="lead__conversations__header__left">
                        {this.props.backFunction && 
                            <IconButton onClick={this.props.backFunction} style={{ padding: 0, color: "black", marginRight: "10px" }}>
                                <ArrowBack />
                            </IconButton>
                        }
                        <div className="lead__conversations__header__title">{this.props.title}</div>
                    </div>

                    <div className="lead__conversations__header__right">
                        {this.props.rightHeader}
                    </div>
                    
                </div>

                <div className="lead__conversations__body" onScroll={(e) => this.props.onScroll ? this.props.onScroll(e) : null}>
                    {this.props.body}
                </div>

                {this.props.footerAction && 
                    <div className="lead__conversations__footer" onClick={this.props.footerAction}>
                        <div className="lead__conversations__footer__icon">{this.props.footerIcon}</div>
                        <div className="lead__conversations__footer__title">{this.props.footerTitle}</div>
                    </div>
                }
            </div>
        );
    }
}

export default ConversationsWrapper;

import React, { useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import Dialog from "app/components/global/dialog";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import "sass/components/clioGrow.scss";
import { customSelectStyles } from "lib/utilities/ui";
import { RootStore } from "reducers";
import connect from "app/connect";
import { fetchMarketingSources, fetchLeadDocketMarketingSources, fetchLitifyMarketingSources, MarketingSource, LeadDocketMarketingSource, LitifyMarketingSource } from "actions/marketingSources";
import {
  createMarketingSource,
  createCampaign,
  updateMarketingSource,
  updateCampaign
} from "lib/marketingSources";
import { Campaign } from "actions/leads";
import { hasLeaddocketPlugin, hasLitifyPlugin } from "selectors/plugins";

const LitifySources = [
  
]

export interface NewSourceInput {
  name: string;
  utm_source: string;
}

export interface UpdateSourceInput {
  id: number;
  name: string;
  utm_source: string;
}

export interface NewCampaignInput {
  marketing_source_id: number;
  name: string;
  utm_campaign: string;
  meta: any;
}

export interface UpdateCampaignInput {
  id: number;
  name: string;
  utm_campaign: string;
  meta: any;
}

export interface LeadDocket {
  label: string;
  value: string;
}

export interface Litify {
  label: string;
  value: string;
}

type Props = {
  setVisible: Function;
  visible: boolean;
  isMarketingSource: boolean;
  marketingSourceId: string;
  fetchMarketingSources: Function;
  fetchLeadDocketMarketingSources: Function;
  fetchLitifyMarketingSources: Function;
  marketingCampaign?: Campaign | undefined;
  marketingSource?: MarketingSource | undefined;
  hasLeaddocketPlugin?: boolean;
  hasLitifyPlugin?: boolean;
  leadDocketMarketingSources?: LeadDocketMarketingSource[];
  litifyMarketingSources?: LitifyMarketingSource[];
} & RouteComponentProps;

function MarketingSourceModal(props: Props) {

  const { visible, setVisible, isMarketingSource, marketingSourceId, marketingCampaign, marketingSource, fetchMarketingSources, hasLeaddocketPlugin, leadDocketMarketingSources, hasLitifyPlugin, litifyMarketingSources, toggleSnackbar } = props;

  const [name, setName] = React.useState('');
  const [utm, setUTM] = React.useState('');
  const [sourceId, setSourceId] = React.useState('');

  const [nameError, setNameError] = React.useState(false);
  const [utmError, setUtmError] = React.useState(false);

  const [editable, setEditable] = React.useState(false);

  const [leadDockOptions, setLeadDockOptions] = React.useState<Array<LeadDocket>>([]);
  const [leadDocket, setLeadDocket] = React.useState<LeadDocket>(null);

  const [litifyOptions, setLitifyOptions] = React.useState<Array<Litify>>([])
  const [litify, setLitify] = React.useState<Litify>(null);

  const isEdit = !!marketingCampaign || !!marketingSource;

  useEffect(() => {
    setEditable(isEdit)
    setName('');
    setUTM('');
    setLeadDocket(null);
    if (isEdit && marketingSource) { 
      setName(marketingSource?.name ?? '');
      setUTM(marketingSource?.utm_source ?? '');
    }
    if (isEdit && marketingCampaign) {
      setName(marketingCampaign?.name ?? '');
      setUTM(marketingCampaign?.utm_campaign ?? '');
    }
  }, [marketingSource, marketingCampaign, isEdit]);

  useEffect(() => {  
    const leadDockOptions: LeadDocket[] = leadDocketMarketingSources.map((leadDocket: LeadDocketMarketingSource) => (
      { value: leadDocket.SourceName, label: leadDocket.SourceName }
    ));
    const litifyOptions: Litify[] = litifyMarketingSources.map((litify: LitifyMarketingSource) => (
      { value: litify.Name, label: litify.Name }
    ))
    setLeadDockOptions(leadDockOptions);
    setLitifyOptions(litifyOptions);
    if (marketingCampaign?.meta) {
      const { leaddocket_marketing_source_id, litify_marketing_source } = marketingCampaign.meta
      //const { litify_marketing_source } = 
    
      if (leaddocket_marketing_source_id) {
        let selectDocketSource: LeadDocketMarketingSource = leadDocketMarketingSources.find((docket: LeadDocketMarketingSource) => docket.Id === leaddocket_marketing_source_id )
        
        let leaddocket = leadDockOptions.find(option => option.label === selectDocketSource.SourceName)
        setLeadDocket(leaddocket)
      }  
      if (litify_marketing_source) {
        let selectedLitifySource: LitifyMarketingSource = litifyMarketingSources.find((lit: LitifyMarketingSource) => lit.Name === litify_marketing_source)

        let lit = litifyOptions.find(option => option.label === selectedLitifySource.Name)
        setLitify(lit);
      }
    }
  }, [leadDocketMarketingSources, litifyMarketingSources, marketingCampaign]);

  const handleSubmit = useCallback(() => {
    if(name === "") {
      setNameError(true)
      return
    }
    if(utm ==="") {
      setUtmError(true)
      return
    }

    if (isEdit) {
      if (isMarketingSource) {
        const payload: UpdateSourceInput = { name, utm_source: utm , id: marketingSource?.id};
        updateMarketingSource(payload)
          .then(() => {
            fetchMarketingSources();
            clearFields();
            setVisible(false)
          })
          .catch(() => toggleSnackbar("We ran into an issue updating this Marketing Source! Please try again later."));
      } else {
        let leaddocket;
        let lit;
        if(leadDocket !== null) leaddocket = leadDocketMarketingSources.find((docket) => docket.SourceName === leadDocket.value );
        if(litify !== null) lit = litifyMarketingSources.find((source) => source.Name === litify.value)
        //console.log(litify)
        const meta = {
          leaddocket_marketing_source_id: leaddocket ? leaddocket.Id : null,
          litify_marketing_source: lit ? lit.Name : null
        }
        //console.log(meta)
        const payload: UpdateCampaignInput = { name, utm_campaign: utm, id: marketingCampaign?.id, meta };
        updateCampaign(payload)
          .then(() => {
            fetchMarketingSources();
            clearFields();
            setVisible(false)
          })
          .catch(() => toggleSnackbar("We ran into an issue updating this Campaign! Please try again later."));
      }
    } else {
      if (isMarketingSource) {
        const payload: NewSourceInput = { name, utm_source: utm };
        createMarketingSource(payload)
          .then(() => {
            fetchMarketingSources();
            clearFields();
            setVisible(false)
          })
          .catch(() => toggleSnackbar("We ran into an issue creating this Marketing Source! Please try again later."));
      } else {
        let leaddocket;
        let lit;
        if(leadDocket !== null) leaddocket = leadDocketMarketingSources.find((docket) => docket.SourceName === leadDocket.value );
        if(litify !== null) lit = litifyMarketingSources.find((source) => source.Name === litify.value)
        const meta = {
          leaddocket_marketing_source_id: leaddocket ? leaddocket.Id : null,
          litify_marketing_source: lit ? lit.Name : null
        }
        const payload: NewCampaignInput = { name, utm_campaign: utm, marketing_source_id: parseInt(marketingSourceId), meta};
        createCampaign(payload)
          .then(() => {
            fetchMarketingSources();
            clearFields();
            setVisible(false)
          })
          .catch(() => toggleSnackbar("We ran into an issue creating this Campaign! Please try again later."));
      }
    }
  },[leadDocket, leadDocketMarketingSources, litify, litifyMarketingSources, isMarketingSource, isEdit, name, utm, marketingSource, marketingCampaign]);

  const clearFields = () => {
    setName('');
    setUTM('');
    setLeadDocket(null);
    setSourceId('');
    setNameError(false)
    setUtmError(false)
    setEditable(false)
  }


  return (
    <Dialog className="leaddocket" setVisible={setVisible} visible={visible}>
      <h3 className="type-24-bold">{isMarketingSource ? editable ? "Update Marketing Source" : "Add New Marketing Source" : editable ? "Update Campaign" : "Add New Campaign"}</h3>
      <div className="clio__grow__settings__help">
        <div className="marketing__module">
          <div className={`marketing__module__title`}>Name</div>
          <TextField
            required
            type="text"
            variant="outlined"
            error={nameError}
            value={name}
            helperText={nameError ? "This field is required" : ""}
            onChange={(e): void => {setName(e.target.value), setNameError(false)}}
            style={{ width: "100%" }}
          />
          <br /><br />
          <div className={`marketing__module__title`}>{isMarketingSource ? "UTM_Source" : "UTM_Campaign"}</div>
          <TextField
            required
            type="text"
            variant="outlined"
            error={utmError}
            value={utm}
            helperText={utmError ? "This field is required" : ""}
            onChange={(e): void => {setUTM(e.target.value), setUtmError(false)}}
            style={{ width: "100%" }}
          />
          <br /><br />
          {!isMarketingSource ?
            <>
              <div className={`marketing__module__title`}>Lead Docket Marketing Source</div>
              <Select<LeadDocket>
                className="marketing__module__select"
                isDisabled={!hasLeaddocketPlugin}
                value={leadDocket}
                onChange={setLeadDocket}
                styles={customSelectStyles}
                options={leadDockOptions}
              />
              <div className={`marketing__module__title`}>Litify Marketing Source</div>
              <Select<Litify>
                className="marketing__module__select"
                isDisabled={!hasLitifyPlugin}
                value={litify}
                onChange={setLitify}
                styles={customSelectStyles}
                options={litifyOptions}
              />
              <></>
              <div style={{display:"flex"}}>
                <button onClick={(): void => setVisible(false)} style={{width:"250px", backgroundColor:"#CBD7D8"}}>Cancel</button>
                <button onClick={(): void => handleSubmit()} style={{width:"250px"}}>Save</button>
              </div>
            </> :
              <button onClick={(): void => handleSubmit()} style={{margin: "1% 15%"}} >Save</button>
            }
        </div>
      </div>
    </Dialog>
  );
};

export default connect( (state: RootStore) => ({
  leadDocketMarketingSources: state.marketingSources.leaddocket_marketing_sources,
  litifyMarketingSources: state.marketingSources.litify_marketing_sources,
  hasLeaddocketPlugin: hasLeaddocketPlugin(state),
  hasLitifyPlugin: hasLitifyPlugin(state),
}), { fetchMarketingSources, fetchLeadDocketMarketingSources, fetchLitifyMarketingSources } )(MarketingSourceModal);

import * as React from "react";
import connect from "app/connect";
import { Node, fetchNSetNode } from "actions/bot";
import { RootStore } from "reducers";
import TextareaAutosize from "react-autosize-textarea";
import { withRouter, RouteComponentProps } from "react-router";
import { updateOption, createOption, deleteOption, updateOptionParams } from "lib/nodes";
import { Tag } from "actions/classifiers";
import NodeTags from "app/components/botDetail/nodeTags";
import Plus from "assets/icons/ui/plus.inline.svg";
import X from "assets/icons/ui/x.inline.svg";
import { userIsAdmin } from "lib/utilities/global";

// Sass
import "sass/components/dialogNodeOption.scss";

// Cmps
type State = {
  label: string;
};

type Props = {
  isNew: boolean;
  index?: number;
  isActive: boolean;
  node: Node;
  option?: { id: number; label: string; tag?: Tag | null };
  addFollowUp: () => {};
  makeOptionActive: () => {};
  textInput: Function;
  isFollowUpNode?: boolean;
  selected: boolean;
  numberOptions?: number;
  isDisclaimer?: boolean;
  editing: boolean;
  updateTags: (option_id: number, label: string) => {};
  updateOptions: (option: any, add: boolean, remove: boolean, index: number) => {};
  tagData: { option_id?: Number, node_connection_id?: Number };
  tags: Tag[];
} & RouteComponentProps;

/**
 * Sample cmp.
 */
class DialogNodeOption extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { option } = props;

    this.state = {
      label: option && option.label ? option.label : "",
    };
  }

  textInput: any;

  onChange = ({ target }) => {
    const { isNew } = this.props;
    this.setState({ label: target.value });
    let index = this.props.index - 1
    if(!isNew) {
      this.props.updateOptions(target.value, false, false, index)
    }
  };

  /**
   * Create Option
   */
  createOption = (label: string) => {
    const { detailBot } = this.props;
    
    return createOption({ nodeId: detailBot.selectedNode.id, label })
      .then((res) => {
        this.props.fetchNSetNode(detailBot.selectedNode.id);
        this.textInput.focus();
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error creating option ${label}`);
      });
  };

  /**
   * Soft Option Update
   */
  updateOptionSoft = (params: updateOptionParams) => {
    return updateOption(params).catch((err) => {
      this.props.toggleSnackbar(`We ran into an error updating option ${params.label ? params.label : params.id}`);
      throw err;
    });
  };

  /**
   * Delete Option
   */
  deleteOption = () => {
    this.props.updateOptions("", false, true);
  };

  captureEnter = (e) => {
    const { isNew } = this.props;
    const { label } = this.state;
    if ((e.key === "Enter") && isNew) {
      // e.target.blur();
      this.props.updateOptions(e.target.value, true, false);
      var tI = this.textInput
      //revert text back to blank
      window.setTimeout(function() {
        tI.value = ''
      }, 1)
    } else if ((e.key === "Delete" || e.keyCode == 8) && !isNew && label.length == 0) {
      e.target.blur();
      if (e.keyCode == 8) e.preventDefault();
    }
  };

  updateTagFunc = (label: string, tag_id: number = null, remove: boolean = false) => {
    this.props.updateTags({option_id: this.props.option.id, tag_id: tag_id}, label, remove)
  }

  render() {
    const {
      isNew,
      index,
      addFollowUp,
      option,
      makeOptionActive,
      isActive,
      isFollowUpNode,
      user,
      selected,
      node,
      editing,
      tagData
    } = this.props;
    const { label } = this.state;
    const multipleSelection = node && node.multiple_selection;
    if (isNew) {
      if (this.props.isDisclaimer && this.props.numberOptions == 0) {
        this.createOption('Agree');
      }
      return (
        <div className="node__body__option__new">
          <span className={`node__body__option__new-icon ${userIsAdmin(this.props.user) ? "" : "disabled"}`}>
            <Plus />
          </span>
          <TextareaAutosize
            className="type-14-regular node__body__option__new-label"
            onKeyPress={this.captureEnter}
            onFocus={makeOptionActive}
            placeholder="New Response"
            innerRef={(textInput) => (this.textInput = textInput)}
            disabled={!this.props.editing}
          />
        </div>
      );
    } else {
      return (
        <div className="node__body__option">
          <span className={`node__body__option-icon ${userIsAdmin(this.props.user) ? "" : "disabled"}`}>
            <span>
              <span className="node__body__option-number">{index !== undefined ? index : ""}</span>
            </span>
          </span>
          <TextareaAutosize
            className="type-14-regular node__body__option-label"
            onChange={this.onChange}
            onFocus={makeOptionActive}
            value={option.label}
            placeholder="Delete Response?"
            onKeyPress={this.captureEnter}
            innerRef={(textInput) => (this.textInput = textInput)}
            disabled={!this.props.editing}
          />
          {!isFollowUpNode && userIsAdmin(this.props.user) && !multipleSelection && editing && (
            <>
              <span className="node__body__option-add" onClick={addFollowUp}>
                <Plus />
              </span>
              <span className="node__body__option-remove" onClick={this.deleteOption}>
                <X />
              </span>
            </>
          )}
          <div className="node__body__option-actions active">
            {(option.tag || editing) && <NodeTags
              user={user}
              tagData={{option_id: option.id, node_conenction_id:this.props.tagData.node_connection_id}}
              option={option}
              tags={option.tag ? [option.tag] : []}
              selected={selected}
              editing={editing}
              updateTags={this.props.updateTags}
            />}
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect((state: RootStore) => ({ detailBot: state.detailBot }), { fetchNSetNode })(DialogNodeOption)
);

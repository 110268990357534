import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import { getAttorneys } from "actions/attorneys";
import { setAttorney } from "actions/attorney";
import { AttorneyStore } from "reducers/attorneys";
import { RootStore } from "reducers";
import { UserStore } from "reducers/user";
import { getAvailabilities, getPluginEvents } from "actions/availabilities";

// Cmps
import List from "@material-ui/core/List";
import UserListItem from "app/components/global/attorneyListItem";
import Availabilities from "app/components/calendar/availabilities";

// Sass
import "sass/pages/calendar.scss";
import "sass/pages/handoff.scss";
import Layout from "app/components/global/layout";

type Props = {
  getAttorneys: Function;
  setAttorney: Function;
  getAvailabilities: Function;
  attorneys: AttorneyStore;
  user: UserStore;
} & RouteComponentProps;

type State = {};

class Calendar extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
    props.getAttorneys();
  }

  componentDidUpdate(prevProps): void {
    if (
      !this.props.attorneys.selectedAttorney.id &&
      this.props.attorneys.data.length > 0 &&
      prevProps.attorneys.data.length == 0
    ) {
      let attorneySet = false;
      this.props.attorneys.data.forEach((attorney) => {
        if (attorney.id === this.props.user.id) {
          this.setSelectedAttorney(attorney);
          attorneySet = true;
        }
      });
      if (!attorneySet) this.setSelectedAttorney(this.props.attorneys.data[0]);
    } else if (
      this.props.attorneys.data.length > 0 &&
      prevProps.attorneys.selectedAttorney &&
      prevProps.attorneys.selectedAttorney.id
    ) {
      this.props.attorneys.data.forEach((attorney) => {
        if (
          attorney.id === prevProps.attorneys.selectedAttorney.id &&
          attorney.working_hours !== prevProps.attorneys.selectedAttorney.working_hours &&
          prevProps.attorneys.loading &&
          !this.props.attorneys.loading
        ) {
          this.setSelectedAttorney(attorney);
        }
      });
    }
  }

  setSelectedAttorney = (attorney): void => {
    const { attorneys, setAttorney, getAvailabilities, getPluginEvents } = this.props;

    setAttorney(attorney);
    getAvailabilities(attorney.id, attorneys.pageStartDate, attorneys.pageEndDate);
    getPluginEvents(attorney.id, attorneys.pageStartDate, attorneys.pageEndDate);
  };

  render(): React.ReactElement {
    const { attorneys } = this.props;

    return (
      <Layout appClassName="platform" desktopOnly mobileTitle="Calendar">
        <div className="calendar">
          <div className="handoff__menu">
            <h3 className="handoff__menu__header type-24-bold-nexa">Calendar</h3>

            <List>
              {attorneys.data.map((attorney, i) => (
                <UserListItem
                  key={i}
                  clickHandler={(): void => this.setSelectedAttorney(attorney)}
                  isClicked={attorneys.selectedAttorney && attorneys.selectedAttorney.id === attorney.id}
                  userData={attorney}
                />
              ))}
            </List>
          </div>
          <div className="calendar container container--unStyled container--noScroll">
            <Availabilities />
          </div>
        </div>
      </Layout>
    );
  }
}

export default connect((state: RootStore) => ({ attorneys: state.attorneys }), {
  getAttorneys,
  setAttorney,
  getAvailabilities,
  getPluginEvents,
})(Calendar);

import { toggleSnackbar } from "actions/ui";
import { getBotCustomizations as fetchBotCustomizations} from  "lib/user";

export type BotDisplayOptions = {
  botColor?: string;
  botTitle?: string;
  botLogoURL?: string;
}

export const FETCH_BOT_CUSTOMIZATIONS = 'FETCH_BOT_CUSTOMIZATIONS';
export const SET_BOT_CUSTOMIZATIONS = 'SET_BOT_CUSTOMIZATIONS';

export const getBotCustomizations = (orgId: number) => dispatch => {
    dispatch({
        type: FETCH_BOT_CUSTOMIZATIONS
    });

    fetchBotCustomizations(orgId)
    .then(res => {
        dispatch({
            type: SET_BOT_CUSTOMIZATIONS,
            displayOptions: typeof res.display_options === 'string' ? JSON.parse(res.display_options) : res.display_options,
        })
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your organization's Bot Customizations! Please try again", undefined, 'error')(dispatch);
    })
}

export type BotCustomizationsAction =
    | {
          type: "FETCH_BOT_CUSTOMIZATIONS";
          loading: boolean;
      }
    | {
        type: "SET_BOT_CUSTOMIZATIONS";
        displayOptions?: BotDisplayOptions;
    };
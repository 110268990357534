import {
    Lead,
    Filter,
    Message,
    Conversation,
    FETCH_LEADS,
    SET_LEAD,
    SET_LEADS,
    SET_SORT,
    ADD_LEADS,
    SET_DETAIL_LEAD,
    LeadsAction,
    SET_FILTER,
    FETCH_LEAD,
    FETCH_MESSAGES,
    SET_LEAD_MESSAGES,
    ADD_LEAD_MESSAGES,
    FETCH_CONVERSATIONS,
    SET_LEAD_CONVERSATIONS,
    ADD_LEAD_CONVERSATIONS,
    LEAD_ARCHIVED,
    RESET_MESSAGES,
    SET_CONVERSATION_ORDER,
    FETCH_CONVERSATION_DOCUMENTS,
    GET_CONVERSATION_DOCUMENTS,
    Conversation_Documents,
    GET_LEAD_APPOINTMENTS,
    Lead_Appointments,
    EXPORT_LEADS,
    COUNT_LEADS,
    FETCH_CONVERSATION_TRANSCRIPTS
} from "actions/leads";

export type Order = "ASC" | "DESC";

export type LeadsStore = {
    leads: Lead[],
    totalLeads: number,
    detailLead: Lead | null,
    offset: number | null;
    hasMore: boolean,
    loading: boolean;
    sort: string;
    order: string;
    filter: Filter | null;
    detailLeadMessages: detailLeadMessages;
    detailLeadConversations: detailLeadConversations;
    leadDocassembleLinks: leadDocassembleLinks;
    appointments: Lead_Appointments[];
    chatTranscripts: chatTranscripts;
};

export type detailLeadMessages = {
  messages: Message[];
  page: number;
  loading: boolean;
  hasMore: boolean;
};

export type detailLeadConversations = {
  conversations: Conversation[];
  page: number;
  loading: boolean;
  hasMore: boolean;
  order: Order;
};

export type leadDocassembleLinks = {
    doc_files: Conversation_Documents[];
}

export type chatTranscripts = {
    transcripts: Conversation[];
}

const emptyMessages: Message[] = [];
const emptyConversations: Conversation[] = [];
const defaultConversationOrder: Order = "DESC";
const emptyConversationDocuments: Conversation_Documents[] = [];

const initialState = {
    leads: [],
    totalLeads: 0,
    loading: false,
    offset: 0,
    hasMore: true,
    detailLead: null,
    sort: 'last_active',
    order: 'desc',
    filter: null,
    detailLeadMessages: {
        messages: emptyMessages,
        page: 1,
        loading: false,
        hasMore: false,
    },
    detailLeadConversations: {
        conversations: emptyConversations,
        page: 1,
        loading: false,
        hasMore: false,
        order: defaultConversationOrder,
    },
    leadDocassembleLinks: {
        doc_files: emptyConversationDocuments
    },
    appointments: [],
    chatTranscripts: {
        transcripts: emptyConversations
    }

};

export default function(
    state: LeadsStore = initialState,
    action: LeadsAction,
): LeadsStore {
    switch (action.type) {
        case FETCH_LEADS:
        case FETCH_LEAD: {
            return {
                ...state,
                loading: action.loading !== undefined ? action.loading : true
            };
        }
        case FETCH_MESSAGES: {
            return {
                ...state,
                detailLeadMessages: {
                    ...state.detailLeadMessages,
                    loading: action.loading !== undefined ? action.loading : true
                }
            };
        }
        case FETCH_CONVERSATIONS: {
            return {
                ...state,
                detailLeadConversations: {
                    ...state.detailLeadConversations,
                    loading: action.loading !== undefined ? action.loading : true
                }
            };
        }

        case FETCH_CONVERSATION_DOCUMENTS: {
            return {
                ...state,
                leadDocassembleLinks: {
                    ...state.leadDocassembleLinks
                }
            }
        }
        case SET_LEADS: {
            return {
                ...state,
                loading: false,
                leads: action.leads,
                offset: action.offset,
                hasMore: action.hasMore,
            };
        }
        case SET_LEAD: {
            const nextLeads = state.leads.map((l) => l.id === action.lead.id ? action.lead : { ...l })
            return {
                ...state,
                loading: false,
                leads: nextLeads,
            }

        }
        case SET_DETAIL_LEAD: {
            return {
                ...state,
                loading: false,
                detailLead: action.lead,
                detailLeadMessages: {
                    messages: emptyMessages,
                    page: 1,
                    loading: false,
                    hasMore: false,
                }
            };
        }
        case ADD_LEAD_MESSAGES:
        case SET_LEAD_MESSAGES: {
            // Reverse so it's newest at the bottom of the chat window
            const sortedMessages: Message[] = action.messages.reverse()

            // Either set or add
            const messages = action.type == ADD_LEAD_MESSAGES ?
            sortedMessages.concat(state.detailLeadMessages.messages) : sortedMessages;

            return {
                ...state,
                detailLeadMessages: {
                    messages,
                    page: action.page,
                    loading: false,
                    hasMore: action.hasMore,
                }
            }
        }
        case ADD_LEAD_CONVERSATIONS:
        case SET_LEAD_CONVERSATIONS: {
            const actionConversations: Conversation[] = action.conversations;

            // Either set or add
            const conversations = action.type == ADD_LEAD_CONVERSATIONS ?
            [...state.detailLeadConversations.conversations, ...actionConversations] : actionConversations;

            return {
                ...state,
                detailLeadConversations: {
                    conversations,
                    page: action.page,
                    loading: false,
                    hasMore: action.hasMore,
                    order: action.order,
                }
            }
        }
        case ADD_LEADS: {
            return {
                ...state,
                loading: false,
                leads: state.leads.concat(action.leads),
                offset: action.offset,
                hasMore: action.hasMore,
            };
        }
        case SET_FILTER: {
            return {
                ...state,
                filter: action.filter
            }
        }
        case SET_SORT: {
            return {
                ...state,
                sort: action.sort || state.sort,
                order: action.order
            };
        }
        case LEAD_ARCHIVED: {
            return {
                ...state,
                leads: state.leads.filter(l => l.id === action.leadId)
            }
        }
        case RESET_MESSAGES: {
            return {
                ...state,
                detailLeadMessages: {
                    messages: emptyMessages,
                    page: 1,
                    loading: false,
                    hasMore: false,
                },
            }
        }
        case SET_CONVERSATION_ORDER: {
            return {
                ...state,
                detailLeadConversations: {
                    ...state.detailLeadConversations,
                    order: action.orderBy,
                }
            }
        }
        case GET_CONVERSATION_DOCUMENTS: {
            return {
                ...state,
                leadDocassembleLinks: {
                    ...state.leadDocassembleLinks,
                    doc_files: action.doc_files
                }

            }

        }
        case COUNT_LEADS: {
            return {
                ...state,
                totalLeads: action.leadCount
            }
        } 
        case FETCH_CONVERSATION_TRANSCRIPTS: {
            return {
                ...state,
                chatTranscripts: {
                    ...state.chatTranscripts,
                    transcripts: action.transcripts
                }            
            }
        }
        case GET_LEAD_APPOINTMENTS: {
            return {
                ...state,
                appointments: action.leadAppointments
            }
        }
        case EXPORT_LEADS:
        default: {
            return state;
        }
    }
}

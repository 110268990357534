import * as React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import connect from "app/connect";
import { RootStore } from "reducers";

import { Dialog, Bot, deleteDialog, setNode, selectNode, setBot } from "actions/bot";

import { orderedNodesWithBaseNode } from "lib/utilities/node";
import { createNode, deleteNode, createNodeParams, updateNodeParams, metaForType, editNode } from "lib/nodes";

import { deleteDialog as deleteDialogRequest } from "lib/dialogs";

import { CueType, cueTypeToNodeSpecs, defaultCueValues } from "lib/cue";
import { saveBotYml } from "lib/bots"; //add to each function

// Sass
import "sass/components/botDialog.scss";

// Cmps
import DialogNode from "app/components/botDetail/dialogNode";
import CueTypeSelection from "app/components/botDetail/cueTypeSelection";
import Confirmation from "app/components/global/confirmation";

type Props = {
  bot: Bot;
  dialog: Dialog;
  getBot: Function;
  setNode: Function;
  dialogsById: Record<string, any>;
  refresh: Function;
  toggleSnackbar: Function;
  selectNode: Function;
  setBot: Function;
  limit: number;
  deleteDialog: Function;
};

type State = {
  deleteConfirm: boolean;
  nodeToDelete?: string | null;
  dialogToDelete?: string;
};

/**
 * Dialog component, part of the Bot Detail
 */
class BotDialog extends React.Component<Props, State> {
  state = {
    deleteConfirm: false,
    nodeToDelete: null,
    dialogToDelete: undefined,
  };

  componentDidMount() {
    const { dialog, selectNode } = this.props;
    const firstNode: Node = dialog.nodes.find((node: { id: number }) => node.id === dialog.first_node_id);
    selectNode(firstNode);
  }

  /**
   * Creates a new node
   */
  createNode = (params: createNodeParams) => {
    params.meta = JSON.stringify(metaForType(params.node_type));
    createNode(params)
      .then((res) => {
        saveBotYml(this.props.bot.id);

        this.props.refresh();
      })
      .catch((err) => {
        console.log(err);
        this.props.toggleSnackbar("Sorry, but we ran into an issue creating this node!");
      });
  };

  /**
   * Update Node
   */
  // TODO: Move to bottom level cmp.
  updateNode = (params: updateNodeParams) => {
    editNode(params)
      .then((res) => {
        this.props.setNode(res.data, this.props.dialog.id);
        this.props.setBot(this.props.dialog.bot_id.toString());
        saveBotYml(this.props.bot.id);

        // this.props.refresh();
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error updating ${params.title ? params.title : params.id}`);
      });
  };

  /**
   * Deletes node for us. Checks state var. for node to delete
   */
  // TODO: Move to bottom level cmp.
  deleteNode = (): void => {
    const { nodeToDelete, dialogToDelete } = this.state;
    const { toggleSnackbar, deleteDialog, dialogsById } = this.props;

    if (!nodeToDelete) return;

    if (dialogToDelete && dialogsById[dialogToDelete]) {
      deleteDialogRequest(dialogToDelete, nodeToDelete)
        .then((res) => {
          deleteDialog(dialogToDelete);
          saveBotYml(this.props.bot.id);
          this.props.selectNode(null);
          this.props.refresh();
        })
        .catch((err) => {
          toggleSnackbar("We couldn't delete this Dialog! Please try again", true, "error");
          throw err;
        });
    } else {
      deleteNode(nodeToDelete)
        .then(() => {
          saveBotYml(this.props.bot.id);
          this.props.selectNode(null);
          this.props.refresh();
        })
        .catch((err) => {
          toggleSnackbar("We ran into an error deleting this node. Please try again!");
          throw err;
        });
    }
  };

  createSelectedCueType = (type: CueType) => {
    const { dialog } = this.props;
    this.createNode({
      dialog_id: dialog.id,
      multi_case_type: false,
      ...defaultCueValues(type),
      ...cueTypeToNodeSpecs(type),

    });
  };

  /**
   * React Drag and Drop handler
   */
  onDragEnd = (result) => {
    // dropped outside the list or droped in the same spot
    if (!result.destination || result.destination.index == result.source.index) {
      return;
    }

    const { dialogsById, dialog } = this.props;
    const nodesById = dialogsById[dialog.id].nodesById;
    const orderedArrayOfNodes = orderedNodesWithBaseNode(nodesById[dialog.first_node_id.toString()], nodesById);
    const nodeToBeUpdated = orderedArrayOfNodes[result.source.index];
    let link_from: number | string | null = null;

    if (!nodeToBeUpdated) {
      throw "nodeToBeUpdated can't be found!";
    }

    if (result.destination.index == 0)
      // Moved to the last space
      link_from = "first";
    else if (result.source.index < result.destination.index)
      // Moving down
      link_from = orderedArrayOfNodes[result.destination.index].id;
    // Moving up
    else link_from = orderedArrayOfNodes[result.destination.index - 1].id;

    if (link_from == null) return;

    // // Save new ordering via API
    editNode({
      id: nodeToBeUpdated.id,
      link_from,
    })
      .then((res) => {
        // TODO: Update redux data store
        saveBotYml(this.props.bot.id);
        this.props.refresh();
        
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error updating the ordering of this node!`);
      });
  };

  render() {
    const { dialog, dialogsById, bot } = this.props;
    const { deleteConfirm } = this.state;
    const nodesById = dialogsById[dialog.id].nodesById;

    // Check if there are any nodes
    if (dialog.nodes.length == 0 || dialog.first_node_id === null) {
      return (
        <div className="bot__dialog">
          <div className="bot__dialog__emptyNode">
            <div className="node__connector--bottom">
              <div className="node__connector">
                <img className={`plus visible`} src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`} />
              </div>
            </div>

            <CueTypeSelection visible={true} onSelect={this.createSelectedCueType} isNodeAllowed={true} />
          </div>
        </div>
      );
    } else {
      const node = nodesById[dialog.first_node_id.toString()];

      return (
        <div className="bot__dialog">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <ul ref={provided.innerRef}>
                  <DialogNode
                    bot={bot}
                    root
                    node={node}
                    dialog={dialog}
                    nodesById={nodesById}
                    createNode={this.createNode}
                    deleteNode={(nodeId, dialogId = undefined) =>
                      this.setState({
                        deleteConfirm: true,
                        nodeToDelete: nodeId,
                        dialogToDelete: dialogId,
                      })
                    }
                    limit={this.props.limit}
                    updateNode={this.updateNode}
                  />

                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <Confirmation
            title="Delete node"
            message="Are you sure you want to permanently delete this node?
                        This action cannot be undone."
            actionTitle="Delete"
            cancelTitle={null}
            setVisible={(visible) => this.setState({ deleteConfirm: visible })}
            cancel={null}
            visible={deleteConfirm}
            confirmation={this.deleteNode}
          />
        </div>
      );
    }
  }
}

export default connect(
  (state: RootStore) => ({
    detailBot: state.detailBot,
  }),
  {
    setNode,
    selectNode,
    setBot,
    deleteDialog,
  }
)(BotDialog);

import api from "lib/api";

export type createBotParams = {
  title: string;
  open_on_load?: boolean | null;
  open_fullscreen?: boolean | null;
  full_height?: boolean | null;
  site_id: string | null;
  site_url: string | null;
  site_is_wildcard_uri: boolean | null;
  display_options: any | null;
  display_preset_id?: string;
  file_format?: string;
  leaddocket_sync_enabled?: boolean;
  leaddocket_case_type_id?: number;
  leaddocket_sync_override?: object;
  litify_sync_enabled?: boolean;
  litify_case_type?: string;
  hubspot_sync_enabled: boolean;
};

export type updateBotParams = {
  title?: string;
  id: string;
  open_on_load?: boolean | null;
  open_fullscreen?: boolean | null;
  full_height?: boolean | null;
  site_id?: string | null;
  active?: boolean | null;
  site_is_wildcard_uri?: boolean | null;
  display_options?: any | null;
  document_generation_emails?: string[];
  display_preset_id?: string;
  file_format?: string;
  leaddocket_sync_enabled?: boolean;
  leaddocket_case_type_id?: number;
  leaddocket_sync_override?: object;
  litify_sync_enabled?: boolean;
  litify_case_type?: string;
  hubspot_sync_enabled?: boolean;
};

export const fetchBots = () => {
  return api.get("/bots", null);
};

export const fetchBot = (id: string) => {
  const res = api.get(`/bots/${id}`, null);
  return res;
};

export const exportBot = (bot_id: number) => {
  return api.get(`/bots/export?bot_id=${bot_id}`, null);
};

export const importBot = (form_data) => {
  return api.post("/bots/import", form_data);
};

export const fetchSites = () => {
  return api.get("/sites", null);
};

export const fetchDisplayPresets = (orgId: string) => {
  return api.get(`/presets?org_id=${orgId}`);
};

export const newDisplayPreset = (params) => {
  const mappedParams = {
    color: params.botColor,
    display_title: params.botTitle,
    logo_url: params.botLogoURL,
    widget_url: params.botWidgetURL,
    widget_same_as_logo: params.botWidgetSameAsLogo,
    fullscreen_url: params.botFullscreenImageURL,
    org_id: params.orgId,
    display_name: params.botName,
    preset_title: params.presetTitle,
  };
  return api.post("/presets", mappedParams);
};

export const updateDisplayPreset = (params) => {
  const mappedParams = {
    color: params.botColor,
    display_title: params.botTitle,
    logo_url: params.botLogoURL,
    widget_url: params.botWidgetURL,
    widget_same_as_logo: params.botWidgetSameAsLogo,
    fullscreen_url: params.botFullscreenImageURL,
    display_name: params.botName,
    preset_title: params.presetTitle,
    preset_id: params.presetId,
  };
  return api.put(`/presets`, mappedParams);
};

export const deleteDisplayPreset = (presetId: number) => {
  return api.deleteReq("/presets", { preset_id: presetId });
};

export const createBot = (params: createBotParams) => {
  return api.post("/bots", params);
};

export const editBot = (params: updateBotParams) => {
  return api.put(`/bots/${params.id}`, params);
};

export const deleteBot = (id: string) => {
  return api.deleteReq(`/bots/${id}`, null);
};

export const saveBotYml = (bot_id: number) => {
  return api.post("/bots/yml", { bot_id });
};

export const exportBotYml = (bot_id: number) => {
  return api.get(`/bots/yml/export?bot_id=${bot_id}`, null);
}

export const deleteBotYml = (bot_id: number) => {
  return api.deleteReq(`/bots/yml?bot_id=${bot_id}`, null);
}

import * as React from "react";

import TimezoneSelector from "app/components/calendar/timezoneSelector";

/**
 * Calender Navigation Toolbar component
 */

type ToolbarProps = {
  onView: Function;
  onNavigate: Function;
  label: string;
  date: Date;
  refresh: () => void;
};

const ToolBar = ({
  clickEvent,
  refresh,
  setWorkingHoursClickEvent,
  getFormattedHours,
  selectedAttorneyIsUser,
  selectedAttorneyUser,
  userIsAdmin,
}) => (props: ToolbarProps): React.ReactElement => {
  const { onNavigate, date } = props;
  return (
    <div className="availabilities__actions">
      <div className="availabilities__actions--navigate">
        <span>{date.toLocaleDateString("en-US", { year: "numeric", month: "long" })}</span>
        <span>
          <span className="availabilities__actions--nav prev" onClick={() => onNavigate("PREV")}></span>
          <span className="availabilities__actions--nav next" onClick={() => onNavigate("NEXT")}></span>
          <span className="availabilities__actions--today" onClick={() => onNavigate("TODAY")}>
            Today
          </span>
        </span>
        {selectedAttorneyUser() && (
          <TimezoneSelector
            refresh={refresh}
            disabled={!selectedAttorneyIsUser() && !userIsAdmin}
            selectedAttorneyUser={selectedAttorneyUser()}
          />
        )}
      </div>
      {selectedAttorneyIsUser() || userIsAdmin ? (
        <div className="availabilities__actions--edit">
          <div className="availabilities__actions--edit workinghours" onClick={() => setWorkingHoursClickEvent()}>
            <img src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`} />
            <span>set working hours</span>
          </div>
          <div className="availabilities__actions--edit workinghoursdisplay">
            <span>{getFormattedHours()}</span>
          </div>
          <div className="availabilities__actions--edit availability" onClick={() => clickEvent()}>
            <img src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`} />
            <span>add new unavailability</span>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ToolBar;

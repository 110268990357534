import * as React from "react";

type Props = {
    options: any;
    title: String;
    onChange: any;
    value: string | null;
};

const Select: React.FC<Props> = ({ options, title, onChange, value }) => {
    return (
        <div className="form-control">
            {title && <span>{title}</span>}

            <select onChange={onChange} value={value || ""}>
                {options.map((option, i) => (
                    <option key={i} value={option.value}>{option.text}</option>
                ))}
            </select>
        </div>
    ); 
};

export default Select;

import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";

// Cmps
import Nav from "app/components/navbar"
import Container from "app/components/global/container";
import SignupForm from "app/components/lander/signupForm";

// Sass
// import "sass/pages/signup.scss"

/**
 * Signup Page Component
 */
class Signup extends React.PureComponent {
    render() {
        return (
            <App className="public">
                <Nav />
                <Container name="lander">
                    <SignupForm />
                </Container>
            </App>
        );
    }
}

export default connect()(Signup);

import * as React from "react";
import connect from "app/connect";
import { INDEX } from "app/router";
import { withRouter, RouteComponentProps } from "react-router-dom";

// Sass
import "sass/components/nav.scss";

// Cmps

type State = {

}

type Props = {
} & RouteComponentProps;

/**
 * Sample cmp.
 */
class Nav extends React.Component<Props, State> {

    render() {
        return (
            <div className="navbar hero">
                <img
                    onClick={() => this.props.history.push(INDEX)} 
                    src={`${process.env.REACT_STATIC_CDN}/logo.svg`} />
            </div>
        );
    }
}

export default withRouter(Nav);

import { fetchInvites } from "lib/invites";
import { Invite } from "reducers/invites";
import { toggleSnackbar } from "actions/ui";

export const FETCH_INVITES = 'FETCH_INVITES';
export const SET_INVITES = 'SET_INVITES';
export const ADD_INVITES = 'ADD_INVITES';

export const getInvites = (page:number = 1) => dispatch => {
    // Tell reducer we're fetching
    dispatch({
        type: FETCH_INVITES
    });
    // Actually fetch invites
    fetchInvites(page)
    .then(res => {
        dispatch({
            type: page > 1 ? ADD_INVITES : SET_INVITES,
            page: parseInt(res.page),
            invites: res.invites,
            seats_remaining: parseInt(res.seats_remaining),
        })
        return res;
    })
    .catch(err => {
        dispatch({
            type: FETCH_INVITES,
            loading: false
        });
        toggleSnackbar("We couldn't fetch your organization's invites! Please try again", true)(dispatch);
    })
}


export type InviteAction =
    | {
          type: "FETCH_INVITES";
          loading: Boolean
      } 
    | {
        type: "SET_INVITES" | "ADD_INVITES",
        invites: Invite[],
        page: number,
        seats_remaining: number,
    }
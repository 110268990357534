import * as React from "react";
import connect from "app/connect";
import { RootStore } from "reducers";
import { BotStore } from "reducers/detailBot";
import { Node, Dialog, selectedDialogId } from "actions/bot";
import { NODE_TYPES } from "lib/nodes";
import { withRouter, RouteComponentProps } from "react-router";

//cmps
import DialogList from "app/components/botDetail/dialogList";
import { Link } from "react-router-dom";

//scss
import "sass/components/botEditor.scss";
import "sass/components/botNavigator.scss";

type Props = {
  detailBot: BotStore;
} & RouteComponentProps;

type State = {
  activeTab: number;
  dialog?: Dialog;
};
/**
 * Bot navigator component for dialog tree and list
 */
class BotNavigator extends React.Component<Props, State> {
  state: State = {
    activeTab: 0,
  };

  /**
   * Setting the active tab in navigator
   */
  tabChangeHandler = (value) => {
    this.setState({ activeTab: value });
  };

  /**
   * Setting the List item recursively
   */
  setListingMap = (treeDialog: any) => {
    const { detailBot, match, selectedDialogId } = this.props;
    const activeDialogId = detailBot.selectedDialogId ? detailBot.selectedDialogId : detailBot.bot.initial_dialog_id;

    return Object.keys(treeDialog).map((key) => {
      const dialog = treeDialog[key];

      return (
        dialog && (
          <li key={dialog.p_dialog.id}>
            <label
              className={`${parseInt(activeDialogId) === dialog.p_dialog.id ? "active" : ""}`}
              onClick={() => selectedDialogId(dialog.p_dialog.id)}
              style={{ fontSize:"13px" }}
            >
              <Link to={`${match.url}/dialog/${dialog.p_dialog.id}`}>{dialog.p_dialog.title}</Link>
            </label>
            {dialog.c_dialog.length > 0 && <ul className="dialogTree">{this.setListingMap(dialog.c_dialog)}</ul>}
          </li>
        )
      );
    });
  };

  /**
   * Drawing the dialog tree
   */
  dialogTree = () => {
    const { detailBot } = this.props;
    const { dialogsById } = detailBot;
    let nodesWithDialogLink: Array<Node> = [];
    let treeDialog: object = {};

    /**
     * Getting all nodes of type DIALOG_LINK
     */
    if (dialogsById) {
      Object.keys(dialogsById).forEach((key) => {
        const nodesById = dialogsById[key].nodes.filter((node: Node) => {
          return node.node_type === NODE_TYPES.DIALOG_LINK && node.meta && node.meta.dialog_to_link_to;
        });
        nodesWithDialogLink = nodesWithDialogLink.concat(nodesById);
        treeDialog[key] = { p_dialog: dialogsById[key], c_dialog: [] };
      });
    }

    /**
     * Matching dialog_id if it present in the linked nodes
     */
    nodesWithDialogLink.reverse().forEach((dialogLinkNode) => {
      if (dialogLinkNode.dialog_id && treeDialog[dialogLinkNode.dialog_id]) {
        treeDialog[dialogLinkNode.dialog_id].c_dialog.push(treeDialog[dialogLinkNode.meta.dialog_to_link_to]);
      }

      // Removing the dialog id which are linked.
      treeDialog[dialogLinkNode.meta.dialog_to_link_to] = null;
    });

    if (Object.keys(treeDialog).length > 0) {
      return <ul>{this.setListingMap(treeDialog)}</ul>;
    }

    return null;
  };

  render() {
    const { activeTab } = this.state;
    const { detailBot } = this.props;
    return (
      <div className="bot__editor">
        <div className="bot__editor__panel bot__editor__panel--navigator">
          <div className="bot__editor__panel__header bot__editor__panel__header--noNode">
            <p className="type-16-regular">Bot Navigator</p>
          </div>
          <div className="botNav">
            <ol className="tabList">
              <li
                className={`tabList-item ${activeTab === 0 ? "tabList-item-active" : ""}`}
                onClick={() => this.tabChangeHandler(0)}
              >
                Main Bot
              </li>
              <li
                className={`tabList-item ${activeTab === 1 ? "tabList-item-active" : ""}`}
                onClick={() => this.tabChangeHandler(1)}
              >
                Dialogs
              </li>
            </ol>

            {activeTab === 0 && <div className="tabContent">{this.dialogTree()}</div>}

            {activeTab === 1 && (
              <div className="tabContent">
                <DialogList detailBot={detailBot} className="dialogItem"/>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootStore) => ({
      detailBot: state.detailBot,
    }),
    { selectedDialogId }
  )(BotNavigator)
);

import api from "lib/api";

export type createDialogParams = {
    title: String;
    bot_id: Number | null;
}

export type updateDialogParams = {
    title?: String;
    id: String;
    bot_id?: Number | null;
}

export const fetchBotDialogs = (id: String) => {
    return api.get('/dialogs', {bot_id: id})
}

export const fetchDialog = (id: String) => {
    return api.get(`/dialogs/${id}`, null)
}


export const createDialog = (params: createDialogParams) => {
    return api.post('/dialogs', params)
}

export const editDialog = (params: updateDialogParams) => {
    return api.put(`/dialogs/${params.id}`, params)
}

export const deleteDialog = (dialogId: String, dialogNodeId: String) => {
    return api.deleteReq(`/dialogs/${dialogId}`, { dialog_node_id: dialogNodeId })
}

import * as React from "react";
import connect from "app/connect";
// import withAnalytics from "lib/withAnalytics";
import { PUBLIC, LOGOUT } from "app/router";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UserStore } from "reducers/user";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { me } from "lib/user";
import { getAdminToken } from "lib/admin";
import { cacheUser } from "actions/user";
import AdminImpersonatingFlag from "app/components/global/adminImpersonatingFlag";

// Sass
import "sass/global/index.scss";
import "sass/components/app.scss";

// Cmps
import StripeListener from "app/components/stripeListener";
import Snackbar from "@material-ui/core/Snackbar";
import { RootStore } from "reducers";

type Props = {
  user: UserStore;
  ui: any;
  script: string;
  className: string;
  scriptAdded: Function;
} & RouteComponentProps;

const theme = createMuiTheme({
  palette: {
    primary: { main: "#6cc1e9" }, // Purple and green play nicely together.
    secondary: { main: "#88e2b0" },
    error: { main: "#F32013" },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

/**
 * Root App container.
 */
class App extends React.Component<Props> {
  state = {
    // modalTimeout: null
  };

  static defaultProps = {
    className: "",
  };

  componentWillMount() {
    if (this.props.script) {
      this.addScript(this.props.script);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.script !== nextProps.script) {
      this.addScript(nextProps.script);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this.updateBackground(nextProps.background);
  }

  componentDidMount() {
    const { user, ui } = this.props;
    this.trackUser();
    this.checkAuthentication();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.loggedIn !== this.props.user.loggedIn) {
      this.trackUser();
    }
  }

  /**
   * Logout if neccessary
   */
  checkAuthentication = () => {
    if (!this.props.user || !this.props.user.loggedIn) return;

    me()
      .then((response) => {
        this.props.cacheUser(response);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.status);

        if (err.response.status === 401) {
          this.props.history.push(LOGOUT);
        }
      });
  };

  /**
   * Tracks user using loaded analytics engine
   */
  trackUser = () => {
    // const { user } = this.props;
    // if (user.loggedIn) {
    //     this.props.analytics.identify(user.id, {
    //         name: user.name,
    //         email: user.email,
    //         ...situation,
    //         ...meta
    //     });
    // }
  };

  /**
   * Adds remote script to DOM
   */
  addScript = (src = "") => {
    if (!src) return;
    const script = document.createElement("script");
    script.setAttribute("src", src);
    if (document && document.head) {
      document.head.appendChild(script);
    }

    script.onload = () => {
      this.props.scriptAdded(script);
    };
  };

  /**
   * Because the body background changes from page to page
   * we're updating it here based on how the parent component decides to
   * pass the `background` prop
   * @param  {Boolean} background To include a background or not
   */
  updateBackground = (background) => {
    document.body.className = background || "grayBg";
  };

  render() {
    const { children, ui, className, location, user, history } = this.props;
    // Check if our pathname does not against any of the routes in the public part of the site
    const withinApp = PUBLIC.filter((s) => s.startsWith(location.pathname)).length == 0;
    const adminToken = getAdminToken();

    return (
      <MuiThemeProvider theme={theme}>
        <div className={`app ${className}`}>
          {adminToken && <AdminImpersonatingFlag user={user} history={history} />}
          <Snackbar
            message={ui.snackbarText}
            open={ui.showSnackbar}
            autoHideDuration={5000}
            className={`gideon-snackbar variant__${ui.snackbarVariant}`}
          />
          <StripeListener />
          <div className="app__inner">{children}</div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(connect((state: RootStore) => ({}), { cacheUser })(App));

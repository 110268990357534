import React from "react";
import ReactDom from "react-dom";
import { AxiosError } from "axios";
import { Button, FormControl, Select, MenuItem, InputLabel, Typography, makeStyles } from "@material-ui/core";

import { LeadDocketField, getLeaddocketMappingForNode, upsertLeaddocketMappingForNode } from "lib/plugins";

type Props = {
  leadDocketFields: LeadDocketField[];
  node: any;
  case_type: number;
  dialogActionArea: React.MutableRefObject<HTMLDivElement>;
  onRequestClose: Function;
};

const useStyles = makeStyles({
  acceptableTitle: {
    margin: "20px 0 5px",
  },
  acceptableBody: {
    margin: "5px 0 20px",
  },
});

const LEADDOCKET_FIELD_VALUE_ATTRIBUTE = "FieldNameUnderscore";

const LeadDocketSetting: React.FC<Props> = ({ node, case_type, onRequestClose, leadDocketFields = [], dialogActionArea }) => {
  const [ignored, forceUpdate] = React.useReducer((x: number) => x + 1, 0);

  const [deleteEnabled, setDeleteEnabled] = React.useState(false);
  const [selected, setSelected] = React.useState<string>(null);
  const classes = useStyles();
  const handleSave = () => {
    const payload = {
      node_id: node.id,
      leaddocket_field: selected,
      active: true,
    };

    upsertLeaddocketMappingForNode(payload)
      .catch((err) => alert(err.toString()))
      .finally(() => onRequestClose());
  };

  const handleRemove = () => {
    const payload = {
      node_id: node.id,
      active: false,
    };
    setDeleteEnabled(false);

    upsertLeaddocketMappingForNode(payload)
      .then(() => setSelected(null))
      .catch((err) => {
        alert(err.toString());
        setDeleteEnabled(true);
      })
      .finally(() => onRequestClose());
  };

  React.useEffect(() => {
    getLeaddocketMappingForNode(node.id)
      .then((ldMapping) => {
        if (ldMapping.leaddocket_field && ldMapping.active) {
          const selectedLDField = leadDocketFields.find(
            (ldField) => ldField[LEADDOCKET_FIELD_VALUE_ATTRIBUTE] === ldMapping.leaddocket_field
          );
          if (selectedLDField) {
            setSelected(selectedLDField[LEADDOCKET_FIELD_VALUE_ATTRIBUTE]);
            setDeleteEnabled(true);
          } else {
            setSelected(null);
            setDeleteEnabled(false);
          }
        }
      })
      .catch(({ response }: AxiosError) => response.status !== 404 && console.error(response));
  }, [node.id]);

  const handleChange = (event) => setSelected(event.target.value);

  if (!dialogActionArea.current) { // hack: dialogActionArea.current might not be available at initial render
    setTimeout(() => {
      forceUpdate();
    }, 10);
  }

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="ld-field-select-label">LeadDocket Field</InputLabel>
        <Select
          labelId="ld-field-select-label"
          id="ld-field-select"
          value={selected === null ? "" : selected}
          label="LeadDocket Field"
          onChange={handleChange}
        >
          {leadDocketFields.map((field) => (
            <MenuItem key={field.Id} value={field[LEADDOCKET_FIELD_VALUE_ATTRIBUTE]}>
              {field.FieldName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selected !== null ? (
        <div>
          <Typography className={classes.acceptableTitle} color="textSecondary" component="h2">
            LeadDocket Acceptable Values:
          </Typography>
          <Typography className={classes.acceptableBody}>
            {leadDocketFields.find((f) => f[LEADDOCKET_FIELD_VALUE_ATTRIBUTE] === selected)?.DefaultValues || (
              <i>Open Response</i>
            )}
          </Typography>
        </div>
      ) : null}
      {!leadDocketFields.length ? (
        <Typography className={classes.acceptableTitle} color="textSecondary" component="h2">
          No available LeadDocket fields yet!
        </Typography>
      ) : null}
      {dialogActionArea.current &&
        ReactDom.createPortal(
          <>
            <Button
              onClick={handleRemove}
              variant="contained"
              className="settingsRemoveButton"
              disabled={!deleteEnabled}
            >
              Delete Mapping
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained" className="settingsSaveButton">
              Save
            </Button>
          </>,
          dialogActionArea.current
        )}
    </>
  );
};

export default LeadDocketSetting;

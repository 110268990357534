import * as React from "react";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";

import connect from "app/connect";
import { getBots } from "actions/bots";
import { Bot } from "actions/bot";
import { importBot } from "lib/bots";
import { BotsStore } from "reducers/bots";
import { RootStore } from "reducers";
import { BOTS } from "app/router";
import { axiosErr } from "lib/api";
import { userIsAdmin } from "lib/utilities/global";
import { AttorneyStore } from "reducers/attorneys";
import { getAttorneys } from "actions/attorneys";
import { fetchPlugins } from "actions/plugins";

// Cmps
import CreateBot from "app/components/bots/createBot";
import Loader from "app/components/global/loader";
import BotListItem, { ConnectedMenu } from "../components/bots/botListItem";
import EditDocumentGenerationEmails from "app/components/bots/editDocumentGenerationEmails";
import Layout from "app/components/global/layout";

// Sass
import "sass/pages/bots.scss";

type Props = {
  getBots: Function;
  bots: BotsStore;
  toggleSnackbar: Function;
  attorneys: AttorneyStore;
} & RouteComponentProps;

type State = {
  visible: boolean;
  visibleEditDocumentGenerationEmails: boolean;
  activeContext: Number | null;
  botToEdit: Bot | null;
  importing: boolean;
};

/**
 * Bots Container Component
 */
class Bots extends React.Component<Props, State> {
  state = {
    visible: false,
    activeContext: null,
    botToEdit: null,
    importing: false,
    visibleEditDocumentGenerationEmails: false,
  };

  componentWillMount() {
    this.props.getBots();
    this.props.fetchPlugins();
    if (this.props.attorneys && this.props.attorneys.data && this.props.attorneys.data.length === 0) {
      this.props.getAttorneys();
    }
  }

  refresh = () => {
    this.props.getBots();
  };

  fileHandlerClick = () => {
    const element: HTMLInputElement = this.refs["import_file"] as HTMLInputElement;
    element.click();
  };

  /**
   * Handles input file change.
   * Sends JSON file as bot template to be impoted
   */
  fileChange = (e) => {
    const element: HTMLInputElement = this.refs["import_file"] as HTMLInputElement;
    const file = element.files && element.files.length > 0 ? element.files[0] : null;

    if (file && !this.state.importing) {
      this.setState({ importing: true });

      var formData = new FormData();
      formData.append("template", file);

      importBot(formData)
        .then((res) => {
          this.refresh();
        })
        .catch((err) => {
          err = axiosErr(err);

          if (err.code === "ERR_INVALID_FILE_FORMAT") {
            this.props.toggleSnackbar("The file selected is not correctly formatted. Please try another file!");
          } else {
            this.props.toggleSnackbar("We ran into an error processing this bot template. Please try again later!");
          }
        })
        .then(() => {
          element.value = "";
          this.setState({ importing: false });
        });
    }
  };

  render() {
    const { bots, user, attorneys } = this.props;

    return (
      <Layout appClassName="platform" desktopOnly mobileTitle="Bots">
        <div className="bots container">
          <div className="bots__import">
            {userIsAdmin(this.props.user) &&
              (this.state.importing ? <Loader /> : <span onClick={() => this.fileHandlerClick()}>Import</span>)}

            <input
              type="file"
              ref="import_file"
              onChange={this.fileChange}
              accept="application/json"
              multiple={false}
            />
          </div>

          <div className="platform__nav">
            <div className="title title--first">
              <Link to={BOTS}>Bots</Link>
            </div>
          </div>

          <div className="botList">
            {bots.data.map((bot, i) => (
              <BotListItem
                bot={bot}
                key={i}
                refresh={this.refresh}
                redundantKey={i}
                edit={(botToEdit) => this.setState({ botToEdit, visible: true })}
                editDocumentGenerationEmails={(botToEdit): void =>
                  this.setState({ botToEdit, visibleEditDocumentGenerationEmails: true })
                }
                contextRequested={(i) => this.setState({ activeContext: i })}
                contextMenu={i !== this.state.activeContext && false}
                user={user}
              />
            ))}

            {userIsAdmin(this.props.user) && (
              <div
                onClick={() => this.setState({ visible: true, botToEdit: null })}
                className="botList__item botList__item--create"
              >
                <span className="icon icon-plus" />
                <p className="type-16-regular">Create Bot</p>
              </div>
            )}
          </div>

          <ConnectedMenu user={user} />
        </div>

        <CreateBot
          refresh={this.refresh}
          visible={this.state.visible}
          bot={this.state.botToEdit}
          setVisible={(visible) => this.setState({ visible })}
        />

        <EditDocumentGenerationEmails
          refresh={this.refresh}
          bot={this.state.botToEdit}
          visible={this.state.visibleEditDocumentGenerationEmails}
          setVisible={(visible): void => this.setState({ visibleEditDocumentGenerationEmails: visible })}
          attorneys={attorneys}
        />
      </Layout>
    );
  }
}

export default connect((state: RootStore) => ({ bots: state.bots, attorneys: state.attorneys }), {
  getBots,
  getAttorneys,
  fetchPlugins,
})(Bots);

import * as React from "react";
import { List, ListItem, ListItemText } from '@material-ui/core'

const OrganizationInvites = ({ invites }) => {
  const secondaryText = invite => `${invite.organization && invite.organization.title} -- ${invite.used_at ? "USED AT " + invite.used_at : "NOT USED"} -- PLAN: ${invite.organization.plan}`;

  return (
    <List className="organizationInvitesList">
      {invites.map(invite => { 
        return (
          <ListItem className="organizationInvitesList__item" key={invite.id}>
            <ListItemText primary={invite.recipient_email} secondary={secondaryText(invite)} />
          </ListItem>
        );
      })}
    </List>
  )
}

export default OrganizationInvites;

import {
  countLeads,
  fetchLeads,
  fetchLead,
  fetchMessages,
  fetchConversations,
  leadLimit,
  archiveLead as putArchiveLead,
  fetchConversationDocuments,
  checkConversationDocuments,
  fetchLeadsConversationDocuments,
  fetchLeadAppointments,
  exportLeads,
  fetchConversationTranscripts,
  fetchPDF,
  fetchCSV,
} from "lib/leads";

import { Tag } from "./classifiers";
import store from "store";

import { toggleSnackbar } from "actions/ui";
import { Classifier } from "./classifiers";
import { User } from "reducers/user";
import { Order } from "reducers/leads";

// Action types
// Used in dispatch calls to denote what changes should be made to the state
export const FETCH_ARCHIVE_LEAD = "ARCHIVE_LEAD";
export const LEAD_ARCHIVED = "LEAD_ARCHIVED";
export const FETCH_LEADS = "FETCH_LEADS";
export const FETCH_LEAD = "FETCH_LEAD";
export const SET_LEAD = "SET_LEAD";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const SET_DETAIL_LEAD = "SET_DETAIL_LEAD";
export const SET_LEADS = "SET_LEADS";
export const SET_SORT = "SET_SORT";
export const ADD_LEADS = "ADD_LEADS";
export const SET_FILTER = "SET_FILTER";
export const SET_LEAD_MESSAGES = "SET_LEAD_MESSAGES";
export const ADD_LEAD_MESSAGES = "ADD_LEAD_MESSAGES";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const SET_LEAD_CONVERSATIONS = "SET_LEAD_CONVERSATIONS";
export const ADD_LEAD_CONVERSATIONS = "ADD_LEAD_CONVERSATIONS";
export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const SET_CONVERSATION_ORDER = "SET_CONVERSATION_ORDER";
export const FETCH_CONVERSATION_DOCUMENTS = "FETCH_CONVERSATION_DOCUMENTS";
export const GET_CONVERSATION_DOCUMENTS = "GET_CONVERSATION_DOCUMENTS";
export const COUNT_LEADS = "COUNT_LEADS";
export const GET_LEAD_APPOINTMENTS = "GET_LEAD_APPOINTMENTS";
export const EXPORT_LEADS = "EXPORT_LEADS";
export const FETCH_CONVERSATION_TRANSCRIPTS = "FETCH_CONVERSATION_TRANSCRIPTS";
export const FETCH_PDF = "FETCH_PDF";
export const FETCH_CSV = "FETCH_CSV";

export type Filter = {
  field: string | null;
  value: string | null;
};

export type Sort = {
  field: string;
  order: Order;
};

type Clio = {
  matter: Matter;
};

type Matter = {
  id: number;
};

export type Lead = {
  id: number;
  email: string;
  name: string;
  files: any;
  node_progresses: any;
  tags: Tag[];
  created_at: string;
  last_active: string;
  classifiers: Classifier[];
  phone_number: string;
  organization_id: string;
  appointment_request: any;
  assignee: User;
  clio: Clio;
  doc_files: any;
  address: string;
  latest_marketing_setting?: ConverationMarketingSetting;
};

export type Message = {
  message: string;
  formatted_message: string;
  sender_type: "chatbot_user" | "bot" | "user";
  user_id: number | null;
  user: User | null;
  created_at: any;
  id: number;
};

export interface ConverationMarketingSetting {
  source_name: string;
  campaign_name: string;
  meta?: object;
}

export type Conversation = {
  id: number;
  updated_at: Date;
  message_count: number;
  lead_count: number;
  classifier?: Classifier;
  campaign?: Campaign;
  bot?: Bot;
  marketing_setting?: ConverationMarketingSetting;
};

export type Campaign = {
  medium?: string;
  source?: string;
  campaign?: string;
};

export type Bot = {
  id?: number;
  title?: string;
  active?: boolean;
};

export type Conversation_Documents = {
  id: number;
  conversation_id: number;
  document_link: string;
  created_at: any;
  updated_at: any;
  file_number: number;
  file_type: string;
};

export type Lead_Appointments = {
  id: number;
  conversation_id: number;
  requested_time: Date;
  appointment_type: number;
  appointment_title: string;
  duration: string;
};

export const setFilter = (filter: Filter) => (dispatch) => {
  dispatch({
    type: SET_FILTER,
    filter,
  });

  getLeads()(dispatch);
};

/**
 * Updates the field and order to sort by in the state of the leads page
 * @param sort - the name of the field to sort by
 * @param order - the order to sort in (ASC or DESC)
 */
export const setSortAndOrder = (sort: string, order: Order) => (dispatch) => {
  dispatch({
    type: SET_SORT,
    sort,
    order,
  });

  getLeads()(dispatch);
};

export const getLead = (leadId) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_LEAD,
  });

  // Actually fetch lead
  fetchLead(leadId)
    .then((res) => {
      dispatch({
        type: SET_DETAIL_LEAD,
        lead: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_LEAD,
        loading: false,
      });
      // toggleSnackbar("We couldn't fetch this lead! Please try again", true)(dispatch);
    });
};

export const archiveLead = (leadId: number, onSuccess: Function) => (dispatch) => {
  dispatch({
    type: FETCH_ARCHIVE_LEAD,
  });

  putArchiveLead(leadId)
    .then((response) => {
      dispatch({
        type: LEAD_ARCHIVED,
        leadId: response.data.lead_id,
      });
      onSuccess();
    })
    .catch((error) => {
      toggleSnackbar("Unable to archive this Lead! Please try again.", null, "error");
    });
};

export const resetMessages = () => (dispatch) => {
  dispatch({
    type: RESET_MESSAGES,
  });
};

export const getMessages = (conversationId: number, page: number = 0) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_MESSAGES,
  });

  // Actually fetch lead
  fetchMessages(conversationId, page)
    .then((res) => {
      dispatch({
        type: page > 1 ? ADD_LEAD_MESSAGES : SET_LEAD_MESSAGES,
        page: parseInt(res.page),
        messages: res.messages,
        hasMore: res.hasMore,
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_MESSAGES,
        loading: false,
      });

      toggleSnackbar("We couldn't fetch this lead's messages! Please try again", true)(dispatch);
    });
};

export const getConversations = (leadId: number, page = 0, orderBy: Order, limit = 20) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_CONVERSATIONS,
  });

  // Actually fetch lead
  fetchConversations(leadId, page, orderBy, limit)
    .then((res) => {
      dispatch({
        type: page > 1 ? ADD_LEAD_CONVERSATIONS : SET_LEAD_CONVERSATIONS,
        page: parseInt(res.page),
        conversations: res.conversations,
        hasMore: res.hasMore,
        order: res.order,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_CONVERSATIONS,
        loading: false,
      });

      toggleSnackbar("We couldn't fetch this lead's conversations! Please try again", true)(dispatch);
    });
};

export const setConversationOrder = (orderBy: Order) => (dispatch) => {
  dispatch({ type: SET_CONVERSATION_ORDER, orderBy });
};

export const getLeads = (offset = null, reset = true) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_LEADS,
  });

  const { sort, order, filter } = store.getState().leads;

  // Actually fetch classifiers
  fetchLeads(offset, sort, order, filter)
    .then((res) => {
      dispatch({
        type: reset ? SET_LEADS : ADD_LEADS,
        leads: res.leads,
        offset: res.offset,
        hasMore: res.leads.length >= leadLimit,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_LEADS,
        loading: false,
      });
      toggleSnackbar("We couldn't fetch your leads! Please try again", true)(dispatch);
    });
};

export const countAllLeads = () => (dispatch) => {
  countLeads().then((res) => {
    dispatch({
      type: COUNT_LEADS,
      leadCount: res.lead_count,
    });
  });
  // .catch(err => {

  // })
};

export const setLead = (lead) => (dispatch) => {
  dispatch({
    type: SET_LEAD,
    lead,
  });
};

export const getLeadConversationDocuments = (lead_id: number) => (dispatch) => {
  dispatch({
    type: FETCH_CONVERSATION_DOCUMENTS,
  });
  fetchLeadsConversationDocuments(lead_id)
    .then((res) => {
      dispatch({
        type: GET_CONVERSATION_DOCUMENTS,
        doc_files: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_CONVERSATION_DOCUMENTS,
      });
      toggleSnackbar("Error fetching lead conversation documents! Please try again", true)(dispatch);
    });
};

export const getLeadAppointments = (lead_id: number) => (dispatch) => {
  dispatch({
    type: GET_LEAD_APPOINTMENTS,
  });
  fetchLeadAppointments(lead_id)
    .then((res) => {
      dispatch({
        type: GET_LEAD_APPOINTMENTS,
        leadAppointments: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_LEAD_APPOINTMENTS,
      });
      toggleSnackbar("Error fetching lead appointments! Please try again", true)(dispatch);
    });
};

export const getExportLeads = (sort: Sort, filter: Filter) => (dispatch) => {
  exportLeads(sort.field, sort.order, filter)
    .then((res) => {
      dispatch({
        type: EXPORT_LEADS,
      });
      //downloads leads csv file
      let link = document.createElement("a");
      let blob = new Blob(["\ufeff", res]);
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "leads_export.csv");
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      dispatch({
        type: EXPORT_LEADS,
      });
      toggleSnackbar("Error exporting lead inbox! Please try again", true)(dispatch);
    });
};

export const getConversationTranscripts = (lead_id: number) => (dispatch) => {
  fetchConversationTranscripts(lead_id)
    .then((res) => {
      dispatch({
        type: FETCH_CONVERSATION_TRANSCRIPTS,
        transcripts: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("Error fetching lead conversation transcripts! Please try again", true)(dispatch);
    });
};

export const getPDF = (lead_id: number, conversation_id: number) => (dispatch) => {
  fetchPDF(lead_id, conversation_id)
    .then((res) => {
      dispatch({
        type: FETCH_PDF,
      });
    })
    .catch((err) => {
      toggleSnackbar("Error fetching this file! Please try again.", true)(dispatch);
    });
};

export const getCSV = (lead_id: number, conversation_id: number) => (dispatch) => {
  fetchCSV(lead_id, conversation_id)
    .then((res) => {
      dispatch({
        type: FETCH_CSV,
      });
    })
    .catch((err) => {
      toggleSnackbar("Error fetching this file! Please try again.", true)(dispatch);
    });
};

export type LeadsAction =
  | {
      type:
        | "FETCH_LEADS"
        | "FETCH_LEAD"
        | "FETCH_MESSAGES"
        | "FETCH_LEAD_ARCHIVE"
        | "FETCH_CONVERSATIONS"
        | "FETCH_CONVERSATION_DOCUMENTS";
      loading: boolean;
    }
  | {
      type: "SET_LEADS" | "ADD_LEADS";
      leads: Lead[];
      offset: number;
      hasMore: boolean;
    }
  | {
      type: "SET_SORT";
      sort: string;
      order: string;
    }
  | {
      type: "SET_LEAD";
      lead: Lead;
    }
  | {
      type: "SET_DETAIL_LEAD";
      lead: Lead;
    }
  | {
      type: "SET_FILTER";
      filter: Filter;
    }
  | {
      type: "SET_LEAD_MESSAGES" | "ADD_LEAD_MESSAGES";
      messages: Message[];
      page: number;
      hasMore: boolean;
    }
  | {
      type: "SET_LEAD_CONVERSATIONS" | "ADD_LEAD_CONVERSATIONS";
      conversations: Conversation[];
      page: number;
      hasMore: boolean;
      order: Order;
    }
  | {
      type: "LEAD_ARCHIVED";
      leadId: number;
    }
  | {
      type: "RESET_MESSAGES";
    }
  | {
      type: "SET_CONVERSATION_ORDER";
      orderBy: Order;
    }
  | {
      type: "GET_CONVERSATION_DOCUMENTS";
      leadId: number;
      doc_files: Conversation_Documents[];
    }
  | {
      type: "COUNT_LEADS";
      leadCount: number;
    }
  | {
      type: "GET_LEAD_APPOINTMENTS";
      leadId: number;
      leadAppointments: Lead_Appointments[];
    }
  | {
      type: "EXPORT_LEADS";
    }
  | {
      type: "FETCH_CONVERSATION_TRANSCRIPTS";
      transcripts: Conversation[];
    }
  | {
      type: "FETCH_PDF";
    }
  | {
      type: "FETCH_CSV";
    };

import React from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { getPresignedLogoS3Url } from "lib/user";
import { userIsAdmin } from "lib/utilities/global";
import { RouteComponentProps } from "react-router";
import { useToggle } from "react-use";
import imageCompression from "browser-image-compression";
import { Crop, Delete } from "@material-ui/icons";

const compressionOptions = {
  maxSizeMB: 10,
  // maxWidthOrHeight: 400,
};

type Props = {
  orgId: number;
  setLogoURL: (string) => void;
  imageUrl?: string;
  onS3UploadError: (any) => void;
  fullscreenChatbotImage?: boolean;
  height: number;
  width: number
} & RouteComponentProps;

const ImageManager = ({ orgId, setLogoURL, onS3UploadError, user, imageUrl, fullscreenChatbotImage, height, width }: Props) => {
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [cropper, setCropper] = React.useState<Cropper>();
  const [cropModalOpen, toggleCropModalOpen] = useToggle(false);
  const [deleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const isAdmin = userIsAdmin(user);

  const onDrop = React.useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const compressedFile = await imageCompression(file, compressionOptions);
    let s3URL = undefined;
    getPresignedLogoS3Url(orgId, compressedFile.type)
      .then((res) => {
        s3URL = res.data.presigned_logo_url.split("?")[0];
        return axios.put(res.data.presigned_logo_url, compressedFile, {
          headers: { "Content-Type": compressedFile.type },
        });
      })
      .then((res) => {
        setLogoURL(s3URL);
      })
      .catch((err) => {
        onS3UploadError(err);
      });
  }, []);

  const dropzoneProps = isAdmin ? { onDrop } : { disabled: true };
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

  const handleSaveCrop = () => {
    if (typeof cropper !== "undefined") {
      toggleCropModalOpen();
      const url = cropper.getCroppedCanvas().toDataURL();
      fetch(url)
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], "File name", { type: "image/png" });
          const compressedFile = await imageCompression(file, compressionOptions);
          let s3URL = undefined;
          getPresignedLogoS3Url(orgId, compressedFile.type)
            .then((res) => {
              s3URL = res.data.presigned_logo_url.split("?")[0];
              return axios.put(res.data.presigned_logo_url, compressedFile, {
                headers: { "Content-Type": compressedFile.type },
              });
            })
            .then((res) => {
              setLogoURL(s3URL);
            })
            .catch((err) => {
              onS3UploadError(err);
            });
        });
    }
  };

  const handleDeleteIcon = () => {
    toggleDeleteModalOpen();
    setLogoURL("");
  };

  return (
    <div className="image_manager">
      {isAdmin && (
        <div {...getRootProps()} className="dropzone_area">
          <input {...getInputProps()} />
          <div>Click or Drag to upload Logo Image</div>
        </div>
      )}
      {imageUrl && (
        <>
          <div className="flex" style={{ margin: isAdmin ? "20px 0" : 0 }}>
            <div
              className="flex"
              style={{ flexDirection: "column", alignItems: "center", marginRight: "auto", marginLeft: 4 }}
            >
              <div className="image_preview_label">Preview</div>
              <div style={{ position: "relative" }}>
                <img className="image_preview" src={imageUrl} alt="cropped image" />
                {isAdmin && (
                  <>
                    <IconButton onClick={toggleDeleteModalOpen} className="delete_button">
                      <Delete htmlColor="white" style={{ fontSize: 16 }} />
                    </IconButton>
                    <IconButton onClick={toggleCropModalOpen} className="edit_button">
                      <Crop htmlColor="white" style={{ fontSize: 16 }} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          </div>
          <Dialog open={cropModalOpen} onClose={toggleCropModalOpen}>
            <DialogTitle>Crop Image</DialogTitle>
            <DialogContent>
              <Cropper
                className={ fullscreenChatbotImage ? 'rectangular' : '' }
                style={{ height: height, width: width }}
                initialAspectRatio={1 / 1}
                guides={true}
                src={imageUrl}
                ref={imageRef}
                dragMode="move"
                cropBoxMovable={false}
                cropBoxResizable={false}
                background={false}
                minCropBoxHeight={height}
                minCropBoxWidth={width}
                autoCropArea={1}
                checkOrientation={true}
                zoomTo={1}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleCropModalOpen}>Cancel</Button>
              <Button onClick={handleSaveCrop}>Save Crop</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Dialog open={deleteModalOpen} onClose={toggleDeleteModalOpen}>
        <DialogTitle>Remove Icon</DialogTitle>
        <DialogContent>
          <div>Are you sure you want to remove this icon? This cannot be undone.</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteModalOpen}>Cancel</Button>
          <Button onClick={handleDeleteIcon} className="remove_icon">
            Remove Icon
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageManager;

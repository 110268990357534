import { BotsAction, FETCH_BOTS, SET_BOTS } from "actions/bots";
import { Bot } from "actions/bot";

export type BotsStore = {
  data: Bot[];
  loading: boolean;
};

const initialState = {
  data: [],
  loading: false,
};

export default function (state: BotsStore = initialState, action: BotsAction): BotsStore {
  switch (action.type) {
    case FETCH_BOTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_BOTS: {
      return {
        ...state,
        loading: false,
        data: action.bots,
      };
    }
    default: {
      return state;
    }
  }
}

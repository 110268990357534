//Reserved
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Routes
import Index from "./routes/index";
import Logout from "./routes/logout";
import Protected from "./routes/protected";
import Public from "./routes/public";

// Cmps.
import Signup from "./pages/signup";
import Forgot from "./pages/forgot";
import ResetPassword from "./pages/resetPassword";
import Bots from "./pages/bots";
import Bot from "./pages/bot";
import Settings from "./pages/settings";
import ChatBot from "./pages/chatbot";
import LeadRouting from "./pages/leadRouting";
import AppointmentTypes from "./pages/appointmentType";
import Calendar from "./pages/calendar";
import Leads from "./pages/leads";
import Lead from "./pages/lead";
import NoMatch from "./pages/404";
import Admin from "./pages/admin";
import Dashboard from "./pages/dashboard";

import Checkout from "./pages/checkout";
import SmsForm from "./pages/sms";
import NewOrganization from "./pages/newOrganization";
import Pricing from "./pages/pricing";

export const INDEX = "/";
export const LOGIN = "/login";
export const NOMATCH = "/404";
export const SIGNUP = "/signup";
export const FORGOT = "/forgot";
export const LOGOUT = "/logout";
export const RESET_PASSWORD = "/reset_password";
export const BOTS = "/bots";
export const BOT = "/bot";
export const SITES = "/sites";
export const CHATBOT = "/chatbot";
export const APPOINTMENT_TYPE = "/appointment_type";
export const LEAD_ROUTING = "/lead_routing";
export const CALENDAR = "/calendar";
export const CONVERSATIONS = "/conversations";
export const DASHBOARD = "/dashboard";
export const LEADS = "/leads";
export const SETTINGS = "/settings";
export const ONBOARDING = "/onboarding";
export const PLUGINS = "/plugins";
export const ADMIN = "/admin";
export const CHECKOUT = "/checkout";
export const SMS = "/sms";
export const NEW_ORGANIZATION = "/new_organization";
export const PRICING = "/pricing";

export const PUBLIC = [LOGIN, SIGNUP, FORGOT, RESET_PASSWORD, SMS];

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop location={this.props.location}>
          <Switch>
            <Route path={INDEX} exact component={Index} />
            <Route path={SIGNUP} exact component={Signup} />
            <Public path={FORGOT} exact component={Forgot} />
            <Public path={RESET_PASSWORD} exact component={ResetPassword} />
            <Route path={SMS} exact component={SmsForm} />
            <Route path={LOGOUT} exact component={Logout} />
            <Route path={DASHBOARD} component={Dashboard} />
            <Route path={NEW_ORGANIZATION} component={NewOrganization} />
            <Route path={PRICING} component={Pricing} />
            <Protected path={LEADS + "/:id"} component={Lead} exact />
            <Protected path={LEADS} component={Leads} exact />
            <Protected path={BOT + "/:id"} component={Bot} />
            <Protected path={BOTS} exact component={Bots} />
            <Protected path={APPOINTMENT_TYPE} exact component={AppointmentTypes} />
            <Protected path={LEAD_ROUTING} exact component={LeadRouting} />
            <Protected path={CALENDAR} exact component={Calendar} />
            <Protected path={SETTINGS} component={Settings} />
            <Protected path={CHATBOT} exact component={ChatBot} />
            <Protected path={ADMIN} component={Admin} />
            <Protected path={CHECKOUT} component={Checkout} />
            <Route component={NoMatch} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default Router;

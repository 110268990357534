import React from "react";
import ReactDom from "react-dom";
import { useToggle } from "react-use";

import {
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { toggleSnackbar } from "actions/ui";

import {
  updateMarkdownVariable,
  createMarkdownVariable,
  deleteMarkdownVariable,
  UpdateMarkdownVariableParams,
} from "lib/markdownVariables";
import { CueType, CueTypeString } from "lib/cue";
import bot from "app/pages/bot";

type Props = {
  node: any;
  fetchNSetNode: Function;
  handleMultipleSelect: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleCaseTypeChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  dialogActionArea: React.MutableRefObject<HTMLDivElement>;
  onRequestClose: Function;
  isCaseSelect: boolean;
};

const initialParams = {
  id: undefined,
  label: "",
  optional: false,
  conditional: false,
  case_type: false,
  node_id: undefined,
};

const MarkdownSetting: React.FC<Props> = ({
  node,
  fetchNSetNode,
  handleMultipleSelect,
  handleCaseTypeChange,
  dialogActionArea,
  onRequestClose,
  isCaseSelect,
}: Props) => {
  const [params, setParams] = React.useState(initialParams);
  const { markdown_variable: markdownVariable } = node;
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useToggle(false);
  const nodeIsOption = node && node.node_type === CueTypeString[CueType.MULTIPLE_CHOICE];
  //const caseTypeSelectable = bot.leaddocket_case_type_id

  //console.log(caseTypeSelectable)
  React.useEffect(() => {
    if (markdownVariable && markdownVariable.id) {
      setParams(markdownVariable);
    } else if (!params.node_id) {
      setParams({
        ...initialParams,
        node_id: node.id,
      });
    }
  }, [node]);

  const deleteVariable = (): void => {
    deleteMarkdownVariable(params.id)
      .then(() => {
        setParams(initialParams);
        setConfirmDeleteVisible(false);
        fetchNSetNode(params.node_id.toString());
      })
      .catch(() => {
        toggleSnackbar("Something went wrong deleting this variable!");
      });
  };

  const createOrUpdateMarkdownVariable = (params) => {
    const methodToUse = params.id ? updateMarkdownVariable : createMarkdownVariable;
    return methodToUse(params)
      .then((res) => {
        const mV: UpdateMarkdownVariableParams = res.data;
        if (mV && mV.id) fetchNSetNode(mV.node_id.toString());
      })
      .catch(() => {
        toggleSnackbar("Something went wrong!");
      });
  };

  const handleConditionalChange = (event): void => {
    setParams({
      ...params,
      conditional: event.target.checked,
    });
  };

  const handleOptionalChange = (event): void => {
    setParams({
      ...params,
      optional: event.target.checked,
    });
  };

  const handleLabelChange = (event): void => {
    setParams({
      ...params,
      label: event.target.value,
    });
  };

  const handleSave = (): void => {
    createOrUpdateMarkdownVariable(params).finally(() => onRequestClose());
  };

  return (
    <div className="node__markdown__variable">
      <TextField
        variant="outlined"
        value={params.label}
        onChange={(event): void => handleLabelChange(event)}
        disabled={false}
        label="Variable Name"
      />
      <div className="flex">
        <FormControlLabel
          label="Conditional"
          control={<Checkbox checked={params.conditional} onChange={handleConditionalChange} />}
        />
        <FormControlLabel
          label="Optional"
          control={<Checkbox checked={params.optional} onChange={handleOptionalChange} />}
        />
        {nodeIsOption && (
          <FormControlLabel
            label="Multi"
            control={<Checkbox checked={node.multiple_selection} onChange={handleMultipleSelect} />}
          />
        )}
        {isCaseSelect && nodeIsOption && (
          <FormControlLabel
          label="Case Type"
          control={<Checkbox checked={node.multi_case_type} onChange={handleCaseTypeChange} />}
        />
        )}
        {params.id && (
          <Button
            onClick={() => setConfirmDeleteVisible(true)}
            style={{ marginLeft: "auto", color: "red" }}
            size="small"
          >
            Remove Variable
          </Button>
        )}
      </div>
      {confirmDeleteVisible && (
        <div
          className="flex"
          style={{
            flexDirection: "column",
            border: "1px solid lightgray",
            marginTop: 20,
            borderRadius: 5,
            padding: 7,
          }}
        >
          <div style={{ marginBottom: 5 }}>Are you sure you want to delete this variable? This cannot be undone.</div>
          <div>
            <Button onClick={() => setConfirmDeleteVisible(false)} size="small">
              Cancel
            </Button>
            <Button
              onClick={deleteVariable}
              style={{ color: "red", marginLeft: 3, border: "1px solid red" }}
              size="small"
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      {dialogActionArea.current &&
        ReactDom.createPortal(
          <>
            <Button onClick={handleSave} color="primary" variant="contained" className="settingsSaveButton">
              Save
            </Button>
          </>,
          dialogActionArea.current
        )}
    </div>
  );
};

export default MarkdownSetting;

import api from "lib/api";

export type CreateMarkdownVariableParams = {
  label: string;
  optional: boolean;
  node_id: number;
  conditional: boolean;
  case_type: boolean;
};

export type UpdateMarkdownVariableParams = {
  id: number;
  label: string;
  optional: boolean;
  conditional: boolean;
  conditional_variables?: string[];
  case_type: boolean;
  node_id: number;
};

export const createMarkdownVariable = (params: CreateMarkdownVariableParams) => {
  return api.post("/markdown_variables", params);
};

export const updateMarkdownVariable = (params: UpdateMarkdownVariableParams) => {
  return api.put(`/markdown_variables/${params.id}`, params);
};

export const fetchMarkdownVariables = (bot_id: number) => {
  return api.get(`/markdown_variables?bot_id=${bot_id}`, null);
};

export const deleteMarkdownVariable = (id: number) => {
  return api.deleteReq(`/markdown_variables/${id}`, null);
};

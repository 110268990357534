import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";
import NavBar from "app/components/navbar";
import { RouteComponentProps } from "react-router-dom";
import { getSmsTemplate, sendSms } from "lib/sms";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { axiosErr } from "lib/api";
import useQuery from "lib/utilities/useQuery";
import { AsYouType } from "libphonenumber-js";

import "sass/components/sms.scss";

type Site = {
  id: number;
  domain: string;
  bot_id: number;
  is_wildcard_uri: boolean;
  bot_title: string;
};

type Props = {
  toggleSnackbar: Function;
};

const SmsForm: React.FC<Props> = ({ toggleSnackbar }: Props) => {
  const query = useQuery();
  const apiKey = query.get("api_key");

  const [sites, setSites] = React.useState<Site[] | undefined>(undefined);
  const [template, setTemplate] = React.useState<string | undefined>(undefined);
  const [form, setForm] = React.useState({
    isSubmitting: false,
    phoneNumberForDisplay: undefined,
    phoneNumber: undefined,
    message: "",
    selectedSite: null,
  });

  React.useEffect(() => {
    const getTemplate = async (): Promise<void> => {
      try {
        const response = await getSmsTemplate(apiKey);
        const { sites, template } = response;
        setSites(sites);
        setTemplate(template);
        setForm({ ...form, message: template });
      } catch (err) {
        toggleSnackbar(axiosErr(err).error);
      }
    };

    if (apiKey) getTemplate();
  }, [apiKey]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e.preventDefault();
      setForm({ ...form, isSubmitting: true });
      await sendSms(apiKey, form.message, form.phoneNumber);
      toggleSnackbar("Message successfully sent!");
    } catch (e) {
      toggleSnackbar("Error sending SMS", true, "error");
    } finally {
      setForm({ ...form, isSubmitting: false });
    }
  };

  if (!apiKey) {
    return <h2 className="loading">api_key param required - please contact support@gideon.legal</h2>;
  } else if (apiKey && !sites) {
    return <h2 className="loading">Loading...</h2>;
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <h2>SMS Form</h2>
        <FormControl>
          <InputLabel shrink id="sms-site">
            Bot
          </InputLabel>
          <Select
            labelId="sms-site"
            value={form.selectedSite}
            onChange={(e): void => {
              setForm({ ...form, selectedSite: e.target.value, message: template + e.target.value });
            }}
          >
            {sites &&
              sites.map((site) => {
                return (
                  <MenuItem key={site.bot_id} value={site.domain}>{`${site.bot_title} - ${site.domain}`}</MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <TextField
          error={form.phoneNumber ? false : true}
          label="Phone #"
          id="standard-basic"
          defaultValue="+1"
          helperText="US phone numbers only"
          value={form.phoneNumberForDisplay}
          onChange={(e): void => {
            const ayt = new AsYouType("US");
            const num = ayt.input(e.target.value);
            setForm({
              ...form,
              phoneNumberForDisplay: e.target.value,
              phoneNumber: ayt.getNumber()?.isValid() ? ayt.getNumber().number : null,
            });
          }}
        />
        <TextField
          id="sms-message"
          label="Message"
          multiline
          rows={4}
          value={form.message}
          onChange={(e): void => setForm({ ...form, message: e.target.value })}
        />
        <button disabled={form.isSubmitting || !form.phoneNumber || !form.selectedSite} type="submit">
          {form.isSubmitting ? "Sending..." : "Send"}
        </button>
      </form>
    );
  }
};

const SMS: React.FC<RouteComponentProps> = ({ toggleSnackbar }: RouteComponentProps) => {
  return (
    <App className="public sms">
      <NavBar />
      <SmsForm toggleSnackbar={toggleSnackbar} />
    </App>
  );
};
export default connect()(SMS);

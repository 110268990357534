import { AxiosPromise } from "axios";
import api from "lib/api";

export const getStripeCheckoutSession = (priceId: string, seats: number, email): AxiosPromise => {
  return api.post("/stripe/checkout_session", { price_id: priceId, seats, email });
};

export const getStripeBillingPortalSession = (): AxiosPromise => {
  return api.post("/stripe/billing_portal_session", {});
};

import * as React from "react";
import connect from "app/connect";
import { fetchSuggestions } from "lib/tags";
import { Classifier, Tag, createClassifier, updateClassifier, getClassifiers } from "actions/classifiers";
import { RootStore } from "reducers";
import { User } from "reducers/user";
import { get } from "lib/utilities/global";
import { classifierUser } from "lib/classifiers";
import { Select, MenuItem } from "@material-ui/core";
import { AppointmentType } from "actions/appointmentTypes";
import { withStyles } from "@material-ui/core/styles";
import { userIsAdmin } from "lib/utilities/global";
import { RouteComponentProps } from "react-router";

//Cmps
import X from "assets/icons/ui/x.inline.svg";
import { isEmptyString } from "lib/utilities/global";
import HotInput from "../global/hotInput";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  classifier?: Classifier;
  className?: string;
  updateClassifier: Function;
  createClassifier: Function;
  getClassifiers: Function;
  toggleSnackbar: Function;
  setDeletion?: Function;
  selectedAttorney: User;
  currentUser: User;
  appointmentTypes: AppointmentType[];
} & RouteComponentProps;

type State = {
  suggestions: Tag[];
  inputTag: string;
  prevInputTag?: string | null;
  title?: string;
  appointmentTypeId?: number;
  userActive?: boolean;
  active?: boolean;
  notifications?: boolean;
  esign?: boolean;
};

const selectStyle = {
  width: "200px",
};

const StyledSelect = withStyles({
  root: {
    fontSize: 14,
  },
})(Select);

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
  },
})(MenuItem);

/**
 * Classifier Row Item that appears in classifiers component
 */
class ClassifierItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = props.classifier
      ? ClassifierItem.stateForClassifier(props.classifier, props.selectedAttorney)
      : {
          suggestions: [],
          inputTag: "",
        };
  }

  static stateForClassifier(classifier: Classifier, selectedAttorney: User): State {
    return {
      suggestions: [],
      inputTag: "",
      title: classifier.title,
      prevInputTag: null,
      active: classifier.active,
      appointmentTypeId: classifier.appointment_type_id,
      userActive:
        classifier.classifier_users.filter((c_u) => c_u.user_id === selectedAttorney.id && c_u.enabled).length > 0,
      notifications:
        classifier.classifier_users.filter((c_u) => c_u.user_id === selectedAttorney.id && c_u.notifications).length >
        0,
      esign:
        classifier.classifier_users.filter((c_u) => c_u.user_id === selectedAttorney.id && c_u.esign).length > 0
    };
  }

  componentDidUpdate(prevProps): void {
    if (
      (this.state.userActive === undefined && this.props.classifier !== undefined) ||
      (get(["id"], prevProps.selectedAttorney) !== get(["id"], this.props.selectedAttorney) && this.props.classifier)
    ) {
      this.setState(ClassifierItem.stateForClassifier(this.props.classifier, this.props.selectedAttorney));
    } else if (
      this.props.classifier &&
      prevProps.classifier &&
      this.props.classifier.classifier_users !== prevProps.classifier.classifier_users
    ) {
      this.updateAppointmentType(this.props.classifier.appointment_type_id);
    }
  }

  updateAppointmentType = (appointmentTypeId) => {
    this.setState({
      ...this.state,
      appointmentTypeId: appointmentTypeId,
    });
  };

  clearSuggestions = (): void => {
    this.setState({
      inputTag: "",
      prevInputTag: null,
      suggestions: [],
    });
  };

  updateClassifierUser = (updates): void => {
    if (!this.props.classifier) return;

    const params = {
      ...updates,
      user_id: this.props.selectedAttorney.id,
      classifier_id: this.props.classifier.id,
    };

    classifierUser(params)
      .then(() => {
        this.props.getClassifiers();
      })
      .catch(() => {
        this.props.toggleSnackbar("We ran into an issue updating this classifier! Please try again later.");
      });
  };

  // Proxy function which adds classifier ID to function call
  updateClassifier = (updates, withRefresh = true): void => {
    if (!this.props.classifier) return;

    this.props.updateClassifier(
      {
        ...updates,
        user_id: this.props.selectedAttorney.id,
        id: this.props.classifier.id,
        classifier_id: this.props.classifier.id,
      },
      withRefresh
    );
  };

  removeTag = (tagId: number): void => {
    const { classifier } = this.props;
    if (!classifier) return;
    // Filter out tag to remove from existing array of tags
    const tags = classifier.tags.filter((t) => t.id !== tagId);
    this.updateClassifier({
      tags: tags.map((t) => t.id),
    });
    this.clearSuggestions();
  };

  addTag = (tagId: number): void => {
    const { classifier } = this.props;
    if (!classifier) {
      this.props.createClassifier({
        tags: [tagId],
        title: "New classifier",
      });
    } else {
      const tag_ids = classifier.tags.map((t) => t.id);
      if (classifier.tags.find((t) => t.id === tagId))
        return this.props.toggleSnackbar("This tag is already a part of this classifier!", true);
      // Insert tag ID into existing list of tags
      this.updateClassifier({
        tags: tag_ids.concat([tagId]),
      });
    }
    this.clearSuggestions();
  };

  handleChange = (key: any, e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      [key]: e.target.value,
    } as any);
  };

  assignAppointmentType = (e) => {
    // 0: DEFAULT 30 MINUTE
    // -1: NONE
    //this.updateClassifierUser({ enabled: e.target.value <= 0 ? false : true });
    this.updateClassifier({
      appointment_type_id: e.target.value <= 0 ? null : e.target.value,
    });
  };

  /**
   * Handles creating a new classifier if
   * this classifier item doesn't have one
   */
  onClassifierTitleKeyUp = (e): void => {
    if (e.keyCode === 13 && !this.props.classifier) {
      this.props.createClassifier({
        title: this.state.title,
      });

      this.setState({ title: "" });
    }
  };

  /**
   * On Key up event for handling tag input
   */
  onKeyUp = (e): void => {
    const { classifier } = this.props;

    if (e.keyCode === 13) {
      const matchingTag: Tag = this.state.suggestions.find((t: Tag) => t.name === e.target.value);
      if (!matchingTag) return this.props.toggleSnackbar("Sorry, but the tag entered is not valid!");
      if (classifier) {
        this.addTag(matchingTag.id);
      }
    } else if (e.keyCode == 8 && this.state.inputTag === "" && this.state.prevInputTag === "") {
      // We check the prev. input because we want to make sure the user
      // has hit the backspace button twice
      if (!classifier || classifier.tags.length === 0) return;
      // Remove last tag
      this.removeTag(classifier.tags[classifier.tags.length - 1].id);
    } else if (this.state.prevInputTag !== e.target.value) {
      fetchSuggestions(e.target.value)
        .then((res) => {
          this.setState({
            suggestions: res,
          });
        })
        .catch(() => {
          this.setState({ suggestions: [] });
        });
    }

    this.setState({
      inputTag: e.target.value,
      prevInputTag: this.state.inputTag,
    });
  };

  scheduledDefaultValue = (classifier) => {
    if (!classifier.appointment_type_id && this.state.userActive) {
      return 0;
    } else {
      return classifier.appointment_type_id;
    }
  };

  dropDownOptions = (appointmentTypes) => {
    let options = [];
    options.push(<StyledMenuItem key={-1} value={-1}></StyledMenuItem>);
    if (!this.state.userActive) {
      // only give options if scheduled is checked
      return options;
    }
    options = options.concat(
      appointmentTypes.map((a_t) => (
        <StyledMenuItem key={a_t.id} value={a_t.id}>
          {a_t.title}
        </StyledMenuItem>
      ))
    );
    return options;
  };

  render(): JSX.Element {
    const { classifier, className, setDeletion, currentUser, selectedAttorney, appointmentTypes } = this.props;
    const { suggestions } = this.state;

    return (
      <div className={`classifier__row ${className || ""}`}>
        {classifier && setDeletion ? (
          <X
            onClick={(): void => userIsAdmin(this.props.user) && setDeletion(classifier.id)}
            className={`classifier__row__indicator icon-delete ${userIsAdmin(this.props.user) ? "" : "disabled"}`}
          />
        ) : (
          <img className="classifier__row__indicator" src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`} />
        )}

        <div className="classifier__row__name">
          <HotInput
            type="text"
            initialValue={this.state.title}
            onChange={(value): void => {
              this.setState({ title: value });
              if (!isEmptyString(value) && classifier && value !== classifier.title)
                this.updateClassifier({ title: value }, false);
            }}
            onKeyUp={this.onClassifierTitleKeyUp}
            noDelay={classifier == null}
            placeholder={classifier ? "Classifier title" : "add new classification"}
            disabled={!userIsAdmin(this.props.user)}
          />
        </div>

        {classifier && (
          <>
            <div className="classifier__row__active">
              <Checkbox
                defaultChecked={this.state.active}
                className="classifier__row__checkbox"
                onChange={(e): void => {
                  this.updateClassifier({ active: e.target.checked }, false);
                  this.setState({ active: e.target.checked });
                }}
                disabled={!userIsAdmin(this.props.user)}
              />
            </div>

            {(currentUser.id === selectedAttorney.id || currentUser.role === 'admin' || currentUser.role === 'superadmin') && (
              <div className="classifier__row__scheduled">
                <Checkbox
                  checked={this.state.notifications}
                  className="classifier__row__checkbox"
                  onChange={(e): void => {
                    this.updateClassifierUser({ notifications: e.target.checked });
                    this.setState({ notifications: e.target.checked });
                  }}
                  disabled={!userIsAdmin(this.props.user) && currentUser.id !== selectedAttorney.id}
                />
              </div>
            )}

            <div className="classifier__row__scheduled">
              <Checkbox
                checked={this.state.esign}
                className="classifier__row__checkbox"
                onChange={(e): void => {
                  this.updateClassifierUser({ esign: e.target.checked });
                  this.setState({ esign: e.target.checked });
                }}
                disabled={!userIsAdmin(this.props.user) && currentUser.id !== selectedAttorney.id}
              />
            </div>

            <div className="classifier__row__scheduled">
              <Checkbox
                checked={this.state.userActive}
                className="classifier__row__checkbox"
                onChange={(e): void => {
                  this.updateClassifierUser({ enabled: e.target.checked });
                  this.setState({ userActive: e.target.checked });
                }}
                disabled={!userIsAdmin(this.props.user) && currentUser.id !== selectedAttorney.id}
              />
            </div>

            

            <div className="classifier__row__appointment__type">
              <StyledSelect
                style={selectStyle}
                className="classifier__row__appointment__type"
                value={!this.state.appointmentTypeId ? (this.state.userActive ? 0 : -1) : this.state.appointmentTypeId}
                onChange={(e) => this.assignAppointmentType(e)}
                disabled={!this.state.userActive || !userIsAdmin(this.props.user)}
              >
                {this.dropDownOptions(appointmentTypes)}
              </StyledSelect>
            </div>

            <div className="classifier__row__tags">
              <div className="classifier__row__tags__list">
                {classifier &&
                  classifier.tags.map((tag, i) => (
                    <span
                      className={`tag ${userIsAdmin(this.props.user) ? "" : "disabled"}`}
                      key={i}
                      onClick={(): void => userIsAdmin(this.props.user) && this.removeTag(tag.id)}
                    >
                      {tag.name}
                    </span>
                  ))}
              </div>

              <div className="classifier__row__tags-inputContainer">
                <input
                  type="text"
                  value={this.state.inputTag}
                  onChange={(e): void => this.handleChange("inputTag", e)}
                  onKeyUp={this.onKeyUp}
                  placeholder="type to add new tag"
                  disabled={!userIsAdmin(this.props.user)}
                />

                <ul className={`classifier__suggestions ${suggestions.length > 0 && "visible"}`}>
                  <span>Suggested tags</span>

                  {suggestions.map((suggestion: Tag, i) => (
                    <li key={i} onClick={(): void => userIsAdmin(this.props.user) && this.addTag(suggestion.id)}>
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(
  (state: RootStore) => ({
    selectedAttorney: state.attorneys.selectedAttorney,
    currentUser: state.user,
    appointmentTypes: state.appointmentTypes.data,
  }),
  { createClassifier, updateClassifier, getClassifiers }
)(ClassifierItem);

import React from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, List, ListItem } from "@material-ui/core";
import connect from "app/connect";
import { RootStore } from "reducers";
import { getConversations, setConversationOrder, Lead } from "actions/leads";
import { detailLeadConversations } from "reducers/leads";
import SortingLabel from "../leads/sortingHeader/sortingLabel";
import ConversationsWrapper from "./conversations/conversationsWrapper";

type Props = {
  lead: Lead;
  getConversations: Function;
  setConversationOrder: Function;
  toggleView: Function;
  detailLeadConversations: detailLeadConversations;
  setCurrentConversation: Function;
};

const LeadConversations = ({
  getConversations,
  setConversationOrder,
  lead,
  detailLeadConversations,
  toggleView,
  setCurrentConversation,
}: Props) => {
  const { conversations, page, hasMore, order } = detailLeadConversations;
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    getConversations(lead.id, 1, order);
  }, [order]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const loadMore = () => {
    getConversations(lead.id, page + 1, order);
  };

  const handleMenuClick = (orderArg: string) => {
    handleClose();
    if (orderArg !== order) {
      setConversationOrder(orderArg);
    }
  };

  const handleBackConvo = () => {
    //setCurrentConversation(conversations[0])
    //set to last convo that's not empty
    let convo = conversations.find(i => i.lead_count !== 0);
    convo ? setCurrentConversation(convo) : setCurrentConversation(undefined);
  }

  const conversationListItems = conversations.map((conversation, i) => {
    const { updated_at, message_count, lead_count } = conversation;
    if(lead_count){
      const formattedDate = moment(updated_at).format("MM/DD/YYYY");
      const time = moment(updated_at).format("h:mm a");
      return (
        <div key={i}>
          <ListItem onClick={() => setCurrentConversation(conversation)} className="conversationCard">
            <div className="flex">
              <div className="messageCount">{message_count} Messages</div>
              <div className="messageTime" style={{ marginLeft: "auto", fontSize: 18 }}>{time}</div>
            </div>
            <div className="flex">
              <div>{lead_count} Lead Messages</div>
              <div style={{ marginLeft: "auto" }}>{formattedDate}</div>
            </div>
          </ListItem>
          <div style={{display: "flex", justifyContent: "center"}}>
            <Divider component="li" />
          </div>
        </div>
      );
    } else {
      return;
    }
    
  });

  return (
    <ConversationsWrapper 
      title="Conversation History"
      rightHeader={
        <SortingLabel 
          title="Newest" 
          currentSort={{field: "Newest", order: "ASC"}} 
          setSort={handleMenuClick} 
          defaultColor="#727272" 
          reverseArrow={true}
        />
      }
      body={
        <List disablePadding className="conversationList" id="scrollableList">
          <InfiniteScroll
            dataLength={conversationListItems.length}
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="scrollableList"
            loader={
              <h3 className="loader" key={0}>
                Loading ...
              </h3>
            }
          >
            {conversationListItems}
          </InfiniteScroll>
        </List>
      }
      backFunction={handleBackConvo}
    />
  );
};

export default connect(
  (state: RootStore) => ({
    detailLeadConversations: state.leads.detailLeadConversations,
  }),
  {
    getConversations,
    setConversationOrder,
  }
)(LeadConversations);

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import * as ui from "actions/ui";
import * as user from "actions/user";

/**
 * Returns baseline state to props e.g. UI and User objects
 * @param {Funciton} customStateToProps A custom funciton to define prop variables from state
 */
const mapStateToProps = (customStateToProps = null) => (state) => {
  return {
    ui: state.ui,
    user: state.user,
    ...(customStateToProps !== null ? customStateToProps(state) : {}),
  };
};

/**
 * Returns baseline dispatch props and allows you to include your own dispatch functions
 * @param {Object} actionCreators Object containing dispatch functions that should be available in props
 */
const mapDispatchToProps = (actionCreators) => (dispatch) =>
  bindActionCreators(
    {
      ...ui,
      ...user,
      ...actionCreators,
    },
    dispatch
  );

/**
 * Custom connect component
 * @param {*} stateToProp
 * @param {*} actionCreators
 */
export const customConnect = (stateToProp = null, actionCreators = null) => {
  return connect(mapStateToProps(stateToProp), mapDispatchToProps(actionCreators));
};

export default customConnect;

import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import { getAttorneys } from "actions/attorneys";
import { getAvailabilities, getPluginEvents } from "actions/availabilities";
import { getAppointmentTypes } from "actions/appointmentTypes";
import { setAttorney } from "actions/attorney";
import { AttorneyStore } from "reducers/attorneys";
import { RootStore } from "reducers";
import { UserStore } from "reducers/user";

// Cmps
import List from "@material-ui/core/List";
import UserListItem from "app/components/global/attorneyListItem";
import HandoffPanel from "app/components/leadRouting/handoffPanel";
import Layout from "app/components/global/layout";

// Sass
import "sass/pages/handoff.scss";

type Props = {
  getAttorneys: Function;
  setAttorney: Function;
  getAvailabilities: Function;
  attorneys: AttorneyStore;
  user: UserStore;
  getAppointmentTypes: Function;
} & RouteComponentProps;

type State = {};

/**
 * Handoff Container Component
 */
class LeadRouting extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.getAttorneys();
    this.props.getAppointmentTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.attorneys.selectedAttorney.id &&
      this.props.attorneys.data.length > 0 &&
      prevProps.attorneys.data.length == 0
    ) {
      let attorneySet = false;
      this.props.attorneys.data.forEach((attorney) => {
        if (attorney.id === this.props.user.id) {
          this.setSelectedAttorney(attorney);
          attorneySet = true;
        }
      });
      if (!attorneySet) this.setSelectedAttorney(this.props.attorneys.data[0]);
    } else if (
      this.props.attorneys.data.length > 0 &&
      prevProps.attorneys.selectedAttorney &&
      prevProps.attorneys.selectedAttorney.id
    ) {
      this.props.attorneys.data.forEach((attorney) => {
        if (
          attorney.id === prevProps.attorneys.selectedAttorney.id &&
          attorney.working_hours !== prevProps.attorneys.selectedAttorney.working_hours &&
          prevProps.attorneys.loading &&
          !this.props.attorneys.loading
        ) {
          this.setSelectedAttorney(attorney);
        }
      });
    }
  }

  setSelectedAttorney = (attorney) => {
    const { attorneys, setAttorney, getAvailabilities, getPluginEvents } = this.props;

    setAttorney(attorney);
    getAvailabilities(attorney.id, attorneys.pageStartDate, attorneys.pageEndDate);
    getPluginEvents(attorney.id, attorneys.pageStartDate, attorneys.pageEndDate);
  };

  render() {
    const { attorneys } = this.props;

    return (
      <Layout appClassName="platform" mobileTitle="Lead Routing" desktopOnly>
        <div className="handoff">
          <div className="handoff__menu">
            <h3 className="handoff__menu__header type-24-bold-nexa">Lead Routing</h3>

            <List>
              {attorneys.data.map((attorney, i) => (
                <UserListItem
                  key={i}
                  clickHandler={() => this.setSelectedAttorney(attorney)}
                  isClicked={attorneys.selectedAttorney && attorneys.selectedAttorney.id === attorney.id}
                  userData={attorney}
                />
              ))}
            </List>
          </div>

          <HandoffPanel />
        </div>
      </Layout>
    );
  }
}

export default connect((state: RootStore) => ({ attorneys: state.attorneys }), {
  getAttorneys,
  setAttorney,
  getAvailabilities,
  getPluginEvents,
  getAppointmentTypes,
})(LeadRouting);

import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import { createClioMatter } from "actions/plugins";
import { customSelectStyles } from "lib/utilities/ui";
import { getPracticeAreas } from "lib/plugins";
import "sass/components/leadClioSync";
import { TextField, CircularProgress, DialogTitle, IconButton } from "@material-ui/core";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { updateLead } from "lib/leads";
import { setLead } from "actions/leads";
import CloseIcon from "@material-ui/icons/Close"
import clioCheckmark from "assets/clio-checkmark.png"

// Cmps
import Dialog from "app/components/global/dialog";
import { Lead } from "actions/leads";
import { Plugin } from "actions/plugins";
import Select from "react-select";
import Confirmation from "app/components/global/confirmation";
import { axiosErr } from "lib/api";

type Props = {
  setVisible: Function;
  setIsClioTokenValid: Function;
  visible: boolean;
  lead: Lead;
  clioPlugin: Plugin;
  createClioMatter: Function;
} & RouteComponentProps;

type State = {
  loading: boolean;
  practiceArea: string;
  practiceAreaOptions?: Array<{ id: number; name: string }>;
  practiceAreaError: string;
  practiceAreaSelected: string;
  name: string;
  nameError: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phoneError: string;
  email: string;
  emailError: string;
  description: string;
  descriptionError: string;
  conflictConfirmVisible: boolean;
  address: string;
  addressError: string;
};

class LeadClioSync extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      practiceArea: "",
      practiceAreaError: undefined,
      practiceAreaSelected: "",
      name: this.props.lead.name || "",
      nameError: undefined,
      phone1: this.props.lead.phone_number ? this.props.lead.phone_number.slice(0, 3) : "",
      phone2: this.props.lead.phone_number ? this.props.lead.phone_number.slice(3, 6) : "",
      phone3: this.props.lead.phone_number ? this.props.lead.phone_number.slice(6) : "",
      phoneError: undefined,
      email: this.props.lead.email || "",
      emailError: undefined,
      description: `${this.props.lead.name?.toUpperCase() || ""} MATTER`,
      descriptionError: undefined,
      conflictConfirmVisible: false,
      address: this.props.lead.address || "",
      addressError: undefined
    };
  }

  componentDidMount() {
    getPracticeAreas(this.props.clioPlugin.id)
      .then((response) => {
        this.setState({
          practiceAreaOptions: response.practice_areas.map((a) => {
            return { value: a.id, label: a.name };
          }),
        });
      })
      .catch((error) => {
        const err = axiosErr(error);

        if (err.code === "invalid_token") {
          this.props.setIsClioTokenValid(false);
        } else {
          this.props.toggleSnackbar("Error fetching Clio Practice Areas.", true, "error");
        }
      });
  }

  handleChange = (key, e) => {
    this.setState({
      ...this.state,
      [key]: e.target.value,
      [`${key}Error`]: undefined,
    });
  };

  clioSummaryForLeadAttribute(attribute) {
    const { title, key } = attribute;

    return (
      <div className={`leadFields__row leadFields__row__${key}`}>
        <div className={`leadFields__row__title leadFields__row__title__${key}`}>{title} </div>
        <TextField
          variant="outlined"
          type="text"
          className={`leadFields__row__input leadFields__row__input__${key}`}
          error={!!this.state[`${key}Error`]}
          helperText={!!this.state[`${key}Error`] ? this.state[`${key}Error`] : ""}
          value={this.state[key] || ""}
          onChange={(e) => this.handleChange(key, e)}
          disabled={this.state.loading}
        />
      </div>
    );
  }

  inputPhoneNumber() {
    return (
      <div className={`leadFields__row leadFields__row__phone`} style={{display: "flex", flexDirection:"row", flexWrap:"wrap", alignItems: "center"}}>
        <div className={`leadFields__row__title leadFields__row__title__phone`}>Phone Number </div>
        <div className="lineBreak" style={{ width:"100%" }}></div>
        <TextField
          variant="outlined"
          type="text"
          className={`leadFields__row__input__phone`}
          error={!!this.state[`phoneError`]}
          helperText={!!this.state[`phoneError`] ? this.state[`phoneError`] : ""}
          value={this.state.phone1 || ""}
          onChange={(e) => this.handleChange("phone1", e)}
          disabled={this.state.loading}
          inputProps={{ maxLength: 3}}
          
        />
        <span style={{padding: "0 10px"}}>-</span>
        <TextField
          variant="outlined"
          type="text"
          className={`leadFields__row__input__phone`}
          error={!!this.state[`phoneError`]}
          helperText={!!this.state[`phoneError`] ? this.state[`phoneError`] : ""}
          value={this.state.phone2 || ""}
          onChange={(e) => this.handleChange("phone2", e)}
          disabled={this.state.loading}
          inputProps={{ maxLength: 3}}
        />
        <span style={{padding: "0 10px"}}>-</span>
        <TextField
          variant="outlined"
          type="text"
          className={`leadFields__row__input__phone`}
          error={!!this.state[`phoneError`]}
          helperText={!!this.state[`phoneError`] ? this.state[`phoneError`] : ""}
          value={this.state.phone3 || ""}
          onChange={(e) => this.handleChange("phone3", e)}
          disabled={this.state.loading}
          inputProps={{ maxLength: 4}}
        />
      </div>
      
    );
  }

  validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  validatePhone = (phone: string) => {
    const phoneNumber = parsePhoneNumberFromString(phone, "US");
    return phoneNumber && phoneNumber.isValid();
  };

  validateClio = () => {
    let validated = true;
    let nameError, emailError, phoneError, descriptionError;

    if (!this.state.name || this.state.name === "") {
      nameError = "Name is required.";
      validated = false;
    }

    if (this.state.email && !this.validateEmail(this.state.email)) {
      emailError = "That email doesn't look quite right";
      validated = false;
    }

    let newPhone = `${this.state.phone1 + this.state.phone2 + this.state.phone3}`
    if (this.state.phone1 && this.state.phone2 && this.state.phone3 && !this.validatePhone(newPhone)) {
      phoneError = "That phone number doesn't look quite right";
      validated = false;
    }

    this.setState({
      ...this.state,
      nameError,
      emailError,
      phoneError,
      descriptionError,
    });

    return validated;
  };

  showConflictScreen = (visible) => {
    this.setState({
      ...this.state,
      loading: false,
      conflictConfirmVisible: visible,
    });
  };

  showCircularProgress = (visible) => {
    this.setState({
      ...this.state,
      loading: visible,
      conflictConfirmVisible: false,
    });
  };

  clioFinished = () => {
    this.showCircularProgress(false);
    this.props.setVisible(false);
  };

  handleClioChange = (createNew) => {
    const { createClioMatter, clioPlugin, lead, setVisible } = this.props;
    let updatedPhone = `${this.state.phone1 + this.state.phone2 + this.state.phone3}`
    if (this.validateClio()) {
      const params = {
        id: lead.id,
        email: this.state.email,
        phone_number: updatedPhone,
        name: this.state.name,
        address: this.state.address
      };

      this.showCircularProgress(true);
      updateLead(params)
        .then((res) => {
          setLead(res.data);
          createClioMatter(
            lead,
            clioPlugin,
            createNew,
            this.state.practiceArea,
            this.state.description,
            () => this.clioFinished(),
            () => this.showConflictScreen(true)
          );
        })
        .catch((err) => {
          this.props.toggleSnackbar("Error updating lead, please try again!");
          this.showCircularProgress(false);
        });
    }
  };

  handlePracticeAreaChange = (option) => {
    this.setState({
      ...this.state,
      practiceArea: option.value,
      practiceAreaSelected: option,
    });
  };

  render() {
    const { lead, setVisible, visible } = this.props;

    return (
      <Dialog className="leadClioSync" setVisible={setVisible} visible={visible}>
          <h2>Sync To Clio</h2>
          <IconButton className="cancel" disabled={this.state.loading} onClick={() => setVisible(false)}>
            <CloseIcon />
          </IconButton>
          <hr />
        <p>{`Would you like to ${
          lead.clio ? "Update" : "Create a"
        } Contact and Matter in Clio with the following contact information?`}</p>

        <div className="leadFields">
          {this.clioSummaryForLeadAttribute({ title: "Name", key: "name" })}
          {this.clioSummaryForLeadAttribute({ title: "Email", key: "email" })}
          {/* {this.clioSummaryForLeadAttribute({ title: "Phone Number", key: "phone" })} */}
          {this.inputPhoneNumber()}
          {this.clioSummaryForLeadAttribute({ title: "Description", key: "description" })}
        </div>

        <div className="leadFields__row">
          <span className="leadFields__row__title">Practice Area</span>
          <Select
            className="leadFields__row__input__select"
            placeholder="Practice Area"
            onChange={(option) => this.handlePracticeAreaChange(option)}
            options={this.state.practiceAreaOptions}
            value={this.state.practiceAreaSelected}
            styles={customSelectStyles}
            maxMenuHeight={45}
            disabled={this.state.loading}
          />
        </div>

        <div className="leadClioSync__button_row">
          <button disabled={this.state.loading} onClick={() => this.handleClioChange(false)} style={{border:"0", color:"#FEFEFE", backgroundColor:"#3F6DE1"}}>
            Sync Lead to Clio
          </button>
          <hr style={{width:"76px", borderTop:"4px dashed #3F6DE1", opacity:"30%", marginLeft:"10px"}} />
          <img src={clioCheckmark} style={{opacity:"30%"}} />
        </div>
        <Confirmation
          title="Create new matter"
          message="A contact already exists with an associated matter.
                        This lead has been updated to reflect the existing contact. 
                        Would you like to create a new matter for this contact?"
          actionTitle="Create"
          cancelTitle={null}
          setVisible={(visible) => this.setState({ ...this.state, conflictConfirmVisible: visible })}
          cancel={null}
          visible={this.state.conflictConfirmVisible}
          confirmation={() => this.handleClioChange(true)}
        />
        {this.state.loading && (
          <CircularProgress
            className="leadClioSync__loading"
            style={{
              height: 80,
              width: 80,
            }}
          />
        )}
      </Dialog>
    );
  }
}

export default connect(null, { createClioMatter })(LeadClioSync);

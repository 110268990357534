import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { User } from "reducers/user";

// Cmps
import UserItem from "./userItem";

type State = {
    activeUserItem: number | null;
}

type Props = {
    users: User[];
    title?: string;
    loading: boolean;
    overflow: boolean;
    // fetchMore: Function;
};

class UserList extends React.Component<Props, State> {

    static defaultProps = {
        overflow: false
    }

    constructor(props:Props){
        super(props);

        this.state = {
            activeUserItem: null
        }
    }

    render() {
        const { overflow, users } = this.props;

        return (
            <div className={`user__list ${overflow ? 'overflow' : ''}`}>
                <h3 className="type-16-nexa user__list__title">Current Users</h3>
                <li className="user__item user__item--first"> 
                    <div className="user__item__title">Name</div>
                    <div className="user__item__email">Email</div>
                    <div className="user__item__title">Permissions</div>
                    <div className="user__item__options">Options</div>
                </li>

                <ul ref="userList">
                    {users.map((user, i) => {
                        return (
                        <UserItem 
                            key={i}
                            member={user} 
                            isActive={this.state.activeUserItem === user.id}
                            makeUserItemActive={() => this.setState({activeUserItem: user.id})}
                        />)
                    })}
                </ul>
            </div>
        );
    }
}

export default UserList;

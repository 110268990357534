import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";
import NavBar from "app/components/navbar";
import { Switch, Route } from "react-router";
import AdminImpersonateList from  "app/components/admin/impersonateList";
import OrganizationForm from  "app/components/admin/organizationForm";

// Sass
import "sass/pages/admin.scss";

const Admin = ({ match }) => {
    return (
      <App className="platform">
        <div className="admin container container--unStyled">
          <NavBar />
          <h2>Admin Portal</h2>
          <div className="admin__container">                    
            <Switch>
              <Route
                path={match.url + "/impersonate"}
                exact
                component={AdminImpersonateList}
              />
              <Route
                path={match.url + "/organizations"}
                exact
                component={OrganizationForm}
              />
            </Switch>
          </div>
        </div>
      </App>
    )
}

export default connect()(Admin);

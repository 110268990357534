import api from "lib/api";

export const fetchUsers = () => {
  return api.get("/admin/users", null);
};

export const impersonateUser = (id: string) => {
  return api.put("/admin/impersonate", { auth: { id } });
};

export const getAdminToken = () => {
  return localStorage.getItem("adminToken");
};

export const setAdminToken = (token: string) => {
  return localStorage.setItem("adminToken", token);
};

export const createOrganizationInvite = (organizationParams) => {
  return api.post("/admin/create_organization_invite", organizationParams);
};

export const getOrganizationInvites = () => {
  return api.get("/admin/organization_invites");
};

export const getOrganizationPlans = () => {
  return api.get("/organizations/plans");
};

import * as React from "react";
import { login } from "lib/user";
import { Link } from "react-router-dom";
import connect from "app/connect";

// Cmps.
import Loader from "app/components/global/loader";
import FormControl from "app/components/global/formControl";
import { axiosErr } from "lib/api";

type Props = {
    saveUser: Function; // TODO; type this
    toggleSnackbar: Function;
}

type State = {
    loading: Boolean;
    username: String;
    password: String;
}

class LoginForm extends React.Component<Props, State> {
    state = {
        loading: false,
        username: "",
        password: ""
    };

    handleChange = (key:any, e:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            [key] : e.target.value 
        } as any);
    }

    handleLogin = e => {
        e.preventDefault()
        if (this.state.loading) return
        this.setState({ loading: true })
        
        const { saveUser } = this.props;

        login(this.state.username, this.state.password)
        .then(response => {
            saveUser(response.data.jwt);
        })
        .catch(err => {
            err = axiosErr(err)

            if (err.code == 'ERR_404_NO_USER') {
                this.props.toggleSnackbar("We couldn't find a user with this email!")
            } else if (err.code == 'ERR_INCORRECT_PASS'){
                this.props.toggleSnackbar("That password doesn't seem to be correct!")
            } else {
                this.props.toggleSnackbar("We're having trouble logging you in!")
            }
        })
        .then(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        return (
            <div className="loginForm window-form">
                <h2>Sign in</h2>

                <h4>Sign in to continue to Gideon</h4>

                <form onSubmit={this.handleLogin}>
                    <FormControl 
                        type="text" 
                        value={this.state.username} 
                        title
                        placeholder="Email"
                        onChange={(e) => this.handleChange('username', e)} 
                    />
                    
                    <FormControl 
                        type="password" 
                        placeholder="Password"
                        title
                        value={this.state.password} 
                        onChange={(e) => this.handleChange('password', e)} 
                    />

                    <button onClick={(e) => this.handleLogin(e)}>
                        {this.state.loading ? <Loader dark /> : 'Log in'}
                    </button>
                </form>
                <Link to='/forgot' className="light-blue align-right">Forgot password?</Link>
            </div>
        )
    }
}

export default connect()(LoginForm);
import React, { useState, useRef } from "react";

import { Dialog, DialogTitle, DialogContent, DialogActions, Tab, Tabs, Paper, withStyles } from "@material-ui/core";
import { fetchNSetNode } from "actions/bot";
import connect from "app/connect";
import TabPanel from "app/components/global/tabPanel";

import { RootStore } from "reducers";
import { hasLeadDocketConfigured, isLDMulti } from "selectors/detailbot";
import { documentGenerationEnabled } from "selectors/user";

import { LeadDocketCaseType, LeadDocketField } from "lib/plugins";
import { isLeadDocketMappableNode } from "lib/nodes";

import MarkdownSetting from "./markdownSetting";
import LeadDocketSetting from "./leaddocketSetting";
import LeadDocketCaseSetting from "./leaddocketCaseSetting";
import { CueType, CueTypeString } from "lib/cue";
import { getLeaddocketMappingForNode, upsertLeaddocketMappingForNode, getLeaddocketCaseTypes } from "lib/plugins";
import bot from "app/pages/bot";

type Props = {
  settingsOpen: boolean;
  toggleSettingsOpen: Function;
  node: any;
  dialog: any;
  parentNode: any;
  fetchNSetNode: Function;
  handleMultipleSelect: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleCaseTypeChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  classes: any;
  // redux props
  hasLeadDocketConfigured: boolean;
  isLDMulti: boolean;
  documentGenerationEnabled: boolean;
  leadDocketFields: LeadDocketField[];
  leadDocketCaseType: LeadDocketCaseType[];
  isCaseSelect: boolean;
};

const styles = (theme) => ({
  paper: {
    background: theme.palette.grey[100],
  },
});

const DialogSettings: React.FC<Props> = ({
  settingsOpen,
  toggleSettingsOpen,
  node,
  dialog,
  handleMultipleSelect,
  handleCaseTypeChange,
  hasLeadDocketConfigured,
  isLDMulti,
  documentGenerationEnabled,
  leadDocketFields = [],
  leadDocketCaseType = [],
  isCaseSelect,
  classes,
}: Props) => {
  const [selectedTab, selectTab] = useState(0);
  const { title } = node;
  const dialogActionArea = useRef(null);

  const handleClose = () => {
    toggleSettingsOpen();
    selectTab(0);
  };
  const nodeIsDialog = node && node.node_type === CueTypeString[CueType.DIALOG_LINK];
  const isLeadDocketEnabled = (hasLeadDocketConfigured && isLeadDocketMappableNode(node)) && ((dialog.case_type && isLDMulti) || !isLDMulti);
  
  const isCaseTypeDialog = isCaseSelect && nodeIsDialog
  //const ldCaseTypes = fetchLDCaseTypes();

  return (
    <Dialog open={settingsOpen} onClose={handleClose} fullWidth maxWidth="sm" classes={classes}>
      <DialogTitle>{title} Settings</DialogTitle>
      <DialogContent>
        <Paper elevation={0}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => selectTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
          >
            {(documentGenerationEnabled || hasLeadDocketConfigured) ? (
              <Tab
                label="Add Variable"
                classes={{
                  root: classes.tab,
                }}
              />
            ) : null}
            {isLeadDocketEnabled ? <Tab label="Add Mapping" /> : null}
            {isCaseTypeDialog ? <Tab label="Case Type" /> : null}
          </Tabs>
          {(documentGenerationEnabled || hasLeadDocketConfigured)  ? (
            <TabPanel value={selectedTab} index={0}>
              <MarkdownSetting
                node={node}
                handleMultipleSelect={handleMultipleSelect}
                handleCaseTypeChange={handleCaseTypeChange}
                fetchNSetNode={fetchNSetNode}
                dialogActionArea={dialogActionArea}
                onRequestClose={handleClose}
                isCaseSelect={isCaseSelect}
              />
            </TabPanel>
          ) : null}
          {isLeadDocketEnabled ? (
            <TabPanel value={selectedTab} index={(documentGenerationEnabled || hasLeadDocketConfigured) ? 1 : 0}>
              <LeadDocketSetting
                node={node}
                case_type={dialog.case_type}
                onRequestClose={handleClose}
                leadDocketFields={leadDocketFields}
                dialogActionArea={dialogActionArea}
              />
            </TabPanel>
          ) : null}
          {isCaseTypeDialog ? (
            <TabPanel value={selectedTab} index={(documentGenerationEnabled|| hasLeadDocketConfigured) ? 1 : 0}>
            <LeadDocketCaseSetting
              node={node}
              onRequestClose={handleClose}
              leadDocketCaseType={leadDocketCaseType}
              dialogActionArea={dialogActionArea}
            />
          </TabPanel>
          ) : null}          
        </Paper>
      </DialogContent>
      <DialogActions>
        <div ref={dialogActionArea} />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(
  connect(
    (state: RootStore) => ({
      documentGenerationEnabled: documentGenerationEnabled(state),
      hasLeadDocketConfigured: hasLeadDocketConfigured(state),
      isLDMulti: isLDMulti(state),
    }),
    { fetchNSetNode }
  )(DialogSettings)
);

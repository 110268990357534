import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import Checkbox from "@material-ui/core/Checkbox";
import { getAttorneys } from "actions/attorneys";
import { updateUser } from "lib/user";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Sass
import "sass/components/setWorkingHours.scss";

// Cmps
import Dialog from "app/components/global/dialog";
import FormControl from "app/components/global/formControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { utcTimeStringToDates } from "lib/utilities/time";
import moment from "moment";
import momentTz from "moment-timezone";
import { User } from "reducers/user";

const formatBlockOfTime = (start, end): string => {
  return start + "," + end;
};

type Props = {
  getAttorneys: Function;
  selectedUser: User;
  setVisible: Function;
  visible: boolean;
  refresh: Function;
  workingHours: { time_zone: string; [day: number]: { available: boolean; hours: string[] } };
} & RouteComponentProps;

const initialState = {
  loading: false,
  working_hours: {
    0: {
      available: false,
      hours: [],
    },
    1: {
      available: false,
      hours: [],
    },
    2: {
      available: false,
      hours: [],
    },
    3: {
      available: false,
      hours: [],
    },
    4: {
      available: false,
      hours: [],
    },
    5: {
      available: false,
      hours: [],
    },
    6: {
      available: false,
      hours: [],
    },
  },
  start_time: "09:00",
  end_time: "17:00",
};

const SetWorkingHours: React.FC<Props> = ({
  getAttorneys,
  toggleSnackbar,
  selectedUser,
  setVisible,
  visible,
  refresh,
  workingHours,
}: Props) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    if (workingHours && workingHours !== state.working_hours) {
      Object.keys(workingHours).forEach((day) => {
        if (workingHours[day].available && workingHours !== state.working_hours) {
          const [start, end] = utcTimeStringToDates(
            workingHours[day].hours[0],
            moment().day(parseInt(day)).format("YYYY-MM-DD")
          );

          const tZ = workingHours.time_zone;
          let start_time, end_time;
          if (tZ) {
            start_time = momentTz(start).tz(tZ).toDate();
            end_time = momentTz(end).tz(tZ).toDate();
          } else {
            start_time = moment(start).toDate();
            end_time = moment(end).toDate();
          }

          setState({
            ...state,
            working_hours: workingHours,
            start_time: start_time,
            end_time: end_time,
          });
        }
      });
    }
  }, [workingHours]);

  const handleWorkingHoursChange = (key: string, m: Date): void => {
    // need to return to prevent users from deleting/saving invalid times
    if (!m) return;
    const newHours = {};
    const start_time = key === "start_time" ? m : state.start_time;
    const end_time = key === "end_time" ? m : state.end_time;
    Object.keys(state.working_hours).forEach((day) => {
      if (state.working_hours[day].available) {
        newHours[day] = {
          available: true,
          hours: [formatBlockOfTime(start_time, end_time)],
        };
      } else {
        newHours[day] = {
          available: false,
          hours: [],
        };
      }
    });

    setState({
      ...state,
      [key]: m,
      working_hours: newHours,
    } as any);
  };

  const removeHoursForDay = (day): void => {
    setState({
      ...state,
      working_hours: {
        ...state.working_hours,
        [day]: {
          available: false,
        },
      },
    });
  };

  const setHoursForDay = (day): void => {
    setState({
      ...state,
      working_hours: {
        ...state.working_hours,
        [day]: {
          available: true,
          hours: [formatBlockOfTime(state.start_time, state.end_time)],
        },
      },
    });
  };

  const handleDaySelectCheck = (e) => {
    e.target.checked ? setHoursForDay(e.target.value) : removeHoursForDay(e.target.value);
  };

  const createDayCheckbox = (label, day): React.ReactElement => {
    return (
      <FormControlLabel
        value={day.toLowerCase()}
        className="day__checkbox__label"
        control={
          <Checkbox
            checked={state.working_hours[day].available !== undefined ? state.working_hours[day].available : false}
            className="day__checkbox"
            onChange={(e): void => handleDaySelectCheck(e)}
          />
        }
        label={label}
        labelPlacement="top"
      />
    );
  };

  const createWorkingHours = (e): void => {
    e.preventDefault();
    const { working_hours, start_time, end_time } = state;
    setState({ ...state, loading: true });

    // strip timezone
    const wh = { ...working_hours };
    Object.keys(wh).forEach((day) => {
      if (wh[day].available) {
        wh[day]["hours"] = [formatBlockOfTime(start_time, end_time)];
      }
    });

    const params = {
      id: selectedUser.id,
      working_hours: wh,
    };

    updateUser(params)
      .then(() => {
        getAttorneys();
      })
      .catch((err) => {
        const errData = err.response.data;
        let errorMsg = "We ran into an error saving working hours!";
        if (errData && errData.code && errData.error) {
          errorMsg = errData.error;
        }
        toggleSnackbar(errorMsg);
      })
      .finally(() => {
        refresh();
        setState({
          ...state,
          loading: false,
        });
      });
  };

  return (
    <Dialog className="set__working__hours" setVisible={setVisible} visible={visible} excludedTargetRegex={"Mui*"}>
      <h3 className="type-24-bold">{workingHours ? "Edit Working Hours" : "Set Working Hours"}</h3>

      <div className="formField-container-day-selection">
        {createDayCheckbox("Su", "0")}
        {createDayCheckbox("Mo", "1")}
        {createDayCheckbox("Tu", "2")}
        {createDayCheckbox("We", "3")}
        {createDayCheckbox("Th", "4")}
        {createDayCheckbox("Fr", "5")}
        {createDayCheckbox("Sa", "6")}
      </div>

      <div className="formField-container">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="timePicker">
            <KeyboardTimePicker
              value={state.start_time}
              onChange={(e): boolean => {
                handleWorkingHoursChange("start_time", e);
                return false;
              }}
              style={{ backgroundColor: "#f6f7f8", border: "none" }}
            />
          </div>
          <div className="timePicker">
            <KeyboardTimePicker
              value={state.end_time}
              onChange={(e): boolean => {
                handleWorkingHoursChange("end_time", e);
                return false;
              }}
              style={{ backgroundColor: "#f6f7f8", border: "none" }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>

      <div className="formField-container Timezone">
        <FormControl type="string" value={selectedUser.time_zone} title placeholder="Timezone" disabled />
      </div>
      <button onClick={(e): void => !state.loading && visible && createWorkingHours(e)} style={{marginTop: "25px"}}>{"Save"}</button>
    </Dialog>
  );
};

export default connect(null, { getAttorneys })(SetWorkingHours);

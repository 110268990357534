import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Bot, Node } from "actions/bot";
import { createNodeParams, updateNodeParams } from "lib/nodes";
import Select from "react-select";
import DialogNodeOption from "app/components/botDetail/dialogNodeOption";
import NodeConnection, { SKIP, SUBMITTED } from "app/components/botDetail/nodeConnection";
import {
  Cue,
  CueType,
  nodeToCue,
  cueTitle,
  dateAttributesToOptions,
  dateOptionsToAttributes,
  cueTypeToNodeSpecs,
  nodeSpecsToCueType,
  dateOptions,
  defaultCueValues,
} from "lib/cue";
import CueTypeSelection from "app/components/botDetail/cueTypeSelection";
import DialogLink from "app/components/botDetail/dialogLink";
import MarkDownInput from "app/components/botDetail/markDownInput";
import HandoffNodeBodyElement from "./handoffNodeBodyElement";
import { LEAD_ROUTING } from "app/router";
import { Checkbox } from "@material-ui/core";
import { userIsAdmin } from "lib/utilities/global";

// Sass
import "sass/components/dialogNodeBody.scss";
import NodeTags from "./nodeTags";
import { Tag } from "actions/classifiers";

type State = {
  addFollowUpToOption?: number;
  activeOption?: number;
  addFollowUpToPattern?: number;
  activeNodeConnection?: number;
};

type Props = {
  bot: Bot;
  node: Node;
  selected: boolean;
  editing: boolean;
  dialogsById: Record<string, any>;
  updateNode: (params: updateNodeParams) => {};
  createNode: (params: createNodeParams) => {};
  getFollowUp: (optionId: number, isOption?: boolean) => { followUp: Node | null };
  isFollowUpNode?: boolean;
  // only used to create node at the lower level
  nodesById: Record<string, any>;
  deleteNode: Function;
  message: string;
  title: string;
  pre_sched: string;
  post_sched: string;
  sign_message: string;
  postsign_message: string;
  userdl_message: string;
  initSched: string;
  updateInitSched: (initSched: boolean) => {};
  updatePre: (pre: string) => {};
  updatePost: (post: string) => {};
  updateSignMeta: (sign: string) => {};
  updatePostSignMeta: (sign: string) => {};
  updateUserDownloadMeta: (user_download: string) => {};
  updateBody: (message: string) => {};
  updateTags: (tagData: any, label: string, remove: boolean) => {};
  updateMeta: (key: string, value: any) => {};
  updateDocGen: (genDocs: boolean) => {};
  updateEsign: (esign: boolean) => {};
  updateUserDownload: (user_download: boolean) => {};
  metaState: any;
  docGenState: boolean;
  esign: boolean;
  user_download: boolean;
  optionState: any;
  tagState: any;
  updateOptions: (option: any, add: boolean, remove: boolean, index: number) => {};
} & RouteComponentProps;

/**
 * Renders Node body text and icon.  Used in DialogNode
 */
class DialogNodeBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addFollowUpToOption: -1
    }
  }
  
  onChangeMde = (value) => {
    this.props.updateBody(value);
  };

  captureEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  /**
   * Checking if FILE_UPLOAD has both type of followp or not.
   */
  checkBothFileFollowup = (filePatternToCheck?: string) => {
    const { node } = this.props;
    const cue = nodeToCue(node);

    let patternStr = "";
    if (cue.type === CueType.FILE_UPLOAD) {
      node.node_connections.forEach((connection) => {
        patternStr += connection.patterns.toString();
      });
    }
    if (filePatternToCheck) {
      return patternStr.indexOf(filePatternToCheck) > -1;
    }
    return patternStr.indexOf(SKIP) > -1 && patternStr.indexOf(SUBMITTED) > -1;
  };

  setCustomAttributes = (options) => {
    const { node, updateNode } = this.props;
    const updatedAttributes = dateOptionsToAttributes(options);
    updateNode({
      id: node.id,
      custom_attributes: updatedAttributes,
    });
  };

  dateTypes = [
    { value: dateOptions.DATE, label: "Date" },
    { value: dateOptions.DATETIME, label: "Date and Time" },
    { value: dateOptions.DATE_RANGE, label: "Date Range" },
    { value: dateOptions.DATETIME_RANGE, label: "Date and Time Range" },
  ];

  setContactValidation = (type: string, active: boolean) => {
    this.props.updateMeta(type, active);
  };

  getContactValidation = (key: string) => {
    const { node, updateNode } = this.props;
    // if meta is nil, the new contact form card hasn't been used
    // so the previous validation will be used
    // if this is the case, update meta here to keep everything synced
    // this will only happen the first time, and all other changes will persist
    const meta = this.props.metaState;

    return (meta && meta[key] ? meta[key] : false);
    
    // if (node.meta) {
    //   return node.meta[key] !== undefined ? node.meta[key] : false;
    // } else {
    //   const validationEnabled = node.validation === key;
    //   if (validationEnabled) {
    //     updateNode({
    //       id: node.id,
    //       meta: {
    //         ...node.meta,
    //         [key]: true,
    //       },
    //     });
    //   }
    //   return validationEnabled;
    // }
  };

  checkName = () => {
    const meta = this.props.metaState;
  
    if( meta != null && meta.name && meta.name == true){
      return (<><span>First Name:</span><><Checkbox
        className="node__body__selector"
        id="name_checkbox"
        checked={this.getContactValidation("first_name")}
        onChange={(e) => this.setContactValidation("first_name", e.target.checked)}
        disabled={!userIsAdmin(this.props.user) || !this.props.editing} /><span>Middle Name:</span><Checkbox
          className="node__body__selector"
          id="name_checkbox"
          checked={this.getContactValidation("middle_name")}
          onChange={(e) => this.setContactValidation("middle_name", e.target.checked)}
          disabled={!userIsAdmin(this.props.user) || !this.props.editing} /><span>Last Name:</span><Checkbox
          className="node__body__selector"
          id="name_checkbox"
          checked={this.getContactValidation("last_name")}
          onChange={(e) => this.setContactValidation("last_name", e.target.checked)}
          disabled={!userIsAdmin(this.props.user) || !this.props.editing} /></></>)
    }
    
  }

  activeDateType = (cue: Cue, custom_attributes: string[] = []) => {
    if (cue.type !== CueType.DATE) return undefined;
    const dateOption = dateAttributesToOptions(custom_attributes);
    return this.dateTypes.find(({ value }) => value === dateOption);
  };

  createFollowUpNode = (optionId: number, type: CueType) => {
    const { createNode, node } = this.props;
    const nodeSpecs = cueTypeToNodeSpecs(type);
    createNode({
      ...nodeSpecs,
      link_from_option: optionId,
      link_from: node.id,
      dialog_id: node.dialog_id,
      title: cueTitle(type),
      multi_case_type: node.multi_case_type,
      ...defaultCueValues(type),
    });
  };

  optionToInterface = (option, index) => {
    const { getFollowUp, isFollowUpNode, user, selected, node, editing } = this.props;
    const { label, id, tag } = option;
    const { addFollowUpToOption, activeOption } = this.state;
    const followUp = getFollowUp(id);
    const { node_connections } = node;
    const node_connection = node_connections.length === 1 ? node_connections[0] : null;

    return (
      <React.Fragment key={id}>
        <DialogNodeOption
          selected={selected}
          option={{ label, id, tag }}
          index={index + 1}
          addFollowUp={(e) => this.toggleFollowUpInterface(id, "addFollowUpToOption")}
          makeOptionActive={() => {
            this.setState({ activeOption: id });
          }}
          isActive={activeOption === id}
          isFollowUpNode={isFollowUpNode}
          user={user}
          node={node}
          editing={editing}
          tagData={{ node_connection_id: node_connection ? node_connection.id : null }}
          tags={this.props.tagState}
          updateTags={this.props.updateTags}
          label={label}
          updateOptions={(label: string, add: boolean, remove: boolean, index: number) => this.props.updateOptions({id, node_id: node.id, label, tag: null}, add, remove, index)}
        />

        <div className="node__body-fup-spacer">
          {getFollowUp(id)}

          {addFollowUpToOption === option.id && followUp === null && (
            <CueTypeSelection
              onSelect={(value) => {
                this.createFollowUpNode(id, value);
              }}
              visible
              isNodeAllowed={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  createFollowUpNodeFromConn = (connId: number, type: CueType) => {
    const { createNode, node } = this.props;
    const nodeSpecs = cueTypeToNodeSpecs(type);

    createNode({
      ...nodeSpecs,
      link_from_connection: connId,
      link_from: node.id,
      dialog_id: node.dialog_id,
      title: cueTitle(type),
      ...defaultCueValues(type),
    });
  };

  /**
   * Toggles displaying follow up interface
   */
  toggleFollowUpInterface = (id, field) => {
    const currentlyActive = this.state[field] === id;
    this.setState({
      [field]: currentlyActive ? null : id,
    });
  };

  connectionToInterface = (connection, index, array) => {
    if (connection.root) {
      return null;
    }
    const { node, getFollowUp, isFollowUpNode, selected, editing } = this.props;
    const { id } = connection;
    const { addFollowUpToPattern, activeNodeConnection } = this.state;
    const followUp = getFollowUp(id, false);
    const nodeCueType = nodeSpecsToCueType(node.node_type, node.validation);
    const nextReferenceNodeId = node.node_connections[node.node_connections.length - 1].referencing_node_id;

    return (
      <React.Fragment key={id}>
        <NodeConnection
          nodeConnection={connection}
          nodeId={node.id}
          index={index + 1}
          nodeType={nodeCueType}
          nextReferenceNodeId={nextReferenceNodeId}
          addFollowUpToPattern={(e) => this.toggleFollowUpInterface(id, "addFollowUpToPattern")}
          makeConnectionActive={() => {
            this.setState({ activeNodeConnection: id });
          }}
          isActive={activeNodeConnection === id}
          checkBothFileFollowup={(filePatternToCheck) => this.checkBothFileFollowup(filePatternToCheck)}
          isFollowUpNode={isFollowUpNode}
          selected={selected}
          editing={editing}
        />

        <div className="node__body-fup-spacer">
          {getFollowUp(id, false)}
          {addFollowUpToPattern === id && followUp === null && (
            <CueTypeSelection
              onSelect={(value) => {
                this.createFollowUpNodeFromConn(id, value);
              }}
              visible
              isNodeAllowed={true}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  getNodeBody = () => {
    const { bot, node, dialogsById, updateNode, isFollowUpNode, user, selected, editing, message, tagState } = this.props;
    const cue = nodeToCue(node);
    const isFileFollowUpNeeded = this.checkBothFileFollowup();
    const { node_connections } = node;
    const node_connection = node_connections.length === 1 ? node_connections[0] : null;

    const nodeElement: Array<any> = [];
    let nodeBodyElement: any = null;

    switch (cue.type) {
      case CueType.DIALOG: {
        nodeBodyElement = (
          <DialogLink
            bot={bot}
            dialog={node.meta && node.meta.dialog_to_link_to && dialogsById[node.meta.dialog_to_link_to]}
            node={node}
            updateNode={updateNode}
          />
        );
        break;
      }
      case CueType.REPEAT: {
        nodeBodyElement = (
          <div className="node__body__type">
            <span>REPEAT DIALOG</span>
          </div>
        );
        break;
      }
      case CueType.CONTACT: {
        nodeBodyElement = (
          <div className="node__body__type">
            <span>Prefix</span>
            <Checkbox
              className="node__body__selector"
              checked={this.getContactValidation("prefix")}
              onChange={(e) => this.setContactValidation("prefix", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !this.props.editing}
            />
            <span>Name:</span>
            <Checkbox
              className="node__body__selector"
              id="name_checkbox"
              checked={this.getContactValidation("name")}
              onChange={(e) => this.setContactValidation("name", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !this.props.editing}
            />
            <div id="name_checks">
              {this.checkName()}
            </div>
            <span>Email:</span>
            <Checkbox
              className="node__body__selector"
              checked={this.getContactValidation("email")}
              onChange={(e) => this.setContactValidation("email", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !this.props.editing}
            />
            <span>Phone:</span>
            <Checkbox
              className="node__body__selector"
              checked={this.getContactValidation("phone")}
              onChange={(e) => this.setContactValidation("phone", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !this.props.editing}
            />
              <>
                <span>Lead:</span>
                <Checkbox
                  className="node__body__selector"
                  checked={this.getContactValidation("update_lead")}
                  onChange={(e) => this.setContactValidation("update_lead", e.target.checked)}
                  disabled={!userIsAdmin(this.props.user) || !this.props.editing}
                />
              </>
          </div>
        );
        break;
      }

      case CueType.DATE: {
        nodeBodyElement = (
          <div className="node__body__type">
            <span>Name</span>
            {editing ? (
              <Select
                options={this.dateTypes}
                defaultValue={this.activeDateType(cue, node.custom_attributes)}
                className="node__body__selector"
                classNamePrefix="node__body__selector"
                isDisabled={!userIsAdmin(this.props.user)}
                onChange={({ value }) => {
                  this.setCustomAttributes(value);
                }}
              />
            ) : (
              `: ${this.activeDateType(cue, node.custom_attributes).label}`
            )}
          </div>
        );
        break;
      }
      case CueType.MULTIPLE_CHOICE: {
        nodeBodyElement = (
          <>
            {this.props.optionState.map(this.optionToInterface)}
            {editing && <DialogNodeOption 
              selected={selected} 
              user={user} 
              tagData={{ node_connection_id: node_connection ? node_connection.id : null }}
              tags={this.props.tagState}
              updateTags={this.props.updateTags}
              updateOptions={(label: string) => this.props.updateOptions({node_id: node.id, label}, true)}
              editing={editing} 
              isNew/>}
          </>
        );
        break;
      }
      case CueType.DISCLAIMER: {
        nodeBodyElement = (
          <>
            {node.options.map(this.optionToInterface)}
            {editing && <DialogNodeOption 
              selected={selected} 
              user={user} 
              isNew 
              numberOptions={node.options.length} 
              tagData={{ node_connection_id: node_connection ? node_connection.id : null }}
              tags={this.props.tagState}
              updateTags={this.props.updateTags}
              updateOptions={(label: string) => this.props.updateOptions({node_id: node.id, label}, true)}
              editing={editing} 
              isDisclaimer/>}
          </>
        );
        break;
      }
      case CueType.ADDRESS: {
        nodeBodyElement = (
          <div className="node__body__type">
             <Checkbox
              className="node__body__selector"
              checked={this.getContactValidation("apartment")}
              onChange={(e) => this.setContactValidation("apartment", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !editing}
            />
             <span>Collect Apt/Suite Number (Optional)</span>
             <br/>
             <Checkbox
              className="node__body__selector"
              checked={this.getContactValidation("lead")}
              onChange={(e) => this.setContactValidation("lead", e.target.checked)}
              disabled={!userIsAdmin(this.props.user) || !editing}
            />
             <span>Lead</span>
          </div>
        );
        break;
      }
      case CueType.HANDOFF: {
        nodeBodyElement = (
          <HandoffNodeBodyElement
            updateNode={updateNode}
            captureEnter={this.captureEnter}
            node={node}
            redirectToHandoff={() => this.props.history.push(LEAD_ROUTING)}
            user={user}
            selected={selected}
            bot={bot}
            message={this.props.message}
            title={this.props.title}
            post_sched={this.props.post_sched}
            pre_sched={this.props.pre_sched}
            sign_message={this.props.sign_message}
            postsign_message={this.props.postsign_message}
            userdl_message={this.props.userdl_message}
            initSched={this.props.initSched}
            updateInitSched={this.props.updateInitSched}
            updateDocGen={this.props.updateDocGen}
            updateEsign={this.props.updateEsign}
            updateUserDownload={this.props.updateUserDownload}
            updateBody={this.props.updateBody}
            updatePre={this.props.updatePre}
            updateSignMeta={this.props.updateSignMeta}
            updatePostSignMeta={this.props.updatePostSignMeta}
            updateUserDownloadMeta={this.props.updateUserDownloadMeta}
            updatePost={this.props.updatePost}
            updateMeta={this.props.updateMeta}
            metaState={this.props.metaState}
            docGenState={this.props.docGenState}
            user_download={this.props.user_download}
            esign={this.props.esign}
            editing={this.props.editing}
          />
        );
        break;
      }

      default: {
        nodeBodyElement = null;
      }
    }

    /** Adding header input box   */
    if (cue.type !== CueType.DIALOG && cue.type !== CueType.HANDOFF && cue.type !== CueType.REPEAT) {
      nodeElement.push(
        editing ? (
          <MarkDownInput
            className="type-16-regular node__body-message"
            onChange={this.onChangeMde}
            body={message}
            placeholder="What should I ask?"
            disabled={!userIsAdmin(this.props.user)}
            style={{ background: "black !important" }}
          />
        ) : (
          <div>{message || "What should I ask?"}</div>
        )
      );
    }

    /** Adding body part */
    nodeElement.push(nodeBodyElement);
    

    /** add NodeTags to Contact Nodes */
    if (cue.type === CueType.CONTACT) {
      const { node_connections } = node;
      const node_connection = node_connections.length === 1 ? node_connections[0] : null;

      

      ((tagState && tagState.length > 0) || editing) &&
        nodeElement.push(
          <div className="node__body__option-actions active">
            <NodeTags
              user={user}
              tagData={{ node_connection_id: node_connection ? node_connection.id : null }}
              tags={this.props.tagState}
              selected={selected}
              editing={editing}
              updateTags={this.props.updateTags}
            />
          </div>
        );
    }

    /** Node connection for four(open,number,date and file) types of node*/
    if (
      cue.type === CueType.OPEN_RESPONSE ||
      cue.type === CueType.NUMBER_RESPONSE ||
      cue.type === CueType.DATE ||
      cue.type === CueType.FILE_UPLOAD 
    ) {
      nodeElement.push(
        <>
          {node.node_connections.map(this.connectionToInterface)}
          {!isFileFollowUpNeeded && (
            <NodeConnection
              nodeId={node.id}
              nodeType={nodeSpecsToCueType(node.node_type, node.validation)}
              isNew
              isFollowUpNode={isFollowUpNode}
              checkBothFileFollowup={(filePatternToCheck) => this.checkBothFileFollowup(filePatternToCheck)}
              selected={selected}
              editing={editing}
            />
          )}
        </>
      );
    }
    return nodeElement;
  };

  render() {
    return <div className="node__body">{this.getNodeBody()}</div>;
  }
}

export default withRouter(DialogNodeBody);

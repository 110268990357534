import { fetchBots } from "lib/bots";
import { Bot } from "./bot";
import { toggleSnackbar } from "actions/ui";

// Action types
export const FETCH_BOTS = 'FETCH_BOTS';
export const SET_BOTS = 'SET_BOTS';

export const getBots = () => dispatch => {
    // Tell reducer we're fetching
    dispatch({
        type: FETCH_BOTS
    });

    // Actually fetch bots
    fetchBots()
    .then(res => {
        dispatch({
            type: SET_BOTS,
            bots: res
        })
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your organization's bots! Please try again", true)(dispatch);
    })
}

export type BotsAction =
    | {
          type: "FETCH_BOTS";
          loading: Boolean
      }
    | {
          type: "SET_BOTS";
          bots: Bot[]
      };
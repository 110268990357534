import * as React from "react";
import App from "app/components/app";
import { createOrganization } from "lib/organization";
import Nav from "app/components/navbar";
import Container from "app/components/global/container";
import FormControl from "app/components/global/formControl";
import useQuery from "lib/utilities/useQuery";
import { saveUser } from "actions/user";
import connect from "app/connect";
import { RouteComponentProps } from "react-router-dom";
import { axiosErr } from "lib/api";
import { redirectToStripeCheckout } from "lib/utilities/stripe";

const NewOrganization: React.FC = ({ toggleSnackbar, saveUser }: RouteComponentProps) => {
  const query = useQuery();
  const plan = query.get("plan");
  const seats = query.get("seats");

  const [form, setForm] = React.useState({
    isSubmitting: false,
    user: {
      name: "",
      email: "",
      password: "",
      password_confirm: "",
    },
    organization: {
      title: "",
      plan: plan || "",
      seats: seats || 1,
    },
  });

  const handleOrganizationFormVal = (key, e): void => {
    setForm({
      ...form,
      organization: {
        ...form.organization,
        [key]: e.target.value,
      },
    });
  };

  const handleUserFormVal = (key, e): void => {
    setForm({
      ...form,
      user: {
        ...form.user,
        [key]: e.target.value,
      },
    });
  };

  const submitNewOrg = async (): Promise<void> => {
    try {
      setForm({ ...form, isSubmitting: true });

      const response = await createOrganization({
        user: { ...form.user },
        organization: { ...form.organization },
      });
      const { jwt, sessionId } = response.data;

      saveUser(jwt);
      redirectToStripeCheckout(sessionId);
    } catch (error) {
      const err = axiosErr(error);

      if (err.code === "ERR_INVALID_PASS_LENGTH") {
        toggleSnackbar(err.error);
      } else if (err.code === "ERR_INVALID_UPPERCASE") {
        toggleSnackbar(err.error);
      } else if (err.stack.indexOf("Email has already been taken") > -1) {
        toggleSnackbar("This email has already been taken.");
      } else {
        toggleSnackbar("We ran into an error creating your account.");
      }
    } finally {
      setForm({ ...form, isSubmitting: false });
    }
  };

  return (
    <App className="public">
      <Nav />
      <Container name="lander">
        <div className="background" />
        <div className="signupForm window-form">
          <h2>Create Account</h2>
          <h4>
            Enter your info to create a Gideon account. Contact{" "}
            <a href="mailto:support@gideon.legal">support@gideon.legal</a> with any questions.
          </h4>
          <form
            onSubmit={(e): void => {
              e.preventDefault();
              submitNewOrg();
            }}
          >
            <FormControl
              type="text"
              value={form.organization.title}
              title
              placeholder="Organization Title"
              maxLength={100}
              onChange={(e): void => handleOrganizationFormVal("title", e)}
            />
            <FormControl
              type="text"
              value={form.user.name}
              title
              placeholder="Name"
              maxLength={100}
              onChange={(e): void => handleUserFormVal("name", e)}
            />
            <FormControl
              type="text"
              value={form.user.email}
              title
              maxLength={100}
              placeholder="Email"
              onChange={(e): void => handleUserFormVal("email", e)}
            />
            <FormControl
              type="password"
              placeholder="Password"
              title
              value={form.user.password}
              maxLength={50}
              onChange={(e): void => handleUserFormVal("password", e)}
            />

            <FormControl
              type="password"
              placeholder="Confirm password"
              title
              value={form.user.password_confirm}
              maxLength={50}
              onChange={(e): void => handleUserFormVal("password_confirm", e)}
            />

            <button disabled={form.isSubmitting}>{form.isSubmitting ? "Creating Account..." : "Checkout →"}</button>
          </form>
        </div>
      </Container>
    </App>
  );
};

export default connect(null, { saveUser })(NewOrganization);

import api from "lib/api";
import { Node } from "actions/bot";

export type createNodeParams = {
  node_type: string;
  link_from?: number;
  link_to_option?: number;
  link_from_connection?: number;
  validation?: string;
  dialog_id: number;
  multi_case_type: boolean;
  meta?: any;
};

export type updateNodeParams = {
  id: string | number;
  title?: string | null;
  message?: string | null;
  validation?: string | null;
  link_from?: number | string;
  custom_attributes?: string[];
  meta?: any;
};

export type createOptionParams = {
  nodeId: number;
  label: string;
};

export type updateOptionParams = {
  id: number;
  label?: string;
};

export type option = {
  id: number;
  label?: string;
};

export type createNodeConnectionParams = {
  node_id: number;
  patterns?: Array<any>;
};

export type updateNodeConnectionParams = {
  id: number;
  patterns?: Array<any>;
};

export const metaForType = (type: string) => {
  switch (type) {
    case "open":
      return {
        for_name: false,
      };
    case "handoff":
      return {};
    case "dialog_link":
      return {
        dialog_to_link_to: "",
      };
    default:
      return {};
  }
};

export const deleteOption = (id: number) => {
  return api.deleteReq(`/options/${id}`, null);
};

export const updateOption = (params: updateOptionParams) => {
  const { id, label } = params;
  return api.put(`/options/${id}`, { label });
};

export const createOption = (params: createOptionParams) => {
  const { nodeId: node_id, label } = params;
  return api.post(`/options`, { label, node_id });
};

export const fetchNode = (id: string) => {
  return api.get(`/nodes/${id}`);
};

export const editNode = (params: updateNodeParams) => {
  return api.put(`/nodes/${params.id}`, params);
};

export const createNode = (params: createNodeParams) => {
  return api.post("/nodes", params);
};

export const deleteNode = (node_id: string) => {
  return api.deleteReq(`/nodes/${node_id}`, null);
};

export const updateNodeConnection = (params: updateNodeConnectionParams) => {
  return api.put(`/node_connections/${params.id}`, params);
};

export const createNodeConnection = (params: createNodeConnectionParams) => {
  return api.post(`/node_connections`, params);
};

export const deleteNodeConnection = (id: number) => {
  return api.deleteReq(`/node_connections/${id}`, null);
};

export const NODE_TYPES = {
  OPEN: "open",
  PROMPT: "prompt",
  FILE: "file",
  OPTION: "option",
  DIALOG_LINK: "dialog_link",
  DATE: "date",
  HANDOFF: "handoff",
  CONTACT: "contact",
  DISCLAIMER: "disclaimer",
  REPEAT: "repeat",
  ADDRESS: "address",
};

export const VALIDATION_TYPES = {
  EMAIL: "email",
  PHONE: "phone",
  NUMBER: "number",
};

const LEADDOCKET_MAPPING_NODE_TYPES_BLACKLIST = [
  "prompt",
  "contact",
  "handoff",
  "file",
  "disclaimer",
];

export const isLeadDocketMappableNode = (node: Node): boolean => {
  return node && !LEADDOCKET_MAPPING_NODE_TYPES_BLACKLIST.includes(node.node_type);
};

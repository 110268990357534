import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IconButton } from "@material-ui/core";
import { Settings, RemoveCircleOutlined, EditOutlined } from "@material-ui/icons";

import connect from "app/connect";
import { Node } from "actions/bot";
import { nodeToCue, CueTypeString } from "lib/cue";
import CueIcon from "app/components/botDetail/cueIcon";
import { RootStore } from "reducers";
import { isLeadDocketMappableNode } from "lib/nodes";
import { documentGenerationEnabled, isUserAdmin } from "selectors/user";
import { hasLeadDocketConfigured } from "selectors/detailbot";

type OwnProps = {
  node: Node;
  toggleSettingsOpen: Function;
  toggleCollapsed: Function;
  collapsed: boolean;
  editing: boolean;
  title: string;
  updateHeader: (title: string) => {};
};

type Props = {
  documentGenerationEnabled: boolean;
  hasLeadDocketConfigured: boolean;
} & OwnProps &
  RouteComponentProps;

/**
 * Renders Node header text and icon.  Used in DialogNode
 */
class DialogNodeHeader extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  /**
   * Sets the title stored in the node's state
   */
  onChange = ({ target }): void => {
    this.props.updateHeader(target.value);
  };

  /**
   * TODO: determine if this actually just blurs the screen?
   * @param e - key press event 
   */
  captureEnter = (e): void => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  render() {
    const {
      node,
      toggleSettingsOpen,
      toggleCollapsed,
      toggleEditing,
      collapsed,
      isUserAdmin,
      hasLeadDocketConfigured,
      documentGenerationEnabled,
      editing,
      title
    } = this.props;
    const cue = nodeToCue(node);
    const inputName = `${node.id}-title`;

    // determines whether or not to display the settings button
    const nodeSettingsEnabled =
      (isLeadDocketMappableNode(node) && hasLeadDocketConfigured) || documentGenerationEnabled;
      
    return (
      <div className={`node__header node__header--${CueTypeString[cue.type]}`}>
        <label htmlFor={inputName} className="flex alignCenter">
          <CueIcon cueType={cue.type} />

          {editing ? <input
            type="text"
            className="type-14-regular node__header__title"
            onChange={this.onChange}
            name={inputName}
            value={title}
            // onBlur={this.setValue}
            onKeyPress={this.captureEnter}
            disabled={!isUserAdmin}
          /> : <span className="type-14-regular node__header__title">
            {title}
          </span>}
        </label>

        <div style={{ marginLeft: "auto" }}>
          {!collapsed && (
            <IconButton onClick={(): void => toggleCollapsed()} className="node__headerButton">
              <RemoveCircleOutlined className="node__headerButton__icon" />
            </IconButton>
          )}

          {!editing && (
            <IconButton onClick={(): void => toggleEditing()} className="node__headerButton">
              <EditOutlined className="node__headerButton__icon"/>
            </IconButton>
          )}

          {nodeSettingsEnabled && (
            <IconButton
              onClick={(): void => toggleSettingsOpen()}
              className="node__headerButton"
              style={{ marginRight: 5 }}
            >
              <Settings className="node__headerButton__icon" />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state: RootStore, props: OwnProps) => ({
  documentGenerationEnabled: documentGenerationEnabled(state),
  hasLeadDocketConfigured: hasLeadDocketConfigured(state),
  isUserAdmin: isUserAdmin(state),
}))(DialogNodeHeader);

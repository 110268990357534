import * as React from "react";

// Sass
import "sass/components/dialog.scss";

type Props = {
  className: string | null;
  children: JSX.Element[] | JSX.Element | any;
  visible: Boolean | null;
  editing?: Boolean | null;
  setVisible: Function;
  setEditing?: Function;
  excludedTargetRegex?: string;
};

/**
 * Floating dialog cmp.
 */
class Dialog extends React.Component<Props, { visible: boolean, editing: boolean }> {
  inner: any;

  handleClick = (e) => {
    const { excludedTargetRegex } = this.props;
    const ignore =
      excludedTargetRegex &&
      typeof e.target.className.match !== "undefined" &&
      e.target.className.match(excludedTargetRegex);

    if (!ignore && !this.inner.contains(e.target)) {
      this.props.setVisible(false);
      if(this.props.editing) {
        this.props.setEditing(false);
      }
    }
  };

  render() {
    const { className, visible } = this.props;
    return (
      <div className={`dialog ${className} ${visible && "visible"}`} onClick={(e) => this.handleClick(e)}>
        <div className="dialog__background" />
        <div className="dialog__inner" ref={(inner) => (this.inner = inner)}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Dialog;

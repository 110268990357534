import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import connect from "app/connect";
import { RootStore } from "reducers";
import { getAttorneys } from "actions/attorneys";
import { User } from "reducers/user";
import { Filter } from "actions/leads";
import { deleteUser, updateUser } from 'lib/user';
import { resendInvite } from 'lib/invites';
// Cmps
import Confirmation from "app/components/global/confirmation";

type Props = {
    member: User,
    isActive: Boolean;
    makeUserItemActive: Function;
} & RouteComponentProps;

type State = {
    isOptionVisible: boolean;
    isPermissionVisible: boolean;
    deleteConfirm: boolean;
}
/** Options item for options dropdown */
const otherActions = [
    {
        field: 'Delete',
        value: 'delete'
    },
]

/** Options item for permission dropdown */
const permissionOptions = [
    {
        field: 'User',
        value: 'attorney'
    },
    {
        field: 'Admin',
        value: 'admin'
    },
]

/**
 * User List Item
 */
class UserItem extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        
        this.state = {
            isOptionVisible: false,
            deleteConfirm: false,
            isPermissionVisible: false,
        }
    }
    componentWillReceiveProps = (nextProps) => {
        if(this.props.isActive && !nextProps.isActive){
            this.setState({
                isOptionVisible: false,
                isPermissionVisible: false,
            })
        }
    }

    /**
     * Setting the visibility of the options menu
     */
    optionMenuVisible = (e) => {
        this.setState({ 
            isOptionVisible: !this.state.isOptionVisible, 
        })
    }

    /**
     * Setting the visibility of the permissions menu
     */
    permissionMenuVisible = (e) => {
        this.setState({
            isPermissionVisible: !this.state.isPermissionVisible,
        })
    }

    /**
     * Deleting the user
     */
    deleteUser = () => {
        const { member } = this.props;
        deleteUser(member.id)
        .then(res => {
            this.setState({
                deleteConfirm: false
            })
            this.props.getAttorneys();
        })
        .catch(err => {
            const errData = err.response.data;
            let errorMsg = 'We ran into an error deleting this user!';
            if(errData && errData.code && errData.error){
                errorMsg = errData.error;
            }
            this.props.toggleSnackbar(errorMsg);
        })
        
    }


    /**
     * Choosing the action based on the options value
     */
    selected = (action: string) => {
        if(action === 'delete'){
            this.setState({ deleteConfirm: true })
        } else if (action === 'admin' || action === 'attorney') {
            updateUser({
                id: this.props.member.id,
                role: action,
            })
            .then(res => {
                this.props.getAttorneys();
            })
            .catch(err => {
                this.props.toggleSnackbar("Something went wrong changing permissions, please try again!");
            })
        }
    }

    render() {
        const { member, makeUserItemActive, user } = this.props;   
        const memberIsAdmin = member.role && (member.role === 'superadmin' || member.role === 'admin');
        
        return (
            <li className="user__item" onClick={makeUserItemActive}>

                <div className="user__item__title">{member.name || 'No name'}</div>                
                
                <div className="user__item__email"><span>{member.email || 'No Email'}</span></div>

                <div className="user__item__permissions" onClick={(e) => this.permissionMenuVisible(e)}>
                    <OptionMenu
                        title={memberIsAdmin ? "Admin" : "User"}
                        options={permissionOptions}
                        isVisible={this.state.isPermissionVisible}
                        clickHandler={(action) => this.selected(action)}
                    />
                    
                </div>
                
                <div className="user__item__options" onClick={(e) => this.optionMenuVisible(e)}>                    
                    <OptionMenu 
                        title="Options"
                        options={otherActions}
                        isVisible={this.state.isOptionVisible}
                        clickHandler={(action) => this.selected(action)}
                    />
                </div>

                <Confirmation
                    title="Delete User"
                    message="Are you sure you want to permanently delete this user?
                    This action cannot be undone."
                    actionTitle="Delete"
                    cancelTitle={null}
                    setVisible={(visible) => this.setState({ deleteConfirm: visible })}
                    cancel={null}
                    visible={this.state.deleteConfirm}
                    confirmation={this.deleteUser}
                />
            </li>
        );
    }
}

type OptionMenuProps = {
    title: String,
    options: Filter[],
    isVisible: boolean,
    clickHandler: Function
}

/**
 * Cmp for showing the menu items
 */
class OptionMenu extends React.Component<OptionMenuProps> {
    render() {
        const { 
            title,
            options, 
            isVisible,
            clickHandler } = this.props;
        return (
            <React.Fragment>
                <span className={(isVisible) ? 'open' : ''}>{title}</span>
                <div className={`user__item__assignMenu ${isVisible ? 'visible' : ''}`}>
                    <ul>
                        {options.map((option,i) => (
                            <li key={i} onClick={() => clickHandler(option.value)}>{option.field}</li>
                        ))}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(
    (state: RootStore) => ({ attorneys: state.attorneys }), 
    { getAttorneys, updateUser  }
)(UserItem));
/**
 * Action types as consant
 */
export const REDIRECT_SET = 'REDIRECT_SET';
export const REDIRECT_CLEAR = 'REDIRECT_CLEAR'

export const storeRedirectLocation = (pathname) => dispatch => {
    dispatch({
        type: REDIRECT_SET,
        payload: {
            location: pathname,
        },
    });
}

export const clearRedirect = () => dispatch => {
    dispatch({
        type: "REDIRECT_CLEAR",
    })
}

export type RedirectAction =
    | {
          type: "REDIRECT_CLEAR";
      }
    | {
          type: "REDIRECT_SET";
          payload: {
              location: string;
          };
      };

import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import connect from "app/connect";
import { ONBOARDING, DASHBOARD, LEADS } from "app/router";
import Login from "../pages/login";
import { RouteComponentProps } from "react-router";
import { clearRedirect, storeRedirectLocation } from "actions/redirect";
import { RootStore } from "reducers";
import { User } from "reducers/user";
import { getQueryParam } from "lib/utilities/url";

type Props = {
  user: User;
} & StateProps &
  DispatchProps &
  RouteComponentProps<{}>;

type StateProps = {
  redirectLocation: string | null;
};

function mapStateToProps(state: RootStore): StateProps {
  return { redirectLocation: state.redirect.location };
}

type DispatchProps = {
  clearRedirect: () => void;
  storeRedirectLocation: any;
};

/**
 * Determines where to take the user at the base URL. The `proected` route will have saved
 * a redirect location if an attempt was made to access a private rotue
 */
class Index extends React.Component<Props, { location: string | null }> {
  state = {
    location: this.initialLocation(this.props),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.loggedIn !== this.props.user.loggedIn) {
      this.setState({
        location: this.initialLocation(nextProps),
      });
    }
  }

  /**
   * Determines what the initial location of the site should be based on the
   * user's logged in status
   */
  initialLocation(props: Props): string | null {
    if (!props.user.loggedIn) {
      return null;
    }

    if (props.redirectLocation) {
      props.clearRedirect();
      return props.redirectLocation;
    }

    // If no redirect location send the user through to the platform regulary
    return props.user.newUser ? ONBOARDING : LEADS;
  }

  render() {
    const checkoutSuccess = getQueryParam(location.href, "checkout_success");
    const checkoutCancelled = getQueryParam(location.href, "checkout_cancelled");

    let search = null;
    if (checkoutSuccess) search = "?checkout_success=true";
    if (checkoutCancelled) search = "?checkout_cancelled=true";

    return this.state.location ? (
      <Redirect to={{ pathname: this.state.location, search }} />
    ) : (
      <Route exact path={this.props.match.url} component={Login} />
    );
  }
}

export default connect(mapStateToProps, { clearRedirect, storeRedirectLocation })(Index);

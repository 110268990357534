import * as React from "react";
import { Link } from "react-router-dom";

import { BotStore } from "reducers/detailBot";
import { BOTS } from "app/router";

export const BotBreadcrumb: React.FC<{ detailBot: BotStore }> = (props) => {
  const { detailBot } = props;
  const initialDialogId = detailBot.bot && detailBot.bot.initial_dialog_id ? detailBot.bot.initial_dialog_id : null;

  return (
    <div className="platform__nav">
      <div className="title">
        <Link to={BOTS}>Bots</Link>
      </div>

      {!detailBot.loading && (
        <>
          <div className="title">
            <span>{!detailBot.loading && detailBot.bot && detailBot.bot.title}</span>
          </div>

          {initialDialogId && detailBot.dialogsById && (
            <div className="title">
              <Link to={`${detailBot.dialogsById[initialDialogId.toString()].id}`}>
                <span>{detailBot.dialogsById[initialDialogId.toString()].title}</span>
              </Link>
            </div>
          )}

          {detailBot.selectedDialogId && parseInt(detailBot.selectedDialogId) !== initialDialogId && (
            <div className="title">
              <span>
                {!detailBot.loading &&
                  detailBot.dialogsById &&
                  detailBot.dialogsById[detailBot.selectedDialogId] &&
                  detailBot.dialogsById[detailBot.selectedDialogId].title}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BotBreadcrumb;

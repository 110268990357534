/**
 * Checks is passed string is completley empty, also checks for just whitespace
 * @return {Boolean} True if empty, False is not empty
 */
export const isEmptyString = (string) => {
  return !/\S/.test(string);
};

export const get = (p, o) => {
  if (p == null || p == undefined) return null;
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
};

export const anyStringsEmpty = (arrayOfStrings) => {
  return arrayOfStrings.some(isEmptyString);
};

//https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
export const getInitials = (string) => {
  let initials = string.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const deconstructWithPrefix = (object, prefix) => {
  let newObject = {};
  Object.keys(object).forEach((key, index) => {
    newObject[`${prefix}_${key}`] = object[key];
  });
  return newObject;
};

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const userIsAdmin = (user) => {
  return user && user.role && (user.role === "superadmin" || user.role === "admin");
};

export const isLeadDocketMappableNode = (node) => {
  
}
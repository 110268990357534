import * as React from "react";
import { signup, login, fetchInvitation, signupParams } from "lib/user";
import { axiosErr } from "lib/api";
import connect from "app/connect";
import { INDEX } from "app/router";
import { getQueryParam } from "lib/utilities/url";

// Cmps.
import FormControl from "app/components/global/formControl";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";

type Props = {
  saveUser: Function; // TODO; type this
  toggleSnackbar: any;
} & RouteComponentProps;

type State = {
  loading: boolean;
  signupParams: signupParams;
  hasToken: any;
};

class SignUpForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const token = getQueryParam(window.location.href, "token");

    this.state = {
      loading: false,
      signupParams: {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
        token: token ? token : undefined,
        is_owner: undefined,
      },
      hasToken: token ? true : false,
    };
  }

  componentDidMount() {
    const token = this.state.signupParams.token;
    token && this.fetchUserInfo(token);
  }

  fetchUserInfo = (token: string) => {
    fetchInvitation(token)
      .then((res) => {
        console.log("fetchUserInfo res", res);
        this.setState({
          signupParams: {
            ...this.state.signupParams,
            name: res.name,
            email: res.recipient_email,
            is_owner: res.is_owner,
            organization_title: res.organization.title,
          },
        });
      })
      .catch((err) => {
        this.props.toggleSnackbar("We can't find your invite token. Please try again!");
      });
  };

  handleChange = (key: any, e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      signupParams: {
        ...this.state.signupParams,
        [key]: e.target.value,
      },
    } as any);
  };

  handleSignup = (e) => {
    e.preventDefault();

    const { saveUser } = this.props;

    if (this.state.signupParams.password !== this.state.signupParams.password_confirm) {
      return this.props.toggleSnackbar("Please make sure your passwords are matching!");
    }

    const signupParamsCopy = { ...this.state.signupParams };
    delete signupParamsCopy.password_confirm;

    signup(signupParamsCopy)
      .then((response) => {
        const user = response.data;

        return login(user.email, this.state.signupParams.password)
          .then((response) => {
            saveUser(response.data.jwt);
            this.props.history.push(INDEX);
          })
          .catch((e) => {
            this.props.toggleSnackbar("We ran into an error creating your account!");
          });
      })
      .catch((err) => {
        err = axiosErr(err);

        if (err.code === "ERR_INVALID_PASS_LENGTH") {
          this.props.toggleSnackbar(err.error);
        } else if (err.code === "ERR_INVALID_UPPERCASE") {
          this.props.toggleSnackbar(err.error);
        } else if (err.stack.indexOf("Email has already been taken") > -1) {
          this.props.toggleSnackbar("This email has already been taken.");
        } else if (err.code === "ERR_INVITE_TOKEN_DOES_NOT_EXIST") {
          this.props.toggleSnackbar("Invite token is invalid.", null, "error");
        } else this.props.toggleSnackbar("We ran into an error creating your account.");
      });
  };

  render() {
    const { signupParams, hasToken } = this.state;
    const joiningOrg = `Hello ${signupParams.name} and welcome to ${signupParams.organization_title}`;

    return (
      <div className="signupForm window-form">
        {hasToken ? (
          <h2>{`Join ${signupParams.organization_title}`}</h2>
        ) : (
          <h4>Invite Token required for account creation. Check your email for invite link.</h4>
        )}

        <form onSubmit={this.handleSignup}>
          <FormControl
            type="text"
            value={signupParams.name}
            title
            placeholder="Name"
            maxLength={100}
            onChange={(e) => this.handleChange("name", e)}
            disabled={!hasToken}
          />

          <FormControl
            type="text"
            value={signupParams.email}
            title
            maxLength={100}
            placeholder="Email"
            onChange={(e) => this.handleChange("email", e)}
            disabled={!hasToken}
          />

          <FormControl
            type="password"
            placeholder="Password"
            title
            value={signupParams.password}
            maxLength={50}
            onChange={(e) => this.handleChange("password", e)}
            disabled={!hasToken}
          />

          <FormControl
            type="password"
            placeholder="Confirm password"
            title
            value={signupParams.password_confirm}
            maxLength={50}
            onChange={(e) => this.handleChange("password_confirm", e)}
            disabled={!hasToken}
          />

          {hasToken && <button onClick={(e) => this.handleSignup(e)}>Create account</button>}
        </form>
      </div>
    );
  }
}

export default withRouter(connect()(SignUpForm));

import * as React from "react";
import { NEW_ORGANIZATION } from "app/router";
import App from "app/components/app";
import Nav from "app/components/navbar";
import Container from "app/components/global/container";
import { Link, useHistory, RouteComponentProps } from "react-router-dom";

import "sass/pages/pricing.scss";
import { Slider } from "@material-ui/core";

type Plan = {
  name: string;
  price: string;
  features: string[];
};

const planList = {
  pro: {
    name: "Pro",
    price: "$75",
    features: [
      "Unlimited custom chatbots",
      "Unlimited conversations",
      "Calendar integration",
      "Unlimited automated appointments",
      "Chatbot templates by practice area",
      "Chatbot performance analytics",
    ],
  },
  team: {
    name: "Team",
    price: "$200",
    features: [
      "Everything from Pro",
      "Advanced routing features",
      "Team access controls",
      "User roles",
      "Customized support",
    ],
  },
  enterprise: {
    name: "Enterprise",
    price: "Contact us for custom price",
    features: [
      "Everything from Team",
      "Superadmin account",
      "Customized labeling",
      "Customized knowledgebase",
      "Customized referral tool",
    ],
  },
};

type Props = {} & RouteComponentProps;

const Pricing: React.FC<Props> = () => {
  const [seats, setSeats] = React.useState<number | number[]>(5);

  return (
    <App className="public">
      <Nav />
      <Container name="pricing">
        <div className="background" />
        <div className="lead-in">
          <h1>A plan for every firm.</h1>
          <p>
            Gideon's chatbot solution puts law firm lead conversion on autopilot, with simple and predictable pricing
            designed to fit your law form.
          </p>
        </div>
        <div className="plans">
          <div className="plan">
            <div className="plan__priceSection">
              <h2>{planList.pro.name}</h2>

              <div className="plan__price">{planList.pro.price}</div>
              <div className="plan__period">per month</div>
            </div>

            <div className="plan__features">
              <p className="plan__features--greyedOut">For solo lawyers</p>

              <ul>
                {planList.pro.features.map((feature, i) => (
                  <p key={i}>
                    <li>{feature}</li>
                  </p>
                ))}
              </ul>
            </div>

            <Link
              to={{
                pathname: NEW_ORGANIZATION,
                search: "?plan=pro&seats=1",
              }}
            >
              <button>Choose Pro</button>
            </Link>
          </div>

          <div className="plan">
            <div className="plan__priceSection">
              <h2>Team</h2>
              <div className="plan__price">$200</div>
              <div className="plan__period">per month</div>
              <p>Includes first 5 users</p>
              <p>Additional users $25 per month.</p>
              <Slider
                value={seats}
                onChange={(_e, value): void => setSeats(value)}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                min={5}
                max={50}
              />
              <span>{seats} users</span>
            </div>

            <div className="plan__features">
              <p className="plan__features--greyedOut">For law firms with up to 50 employees.</p>

              <ul>
                {planList.team.features.map((feature, i) => (
                  <p key={i}>
                    <li>{feature}</li>
                  </p>
                ))}
              </ul>
            </div>

            <Link
              to={{
                pathname: NEW_ORGANIZATION,
                search: `?plan=team&seats=${seats}`,
              }}
            >
              <button>Choose Team</button>
            </Link>
          </div>
          <div className="plan">
            <div className="plan__priceSection">
              <h2>Enterprise</h2>

              <div className="plan__price plan__price--small">Contact us for pricing options</div>
            </div>

            <div className="plan__features">
              <p className="plan__features--greyedOut">For law firms with more than 50 employees</p>

              <ul>
                {planList.enterprise.features.map((feature, i) => (
                  <p key={i}>
                    <li>{feature}</li>
                  </p>
                ))}
              </ul>
            </div>

            <Link
              to={{
                pathname: NEW_ORGANIZATION,
                search: "?plan=enterprise",
              }}
            >
              <button>Schedule a Demo</button>
            </Link>
          </div>
        </div>
      </Container>
    </App>
  );
};

export default Pricing;


import * as React from "react";
import connect from 'app/connect';
import { RouteComponentProps, withRouter } from "react-router";
import Button from '@material-ui/core/Button';
import { User } from "reducers/user";
import { AdminAction, stopImpersonating } from "actions/admin";

import "sass/components/admin";

type Props = {
  user: User;
  stopImpersonating: AdminAction;
} & RouteComponentProps;

class AdminImpersonatingFlag extends React.Component<Props> {
    render() {
      const { user, history } = this.props;
      return (
        <div className="admin-impersonating-flag">
          <div className="admin-impersonating-flag__adminLogo">Admin</div>
          <div className="admin-impersonating-flag__impersonating">Impersonating:  {user.email}</div>
          <div className="admin-impersonating-flag__stopImpersonating">
            <a color='primary' onClick={() => this.props.stopImpersonating(history) }>Stop Impersonating</a>
          </div>
        </div>
      );
    }
};

export default withRouter(connect(() => {}, { stopImpersonating })(AdminImpersonatingFlag));


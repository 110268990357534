import * as React from "react";
import { RouteComponentProps } from "react-router";
import Select from "react-select";
import { Checkbox, FormControlLabel, FormHelperText, FormGroup } from "@material-ui/core";

import connect from "app/connect";
import { RootStore } from "reducers";
import { hasLeaddocketPlugin, hasLitifyPlugin, hasHubspotPlugin } from "selectors/plugins";
import { isFromMcCuneOrg } from "selectors/user";
import { leadDocketFieldsPerCaseType, leadDocketFieldsLoading } from "selectors/detailbot";
import { fetchLeadDocketFields } from "actions/bot";
import {
  createBotParams,
  updateBotParams,
  createBot,
  editBot,
  fetchSites,
  fetchDisplayPresets,
  saveBotYml,
} from "lib/bots";
import { getLeaddocketCaseTypes, getLitifyCaseTypes, getLeaddocketIntegrationURLS } from "lib/plugins";
import { axiosErr } from "lib/api";
import { customSelectStyles, customSelectIntegrationStyles } from "lib/utilities/ui";
import { massArbCaseType } from "lib/api"

// Sass
import "sass/global/colors.scss";
import "sass/components/createBot.scss";

// Cmps
import Dialog from "app/components/global/dialog";
import FormControl from "app/components/global/formControl";
import { Bot } from "actions/bot";


type State = {
  loading: boolean;
  botParams: createBotParams | updateBotParams;
  sites: any;
  selectedSite: any;
  showSiteInput: boolean;
  displayPresets: any;
  selectedPreset: any;
  fileFormat: any;
  ldCaseTypes: { value: number; label: string }[];
  ldIntegrationURLS: string[],
  litCaseTypes: { label: string }[];
};

type Props = {
  refresh: Function;
  bot: Bot;
  setVisible: Function;
} & RouteComponentProps;

// GD-600
// const MASS_ARB_CASE_TYPE_ID = 3; // staging & local
 const MASS_ARB_CASE_TYPE_ID = massArbCaseType; // prod
//const MASS_ARB_CASE_TYPE_ID = process.env["CONFIG"] == "production" ? 105 : 3;

/**
 * Create Bot Cmp.
 * Doubles as creating/editing if a bot is passed
 * Uses the <Dialog /> cmp.
 */
class CreateBot extends React.Component<Props, State> {
  static initialState = {
    loading: false,
    botParams: {
      title: "",
      open_on_load: false,
      open_fullscreen: false,
      full_height: false,
      site_id: null,
      site_url: null,
      site_is_wildcard_uri: false,
      display_options: "",
      display_preset_id: null,
      file_format: null,
      leaddocket_sync_enabled: false,
      leaddocket_case_type_id: null,
      leaddocket_sync_override: null,
      litify_sync_enabled: false,
      litify_case_type: null,
      hubspot_sync_enabled: false
    },
    sites: [],
    selectedSite: { label: "", value: "" },
    showSiteInput: false,
    displayPresets: [],
    selectedPreset: { label: "", value: "" },
    fileFormat: { label: "", value: "" },
    ldCaseTypes: [],
    ldIntegrationURLS: [],
    litCaseTypes: []
  };

  state = CreateBot.initialState;

  componentDidMount() {
    this.fetchSites();

    this.props.hasLeaddocketPlugin && this.fetchLDCaseTypes();
    this.props.hasLeaddocketPlugin && this.fetchIntegrationURLS();
    this.props.hasLitifyPlugin && this.fetchLitifyTypes();
    
  }

  componentWillReceiveProps(nextProps) {
    const botReceived = nextProps.bot && !this.props.bot;
    const botChanged = this.props.bot && nextProps.bot && this.props.bot.id !== nextProps.bot.id;
    const sites: Array<any> = this.state.sites;

    if (botReceived || botChanged) {
      const siteId = (nextProps.bot.site && nextProps.bot.site.id) || "null";
      const site = this.state.sites.find((s: any) => {
        return s && s.value && s.value === siteId;
      });
      const displayPresetId = nextProps.bot.display_preset_id;

      if (nextProps.bot.file_format) {
        const f = nextProps.bot.file_format;
        var file;
        if (f == "docx") {
          file = { value: "docx", label: "docx" };
        }
        if (f == "pdf") {
          file = { value: "pdf", label: "pdf" };
        }
        if (f == "both") {
          file = { value: "both", label: "docx and pdf" };
        }
      }

      if (this.state.displayPresets.length === 0) {
        fetchDisplayPresets(nextProps.bot.organization_id).then((res) => {
          const presets = res.map((preset) => ({
            value: preset.id,
            label: preset.title,
          }));
          const selectedPreset = presets.find((preset) => preset.value === displayPresetId);

          this.setState({
            displayPresets: [{ value: "default", label: "Default" }, ...presets],
          });
          if (selectedPreset && selectedPreset.value) {
            this.setState({
              selectedPreset,
            });
          }
        });
      }

      this.setState({
        botParams: {
          id: nextProps.bot.id,
          title: nextProps.bot.title,
          open_on_load: nextProps.bot.open_on_load,
          open_fullscreen: nextProps.bot.open_fullscreen,
          site_id: nextProps.bot.site && nextProps.bot.site.id.toString(),
          site_url: this.state.botParams.site_url,
          site_is_wildcard_uri: nextProps.bot.site ? nextProps.bot.site.is_wildcard_uri : false,
          display_options: nextProps.bot.display_options,
          display_preset_id: nextProps.bot.display_preset_id,
          file_format: nextProps.bot.file_format,
          leaddocket_sync_enabled: nextProps.bot.leaddocket_sync_enabled,
          leaddocket_case_type_id: nextProps.bot.leaddocket_case_type_id,
          leaddocket_sync_override: nextProps.bot.leaddocket_sync_override,
          litify_sync_enabled: nextProps.bot.litify_sync_enabled,
          litify_case_type: nextProps.bot.litify_case_type,
          hubspot_sync_enabled: nextProps.bot.hubspot_sync_enabled
        },
        selectedSite: site,
        sites: sites,
        showSiteInput: false,
        fileFormat: file,
      });

      if (this.state.displayPresets.length) {
        const displayPreset = this.state.displayPresets.find((preset) => preset.value === displayPresetId);
        if (displayPreset && displayPreset.value) {
          return this.setState({
            selectedPreset: displayPreset,
          });
        }
      }
      if (this.state.botParams.file_format == "docx") {
        this.setState({
          fileFormat: { value: "docx", label: "docx" },
        });
      }
      if (this.state.botParams.file_format == "pdf") {
        this.setState({
          fileFormat: { value: "pdf", label: "pdf" },
        });
      }
      if (this.state.botParams.file_format == "both") {
        this.setState({
          fileFormat: { value: "both", label: "docx and pdf" },
        });
      }
      this.setState({
        selectedPreset: { value: "default", label: "Default" },
      });
    } else if (!nextProps.bot && this.props.bot) {
      // If there's no bot, reset back to normal
      this.setState({
        botParams: CreateBot.initialState.botParams,
        sites: sites,
        selectedSite: CreateBot.initialState.selectedSite,
        showSiteInput: false,
        selectedPreset: CreateBot.initialState.selectedPreset,
        fileFormat: CreateBot.initialState.fileFormat,
      });
    } else if (nextProps.visible) {
      this.setState({
        showSiteInput: false,
        selectedSite: this.state.showSiteInput ? CreateBot.initialState.selectedSite : this.state.selectedSite,
      });
    }
    if (!this.props.hasLeaddocketPlugin && nextProps.hasLeaddocketPlugin) {
      this.fetchLDCaseTypes();
      this.fetchIntegrationURLS()
    }
    if(!this.props.hasLitifyPlugin && nextProps.hasLitifyPlugin) {
      this.fetchLitifyTypes();
    }
    if (this.props.isFromMcCuneOrg && !this.props.leadDocketFieldsPerCaseType[MASS_ARB_CASE_TYPE_ID] && this.props.hasLeaddocketPlugin) {
      this.props.fetchLeadDocketFields(MASS_ARB_CASE_TYPE_ID);
    }
  }

  handleChange = (key: any, e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      botParams: {
        ...this.state.botParams,
        [key]: e.target.value === "" ? null : e.target.value,
      },
    } as any);
  };

  /**
   * Set the input field state and site data when value in drop changes
   */
  handleSiteChange = (key: any, siteData) => {
    if (siteData.value === "") {
      this.setState({ showSiteInput: true });
    }
    this.setState({
      botParams: {
        ...this.state.botParams,
        [key]: siteData.value === "null" ? null : siteData.value,
      },
      selectedSite: siteData,
    } as any);
  };

  handlePresetChange = (selectedPreset): void => {
    this.setState({
      botParams: {
        ...this.state.botParams,
        display_preset_id: selectedPreset.value,
      },
      selectedPreset,
    });
  };

  handleFileChange = (fileFormat): void => {
    this.setState({
      botParams: {
        ...this.state.botParams,
        file_format: fileFormat.value,
      },
      fileFormat,
    });
  };

  fetchSites = () => {
    fetchSites().then((res) => {
      const sites = res.map((site: any) => ({
        value: site.id,
        label: site.domain,
      }));

      this.setState({
        sites: [{ value: "null", label: "None" }, ...sites, { value: "", label: "Add new site" }],
      });
    });
  };

  fetchLDCaseTypes = () => {
    getLeaddocketCaseTypes().then((case_types) => {
      if (!case_types) {
        return;
      }
      case_types.push({
        Id: 0,
        TypeName: "Multi"
      })
      const validLDCaseTypes = case_types
        .filter((ct) => ct.Id >= 0)
        .map((ct) => ({
          value: ct.Id,
          label: ct.TypeName,
        }));
      this.setState({ ldCaseTypes: validLDCaseTypes });
    });
  };

  fetchIntegrationURLS = () => {
    getLeaddocketIntegrationURLS().then((endpoints) => {
      if(!endpoints) {
        return;
      }
      let url_arr = []
      endpoints.endpoints.forEach(e => {
        var temp_url = new URL(e)
        var url_obj = {
          label: temp_url.pathname,
          value: e
        }
        url_arr.push(url_obj)
      })

      this.setState({ldIntegrationURLS: url_arr})
    })
  }

  fetchLitifyTypes = () => {
    //hey it turns out litify case type isnt based on external id
    getLitifyCaseTypes().then((case_types) => {
      if(!case_types) {
        return;
      }
      case_types.push({
        Name: "Multi"
      })
      const validLitCaseTypes = case_types
        .map((ct) => ({
          label: ct.Name,
        }));
      this.setState({litCaseTypes: validLitCaseTypes})
    }).catch((err) => {
      console.log(err)
    })
  }

  createBot = (e) => {
    e.preventDefault();

    const methodToUse: any = this.props.bot ? editBot : createBot;
    const { botParams } = this.state;
    const params = botParams.display_preset_id === "default" ? { ...botParams, display_preset_id: null } : botParams;
    methodToUse(params)
      .then((response) => {
        this.props.setVisible(false);
        this.props.refresh();
        // Reset state when creating a new bot so the inputs are clear next time
        if (!this.props.bot) {
          this.setState({
            selectedSite: CreateBot.initialState.selectedSite,
            botParams: CreateBot.initialState.botParams,
            showSiteInput: false,
          });
        }
      })
      .catch((err) => {
        err = axiosErr(err);

        if (err.error.indexOf("already exists") > -1) {
          this.props.toggleSnackbar(`A site with this URL already exists!`);
        } else if (err.error.indexOf("not valid") > -1) {
          this.props.toggleSnackbar(`Please enter a valid URL!`);
        } else {
          this.props.toggleSnackbar(`We ran into an error ${this.props.bot ? "saving" : "creating"} your bot!`);
        }
      });
  };

  // GD-600
  renderMcCuneLeaddocketConfig() {
    const { botParams } = this.state;
    const { leadDocketFieldsPerCaseType, isFromMcCuneOrg, leadDocketFieldsLoading } = this.props;

    var mass_arb_case_int = Number(MASS_ARB_CASE_TYPE_ID)

    if (botParams.leaddocket_case_type_id !== mass_arb_case_int || !isFromMcCuneOrg) {
      return null;
    }

    if(!this.props.leadDocketFieldsPerCaseType[MASS_ARB_CASE_TYPE_ID]) {
      this.props.fetchLeadDocketFields(MASS_ARB_CASE_TYPE_ID);
    }

    const FINANCIAL_INSTITUTION_FIELD_NAME = "Financial_Institution";

    const financialInstitutionField = (leadDocketFieldsPerCaseType[mass_arb_case_int] || []).find(
      (f) => f.FieldNameUnderscore === FINANCIAL_INSTITUTION_FIELD_NAME
    );
    const FINANCIAL_INSTITUTION_OPTIONS = (financialInstitutionField?.DefaultValues || "")
      .split(",")
      .map((v) => ({ label: v, value: v }));

    return (
      <div className="form-control" style={{ marginTop: "0" }}>
        <span>
          Financial Institution (MA) - <i>McCune specific</i>
        </span>

        <Select
          placeholder={leadDocketFieldsLoading ? "Loading..." : "Select..."}
          onChange={(option): void =>
            this.setState({
              botParams: {
                ...botParams,
                leaddocket_sync_override: {
                  [FINANCIAL_INSTITUTION_FIELD_NAME]: option.value,
                },
              },
            })
          }
          options={FINANCIAL_INSTITUTION_OPTIONS}
          value={FINANCIAL_INSTITUTION_OPTIONS.find(
            (option) => option.value === botParams?.leaddocket_sync_override?.[FINANCIAL_INSTITUTION_FIELD_NAME]
          )}
          className="bot__site__selector"
          styles={customSelectStyles}
        />
      </div>
    );
  }

  renderLeaddocketSyncConfigs() {
    const { ldCaseTypes = [], ldIntegrationURLS = [], botParams } = this.state;
    
    const INTEGRATION_URL_FIELD_NAME = "Integration_URL"
    var ldIntMap = ldIntegrationURLS.map((v) => ({ label: v, value: v }));

    if (!botParams?.leaddocket_sync_enabled) {
      return null;
    }

    const ldCaseTypeChangeHandler = (option): void =>
      this.setState({
        botParams: {
          ...botParams,
          leaddocket_case_type_id: option.value,
          leaddocket_sync_override: null,
        },
      });

    return (
      <>
        <div className="form-control" style={{marginTop:"5px"}}>
          <span>Assign LeadDocket Case Type</span>

          <Select
            placeholder="Select..."
            onChange={ldCaseTypeChangeHandler}
            options={ldCaseTypes}
            value={ldCaseTypes.find((ct) => ct.value === botParams?.leaddocket_case_type_id)}
            className="bot__site__selector"
            styles={customSelectStyles}
          />
          <br/>
          <span>Assign LeadDocket Integration URL</span>
          <Select
            placeholder="Select..."
            onChange={(option): void => {
                var new_json = {}
                if(botParams?.leaddocket_sync_override != null || botParams?.leaddocket_sync_override?.length > 0) {
                  new_json = botParams.leaddocket_sync_override
                  new_json[INTEGRATION_URL_FIELD_NAME] = option.value
                  
                } else {
                  new_json = {
                    [INTEGRATION_URL_FIELD_NAME]: option.value
                  }
                }
                this.setState({
                  botParams: {
                    ...botParams,
                    leaddocket_sync_override: new_json,
                  },
                })
              }
            }
            value={ldIntegrationURLS.find(
              (option) => option.value === botParams?.leaddocket_sync_override?.[INTEGRATION_URL_FIELD_NAME]
            )}
            options={ldIntegrationURLS}
            className="bot__integration__selector"
            styles={customSelectIntegrationStyles}
          />
        </div>

        {this.renderMcCuneLeaddocketConfig()}
      </>
    );
  }

  renderLitifySyncConfigs() {
    const { litCaseTypes = [], botParams } = this.state;

    if (!botParams?.litify_sync_enabled) {
      return null;
    }

    const litCaseTypeChangeHandler = (option): void =>
      this.setState({
        botParams: {
          ...botParams,
          litify_case_type: option.label,
        },
      });

    return (
      <>
        <div className="form-control" style={{marginTop:"5px"}}>
          <span>Assign Litify Case Type</span>

          <Select
            placeholder="Select..."
            onChange={litCaseTypeChangeHandler}
            options={litCaseTypes}
            value={litCaseTypes.find((ct) => ct.label === botParams?.litify_case_type)}
            className="bot__site__selector"
            styles={customSelectStyles}
          />
        </div>

        {/**this.renderLitifyConfig()**/} 
      </>
    );
  }

  renderHubspotSyncConfigs() {
    const { litCaseTypes = [], botParams } = this.state;

    if (!botParams?.hubspot_sync_enabled) {
      return null;
    }

    
    return (
      <>
        <div className="form-control" style={{marginTop:"5px"}}>
          <span>Assign Litify Case Type</span>

          <Select
            placeholder="Select..."
            options={litCaseTypes}
            value={litCaseTypes.find((ct) => ct.label === botParams?.litify_case_type)}
            className="bot__site__selector"
            styles={customSelectStyles}
          />
        </div>

        {/**this.renderLitifyConfig()**/} 
      </>
    );
  }

  render() {
    const { bot, hasLeaddocketPlugin } = this.props;
    const { displayPresets, botParams, selectedPreset, fileFormat } = this.state;

    const display_options_exists =
      botParams && botParams.display_options && typeof botParams.display_options === "string";
    const display_options = display_options_exists ? JSON.parse(botParams.display_options) : {};

    return (
      <Dialog className="create__new__bot" setVisible={this.props.setVisible} visible={this.props.visible}>
        <h3 className="type-24-bold">{bot ? "Edit bot" : "Create New Bot"}</h3>

        <div className="bot__form-flex">
          <div>
            <FormControl
              type="text"
              placeholder="Name your bot"
              title
              value={botParams.title}
              onChange={(e) => this.handleChange("title", e)}
            />

            {this.state.showSiteInput ? (
              <FormControl
                title
                type="text"
                placeholder="Assign to site"
                secondPlaceholder="https://gideon.legal...."
                value={this.state.selectedSite.value}
                onChange={(e) => this.handleSiteChange("site_url", { value: e.target.value })}
              />
            ) : (
              <div className="form-control">
                <span>Assign to site</span>

                <Select
                  placeholder="Assign to site"
                  onChange={(optionValue) => this.handleSiteChange("site_id", optionValue)}
                  options={this.state.sites}
                  value={this.state.selectedSite}
                  className="bot__site__selector"
                  styles={customSelectStyles}
                />
              </div>
            )}

            {
              <div className="form-control">
                <span>Select format of files to receive</span>

                <Select
                  placeholder="file type"
                  options={[
                    { label: "docx", value: "docx" },
                    { label: "pdf", value: "pdf" },
                    { label: "docx and pdf", value: "both" },
                  ]}
                  value={fileFormat}
                  onChange={(option): void => this.handleFileChange(option)}
                  styles={customSelectStyles}
                />
              </div>
            }

            {displayPresets && (
              <div className="form-control">
                <span>Set Bot Preset Display Options</span>

                <Select
                  placeholder="Select Preset"
                  onChange={(option): void => this.handlePresetChange(option)}
                  options={displayPresets}
                  value={selectedPreset}
                  className="bot__site__selector"
                  styles={customSelectStyles}
                />
              </div>
            )}
          </div>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={botParams.open_on_load}
                  onChange={(e) =>
                    this.setState({
                      botParams: {
                        ...botParams,
                        open_on_load: e.target.checked,
                      },
                    })
                  }
                />
              }
              label="Open chatbot when website loads"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={botParams.full_height}
                  onChange={(e) => 
                    this.setState({
                      botParams: {
                        ...botParams,
                        full_height: e.target.checked
                      },
                    })
                  }
                />
              }
              label="Open chatbot full height"
            />
            <FormControlLabel
                control={
                  <Checkbox
                    checked={botParams.open_fullscreen}
                    onChange={(e) =>
                      this.setState({
                        botParams: {
                          ...botParams,
                          open_fullscreen: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Open chatbot in full screen"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={botParams.site_is_wildcard_uri}
                    onChange={(e) =>
                      this.setState({
                        botParams: {
                          ...botParams,
                          site_is_wildcard_uri: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Launch chatbot to all website pages"
              />
              <FormHelperText>
                Adding chatbot to a specific website page will override 'Launch to All' function for that page only.
              </FormHelperText>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!hasLeaddocketPlugin}
                  checked={botParams.leaddocket_sync_enabled}
                  onChange={(e) =>
                    this.setState({
                      botParams: {
                        ...botParams,
                        leaddocket_sync_enabled: e.target.checked,
                      },
                    })
                  }
                />
              }
              label="Enable LeadDocket Sync"
            />
            {this.renderLeaddocketSyncConfigs()}
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!hasLitifyPlugin}
                  checked={botParams.litify_sync_enabled}
                  onChange={(e) =>
                    this.setState({
                      botParams: {
                        ...botParams,
                        litify_sync_enabled: e.target.checked,
                      },
                    })
                  }
                />
              }
              label="Enable Litify Sync"
            />
            {this.renderLitifySyncConfigs()}
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!hasHubspotPlugin}
                  checked={botParams.hubspot_sync_enabled}
                  onChange={(e) =>
                    this.setState({
                      botParams: {
                        ...botParams,
                        hubspot_sync_enabled: e.target.checked,
                      },
                    })
                  }
                />
              }
              label="Enable Hubspot Sync"
            />
          </FormGroup>
        </div>
        <div style={{display:"flex"}}>
          <button onClick={(): void => this.props.setVisible(false)} style={{ backgroundColor:"#CBD7D8"}}>Cancel</button>
          <button onClick={(e) => this.createBot(e)}>{bot ? "Save" : "Create"}</button>
        </div>
      </Dialog>
    );
  }
}

export default connect(
  (state: RootStore) => ({
    hasLeaddocketPlugin: hasLeaddocketPlugin(state),
    hasLitifyPlugin: hasLitifyPlugin(state),
    isFromMcCuneOrg: isFromMcCuneOrg(state),
    leadDocketFieldsPerCaseType: leadDocketFieldsPerCaseType(state),
    leadDocketFieldsLoading: leadDocketFieldsLoading(state),
    
  }),
  // { getAppointmentTypes, deleteAppointmentType, createAppointmentType, updateAppointmentType }
  { fetchLeadDocketFields }
)(CreateBot);

import * as React from "react";
import { RouteComponentProps } from "react-router";
import FormControl from "app/components/global/formControl";
import { fetchPlugins, Plugin, PluginSource } from "actions/plugins";

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from "@material-ui/core/TextField";

// Sass
import "sass/components/clioGrow.scss";
import { add } from "date-fns";

export interface LeadDocketInput {
  endpoint: string;
  api_key: string;
  additional: string;
}

type Props = {
  setVisible: Function;
  setDisabled: Function;
  visible: boolean;
  disabled: boolean;
  savePluginSource: (payload: LeadDocketInput) => void;
  savePluginSourceEdit: (payload: LeadDocketInput) => void;
  plugin: string;
  editing: boolean;
} & RouteComponentProps;

const LeadDocketPluginModal: React.FC<Props> = ({ visible, setVisible, disabled, setDisabled, editing, setEditing, savePluginSource, plugin }: Props) => {
  const [endpoint, setEndpoint] = React.useState(plugin?.meta.endpoint || "");
  const [additional, setAdditional] = React.useState(plugin?.meta.additional?.join('\r\n')  || "");
  const [apiKey, setApiKey] = React.useState(plugin?.meta.api_key || "");

  React.useEffect(() => {
    setEndpoint(plugin?.meta.endpoint);
  }, [plugin?.meta.endpoint]);

  React.useEffect(() => {
    setAdditional(plugin?.meta.additional?.join('\r\n'));
  }, [plugin?.meta.additional?.join('\r\n')]);

  React.useEffect(() => {
    setApiKey(plugin?.meta.api_key);
  }, [plugin?.meta.api_key]);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    var a = additional
    if(additional == "") {
      a = null
    }
    const payload: LeadDocketInput = { endpoint, api_key: apiKey, additional: a };
    savePluginSource(payload);

    setVisible(false);
    setDisabled(true);
  };

  return (
    <Dialog className="leaddocket" setVisible={setVisible} visible={visible} editing={editing} setEditing={setEditing}>
      <h3 className="type-30-bold-nexa">{"Create Lead Docket Plugin"}</h3>
      <div className="clio__grow__settings__help">
        <p>We need your primary unique lead docket endpoint URL. The endpoint URL will be provided by the Lead Docket team.</p>
        <br></br>
        <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
          <p>
            example: <br />
            https://<i>[FIRMNAME]</i>.leaddocket.com/Opportunities/Form/2?apikey=<i>11111111</i>
          </p>
          <TextField
            required
            type="text"
            disabled={disabled}
            value={endpoint}
            placeholder="Lead Docket Custom Endpoint"
            onChange={(e): void => setEndpoint(e.target.value)}
            style={{ width: "100%" }}
          />
          <br /><br />
          <p>Add additional Endpoint URLs below. If adding multiple, put each Endpoint URL on a separate line.</p>
          <textarea
            disabled={disabled}
            value={additional}
            placeholder="Lead Docket Custom Endpoint"
            onChange={(e): void => setAdditional(e.target.value)}
            style={{ width: "100%", height: "150px", resize: "none" }}
          />
          <br /><br />
          <p>Note: this api_key differs from apikey included in above endpoint.</p>
          <TextField
            required
            type="text"
            disabled={disabled}
            value={apiKey}
            placeholder="API key"
            onChange={(e): void => setApiKey(e.target.value)}
            style={{ width: "100%" }}
          />
          <button type="submit">Save Plugin</button>
        </form>
      </div>
    </Dialog>
  );
};

export default LeadDocketPluginModal;

import * as React from "react";
import { getInitials } from "lib/utilities/global";

import "sass/components/avatar";

type Props = {
    name?: string;
    image?: string;
    size?: number;
};

/**
 * Avatar
 */
class Avatar extends React.Component<Props> {

    render() {
        if (this.props.image) {
            return (
                <img className="avatar avatar_image" src={this.props.image} />
            )
        } else {
            return (
                <div className="avatar avatar_initials">
                    {this.props.name ? getInitials(this.props.name) : '//'}
                </div>
            );
        }
    }
}

export default Avatar;
import * as actions from "../actions/user";
import { isLoggedIn } from "lib/utilities/user";

export type User = {
  id: number;
  email: string;
  name: string;
  avatar?: string;
  organization_id?: number;
  organization?: Organization;
  newUser?: boolean;
  availabilities?: any;
  plugin_events?: any;
  notification_preferences?: NotificationPreferences;
  role?: string;
};

export type Organization = {
  id: number;
  title: string;
  plan: string;
  owner_id: number;
  seats_used: number;
  plan_seats: number;
  seats_remaining: number;
  document_generation_enabled?: boolean;
};

export type NotificationPreferences = {
  notify_on_active_classifications?: boolean;
};

export type UserStore = {
  loggedIn: boolean;
  newUser?: boolean;
  shouldRefresh?: boolean;
} & User;

const initialState = {
  loggedIn: isLoggedIn(),
  id: 0,
  email: "",
  name: "",
};

/**
 * User reducer
 */
export default function UserReducer(state: UserStore = initialState, action: any): UserStore {
  switch (action.type) {
    case actions.SET_LOGGED_IN:
      return { ...state, loggedIn: action.loggedIn, newUser: action.newUser };
    case actions.STORE_USER:
      return Object.assign({}, state, {
        ...action.user,
        shouldRefresh: false,
      });
    case actions.REFRESH_USER:
      return { ...state, shouldRefresh: true };
    default:
      return state;
  }
}

import { RootStore } from "reducers";

export const plugins = (state: RootStore) => state.plugins.plugins;
export const hasLeaddocketPlugin = (state: RootStore) =>
  plugins(state).find((plugin) => plugin.plugin_source?.identifier === "leaddocket" && plugin.active) ? true : false;

export const hasLitifyPlugin = (state: RootStore) =>
  plugins(state).find((plugin) => plugin.plugin_source?.identifier === "litify" && plugin.active) ? true : false;

  export const hasHubspotPlugin = (state: RootStore) =>
  plugins(state).find((plugin) => plugin.plugin_source?.identifier === "hubspot" && plugin.active) ? true : false;
import * as React from "react";
import { RouteComponentProps } from "react-router";

// Cmps

type Props = {
    title: Boolean;
    value: any;
    placeholder: string;
    secondPlaceholder?: string;
    type: String;
    maxLength?: number;
    onChange: Function;
    disabled?: Boolean;
} & RouteComponentProps;

/**
 * FormControl
 */
class FormControl extends React.Component<Props> {

    render() {
        return (
            <div className="form-control">
                {this.props.title && <span>{this.props.placeholder}</span>}

                <input 
                    type={this.props.type}
                    value={this.props.value} 
                    maxLength={this.props.maxLength || 300}
                    disabled={this.props.disabled} 
                    onChange={this.props.onChange}
                    placeholder={this.props.title ? this.props.secondPlaceholder : this.props.placeholder}
                />
            </div>
        );
    }
}

export default FormControl;
// export default connect()(CmpName);
// export default withRouter(connect()(CmpName));

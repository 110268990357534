import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import connect from "app/connect";

/**
 * Custom react-router route that ensures anyone 
 * accessing this route *isn't* logged in
 */
const Public = ({ component:Component, user, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => 
                user.loggedIn ? (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location },
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default connect()(Public);

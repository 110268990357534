import * as React from "react";

// Sass
import "sass/components/loader.scss";

/**
 * Standard Material Google Cmp.
 */
class Loader extends React.PureComponent<{ dark?: boolean }> {
    render() {
        return (
            <div className={`showbox ${this.props.dark && 'dark'}`}>
                <div className="loader">
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle 
                            className="path" 
                            cx="50" 
                            cy="50" 
                            r="20" 
                            fill="none" 
                            strokeWidth="2" 
                            strokeMiterlimit="10" 
                        />
                    </svg>
                </div>
            </div>
        );
    }
}

export default Loader;
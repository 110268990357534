import api from "lib/api";
import { NotificationPreferences } from "../../reducers/user";
import { AxiosResponse } from "axios";

type loginParams = {
  auth: any;
};

type iniviteParams = {
  email: string;
};

export type signupParams = {
  name: string;
  organization_title?: string;
  password: string;
  password_confirm: string;
  email: string;
  token?: string;
  is_owner?: boolean;
};

export type updateUserParams = {
  id: number;
  notification_preferences?: NotificationPreferences;
  working_hours?: Record<string, any>;
  time_zone?: string;
  role?: string;
};

export type udpateBotCustomizationsParams = {
  botColor?: string;
  botTitle?: string;
  botLogoURL?: string;
  botWidgetURL?: string;
  botWidgetSameAsLogo?: boolean;
  botFullscreenImageURL?: string;
  botName?: string;
  orgId: number;
}

export const me = () => {
  return api.get("/users/me");
};

export const login = (email, password) => {
  const params: loginParams = { auth: {} };
  params.auth.email = email;
  params.auth.password = password;
  return api.post("/login", params);
};

export const signup = (params) => {
  return api.post("/signup", params);
};

export const fetchInvitation = (token: string) => {
  return api.get(`/invites/retrieve?token=${token}`);
};

export const updateUser = (params: updateUserParams) => {
  return api.put(`/users/${params.id}`, params);
};

export const getBotCustomizations = (orgId: number) => {
  return api.get(`/organizations/${orgId}/bot_customizations`);
};

export const getPresignedLogoS3Url = (orgId: number, content_type: string) => {
  return api.put(`/organizations/${orgId}/presigned_s3_url_for_custom_logo`, { content_type });
};

export const updateBotCustomizations = (params: udpateBotCustomizationsParams) => {
  const mappedParams = {
      color: params.botColor,
      display_title: params.botTitle,
      logo_url: params.botLogoURL,
      widget_url: params.botWidgetURL,
      widget_same_as_logo: params.botWidgetSameAsLogo,
      fullscreen_url: params.botFullscreenImageURL,
      id: params.orgId,
      display_name: params.botName,
  };

  Object.keys(mappedParams).
      forEach((key) => (mappedParams[key] == null || mappedParams[key] === "" || mappedParams[key] === undefined) && delete mappedParams[key]
  );

  return api.put(`/organizations/${params.orgId}/update_bot_customizations`, mappedParams);
};

export const deleteUser = (id: string) => {
  return api.deleteReq(`/organizations/users/${id}`, null);
};

export const requestPasswordReset = (email: string) => {
  return api.post("/request_password_reset", { email });
};

export const resetPassword = (email: string, password_reset_token: string, newPassword: string) => {
  return api.post("/reset_password", { email, password_reset_token, password: newPassword });
};

type Timezone = { name: string; value: string };
export const getTimezones = (): Promise<{ usaMajor: Timezone[]; all: Timezone[] }> => {
  return api.get("/users/timezones");
};

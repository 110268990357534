import * as React from "react";
import ReactMarkdown from "react-markdown";
import { getMessages, resetMessages, Lead, Message, Conversation } from "actions/leads";
import { detailLeadMessages } from "reducers/leads";
import connect from "app/connect";
import { RootStore } from "reducers";
import "easymde/dist/easymde.min.css";
import History from "assets/icons/history.inline.svg" 
import ConversationsWrapper from "./conversations/conversationsWrapper";

type Props = {
  getMessages: Function;
  resetMessages: Function;
  lead: Lead;
  detailLeadMessages: detailLeadMessages;
  setCurrentConversation: Function;
  currentConversation: Conversation;
};

type SavedState = { messages: Message[]; loading: boolean };

const usePreviousState = (savedState: SavedState): SavedState => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current = savedState;
  });
  return ref.current;
};

const LeadConversation = ({
  lead: { name },
  detailLeadMessages: { messages, page, hasMore, loading },
  getMessages,
  resetMessages,
  setCurrentConversation,
  currentConversation,
}: Props) => {
  const leadName = name || "unknown lead";
  const firstMessageFromUserIndex = messages.findIndex((message) => message.sender_type === "chatbot_user");
  const possibleDuplicates = [];
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const messageRef = React.useRef(null);

  const prevState = usePreviousState({ messages, loading });

  React.useEffect(() => {
    getMessages(currentConversation.id, page);
  }, []);

  React.useEffect(() => {
    if (prevState && prevState.loading && !loading && !firstLoad && messageRef) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
      setFirstLoad(true);
    }

    const difference = prevState && messages.length - prevState.messages.length;

    if (loadingMore && difference > 0) {
      // When loading more, offset height to where the user was
      messageRef.current.scrollTop = difference * 62; // 62 is ~ height of each message cell
    }
  });

  const handleScroll = (e) => {
    const topReached = e.target.scrollTop === 0;

    // Check if scroll hits top
    if (topReached && !loading && hasMore) {
      getMessages(currentConversation.id, page + 1);
      setLoadingMore(true);
    }
  };

  const createSpan = (spans: JSX.Element[], key: string, json: object) => {
    if (key in json) {
      const text = key + ": " + json[key];
      spans.push(<span>{text}</span>);
    }
    return spans;
  };

  const isDuplicateMessage = (
    { message, sender_type }: Message,
    duplicates: string[],
    currentIndex: number,
    firstMessageFromUserIndex: number
  ) => {
    if (sender_type === "chatbot_user" || currentIndex >= firstMessageFromUserIndex) {
      return false;
    } else if (sender_type === "bot" && !duplicates.includes(message)) {
      duplicates.push(message);
      return false;
    } else {
      return true;
    }
  };

  const handleBackClick = () => {
    resetMessages();
    setCurrentConversation(null);
  };

  const getMessageList = () => {
    return messages.map((m, i) => {
      return (
        !isDuplicateMessage(m, possibleDuplicates, i, firstMessageFromUserIndex) && (
          <li
            key={i}
            className={`leadDetail__conversation__message__wrap leadDetail__conversation__message__wrap--${m.sender_type}`}
          >
            <div>
              <ReactMarkdown source={m.formatted_message} />
            </div>
          </li>
        )
      );
    });
  }

  return (
    <ConversationsWrapper
        title={currentConversation.bot.title}
        rightHeader={
          <div className="view__history">
            <span onClick={handleBackClick}>
              View All History 
            </span>
            <History width="20px" height="20px" fill="#727272" />
          </div>
        }
        onScroll={handleScroll}
        body={
          <ul className="leadDetail__conversation__messages" ref={messageRef}>
            {getMessageList()}
          </ul>          
        }
      />
  );
};

export default connect(
  (state: RootStore) => ({
    detailLeadMessages: state.leads.detailLeadMessages,
  }),
  {
    getMessages,
    resetMessages,
  }
)(LeadConversation);

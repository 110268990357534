import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router-dom";
import { 
  AppointmentType, 
  updateAppointmentType,
} from "actions/appointmentTypes";
import {
  updateAppointmentTypeParams,
} from "lib/appointmentTypes";

// Sass
import "sass/global/colors.scss";
import "sass/components/createEmailCustomization.scss";

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from '@material-ui/core/TextField';

type State = {
  email_customizations: EmailCustomizations;
  errors: ErrorState;
}

type ErrorState = {
  titleError: boolean;
  titleErrorMessage: string;
  descriptionError: boolean;
  descriptionErrorMessage: string;
  locationError: boolean;
  locationErrorMessage: string;
}

type EmailCustomizations = {
  title: string;
  description: string;
  location: string;
}

type Props = {
  setVisible: Function;
  visible: boolean;
  refresh: Function;
  appointmentType: AppointmentType;
  updateAppointmentType: Function;
} & RouteComponentProps;

class CreateEmailCustomization extends React.Component<Props, State> {
  static initialState = {
    email_customizations: {
      title: "",
      description: "",
      location: "",
    },
    errors: {
      titleError: false,
      titleErrorMessage: "Title is required",
      descriptionError: false,
      descriptionErrorMessage: "Description is required",
      locationError: false,
      locationErrorMessage: "",
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ...CreateEmailCustomization.initialState,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { appointmentType } = nextProps;
    // need to set shit correctly
    if (appointmentType && appointmentType.email_customizations && appointmentType.email_customizations.title)
    {
      this.setState({
        ...this.state,
        email_customizations: appointmentType.email_customizations,
      });
    } else {
      this.setState({
        ...CreateEmailCustomization.initialState
      });
    }
  }

  handleChange = (key, e) => {
    this.setState({
      ...this.state,
      email_customizations: {
        ...this.state.email_customizations,
        [key]: e.target.value === '' ? null : e.target.value
      }
      
    });
  }

  validateKey = (key) => {
    if (this.state.email_customizations[key] === "") {
      return false;
    }
    return true;
  }

  validateCustomizations = () => {
    let titleError = false, descriptionError = false;

    if (!this.validateKey('title')) {
      titleError = true;
    }

    if (!this.validateKey('description')) {
      descriptionError = true;
    }

    this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        titleError,
        descriptionError,
      }
    })

    return !titleError && !descriptionError;
  }

  updateCustomization = e => {
    const { updateAppointmentType, setVisible, refresh, appointmentType } = this.props;

    e.preventDefault();

    if (!this.validateCustomizations()) return;

    updateAppointmentType({ 
      id: appointmentType.id, 
      email_customizations: this.state.email_customizations 
    });

    this.setState({
      ...CreateEmailCustomization.initialState
    });

    setVisible(false);
  }

  render() {
    const { visible, setVisible } = this.props;

    return (
      <Dialog
        className="create__email__customization"
        setVisible={setVisible}
        visible={visible}
      >
        <h3 className="type-24-bold">Personalize Meeting Invites</h3>

        <span className="create__email__customization__title create__email__customization__title__title">Subject</span>
        <span className="create__email__customization__helper create__email__customization__helper__title">This is where you can customize the subject of your meeting invite.</span>
        <TextField
          type="text"
          placeholder="Subject"
          variant="outlined"
          error={this.state.errors.titleError}
          helperText={this.state.errors.titleError ? this.state.errors.titleErrorMessage : ""}
          value={this.state.email_customizations.title || ""}
          onChange={(e) => this.handleChange('title', e)}
        />
        <span className="create__email__customization__title create__email__customization__title__location">Meeting Location</span>
        <span className="create__email__customization__helper create__email__customization__helper__location">This is where you can add the office address, phone number, or video conference link for your meeting.</span>
        <TextField
          type="text"
          placeholder="Location"
          variant="outlined"
          error={this.state.errors.locationError}
          helperText={this.state.errors.locationError ? this.state.errors.locationErrorMessage : ""}
          value={this.state.email_customizations.location || ""}
          onChange={(e) => this.handleChange('location', e)}
        />
        <span className="create__email__customization__title create__email__customization__title__description">Meeting Description</span>
        <span className="create__email__customization__helper create__email__customization__helper__description">This is where you can customize the message of your meeting invite.</span>
        <TextField
          type="text"
          placeholder="Description"
          className="create__email__customization__input__description"
          error={this.state.errors.descriptionError}
          helperText={this.state.errors.descriptionError ? this.state.errors.descriptionErrorMessage : ""}
          value={this.state.email_customizations.description || ""}
          onChange={(e) => this.handleChange('description', e)}
          multiline
          rows={5}
          variant="outlined"
        />

        <button className="create__email__customization__submit" onClick={(e) => this.updateCustomization(e)}>Save</button>
      </Dialog>
    )
  }
}

export default connect()(CreateEmailCustomization);
import * as React from "react";
import connect from "app/connect";

// Require as a script and put into global context
// const BotChat = require('gideon-webchat/botchat.js')
// window.BotChat = BotChat;
// import "gideon-webchat/botchat.css"

/**
 * Chatbot testing component
 * Not worth using Typescript here because the chatbot package
 * has it's own Typescript architecture/versioning that won't be compatibile with this
 * project
 */
class Chatbot extends React.Component {
    componentDidMount() {
        // BotChat.App({
        //     directLine: { secret: process.env.REACT_APP_DIRECTLINE_SECRET },
        //     gid: "https://1665f210.ngrok.io"
        // }, document.getElementById("bot"));
    }

    render() {
        return (
            <div id="bot">
            </div>
        );
    }
}

export default connect()(Chatbot);

import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import {
  getAppointmentTypes,
  deleteAppointmentType,
  createAppointmentType,
  updateAppointmentType,
} from "actions/appointmentTypes";
import { AppointmentTypesStore } from "reducers/appointmentTypes";
import { RootStore } from "reducers";

// Cmps
import AppointmentTypes from "app/components/appointmentType/appointmentTypes";
import Layout from "app/components/global/layout";

type Props = {
  getAppointmentTypes: Function;
  deleteAppointmentType: Function;
  createAppointmentType: Function;
  updateAppointmentType: Function;
  appointmentTypes: AppointmentTypesStore;
} & RouteComponentProps;

type State = {
  editVisible: boolean;
};

class AppointmentType extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editVisible: false,
    };
  }

  componentWillMount() {
    this.props.getAppointmentTypes();
  }

  render() {
    return (
      <Layout appClassName="platform" desktopOnly mobileTitle="Appointment Types">
        <div className="appointment__type container container--unStyled container--noScroll">
          <AppointmentTypes />
        </div>
      </Layout>
    );
  }
}

export default connect((state: RootStore) => ({ appointmentTypes: state.appointmentTypes }), {
  getAppointmentTypes,
  deleteAppointmentType,
  createAppointmentType,
  updateAppointmentType,
})(AppointmentType);

import * as React from "react";
import connect from "app/connect";
import { withRouter, RouteComponentProps } from "react-router";
import { ContextMenuTrigger, ContextMenu, connectMenu, MenuItem } from "react-contextmenu";
import { RootStore } from "reducers";
import { AttorneyStore, mapAvailabilitiesAsPerCmp } from "reducers/attorneys";
import {
  setPaginationDates,
  editAvailability,
  getAvailabilities,
  getPluginEvents,
  deleteAvailability,
  createAvailability,
} from "actions/availabilities";
import { updateUser } from "lib/user";
import { UserStore } from "reducers/user";
import { userIsAdmin } from "lib/utilities/global";

import moment from "moment";
import momentTz from "moment-timezone";

// Sass
import "react-big-calendar/lib/css/react-big-calendar.css";
import "sass/components/availabilities.scss";

// Cmps
import CreateAvailability from "app/components/calendar/createAvailability";
import Confirmation from "app/components/global/confirmation";
import DayHeader from "app/components/calendar/dayHeader";
import SetWorkingHours from "app/components/calendar/setWorkingHours";
import ToolBar from "app/components/calendar/toolbar";
import BigCalendar from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { utcTimeStringToDates } from "lib/utilities/time";

const AVAILABILITY_MENU = "AVAILABILITY_MENU";
const localizer = BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

/** Date formats */
const formats = {
  dayFormat: "ddd D",
  weekHeaderFormat: "MM yyyy",
  timeGutterFormat: "h A",
};

type Props = {
  attorneys: AttorneyStore;
  setPaginationDates: Function;
  user: UserStore;
} & RouteComponentProps;

type State = {
  selected: any;
  visible: boolean;
  deleteConfirm: boolean;
  selectedAvailability: string;
  setHoursVisible: boolean;
  workingHours: Array<Record<string, any>>;
};

/**
 * Availabilities list Component
 */
class Availabilities extends React.Component<Props, State> {
  static startDate = moment().day(0).format("YYYY-MM-DD");
  static endDate = moment().day(6).format("YYYY-MM-DD");
  static now = moment().toDate();

  static dayDisplayAbbreviationLookup = {
    0: "Su",
    1: "M",
    2: "Tu",
    3: "W",
    4: "Th",
    5: "F",
    6: "Sa",
    7: "Su",
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      visible: false,
      deleteConfirm: false,
      selectedAvailability: "",
      setHoursVisible: false,
      workingHours: [],
    };
    this.handleRangeSelection = this.handleRangeSelection.bind(this);
    this.moveEvent = this.moveEvent.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps): void {
    if (
      (this.state.workingHours.length === 0 ||
        this.props.attorneys.selectedAttorney.working_hours !== nextProps.attorneys.selectedAttorney.working_hours) &&
      nextProps.attorneys.selectedAttorney &&
      nextProps.attorneys.selectedAttorney.id
    ) {
      this.formatWorkingHoursForEvents(nextProps.attorneys.selectedAttorney, Availabilities.now);
    }
  }

  /**
   * Creating availability by dragging the on the calender timeslots
   */
  handleRangeSelection(event: { action: string; start: moment.MomentInput; end: moment.MomentInput }): void {
    if (!this.selectedAttorneyIsAuthenticatedUser() && !userIsAdmin(this.props.user)) return;
    if (event.action === "select") {
      const selectedRangeAvailability = {
        start_date: moment(event.start).format("YYYY-MM-DD"),
        start_time: moment(event.start).format(),
        end_time: moment(event.end).format(),
        type: "busy",
      };
      this.setState({ visible: true, selected: selectedRangeAvailability });
    }
  }

  /**
   * Handling creating new availability button click
   */
  createAvailabilityHandler = (): void => {
    this.setState({ visible: true, selected: null });
  };

  /**
   * Handles set working hour press
   */
  setWorkingHoursHandler = (): void => {
    this.setState({ setHoursVisible: true, selected: null });
  };

  /**
   * Updating the availablity when its been dragged to new position on calendar
   * @param movedEvent Event containing the detail of availability which is moved
   *
   */
  moveEvent(movedEvent): void {
    const { start, end, event } = movedEvent;
    if (event.working_hours) return;
    // if difference is less then 24 hrs then return 0 days as diff and it doesn't change date.
    // So using the dates only not full time
    const currentPos = moment(moment(start).format("YYYY-MM-DD"));
    const prevPos = moment(moment(event.start).format("YYYY-MM-DD"));

    const dayDiff = currentPos.diff(prevPos, "days");

    const newStartDate =
      dayDiff === 0
        ? event.availability.start_date
        : moment(event.availability.start_date).add(dayDiff, "days").format("YYYY-MM-DD");
    const movedAvailability = {
      ...event.availability,
      start_date: newStartDate,
      start_time: moment(start).format("HH:mm"),
      end_time: moment(end).format("HH:mm"),
      list_start_date: this.props.attorneys.pageStartDate,
      list_end_date: this.props.attorneys.pageEndDate,
      type: undefined,
    };
    this.setState({ selectedAvailability: moment(start).format("YYYY-MM-DD") + "_" + event.availability.id });
    this.props.editAvailability(movedAvailability);
  }

  /**
   * Handling the navigation
   */

  handleNavigation = (navDate): void => {
    const sDate = moment(navDate).day(0).format("YYYY-MM-DD");
    const eDate = moment(navDate).day(6).format("YYYY-MM-DD");
    const { attorneys, getAvailabilities, getPluginEvents } = this.props;
    if (attorneys.selectedAttorney && attorneys.selectedAttorney.id) {
      getAvailabilities(attorneys.selectedAttorney.id, sDate, eDate);
      getPluginEvents(attorneys.selectedAttorney.id, sDate, eDate);
      this.formatWorkingHoursForEvents(attorneys.selectedAttorney, navDate);
    }
  };

  /** Setting currently selected availability */
  handleAvailabilitySelection = (availabilityEvent): void => {
    this.setState({ selectedAvailability: availabilityEvent._id });
  };

  /**
   * Refreshes the availability list
   */
  refresh = (): void => {
    this.setState({ visible: false, setHoursVisible: false });
  };

  /**
   * Deleting the availability
   */
  delete = (e): void => {
    e.stopPropagation();

    if (this.state.selected && this.state.deleteConfirm) {
      this.props.deleteAvailability(this.state.selected.id);
      this.setState({ deleteConfirm: false });
    }
  };

  /**
   * Setting the state for edit availability pop-up
   * @param event Availability info.
   */
  editEvent(event): void {
    if (!this.selectedAttorneyIsAuthenticatedUser() && !userIsAdmin(this.props.user)) return;

    if (!event.working_hours && (event.type === "available" || event.type === "busy")) {
      this.setState({
        visible: true,
        selected: {
          ...event.availability,
          start_date: event.availability.start_date,
          start_time: moment(event.start).format(),
          end_time: moment(event.end).format(),
        },
      });
    }
  }

  /**
   * Setting state to delete availability
   */
  deleteEvent(item): void {
    this.setState({ deleteConfirm: true, selected: item.availability });
  }

  /**
   * availability box style
   */

  availabilityStyleGetter = (event, start, end, isSelected) => {
    const bgColor =
      event.is_repeat === "norepeat" ? (isSelected ? "#31ace2" : "#86d4fc") : isSelected ? "#3ad780" : "#88e2b0";
    if (event.working_hours) {
      return {
        className: "working-hours",
        style: {
          backgroundColor: "#cccccc",
          margin: 0,
          opacity: 0.5,
        },
      };
    } else if (event.type === "busy") {
      return {
        style: {
          backgroundColor: "#cccccc",
          opacity: 0.5,
        },
      };
    }
    return {
      style: {
        backgroundColor: bgColor,
      },
    };
  };

  UnavailabilityEventComponent = (props: any): React.ReactElement => {
    return (
      <div className={"availabilityItem"}>
        <div className="availabilityItem--title">
          <div className="availabilityItem-text">{"Unavailable"}</div>
        </div>
      </div>
    );
  };

  SyncedCalendarEventComponent = (props: any): React.ReactElement => {
    const { availability } = props.event;
    const syncedCalStyles = {
      gcal_event: {
        backgroundColor: "#F4B400",
        opacity: 0.75,
      },
      outlook_event: {
        backgroundColor: "#d9390f",
        opacity: 0.75,
      },
    };

    return (
      <div className={"availabilityItem"} style={syncedCalStyles[availability.type]}>
        <div className="availabilityItem--title">
          <div className="availabilityItem-text">{props.title}</div>
          <div className="timeRange">
            {moment(props.event.start).format("ha")}-{moment(props.event.end).format("ha")}
          </div>
        </div>
      </div>
    );
  };

  AvailabilityDayPropGetter = (day) => {
    if (moment(day).day() === 0 || moment(day).day() === 6) {
      return {
        style: { backgroundColor: "#f6f7f8" },
      };
    }
  };
  /**
   * Avalabily box to show name and context menu
   */
  AvailablityBoxComponent = (props: any) => {
    if (props.event.working_hours) return this.UnavailabilityEventComponent(props);
    const is_selected = props.event._id === this.state.selectedAvailability ? "selected" : "";
    const is_repeat = props.event.is_repeat === "norepeat" ? "one-time" : "repeat";
    const is_unavailable = props.event.type === "busy" ? "unavailable" : "";
    return props.event.availability &&
      (props.event.availability.type === "gcal_event" || props.event.availability.type === "outlook_event") ? (
      this.SyncedCalendarEventComponent(props)
    ) : (
      <div className={`availabilityItem  ${is_selected} ${is_repeat} ${is_unavailable}`}>
        <ContextMenuTrigger
          id={AVAILABILITY_MENU}
          holdToDisplay={-1}
          collect={(menuProps) => ({
            ...menuProps,
            edit: (): void => this.editEvent(props.event),
            delete: (): void => this.deleteEvent(props.event),
          })}
        >
          <div className="availabilityItem--title">
            <div className="availabilityItem-text">{props.title}</div>
            <div>({is_repeat})</div>
            <div className="timeRange">
              {moment(props.event.start).format("ha")}-{moment(props.event.end).format("ha")}
            </div>
          </div>
        </ContextMenuTrigger>
      </div>
    );
  };

  formatDateForUtc = (d) => {
    return new Date(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      d.getUTCHours(),
      d.getUTCMinutes(),
      d.getUTCSeconds()
    );
  };

  formatStartEndToEvent = (id, startDate, endDate) => {
    return {
      _id: id,
      title: "Working Hours",
      start: startDate,
      end: endDate,
      is_repeat: false,
      working_hours: true,
    };
  };

  formatWorkingHoursForEvents = (selectedAttorney, visibleDate): void => {
    if (!selectedAttorney.working_hours) return;
    const hoursForCalendar = [];
    Object.keys(selectedAttorney.working_hours).forEach((day) => {
      const dateForDay = moment(visibleDate).day(parseInt(day)).format("YYYY-MM-DD");

      const startOfDayTime = "00:00:00.000Z";
      const endOfDayTime = "23:59:59.999Z";

      const sD = new Date(dateForDay + "T" + startOfDayTime);
      const eD = new Date(dateForDay + "T" + endOfDayTime);

      if (selectedAttorney.working_hours[day].available) {
        // if made more robust than a single block per day, iterate over hours here
        const [sWH, eWH] = utcTimeStringToDates(selectedAttorney.working_hours[day].hours[0], dateForDay);

        hoursForCalendar.push(
          this.formatStartEndToEvent("working-hours-" + day + "-0", this.formatDateForUtc(sD), sWH)
        );
        hoursForCalendar.push(
          this.formatStartEndToEvent("working-hours-" + day + "-1", eWH, this.formatDateForUtc(eD))
        );
      } else if (day !== "time_zone") {
        hoursForCalendar.push(
          this.formatStartEndToEvent(
            "working-hours-" + day + "-0",
            this.formatDateForUtc(sD),
            this.formatDateForUtc(eD)
          )
        );
      }
    });
    this.setState({
      ...this.state,
      workingHours: hoursForCalendar,
    });
  };

  formatWorkingHoursForDisplay = () => {
    if (!this.props.attorneys.selectedAttorney.working_hours) return "";
    const now = new Date();
    let hours = "";
    let consecutiveDay = false;
    let start_time = undefined;
    let end_time = undefined;
    let startAbbr = "";

    for (let i = 0; i < 7; i++) {
      if (this.props.attorneys.selectedAttorney.working_hours[i.toString()].available) {
        if (consecutiveDay) continue;
        else {
          consecutiveDay = true;
          startAbbr = Availabilities.dayDisplayAbbreviationLookup[i];

          const [startTime, endTime] = utcTimeStringToDates(
            this.props.attorneys.selectedAttorney.working_hours[i.toString()].hours[0],
            moment(Availabilities.now).day(i).format("YYYY-MM-DD")
          );

          const tZ = this.props.attorneys.selectedAttorney.working_hours.time_zone;
          if (tZ) {
            start_time = momentTz(startTime).tz(tZ).format("h:mm A");
            end_time = momentTz(endTime).tz(tZ).format("h:mm A z");
          } else {
            start_time = moment(startTime).format("h:mm A");
            end_time = moment(endTime).format("h:mm A z");
          }

          hours = hours.concat((hours.length > 0 ? "," : "") + Availabilities.dayDisplayAbbreviationLookup[i]);
        }
      } else {
        if (consecutiveDay) {
          if (startAbbr !== Availabilities.dayDisplayAbbreviationLookup[i - 1]) {
            hours = hours.concat("-", Availabilities.dayDisplayAbbreviationLookup[i - 1] + "");
          }
          consecutiveDay = false;
        }
      }
    }

    if (consecutiveDay) {
      if (startAbbr !== Availabilities.dayDisplayAbbreviationLookup[6])
        hours = hours.concat("-", Availabilities.dayDisplayAbbreviationLookup[6] + "");
    }

    return hours.concat(" " + start_time + "-" + end_time);
  };

  getWorkingHoursScrollTime = (selectedAttorney) => {
    let scrollTime = undefined;
    if (!selectedAttorney.working_hours) return scrollTime;
    Object.keys(selectedAttorney.working_hours).forEach((day) => {
      if (selectedAttorney.working_hours[day].available)
        scrollTime =
          moment().day(parseInt(day)).format("YYYY-MM-DD") +
          " " +
          selectedAttorney.working_hours[day].hours[0].split(",")[0];
    });
    return moment(scrollTime, moment.defaultFormat).toDate();
  };

  selectedAttorneyIsAuthenticatedUser = () => {
    return (
      this.props.user &&
      this.props.user.id &&
      this.props.attorneys.selectedAttorney &&
      this.props.attorneys.selectedAttorney.id &&
      this.props.user.id === this.props.attorneys.selectedAttorney.id
    );
  };

  selectedAttorneyUser = (): object => {
    return {
      id: this.props.attorneys.selectedAttorney.id,
      time_zone: this.props.attorneys.selectedAttorney.time_zone,
    };
  };

  formatPluginEventsTitles = (pluginEvents) => {
    return this.selectedAttorneyIsAuthenticatedUser()
      ? [...pluginEvents]
      : pluginEvents.map((pE) => {
          pE.title = "Scheduled Event";
          return pE;
        });
  };

  render() {
    const { attorneys, createAvailability, editAvailability, user } = this.props;
    const { selectedAttorney, pageStartDate, pageEndDate } = attorneys;

    const formattedPluginTitlesForAdmin = this.formatPluginEventsTitles(selectedAttorney.plugin_events);

    const availabilityType =
      this.state.selected && this.state.selected.type === "busy" ? "Unavailability" : "Availability";

    return (
      <div className="availabilities">
        <h4 className="type-14-bold class availabilities__header">AVAILABILITY</h4>

        <div className="availability__table">
          <DragAndDropCalendar
            selectable
            localizer={localizer}
            formats={formats}
            events={[...selectedAttorney.availabilities, ...formattedPluginTitlesForAdmin, ...this.state.workingHours]}
            onEventDrop={this.moveEvent}
            onDoubleClickEvent={(event, e) => this.editEvent(event)}
            onSelectSlot={this.handleRangeSelection}
            defaultView={"week"}
            views={["week"]}
            defaultDate={moment(pageStartDate).toDate()}
            date={moment(pageStartDate).toDate()}
            eventPropGetter={this.availabilityStyleGetter}
            dayPropGetter={this.AvailabilityDayPropGetter}
            scrollToTime={this.getWorkingHoursScrollTime(selectedAttorney)}
            onNavigate={this.handleNavigation}
            onSelectEvent={this.handleAvailabilitySelection}
            components={{
              event: this.AvailablityBoxComponent,
              toolbar: ToolBar({
                clickEvent: this.createAvailabilityHandler,
                setWorkingHoursClickEvent: this.setWorkingHoursHandler,
                getFormattedHours: this.formatWorkingHoursForDisplay,
                selectedAttorneyIsUser: this.selectedAttorneyIsAuthenticatedUser,
                selectedAttorneyUser: this.selectedAttorneyUser,
                refresh: () => this.refresh,
                userIsAdmin: userIsAdmin(this.props.user),
              }),
              header: DayHeader,
            }}
          />
        </div>

        <ConnectedMenu />

        {selectedAttorney && (
          <SetWorkingHours
            visible={this.state.setHoursVisible}
            workingHours={selectedAttorney.working_hours}
            selectedUser={selectedAttorney}
            refresh={this.refresh}
            setVisible={(setHoursVisible) => this.setState({ setHoursVisible })}
            updateUser={updateUser}
          />
        )}

        {selectedAttorney && (
          <CreateAvailability
            visible={this.state.visible}
            selectedUserId={selectedAttorney.id}
            selectedAvailability={this.state.selected}
            listStartDate={pageStartDate}
            listEndDate={pageEndDate}
            refresh={this.refresh}
            setVisible={(visible) => this.setState({ visible })}
            createAvailability={createAvailability}
            editAvailability={editAvailability}
            timeZone={selectedAttorney.time_zone}
          />
        )}

        <Confirmation
          title={`Delete ${availabilityType}`}
          message="Are you sure you want to permanently delete this availability?
                    This action cannot be undone."
          actionTitle="Delete"
          cancelTitle={null}
          setVisible={(visible) => this.setState({ deleteConfirm: visible })}
          cancel={null}
          visible={this.state.deleteConfirm}
          confirmation={this.delete}
        />
      </div>
    );
  }
}

/**
 * Context menu for the availability list item
 */
const AvailabilityListItemContextMenu = (props: any) => {
  const { id, trigger } = props;
  return (
    <ContextMenu id={id} className={`availability__table__contextMenu ${trigger && "visible"}`}>
      {trigger ? (
        <ul className="type-20-regular">
          <MenuItem onClick={trigger.edit}>Edit</MenuItem>
          <MenuItem onClick={trigger.delete}>Delete</MenuItem>
        </ul>
      ) : (
        <div />
      )}
    </ContextMenu>
  );
};

export const ConnectedMenu = connectMenu(AVAILABILITY_MENU)(AvailabilityListItemContextMenu);

export default withRouter(
  connect((state: RootStore) => ({ attorneys: state.attorneys }), {
    setPaginationDates,
    editAvailability,
    getAvailabilities,
    getPluginEvents,
    deleteAvailability,
    createAvailability,
  })(Availabilities)
);

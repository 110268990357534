import * as React from "react";
import connect from "app/connect";
import { RootStore } from "reducers";
import { withRouter, RouteComponentProps } from "react-router";
import { sendInvite } from 'lib/invites';
import { getInvites } from 'actions/invites';

// Cmps
import Dialog from "app/components/global/dialog";
import FormControl from "app/components/global/formControl";

type State = {
    name: string;
    email: string;
}

type Props = {
    visible: boolean;    
    setModalVisibility: Function;
} & RouteComponentProps;

/**
 * Create user invite modal component
 */
class CreateInvite extends React.Component<Props, State> {

    constructor(props:Props, state:State){
        super(props);
        this.state = {
            name: '',
            email:''
        }
    }

    /**
     * Handling input value change
     */
    handleChange = (key: any, e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [key]: e.target.value
        } as any);
    }

    /**
     * Sending the invite to the email
     */
    sendInvite = e => {
        e.preventDefault();
        const { name, email } = this.state;
        if(email.length > 0){
            sendInvite({email,name})
            .then(res => {
                console.log(res);
                this.props.setModalVisibility(false);
                this.setState({name: '',email: ''});
                this.props.getInvites();
                this.props.toggleSnackbar('Invitation sent successfully.');
            })
            .catch(err => {
                const errData = err.response.data;
                let errorMsg = 'Sorry, but we ran into an issue sending this invite!';
                if(errData && errData.code && errData.error){
                    errorMsg = errData.error;
                }
                this.props.toggleSnackbar(errorMsg);
            })            
        }
    }

    render() {
        return (
            <Dialog 
                className="addUser" 
                setVisible={this.props.setModalVisibility} 
                visible={this.props.visible}
            >
                <h3 className="type-30-bold">Add User</h3>

                <FormControl
                    type="text"
                    placeholder="Name"
                    title
                    value={this.state.name}
                    onChange={(e) => this.handleChange('name', e)}
                />
                <FormControl
                    type="text"
                    placeholder="Email"
                    title
                    value={this.state.email}
                    onChange={(e) => this.handleChange('email', e)}
                />
                <button onClick={(e) => this.sendInvite(e)}>Send Invite</button>
            </Dialog>
        );
    }
}

export default withRouter(connect(
    (state: RootStore) => ( {invites: state.invites} ), 
    { getInvites }
)(CreateInvite));

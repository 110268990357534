import * as React from "react";
import connect from "app/connect";
import { createOrganizationInvite, getOrganizationInvites, getOrganizationPlans } from "lib/admin";
import { Invite } from "reducers/invites";
import OrganizationInvites from "app/components/admin/organizationInvites";

// Sass
import "sass/pages/admin.scss";
import FormControl from "app/components/global/formControl";
import Select from "../global/select";
import { toCapitalized } from "lib/utilities/capitalize";

type PlanOptions = {
  text: string;
  value: string;
};

type Props = {
  toggleSnackbar: Function;
};

const OrganizationForm: React.FC<Props> = ({ toggleSnackbar }: Props) => {
  const [orgTitle, updateOrgTitle] = React.useState("");
  const [ownerName, updateOwnerName] = React.useState("");
  const [ownerEmail, updateOwnerEmail] = React.useState("");
  const [orgPlan, updateOrgPlan] = React.useState("");
  const [invites, updateInvites] = React.useState<Invite[]>([]);
  const [plans, updatePlans] = React.useState<PlanOptions[]>([]);

  React.useEffect(() => {
    getOrganizationInvites()
      .then((res) => {
        updateInvites(res);
      })
      .catch((err) => {
        toggleSnackbar("Unable to fetch Org invites", err, "error");
      });

    getOrganizationPlans()
      .then((res) => {
        const planOptions = res.plans ? Object.keys(res.plans).map((p) => ({ value: p, text: toCapitalized(p) })) : [];
        updatePlans(planOptions);
        planOptions.length > 0 && updateOrgPlan(planOptions[0].value);
      })
      .catch((err) => {
        toggleSnackbar("Unable to fetch Plans", err, "error");
      });
  }, []);

  const createOrganizationOnSubmit = (orgParams) => {
    createOrganizationInvite(orgParams)
      .then((res) => {
        updateInvites([res.data, ...invites]);
        updateOrgTitle("");
        updateOwnerName("");
        updateOwnerEmail("");
      })
      .catch((err) => {
        toggleSnackbar("Unable to create Org", err, "error");
      });
  };

  return (
    <div className="OrganizationContainer">
      <div className="OrganizationForm">
        <h2>New Organizatin</h2>
        <FormControl
          title
          placeholder="Organization Title"
          type="text"
          value={orgTitle}
          onChange={(e) => updateOrgTitle(e.target.value)}
        />

        <FormControl
          title
          placeholder="Owner Name"
          type="text"
          value={ownerName}
          onChange={(e) => updateOwnerName(e.target.value)}
        />

        <FormControl
          title
          placeholder="Owner Email"
          type="text"
          value={ownerEmail}
          onChange={(e) => updateOwnerEmail(e.target.value)}
        />

        <Select
          title="Organization Plan"
          options={plans}
          onChange={(e) => updateOrgPlan(e.target.value)}
          value={orgPlan}
        />

        <button
          onClick={(e) =>
            createOrganizationOnSubmit({
              title: orgTitle,
              owner_email: ownerEmail,
              owner_name: ownerName,
              plan: orgPlan,
            })
          }
        >
          Create Organization
        </button>
      </div>

      <h2>Organization Invites</h2>
      <OrganizationInvites invites={invites} />
    </div>
  );
};

export default connect()(OrganizationForm);

import { 
  FETCH_CONVERSATION_STATS, 
  SET_CONVERSATION_STATS, 
  DashboardAction,
} from "actions/dashboard";

import { LineComputedSerieData } from "@nivo/line";
import { conversationStatColors } from "app/pages/dashboard";

export type DashboardStore = {
  conversations: LineComputedSerieData;
  classified: LineComputedSerieData;
  scheduled: LineComputedSerieData;
  documents: LineComputedSerieData;
  esign_documents: number;
  signed_documents: number;
  loading: boolean;
  stats: any;
};

const initialState = {
  conversations: { id: 'conversations', data: [], ...conversationStatColors.Conversations },
  classified: { id: 'classified', data: [], ...conversationStatColors.Classified },
  scheduled: { id: 'scheduled', data: [], ...conversationStatColors.Scheduled },
  documents: { id: 'documents', data: [], ...conversationStatColors.Documents },
  esign_documents: 0,
  signed_documents:  0,
  loading: false,
  stats: {},
};

export default function(
  state: DashboardStore = initialState,
  action: DashboardAction,
): DashboardStore {
  switch (action.type) {
      case FETCH_CONVERSATION_STATS: {
          return {
              ...state,
              loading: true,
          };
      }
      case SET_CONVERSATION_STATS: {
          return {
              ...state,
              loading: false,
              conversations: { ...state.conversations, id: `Conversations`, data: action.conversations },
              classified:    { ...state.classified,    id: `Classified`, data: action.classified    },
              scheduled:     { ...state.scheduled,     id: `Scheduled`, data: action.scheduled     },
              documents:     { ...state.documents,     id: `Documents`, data: action.documents     },
              esign_documents: action.esign_documents,
              signed_documents: action.signed_documents,
              stats: action.stats,
          };
      }
      default: {
          return state;
      }
  }
}
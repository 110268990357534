import * as React from "react";
import get from "lodash.get";
import { Lead } from "actions/leads";

import { withRouter, RouteComponentProps } from "react-router";
import { LEADS } from "app/router";
import { User } from "reducers/user";

import momentTz from "moment-timezone";
import { useToggle } from "react-use";

// SVGS
import Checkmark from "assets/icons/checkmark.inline.svg";
import EsignCheckmark from "assets/icons/esigncheckmark.inline.svg"

// Cmps
import AssignMenu from "app/components/lead/assignMenu";
import Avatar from "app/components/global/avatar";
import { formatTime } from "lib/utilities/time";
import { TableRow, TableCell } from "@material-ui/core";

type Props = {
  lead: Lead;
} & RouteComponentProps;

/**
 * Lead List Item
 */
const LeadItem = ({ lead, history }: Props) => {
  const [assignMenuVisible, toggleAssignMenu] = useToggle(false);
  const [assignee, setAssignee] = React.useState<User>(null);

  // Consider active if last_active is within 5 minutes ago
  const recentlyActive =
    lead.last_active !== null ? new Date(lead.last_active).getTime() >= Date.now() - 300000 : false;

  const onClick = () => {
    history.push(`${LEADS}/${lead.id}`);
  };

  const handleAssigneeClick = (e) => {
    e.stopPropagation();
    toggleAssignMenu();
  };

  const checkName = (name) => {
    if(name && name.includes("{")){
      var json_check = JSON.parse(name)
      var new_name = ""
      if(json_check && typeof json_check === "object"){
        if(json_check.first_name){
          new_name+= json_check.first_name + " "
        }
        if(json_check.middle_name){
          new_name+= json_check.middle_name+ " "
        } 
        if(json_check.last_name){
          new_name+= json_check.last_name
        }
        return new_name

      }
    }
    return name

  }

  //check if esign field in lead is true or not, if true then add checkmark to indicate user has completed esign
  const checkEsign = () => {
    if(lead.esign == true){
      return <div style={{display: "flex", justifyContent: "space-between"}}> <div className="esign_classifier">{lead.classifier} </div> 
      <div className="esign_checkmark"><EsignCheckmark /></div></div>

    } else {
      //format regularly
      return lead.classifier
    } 
  }

  return (
    <TableRow key={lead.id} hover onClick={onClick} className="lead__item">
      <TableCell className="lead__item__name">{checkName(lead.name) || "No name"}</TableCell>
      <TableCell className="lead__item__startDate table__cell">{formatTime(lead.created_at, "MM/D/YY")}</TableCell>
      <TableCell className="lead__item__classifier table__cell  table__cell__classifier">
        {lead.classifier|| <span>Unclassified</span>}
      </TableCell>
      <TableCell className="lead__item__marketing table__cell">
        {get(lead, "latest_marketing_setting.source_name")}
      </TableCell>
      <TableCell className="lead__item__campaign table__cell">
        {get(lead, "latest_marketing_setting.campaign_name")}
      </TableCell>
      <TableCell className="lead__item__scheduled table__cell" align="center" size="small">
        {lead.appointment ? <Checkmark /> : ""}
      </TableCell>
      <TableCell className="lead__item__asignee" align="center" size="small">
        <div onClick={(e) => handleAssigneeClick(e)}>
          {lead.assignee ? (
            <Avatar image={lead.assignee.avatar} name={lead.assignee.name} />
          ) : (
            <span className="assign__label">Assign</span>
          )}
          <AssignMenu visible={assignMenuVisible} updateAssignee={setAssignee} lead={lead} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withRouter(LeadItem);

import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import { Plugin, PluginSource } from "actions/plugins";

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from "@material-ui/core/TextField";

// Sass
import "sass/components/clioGrow.scss";

type Props = {
  setVisible: Function;
  visible: boolean;
  plugin: Plugin;
  savePluginSource: Function;
} & RouteComponentProps;

const initialState = {
  apiToken: "",
  apiSecret: "",
  error: false,
};

const FilevineSettings: React.FC<Props> = ({ visible, setVisible, plugin, savePluginSource }: Props) => {
  const [state, setState] = React.useState(initialState);

  const saveApiKeys = (): void => {
    if (state.apiToken === "" || state.apiSecret === "") {
      setState({
        ...state,
        error: true,
      });
      return;
    }

    savePluginSource({
      api_key: state.apiToken,
      api_secret: state.apiSecret,
    });
    setVisible(false);
  };

  const integrationsLink = (
    <a
      onClick={(e): void => {
        e.preventDefault();
        window.open("https://portal.filevine.io/keys");
      }}
    >
      here
    </a>
  );

  return (
    <Dialog className="clio__grow__settings" setVisible={setVisible} visible={visible}>
      <h3 className="type-30-bold-nexa">
        {plugin && plugin.token ? "Edit Filevine Plugin Settings" : "Create Filevine Plugin"}
      </h3>
      <div className="clio__grow__settings__help">
        <span>To create api keys for Gideon, login and generate - {integrationsLink}.</span>
        <br></br>
      </div>
      <span className="clio__grow__settings__inbox__token__label">Api Key</span>
      <TextField
        type="text"
        placeholder="Api Token"
        value={state.apiToken}
        error={state.error}
        helperText={state.error ? state.error : ""}
        onChange={(e): void => setState({ ...state, apiToken: e.target.value, error: false })}
      />
      <span className="clio__grow__settings__inbox__token__label">Api Secret</span>
      <TextField
        type="text"
        placeholder="Api Secret"
        value={state.apiSecret}
        error={state.error}
        helperText={state.error ? state.error : ""}
        onChange={(e): void => setState({ ...state, apiSecret: e.target.value, error: false })}
      />
      <button
        onClick={(e): void => {
          e.preventDefault();
          saveApiKeys();
        }}
      >
        Add Plugin
      </button>
    </Dialog>
  );
};

export default connect()(FilevineSettings);

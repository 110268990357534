import { Node } from "actions/bot";

export const imageForNodeType = (nodeType: String) => {

    switch (nodeType) {
        case "open":
            return `${process.env.REACT_STATIC_CDN}/botbuilder/open.svg`
        default:
            return `${process.env.REACT_STATIC_CDN}/botbuilder/prompt.svg`
    }

}

export const orderedNodesWithBaseNode = (baseNode: Node, nodesById: Node[]) => {
    const nodes = [baseNode];

    let currentNode = baseNode;

    while (currentNode.node_connections.find(nc => nc.root && nc.referencing_node_id != null)) {
        const node_connection = currentNode.node_connections.find(nc => nc.root && nc.referencing_node_id != null)
        if (node_connection) {
            const newNode = nodesById[`${node_connection.referencing_node_id}`];
            nodes.push(newNode)
            currentNode = newNode
        } else {
            break;
        }
    }

    return nodes;
}

import * as React from "react";
import connect from "app/connect";
import { RootStore } from "reducers";
import { User } from "reducers/user";
import {
  createAppointmentType,
  updateAppointmentType,
  getAppointmentTypes,
  deleteAppointmentType,
  AppointmentType
} from "actions/appointmentTypes";
import { RouteComponentProps } from "react-router-dom";
import { userIsAdmin } from "lib/utilities/global";

// Sass
import "sass/components/appointmentTypes.scss";

// Cmps
import AppointmentTypeItem from "app/components/appointmentType/appointmentTypeItem";
import Confirmation from "app/components/global/confirmation";
import CreateAppointmentType from "app/components/appointmentType/createAppointmentType";
import CreateEmailCustomizatin from "app/components/appointmentType/appointmentTypeEmailCustomization";
import HotInput from "../global/hotInput";

type Props = {
  currentUser: User;
  deleteAppointmentType: Function;
  appointmentTypes: AppointmentType[];
  getAppointmentTypes: Function;
  createAppointmentType: Function;
  updateAppointmentType: Function;
} & RouteComponentProps;

type State = {
  deleteConfirm: boolean;
  appointmentTypeToDelete?: number | null;
  createAppointmentTypeVisible: boolean;
  createEmailCustomizationVisible: boolean;
  appointmentTypeToEdit: AppointmentType | null;
  appointmentTypeTitle: string;
};

/**
 * Meetings list component
 */
class AppointmentTypes extends React.Component<Props, State> {
  state: State = {
    deleteConfirm: false,
    createAppointmentTypeVisible: false,
    createEmailCustomizationVisible: false,
    appointmentTypeToEdit: null,
    appointmentTypeTitle: "",
  };

  delete = () => {
    if (this.state.appointmentTypeToDelete && this.state.deleteConfirm) {
      this.props.deleteAppointmentType(this.state.appointmentTypeToDelete);
      this.setState({
        deleteConfirm: false,
        appointmentTypeToDelete: null,
      });
    }
  };

  handleEdit = (appointmentType) => {
    this.setState({
      ...this.state,
      appointmentTypeToEdit: appointmentType,
      createAppointmentTypeVisible: true,
    });
  }

  handleEditEmailCustomization = (appointmentType) => {
    this.setState({
      ...this.state,
      appointmentTypeToEdit: appointmentType,
      createEmailCustomizationVisible: true,
    });
  }

  refresh = () => {
    this.props.getAppointmentTypes();
  } 

  onAppointmentTypeTitleKeyUp = (e): void => {
    if (e.keyCode === 13) {
      this.setState({
        ...this.state,
        appointmentTypeToEdit: {
          id: null,
          title: this.state.appointmentTypeTitle,
          description: "",
          duration: "00:30",
          organization_id: null,
          buffer: 0,
          email_customizations: {}
        },
        createAppointmentTypeVisible: true,
        appointmentTypeTitle: "",
      });
    }
  };

  render() {
    const { createAppointmentType, updateAppointmentType, appointmentTypes } = this.props;
    return (
      <div className="appointment__types">
        <h4 className="type-14-bold class appointment__type__header">APPOINTMENT TYPES</h4>
        
        <div className="appointment__type__row appointment__type__row--first">
          <span className="appointment__type__row__title type-14-bold">Title</span>
          <span className="appointment__type__row__title appointment__type__row__description type-14-bold">Description</span>
          <span className="appointment__type__row__title appointment__type__row__duration type-14-bold">Duration</span>
          <span className="appointment__type__row__title appointment__type__row__buffer type-14-bold">Buffer</span>
        </div>

        <div className="appointment__type__table">
          {appointmentTypes.map((appointmentType, i) => (
            <AppointmentTypeItem
              key={i}
              appointmentType={appointmentType}
              setDeletion={(appointmentTypeToDelete) => this.setState({ deleteConfirm: true, appointmentTypeToDelete })}
              handleEdit={(appointmentType) => this.handleEdit(appointmentType)}
              handleEmailCustomization={(appointmentType) => this.handleEditEmailCustomization(appointmentType)}
            />
          ))}
        </div>
        <CreateAppointmentType 
            refresh={this.refresh}
            visible={this.state.createAppointmentTypeVisible}
            appointmentType={this.state.appointmentTypeToEdit}
            setVisible={(createAppointmentTypeVisible) => this.setState({ createAppointmentTypeVisible })} 
            createAppointmentType={createAppointmentType}
            updateAppointmentType={updateAppointmentType}
        />
        <CreateEmailCustomizatin 
            refresh={this.refresh}
            visible={this.state.createEmailCustomizationVisible}
            setVisible={(createEmailCustomizationVisible) => this.setState({ createEmailCustomizationVisible })} 
            appointmentType={this.state.appointmentTypeToEdit}
            updateAppointmentType={updateAppointmentType}
        />
        <div className="appointment__type__row">
          <img className="appointment__type__row__indicator" src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`} />
          <HotInput
            type="text"
            className="appointment__type__row__create__new"
            initialValue={this.state.appointmentTypeTitle}
            onChange={(value): void => {
              this.setState({
                ...this.state,
                appointmentTypeTitle: value,
              })
            }}
            onKeyUp={this.onAppointmentTypeTitleKeyUp}
            noDelay={true}
            placeholder={"add new appointment type"}
            disabled={!userIsAdmin(this.props.user)}
          />
        </div>
        <Confirmation
          title="Delete appointment"
          message="Are you sure you want to permanently delete this appointment?
                    This action cannot be undone."
          actionTitle="Delete"
          cancelTitle={null}
          setVisible={(visible) => this.setState({ deleteConfirm: visible })}
          cancel={null}
          visible={this.state.deleteConfirm}
          confirmation={this.delete}
        />
      </div>
    )
  }
}

export default connect(
  (state: RootStore) => ({
    currentUser: state.user,
    appointmentTypes: state.appointmentTypes.data
  }),
  { getAppointmentTypes, deleteAppointmentType, createAppointmentType, updateAppointmentType }
)(AppointmentTypes)
import * as React from "react";
import { getConversationStats } from "actions/dashboard";
import { ResponsiveLine } from "@nivo/line";
import connect from "app/connect";
import { RootStore } from "reducers";
import { DashboardStore } from "reducers/dashboard";
import "sass/pages/dashboard";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useWindowSize } from "react-use";
import moment from "moment";
import Layout from "app/components/global/layout";

const Graph = ({ data, rangeOptions, yMax }) => {
  const { width } = useWindowSize();
  const isSmall = width < 800;
  return (
    <ResponsiveLine
      animate
      data={data}
      enableArea
      areaOpacity={0.4}
      areaBlendMode="lighten"
      enableGridX={false}
      enablePoints={false}
      useMesh
      enableSlices="x"
      colors={{ datum: "color" }}
      margin={{ top: 10, right: 30, bottom: 50, left: 40 }}
      xScale={{
        type: "time",
        format: "native",
        precision: rangeOptions.precision,
      }}
      xFormat="time:%m/%d"
      yScale={{ type: "linear", stacked: false, min: "auto", max: yMax + 5 }}
      axisTop={null}
      axisRight={null}
      curve="linear"
      axisBottom={{
        format: "%m/%d",
        tickRotation: isSmall ? 25 : 0,
        tickValues: rangeOptions.tickValues,
        legendOffset: -12,
      }}
      theme={{
        legends: {
          text: {
            fontSize: 16,
          },
        },
      }}
    />
  );
};

type Props = {
  dashboard: DashboardStore;
  getConversationStats: Function;
};

type DateRange = {
  start: string;
  end: string;
  period: string;
  label: string;
};

export const conversationStatColors = {
  Conversations: { color: "#97DAF1" },
  Classified: { color: "#eed77a" },
  Scheduled: { color: "#31ACE2" },
  Documents: { color: "#FF0000" },
  eSign: {color: "#FF781F"},
  Signed: {color: "#5BB450"},
};

const formatDate = (amount?: moment.DurationInputArg1, type?: moment.DurationInputArg2): string => {
  if (amount == 0) {
    return moment().startOf("day").set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD HH:mm:ss");
  }
  if (amount && type) {
    return moment().startOf("day").subtract(amount, type).format("YYYY-MM-DD HH:mm:ss");
  }
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

const now = formatDate();
const startOfToday = formatDate(0, "days");
const weekAgo = formatDate(7, "days");
const monthAgo = formatDate(1, "months");
const yearAgo = formatDate(1, "years");

const defaultRange = {
  start: weekAgo,
  end: now,
  period: "day",
  label: "7_days",
};

const getRangeOptions = (start: string, end: string) => {
  const result = moment(end).diff(moment(start), "days", true);

  switch (true) {
    case result <= 2:
      return { precision: "hour", tickValues: "every 5 hours" };
    case result <= 5:
      return { precision: "hour", tickValues: "every 12 hours" };
    case result <= 16:
      return { precision: "day", tickValues: "every 2 days" };
    case result <= 25:
      return { precision: "day", tickValues: "every 4 days" };
    case result <= 45:
      return { precision: "day", tickValues: "every 5 days" };
    case result <= 80:
      return { precision: "week", tickValues: "every 2 weeks" };
    case result <= 370:
      return { precision: "week", tickValues: "every month" };
    case result > 370:
      return { precision: "month", tickValues: "every 4 months" };
    default:
      return { precision: "day", tickValues: "every 2 days" };
  }
};

const getRangeValue = (range: string): DateRange => {
  switch (range) {
    case "today":
      return { start: startOfToday, end: now, period: "day", label: "today" };
    case "7_days":
      return defaultRange;
    case "30_days":
      return { start: monthAgo, end: now, period: "day", label: "30_days" };
    case "12_months":
      return { start: yearAgo, end: now, period: "month", label: "12_months" };
    case "custom":
      return { start: weekAgo, end: now, period: "day", label: "custom" };
    default:
      return defaultRange;
  }
};

const Dashboard = ({ getConversationStats, dashboard }: Props) => {
  const [conversationRange, setConversationRange] = React.useState<DateRange>(defaultRange);
  const isCustom = conversationRange.label === "custom";
  //console.log(dashboard)

  React.useEffect(() => {
    getConversationStats(conversationRange);
  }, [conversationRange]);

  const handleChange = (e) => {
    const { value } = e.target;
    setConversationRange(getRangeValue(value));
  };

  const renderLegend = () => (
    <div className="conversations-graph-legend">
      {["Conversations", "Classified", "Scheduled", "Documents"].map((k) => {
        return (
          <div className="legend-column" key={k}>
            <span className="legend-title">{k}</span>
            <span className="legend-value" style={{ color: conversationStatColors[k].color }}>
              {dashboard.stats[k]}
            </span>
          </div>
        );
      })}
        <div className="legend-column" key='eSign'>
          <span className="legend-title">{'Signature Requested'}</span>
          <span className="legend-value" style={{ color: conversationStatColors['eSign'].color }}>
            {dashboard.esign_documents}
          </span>
        </div>
        <div className="legend-column" key='Signed'>
          <span className="legend-title">{'Signature Completed'}</span>
          <span className="legend-value" style={{ color: conversationStatColors['Signed'].color }}>
            {dashboard.signed_documents}
          </span>
        </div>
    </div>
  );

  return (
    <Layout appClassName="platform" pageTitle="Dashboard">
      <div className="dashboard">
        <div className="dashboard-row">
          <div className="dashboard-pane__conversations-graph">
            <div className="graph-header">
              <h4>Leads</h4>
              <div className="flex" style={{ flexDirection: "column" }}>
                <FormControl className="conversations-range-fc">
                  <Select
                    value={conversationRange.label}
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                      name: "conversations-range-select",
                      id: "conversations-range-select",
                    }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="7_days">Last 7 Days</MenuItem>
                    <MenuItem value="30_days">Last 30 days</MenuItem>
                    <MenuItem value="12_months">Last 12 months</MenuItem>
                    <MenuItem value="custom">Custom Range</MenuItem>
                  </Select>
                </FormControl>
                {isCustom && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="flex align-center">
                      <KeyboardDatePicker
                        disableToolbar
                        disableFuture
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="start-date"
                        label="Start Date"
                        value={moment(conversationRange.start).toDate()}
                        style={{ margin: "1rem" }}
                        onChange={(date) =>
                          setConversationRange((prevState) => ({
                            ...prevState,
                            start: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                          }))
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <span>to</span>
                      <KeyboardDatePicker
                        disableToolbar
                        disableFuture
                        variant="inline"
                        format="MM/dd/yyyy"
                        id="end-date"
                        label="End Date"
                        value={moment(conversationRange.end).toDate()}
                        style={{ margin: "1rem" }}
                        onChange={(date) =>
                          setConversationRange((prevState) => ({
                            ...prevState,
                            end: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                          }))
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                )}
              </div>
            </div>
            <div className="graph">
              {!dashboard.loading && (
                <>
                  {renderLegend()}
                  <Graph
                    data={[dashboard.scheduled, dashboard.classified, dashboard.conversations, dashboard.documents]}
                    rangeOptions={getRangeOptions(conversationRange.start, conversationRange.end)}
                    yMax={dashboard.stats.yMax}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect((state: RootStore) => ({ dashboard: state.dashboard }), { getConversationStats })(Dashboard);

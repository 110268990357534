import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Redirect } from "react-router-dom";

import connect from "app/connect";
import RetainScroll from "app/modules/retainScroll";
import Layout from "app/components/global/layout";

import { getBot, fetchLeadDocketFields, fetchLeadDocketCaseTypes } from "actions/bot";
import { fetchPlugins } from "actions/plugins";

import { BotStore } from "reducers/detailBot";
import { RootStore } from "reducers";

import * as DetailBotSelector from "selectors/detailbot";
import * as PluginsSelector from "selectors/plugins";

// Cmps
import BotDialog from "app/components/botDetail/botDialog";
import BotNavigator from "app/components/botDetail/botEditor/botNavigator";
import BotBreadcrumb from "app/components/botDetail/botBreadcrumb";

// Sass
import "sass/pages/bot.scss";

type Props = {
  getBot: Function;
  detailBot: BotStore;
  leadDocketCaseTypeId?: number;
  fetchLeadDocketFields: Function;
  fetchLeadDocketCaseTypes: Function;
} & RouteComponentProps;

/**
 * Bot Detail Container Component
 */

class Bot extends React.Component<Props, { updateScroll: boolean }> {
  lastUsedLDCaseTypeIdRef: React.MutableRefObject<any>;
  didPerformCaseTypesGet: React.MutableRefObject<boolean>;

  constructor(props) {
    super(props);
    this.lastUsedLDCaseTypeIdRef = React.createRef();
    this.didPerformCaseTypesGet = React.createRef();
    this.didPerformCaseTypesGet.current = false;
  }

  componentWillMount() {
    this.props.fetchPlugins();
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    const { detailBot, match, location, leadDocketCaseTypeId, fetchLeadDocketFields, fetchLeadDocketCaseTypes, hasLeaddocketPlugin } = this.props;
    // If we're not on a dialog yet, navigate to the root one
    if (detailBot.bot && location.pathname.indexOf("dialog") === -1) {
      this.props.history.replace(this.rootDialogUrl(match, detailBot));
    }

    var case_type = leadDocketCaseTypeId;
    if(detailBot.bot && leadDocketCaseTypeId == 0) {
      const item = detailBot.dialogsById[detailBot.selectedDialogId]
      if(item) {
        case_type = item.case_type
      }
    }

    if(hasLeaddocketPlugin && 
      fetchLeadDocketCaseTypes &&
      this.didPerformCaseTypesGet.current == false)
    {
      this.didPerformCaseTypesGet.current = true;
      fetchLeadDocketCaseTypes();
    }

    if (
      case_type &&
      hasLeaddocketPlugin &&
      fetchLeadDocketFields &&
      this.lastUsedLDCaseTypeIdRef.current !== case_type
    ) {
      this.lastUsedLDCaseTypeIdRef.current = case_type;
      fetchLeadDocketFields(case_type);
    }


  }

  // Returns the URL for the root dialog
  rootDialogUrl = (match, detailBot) => {
    return `${match.url}/dialog/${detailBot.bot.initial_dialog_id}`;
  };

  /**
   * General refresh to get new changes made to the bot
   */
  refresh = () => {
    const { match } = this.props;
    this.props.getBot(match.params.id);
  };

  render() {
    const { detailBot, match } = this.props;

    return (
      <Layout appClassName="platform" desktopOnly mobileTitle="Bot Detail" mobileBreakPoint={1100}>
        <RetainScroll>
          {({ recordScroll, scrollTop, registerContainer }) => (
            <div className="bot_base_container">
              <div className="container bot_detail" ref={registerContainer} onScroll={recordScroll}>
                <BotBreadcrumb detailBot={detailBot} />

                {!detailBot.loading && (
                  <>
                    <Route
                      path={match.url + "/dialog/:id"}
                      exact
                      render={(route) => {
                        const dialog = detailBot.dialogsById[route.match.params.id];

                        // Check if dialog exists
                        if (!dialog) {
                          return <Redirect to={this.rootDialogUrl(match, detailBot)} />;
                        }

                        return (
                          <BotDialog
                            bot={detailBot.bot}
                            dialog={dialog}
                            refresh={this.refresh}
                            dialogsById={detailBot.dialogsById}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </div>
              {!detailBot.loading && <BotNavigator />}
            </div>
          )}
        </RetainScroll>
      </Layout>
    );
  }
}

export default connect(
  (state: RootStore) => ({
    detailBot: state.detailBot,
    leadDocketCaseTypeId: DetailBotSelector.leadDocketCaseTypeId(state),
    hasLeaddocketPlugin: PluginsSelector.hasLeaddocketPlugin(state),
  }),
  { getBot, fetchPlugins, fetchLeadDocketFields, fetchLeadDocketCaseTypes }
)(Bot);

import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { RootStore } from "reducers";
import { 
  AppointmentType, 
  updateAppointmentType, 
  createAppointmentType
} from "actions/appointmentTypes";
import {
  createAppointmentTypeParams,
  updateAppointmentTypeParams,
} from "lib/appointmentTypes";

// Sass
import "sass/global/colors.scss";
import "sass/components/createAppointmentType.scss"

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from '@material-ui/core/TextField';

type State = {
  loading: boolean;
  appointmentTypeParams: createAppointmentTypeParams | updateAppointmentTypeParams;
  errors: ErrorState;
}

type ErrorState = {
  titleError: boolean;
  titleErrorMessage: string;
  descriptionError: boolean;
  descriptionErrorMessage: string;
}

type Props = {
  setVisible: Function;
  visible: boolean;
  refresh: Function;
  appointmentType?: AppointmentType;
  createAppointmentType: Function;
  updateAppointmentType: Function;
} & RouteComponentProps;

class CreateAppointmentType extends React.Component<Props, State> {
  static initialState = {
    loading: false,
    appointmentTypeParams: {
      title: "",
      description: "",
      duration: "00:30",
      buffer: 0,
    },
    errors: {
      titleError: false,
      titleErrorMessage: "Title required.",
      descriptionError: false,
      descriptionErrorMessage: "Description required."
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      ...CreateAppointmentType.initialState,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { appointmentType } = nextProps;
    if(appointmentType && !this.props.visible) {
      this.setState({
        ...this.state,
        appointmentTypeParams: {
          id: appointmentType.id,
          title: appointmentType.title,
          description: appointmentType.description,
          duration: appointmentType.duration,
          buffer: appointmentType.buffer,
        }
      });
    } else if (!appointmentType) {
      this.setState({
        ...CreateAppointmentType.initialState
      })
    }
  }

  handleChange = (key, e) => {
    if(key === 'hours' || key === 'minutes') {
      if(e.target.value < 0) return;
      const curHours = this.parseHours(this.state.appointmentTypeParams.duration);
      const curMinutes = this.parseMinutes(this.state.appointmentTypeParams.duration);
      let newDuration = '';
      if (key === 'hours') {
        newDuration = e.target.value + ":" + curMinutes;
      } else {
        newDuration = curHours + ":" + e.target.value;
      }
      this.setState({
        appointmentTypeParams: {
          ...this.state.appointmentTypeParams,
          ['duration']: newDuration
        }
      })
    } else {
      this.setState({
          appointmentTypeParams: {
              ...this.state.appointmentTypeParams,
              [key]: e.target.value === '' ? null : e.target.value
          },
          errors: {
            ...this.state.errors,
            [`${key}Error`]: false,
          }
      } as any);
    }
  }

  errorForKey = (key) => {
    if (!this.state.appointmentTypeParams[key] || this.state.appointmentTypeParams[key] === "") {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          [`${key}Error`]: true,
        }
      });
      return true;
    }
    return false;
  }

  createAppointmentType = e => {
    const { createAppointmentType, updateAppointmentType } = this.props;
    e.preventDefault();

    if (this.errorForKey('title') || this.errorForKey('description')) return;

    const methodToUse: any = this.props.appointmentType && this.props.appointmentType.id ? updateAppointmentType : createAppointmentType;
    methodToUse(this.state.appointmentTypeParams);

    this.setState({
      ...CreateAppointmentType.initialState
    });

    this.props.setVisible(false);
    this.props.refresh();
  }

  parseHours = (duration) => {
    const split_duration = duration.split(":");
    if(split_duration.length < 2) return 0;
    return parseInt(split_duration[0]);
  }

  parseMinutes = (duration) => {
    const split_duration = duration.split(":");
    if(split_duration.length < 2) return 30;
    return parseInt(split_duration[1]);
  }

  render() {
    const { appointmentType, visible, setVisible } = this.props;

    const numberSelectStyle = {
      textAlign: 'center' as const,
    };

    return (
      <Dialog 
        className="create__appointment__type" 
        setVisible={setVisible} 
        visible={visible}
      >
        <h3 className="type-24-bold">
          {appointmentType && appointmentType.id
            ? "Edit Appointment Type"
            : "Create Appointment Type"}
        </h3>

        <span className="create__appointment__type__title create__appointment__type__title__title">Title</span>
        <TextField
          type="text"
          placeholder="Name"
          variant="outlined"
          error={this.state.errors.titleError}
          helperText={this.state.errors.titleError ? this.state.errors.titleErrorMessage : ""}
          value={this.state.appointmentTypeParams.title || ""}
          onChange={(e) => this.handleChange('title', e)}
        />
        <span className="create__appointment__type__title create__appointment__type__title__description">Description</span>
        <TextField
          type="text"
          placeholder="Description"
          variant="outlined"
          error={this.state.errors.descriptionError}
          helperText={this.state.errors.descriptionError ? this.state.errors.descriptionErrorMessage : ""}
          value={this.state.appointmentTypeParams.description || ""}
          onChange={(e) => this.handleChange('description', e)}
        />
        <span className="create__appointment__type__title create__appointment__type__title__duration">Duration</span>
        <div className="create__appointment__type__duration">
          <div className="create__appointment__type__duration__hours">
            <span className="create__apointment__type__duration__hours__descriptor">Hours</span>
            <TextField
              type="number"
              placeholder="Hours"
              value={this.parseHours(this.state.appointmentTypeParams.duration)}
              onChange={(e) => this.handleChange('hours', e)}
              inputProps={{
                min: 0,
                max: 23,
                style: numberSelectStyle,
              }}
            />
          </div>
          <div className="create__appointment__type__duration__minutes">
            <span className="create__apointment__type__duration__descriptor">Minutes</span>
            <TextField
              type="number"
              placeholder="Minutes"
              value={this.parseMinutes(this.state.appointmentTypeParams.duration)}
              onChange={(e) => this.handleChange('minutes', e)}
              inputProps={{
                min: 1,
                max: 59,
                style: numberSelectStyle,
              }}
            />
          </div>
          <div className="create__appointment__type__duration__buffer">
          <span className="create__apointment__type__duration__descriptor">Buffer</span>
          <TextField
            type="number"
            placeholder="Buffer"
            className="create__appointment__type__duration__buffer__input"
            value={this.state.appointmentTypeParams.buffer}
            onChange={(e) => this.handleChange('buffer', e)}
            inputProps={{
              min: 0,
              style: numberSelectStyle,
            }}
          />
          </div>
        </div>
        

        <button onClick={(e) => this.createAppointmentType(e)}>{appointmentType && appointmentType.id ? 'Save' : 'Create'}</button>
      </Dialog>
    )
  }
}

export default connect()(CreateAppointmentType);
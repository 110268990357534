import moment from "moment";

export const fromNow = (timestamp) => {
  return moment(timestamp).fromNow();
};

export const formatTime = (timestamp, format = "MMMM Do, YYYY"): string => {
  return moment.utc(timestamp).format(format);
};

export const utcTimeStringToDates = (workingHoursString: string, day: string): Date[] => {
  const [startDate, endDate] = workingHoursString.split(",").map((time) => {
    const timeString = moment(time).format("HH:mmZ");
    const s = day + "T" + timeString.toString();
    const d = new Date(s);
    return d;
  });

  return [startDate, endDate];
};

import * as React from "react";
import { RouteComponentProps } from "react-router";

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from "@material-ui/core/TextField";

// Sass
import "sass/components/clioGrow.scss";

export interface LitifyDomainInput {
  oauthState: string;
  api_key: string;
  api_secret: string;
}

type Props = {
  setVisible: Function;
  visible: boolean;
  savePluginSource: (payload: LitifyDomainInput) => void;
} & RouteComponentProps;

const LitifyDomain: React.FC<Props> = ({ visible, setVisible, savePluginSource }: Props) => {
  const [domain, setDomain] = React.useState("");
  const [clientId, setclientId] = React.useState("");
  const [secretKey, setSecretKey] = React.useState("");

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    const payload: LitifyDomainInput = { oauthState: domain, api_key: clientId, api_secret: secretKey };
    savePluginSource(payload);
    setVisible(false);
  };

  return (
    <Dialog className="litify_domain" setVisible={setVisible} visible={visible}>
      <h3 className="type-30-bold-nexa">{"Create Litify Plugin"}</h3>
      <div className="clio__grow__settings__help">
        <p>
          {
            "We need your Litify api domain. In the example below, the subdomain is 'my-firm', and is highlighted in bold."
          }
        </p>
        <br></br>
        <p>
          https://<b>my-firm</b>.lightning.force.com/api
        </p>
        

        <TextField type="text" placeholder="Litify Domain" onChange={(e): void => setDomain(e.target.value)} />
        <br /><br />
          <p>For the consumer key and secret you can find them in setup under Manage Connected Apps and clicking Manage Consumer Details.</p>
          <TextField
            required
            type="text"
            placeholder="Consumer key"
            onChange={(e): void => setclientId(e.target.value)}
            style={{ width: "100%" }}
          />
          <br /><br />
          <TextField
            required
            type="text"
            placeholder="Consumer Secret"
            onChange={(e): void => setSecretKey(e.target.value)}
            style={{ width: "100%" }}
          />
        <button onClick={handleClick}>Add Plugin</button>
      </div>
    </Dialog>
  );
};

export default LitifyDomain;

import { Reducer } from "redux";
import { RedirectAction, REDIRECT_CLEAR, REDIRECT_SET } from "actions/redirect";

export type RedirectStore = {
    location: string | null;
};

const initialState = {
    location: null,
};

export default function(
    state: RedirectStore = initialState,
    action: RedirectAction,
): RedirectStore {
    switch (action.type) {
        case REDIRECT_CLEAR: {
            return {
                ...state,
                location: null,
            };
        }
        case REDIRECT_SET: {
            return {
                ...state,
                location: action.payload.location,
            };
        }
        default: {
            return state;
        }
    }
}

import * as React from "react";

type DayHeaderProps = {
  date: Date;
};

/** Calender day header component */
const DayHeader = (props: DayHeaderProps): React.ReactElement => {
  const { date } = props;
  return (
    <div>
      <span>{date.toLocaleDateString("en-US", { weekday: "short" })} </span>
      <span className="day">{date.toLocaleDateString("en-US", { day: "numeric" })}</span>
    </div>
  );
};

export default DayHeader;

import { User } from "reducers/user";
import { fetchUsers, impersonateUser, setAdminToken } from '../lib/admin'
import { saveUser } from 'actions/user';

// Action types
export const ADMIN_SET_USERS = 'ADMIN_SET_USERS';
export const ADMIN_FETCH_USERS = 'ADMIN_FETCH_USERS';
export const ADMIN_IMPERSONATE_USER = 'ADMIN_IMPERSONATE_USER'

export const getUsers = () => dispatch => {
    dispatch({
        type: ADMIN_FETCH_USERS
    });

    fetchUsers()
    .then(res => {
        dispatch({
            type: ADMIN_SET_USERS,
            users: res,
        })
        return res;
    })
}

export const impersonate = (user_id: string, history) => dispatch => {
    dispatch({
        type: ADMIN_IMPERSONATE_USER
    });

    impersonateUser(user_id)
    .then(res => {
        const adminToken: string | null = localStorage.getItem("token");
        if(adminToken) { setAdminToken(adminToken); }
        saveUser(res.data.jwt)(dispatch);
        history.push("/");
    })

}

export const stopImpersonating = (history) => dispatch => {
    const adminToken: string | null = localStorage.getItem("adminToken");
    localStorage.removeItem('adminToken');
    saveUser(adminToken)(dispatch);
    history.push("/admin/impersonate");
}

export type AdminAction =
    {
          type: "ADMIN_SET_USERS";
          users: User[]
      } 
    | {
        type: "ADMIN_FETCH_USERS";
    }
    | {
        type: "ADMIN_IMPERSONATE_USER";
    }

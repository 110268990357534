import api from "lib/api";

export type MarketingSourcePayload = {
  name: string;
  utm_source: string;
};

export type updateMarketingSourceParams = {
  id: number;
  name: string;
  utm_source: string;
};

export type CampaignPayload = {
  marketing_source_id: number;
  name: string;
  utm_campaign: string;
};

export type updateCampaignParams = {
  id: number;
  name: string;
  utm_campaign: string;
};

// Marketing sources
export const getMarketingSources = () => {
  return api.get(`/marketing_settings/sources`, null);
};

export const createMarketingSource = (params: MarketingSourcePayload) => {
  return api.post(`/marketing_settings/sources`, params);
};

export const updateMarketingSource = (params: updateMarketingSourceParams) => {
  return api.put(`/marketing_settings/sources`, params);
};

export const removeMarketingSource = (source_id: number) => {
  return api.deleteReq(`/marketing_settings/sources?id=${source_id}`, null);
};

// Campaigns
export const getCampaigns = (marketing_source_id: number) => {
  return api.get(`/marketing_settings/campaigns?marketing_source_id=${marketing_source_id}`, null);
};

export const createCampaign = (params: CampaignPayload) => {
  return api.post(`/marketing_settings/campaigns`, params);
};

export const updateCampaign = (params: updateCampaignParams) => {
  return api.put(`/marketing_settings/campaigns`, params);
};

export const removeCampaign = (campaign_id: number) => {
  return api.deleteReq(`/marketing_settings/campaigns?id=${campaign_id}`, null);
};

export const getLeadDocketMarketingSources = () => {
  return api.get(`/leaddocket_mappings/marketing_sources`, null);
}

export const getLitifyMarketingSources = () => {
  return api.get(`/litify/marketing_sources`)
}
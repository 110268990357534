import * as React from "react";
import { updateNodeConnection, updateNodeParams } from "lib/nodes";
import TextareaAutosize from "react-autosize-textarea";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { userIsAdmin } from "lib/utilities/global";
import MarkDownInput from "app/components/botDetail/markDownInput";

// Sass
import "sass/components/dialogNodeBody.scss";
import { Node, Bot } from "actions/bot";
import { generateDocuments } from "lib/markdownDocuments";

type Props = {
  updateNode: (params: updateNodeParams) => {};
  captureEnter: (e: any) => void;
  node: Node;
  redirectToHandoff: () => any;
  selected: boolean;
  editing: boolean;
  docGenState: boolean;
  esign: boolean;
  user_download: boolean;
  initSchedCheck: boolean;
  bot: Bot;
  title: string;
  message: string;
  metaState: any;
  pre_sched: string;
  post_sched: string;
  sign_message: string;
  postsign_message: string;
  userdl_message: string;
  initSched: boolean;
  updateInitSched: (initSched) => {};
  updatePre: (pre: string) => {};
  updateSignMeta: (sign: string) => {};
  updatePostSignMeta: (sign: string) => {};
  updateUserDownloadMeta: (user_download: string) => {};
  updateBody: (message: string) => {};
  updateMeta: (key: string, value: any) => {};
  updateDocGen: (genDocs: boolean) => {};
  updateEsign: (esign: boolean) => {};
  updateUserDownload: (user_download:boolean) => {};
} & RouteComponentProps;

type State = {
  completionMessage: string;
  preScheduleMessage?: string;
  postScheduledMessage?: string;
  esignMessage?: string;
  postsignMessage?: string;
  userdlMessage?: string;
  scheduleChecked?: boolean;
  generateDocumentsChecked?: boolean;
  // changes to the node to be saved in the db when the Save button is clicked
  title: string;
  message: string;
  meta: any;
  generateDocuments: boolean;
};

export default class HandoffNodeBodyElement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);


    const { meta } = props.node;

    const successPresent: boolean = meta && meta.pre_schedule_message ? meta.pre_schedule_message.length > 0 : false;
    const fallbackPresent: boolean =
      meta && meta.post_scheduled_message ? meta.post_scheduled_message.length > 0 : false;

    this.state = {
      completionMessage: props.node.message,
      preScheduleMessage: this.props.pre_sched,
      postScheduledMessage: this.props.post_sched,
      esignMessage: this.props.sign_message,
      postsignMessage: this.props.postsign_message,
      userdlMessage: this.props.userdl_message,
      scheduleChecked: successPresent || fallbackPresent,
      generateDocumentsChecked: props.node.generate_documents,
      title: this.props.node.title,
      message: this.props.node.message,
      meta: this.props.node.meta,
      generateDocuments: this.props.generateDocuments
    };
  }

  updateHandoffNode() {
    const { node, updateNode } = this.props;
    const { preScheduleMessage, postScheduledMessage, esignMessage, postsignMessage, userdlMessage, completionMessage } = this.state;

    const meta = {
      pre_schedule_message: preScheduleMessage,
      post_scheduled_message: postScheduledMessage,
      esign_message: esignMessage,
      postsign_message: postsignMessage,
      userdl_message: userdlMessage
    };

    /*updateNode({
      id: node.id,
      message: completionMessage,
      meta: {
        ...node.meta,
        ...meta,
      },
    });*/
  }

  updateMeta = (key: string, value: any) => {
    const current_meta = this.state.meta;
    this.setState({
      meta: {
        ...current_meta,
        [key]: value
      }
    })

  }

  getDocValidation = () => {
    const { node, updateNode } = this.props;

    const gen_state = this.props.docGenState;

    return gen_state
  }

  

  getEsignValidation = () => {
    const { node, updateNode } = this.props;
    const esign_state = this.props.esign;
    return esign_state
  }

  getUserDownloadValidation = () => {
    const { node, updateNode } = this.props;
    const user_dl_state = this.props.user_download;
    return user_dl_state
  }

  getSchedCheckValidation = () => {
    const { node, updateNode } = this.props;
    const gen_state = this.props.initSched;

    return gen_state
  }

  setSchedCheckValidation = (active: boolean) => {
    this.props.updateInitSched(active)
  }

  setDocValidation = (active: boolean) => {
    this.props.updateDocGen(active)
  }

  setEsignValidation = (active: boolean) => {
    this.props.updateEsign(active)
  }

  setUserDownloadValidation = (active: boolean) => {
    this.props.updateUserDownload(active)
  }

  setPreScheduledMessage = (text: string) => {
    this.props.updateMeta('pre_schedule_message', text);
    this.props.updatePre(text)
  };

  setPostScheduledMessage = (text: string) => {
    this.props.updateMeta('post_scheduled_message', text);
    this.props.updatePost(text)
  };

  setEsignMessage = (text: string) => {
    if(text == ""){
      text = "You may now proceed to the electronic representation agreement by clicking the button below" 
    }
    this.props.updateMeta('esign_message', text);
    this.props.updateSignMeta(text)
  }

  setPostEsignMessage = (text: string) => {
    this.props.updateMeta('postesign_message', text);
    this.props.updatePostSignMeta(text)

  }

  setUserDownloadMessage = (text: string) => {
    this.props.updateMeta('userdownload_message', text);
    this.props.updateUserDownloadMeta(text)
  }

  getScheduledValidation = () => {
    // if meta is nil, the new contact form card hasn't been used
    // so the previous validation will be used
    // if this is the case, update meta here to keep everything synced
    // this will only happen the first time, and all other changes will persist
    const meta = this.props.metaState;
    
    if(!meta) {
      return false;
    } else {
      if(meta['pre_schedule_message'].length > 0 && meta['post_scheduled_message'].length > 0)
        return true;
      else
        return false;
    }
  }

  handleGenerateDocumentsChecked = (e): void => {
    const { node, updateNode } = this.props;

    this.setState({
      generateDocumentsChecked: e.target.checked,
    });

    this.props.updateDocGen(e.target.checked)

  };

  render() {
    const { redirectToHandoff, selected, bot, editing, message, pre_sched, post_sched, sign_message, postsign_message, userdl_message} = this.props;
    const {
      completionMessage,
      preScheduleMessage,
      postScheduledMessage,
      esignMessage,
      postsignMessage,
      scheduleChecked,
      generateDocumentsChecked,
    } = this.state;

    const botHasMarkdownDocuments = bot && bot.markdown_documents && bot.markdown_documents.length > 0;

    return (
      <div className="node__body__type">
        <p>Completion Message - for non-scheduled Leads</p>
        {editing ? (
          <MarkDownInput
            className="type-16-regular node__body-message node__body-message__handoff"
            onChange={(value) => this.props.updateBody(value)}
            body={message}
            //updateNode={() => this.updateHandoffNode()}
            placeholder="Thank you. We're going to get you in touch with someone shortly."
            disabled={!userIsAdmin(this.props.user)}
          />
        ) : (
          <div className="node__body-message-unselected">
            {message || "Thank you. We're going to get you in touch with someone shortly."}
          </div>
        )}

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.getSchedCheckValidation()}
                onChange={(e) => this.setSchedCheckValidation(e.target.checked)}
                disabled={!userIsAdmin(this.props.user) || !this.props.editing}
              />
            }
            label="Add Prompts for Scheduling an Appointment"
          />

          {this.getSchedCheckValidation() && (
            <>
              <p>Pre-Schedule Message</p>

              {editing ? (
                <MarkDownInput
                  className="type-16-regular node__body-message node__body-message__handoff"
                  onChange={(e) => this.setPreScheduledMessage(e)}
                  body={pre_sched}
                  placeholder="If you would like to schedule an appointment with us, please select an available time below."
                  disabled={!userIsAdmin(this.props.user)}
                />
              ) : (
                <div className="node__body-message-unselected">
                  {pre_sched ||
                    "If you would like to schedule an appointment with us, please select an available time below."}
                </div>
              )}

              <p>Post-Schedule Message</p>
              {editing ? (
                <MarkDownInput
                  className="type-16-regular node__body-message node__body-message__handoff"
                  onChange={(e) => this.setPostScheduledMessage(e)}
                  body={post_sched}
                  placeholder="Thank you. We're going to get you in touch with someone shortly."
                  disabled={!userIsAdmin(this.props.user)}
                />
              ) : (
                <div className="node__body-message-unselected">
                  {post_sched || "Thank you. We're going to get you in touch with someone shortly."}
                </div>
              )}
            </>
          )}

          {botHasMarkdownDocuments && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.getDocValidation()}
                  onChange={(e) => this.setDocValidation(e.target.checked)}
                  disabled={!userIsAdmin(this.props.user) || !this.props.editing}
                />
              }
              label="Generate Documents"
            />
            
          )}

          {botHasMarkdownDocuments && this.getDocValidation() == true && this.getUserDownloadValidation() == false && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.getEsignValidation()}
                  onChange={(e) => this.setEsignValidation(e.target.checked)}
                  disabled={!userIsAdmin(this.props.user) || !this.props.editing}
                />
              }
              label="Enable E-Sign Variables"
            />
          )}
          {botHasMarkdownDocuments && this.getDocValidation() == true && this.getEsignValidation() == false && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.getUserDownloadValidation()}
                  onChange={(e) => this.setUserDownloadValidation(e.target.checked)}
                  disabled={!userIsAdmin(this.props.user) || !this.props.editing}
                />
              }
              label="Enable User Document Download and Email"
            />
          )

          }
          
          {this.getEsignValidation() && this.getDocValidation() && (
            <>
              <p>E-Sign Message</p>

              {editing ? (
                <MarkDownInput
                  className="type-16-regular node__body-message node__body-message__handoff"
                  onChange={(e) => this.setEsignMessage(e)}
                  body={sign_message}
                  placeholder="You may now proceed to the electronic representation agreement by clicking the button below"
                  disabled={!userIsAdmin(this.props.user)}
                />
              ) : (
                <div className="node__body-message-unselected">
                  {sign_message ||
                    "You may now proceed to the electronic representation agreement by clicking the button below"}
                </div>
              )}

              <p> Post E-Sign Message</p>

              {editing ? (
                <MarkDownInput
                className="type-16-regular node__body-message node__body-message__handoff"
                onChange={(e) => this.setPostEsignMessage(e)}
                body={postsign_message}
                placeholder="Please download your completed representation agreement below. A member of our team will be in touch 
                to advise you on your next steps. Thank you!"
                disabled={!userIsAdmin(this.props.user)}
                />
              ) : (
              <div className="node__body-message-unselected">
              {postsign_message ||
                 "Please download your completed representation agreement below. A member of our team will be in touch to advise you on your next steps. Thank you!"}
              </div>
              )}
              </>
              
          )}

{this.getUserDownloadValidation() && this.getDocValidation() && (
            <>
              <p> User Download Document Message</p>

              {editing ? (
                <MarkDownInput
                className="type-16-regular node__body-message node__body-message__handoff"
                onChange={(e) => this.setUserDownloadMessage(e)}
                body={userdl_message}
                placeholder="Please download your completed document below."
                disabled={!userIsAdmin(this.props.user)}
                />
              ) : (
              <div className="node__body-message-unselected">
              {userdl_message ||
                 "Please download your completed document below."}
              </div>
              )}
              </>
              
          )}


        </div>
        

        <button onClick={redirectToHandoff} className="node__body-message__handoff-button">
          Lead Routing
        </button>
      </div>
    );
  }
}

import * as React from "react";
import { getQueryParam } from "lib/utilities/url";
import { RouteComponentProps } from "react-router";
import PluginSources from "app/components/settings/plugin_sources";
import ClioGrowSettings from "app/components/settings/clioGrow";
import FilevineSettings from "app/components/settings/filevinePluginModal";
import LeadDocketSettings, { LeadDocketInput } from "app/components/settings/addPlugins/leadDocketPluginModal";
import { fetchPlugins, Plugin, PluginSource } from "actions/plugins";
import {
  createPlugin,
  CreatePluginPayload,
  removePlugin as removePluginRequest,
  redirect_uri_for_plugin_source,
} from "lib/plugins";
import { RootStore } from "reducers";
import connect from "app/connect";
import LitifyDomain, { LitifyDomainInput } from "./litifyDomain";
import plugin_sources from "app/components/settings/plugin_sources";

type Props = {
  fetchPlugins: Function;
  plugins: Plugin[];
  pluginSources: PluginSource[];
} & RouteComponentProps;

const Plugins: React.FC<Props> = ({ fetchPlugins, match, plugins, pluginSources, toggleSnackbar }: Props) => {
  let pizza = null
  plugins.forEach(p => {
    if(p.plugin_source?.identifier == 'leaddocket') {
      pizza = p
    }
  })
  const [showPluginSourceModal, setShowPluginSourceModal] = React.useState(false);
  const [clioGrowVisible, setClioGrowVisible] = React.useState(false);
  const [filevineModalVisible, setFilevineModalVisibility] = React.useState(false);
  const [litifyDomainVisible, setLitifyDomainVisible] = React.useState(false);
  const [leadDocketVisible, setLeadDocketVisible] = React.useState(false);
  const [leadDocketDisabled, setLeadDocketDisabled] = React.useState(true);
  const [leadDocketEditing, setLeadDocketEditing] = React.useState(false);
  


  const savePluginSourceFactory = (sourceIdOrIdentifier: string | number) => async (
    // param: LeadDocketInput | LitifyDomainInput | Partial<CreatePluginPayload>
    param: { [key: string]: any }
  ): Promise<void> => {
    let sourceId;
    let pluginSource;
    if (typeof sourceIdOrIdentifier === "string" && sourceIdOrIdentifier === "3"){
      //testing - force recognize 3
      sourceId = parseInt(sourceIdOrIdentifier);
    } else if (typeof sourceIdOrIdentifier === "string" && !isNumber(sourceIdOrIdentifier)) {
      pluginSource = pluginSources?.find((p) => p.identifier === sourceIdOrIdentifier);
      if (!pluginSource) {
        toggleSnackbar(`Could not find plugin source for ${sourceIdOrIdentifier}`);
        return;
      }
      sourceId = pluginSource.id;
    } else {
      sourceId = sourceIdOrIdentifier;
    }


    /*if (sourceIdOrIdentifier === "litify") {
      const redirect = redirect_uri_for_plugin_source(sourceId);
      const url = `${pluginSource.authorization_url}&redirect_uri=${redirect}&state=${param.domain}`;
      window.location.replace(url);
      return;
    }*/
    
    try {
      console.log("creating plugin")
      const payload = {
        plugin_source_id: sourceId,
        redirect_uri: redirect_uri_for_plugin_source(sourceId),
        ...param,
      };
      console.log(payload)

      await createPlugin(payload);
      if (sourceIdOrIdentifier === "litify") {
        const redirect = redirect_uri_for_plugin_source(sourceId);
        const client_id = param.api_key
        const url = `${pluginSource.authorization_url}?&client_id=${client_id}&response_type=code&redirect_uri=${redirect}&state=${param.domain}`;
        window.location.replace(url);
        return;
      }
      await fetchPlugins();

    } catch (err) {
      await toggleSnackbar("We're running into an issue saving this plugin. Please contact us for help!");
    }
  };

  const setPlugin = (plugin: Plugin) => {

  } 

  const togglePluginSources = (visible?): void => {
    setShowPluginSourceModal(visible || !showPluginSourceModal);
  };

  const isNumber = (str): boolean => {
    if(!isNaN(Number(str))) {
      return true
    }
    return false
  }

  React.useEffect(() => {
    fetchPlugins();

    const plugin_source_id = getQueryParam(window.location.href, "plugin_source_id") || match.params.pluginSourceId;
    const authorization_code = getQueryParam(window.location.href, "code");
    const oauthState = getQueryParam(window.location.href, "state");
    const curr_url = window.location.href;

    /*console.log("current url:");
    console.log(curr_url);
    console.log("plugin source id");
    console.log(plugin_source_id);
    console.log("authorization code");
    console.log(authorization_code);
    console.log("oathState")
    console.log(oauthState)

    console.log("plugin source id");
    console.log(plugin_source_id);
    console.log("authorization code");
    console.log(authorization_code);
    console.log("oathState")
    console.log(oauthState)*/

    if (plugin_source_id && authorization_code) {
      savePluginSourceFactory(plugin_source_id)({ authorization_code, oauthState });
    }
  }, []);

  const removePlugin = (plugin): void => {
    removePluginRequest(plugin.id)
      .then(() => fetchPlugins())
      .catch(() => toggleSnackbar("We ran into an issue removing this plugin! Please try again later."));
  };

  const onAddPluginSource = (plugin_source: PluginSource): void => {
    if (plugin_source.identifier === "clio_grow") {
      setClioGrowVisible(true);
      togglePluginSources(false);
    } else if (plugin_source.identifier === "litify") {
      setLitifyDomainVisible(true);
      togglePluginSources(false);
    } else if (plugin_source.identifier === "filevine") {
      setFilevineModalVisibility(true);
      togglePluginSources(false);
    } else if (plugin_source.identifier === "leaddocket") {
      setLeadDocketVisible(true);
      togglePluginSources(false);
      setLeadDocketDisabled(false);
    } else if (plugin_source.identifier === "outlook") {
      //console.log("outlook case")
      togglePluginSources(false);
      const redirect = redirect_uri_for_plugin_source(plugin_source.id);
      //console.log(redirect);
      const url = `${plugin_source.authorization_url}&redirect_uri=${redirect}`;
      //console.log(url);
      window.location.replace(url);
      // moved outlook redirect
    } else if (plugin_source.identifier === "hubspot") {
      togglePluginSources(false);
      const redirect = redirect_uri_for_plugin_source(plugin_source.id);
      const url = `${plugin_source.authorization_url}`;
      window.location.replace(url);
    } else {
      togglePluginSources(false);
      const redirect = redirect_uri_for_plugin_source(plugin_source.id);
      const url = `${plugin_source.authorization_url}&redirect_uri=${redirect}`;
      window.location.replace(url);
    }
  };

  const loginToLitify = (plugin: Plugin): void => {
    const sourceId = plugin.plugin_source.id
    const domain = plugin.meta.state
    const client_id = plugin.meta.api_key
    const redirect = redirect_uri_for_plugin_source(sourceId);
    const url = `${plugin.plugin_source.authorization_url}?&client_id=${client_id}&response_type=code&redirect_uri=${redirect}&state=${domain}`;
    window.location.replace(url);
    
  }

  const editLeaddocket = (plugin: Plugin): void => {
    setLeadDocketVisible(true);
    setLeadDocketEditing(true);
    setLeadDocketDisabled(false);

  }

  return (
    <div className="plugins">
      <div className="plugins__header">
        <button className="plugins__add__button" onClick={(): void => togglePluginSources()}>
          Add Plugin
        </button>

        <h3 className="type-24-bold">Plug-ins</h3>
      </div>

      <ul className="plugins__list">
        {plugins.map((plugin, i) => {
          return (
            <li key={i} className="plugin__row">
              <div className="plugin__row__icon__container">
                <span>{plugin.plugin_source.title}</span>
                <img src={plugin.plugin_source.image} />
              </div>
              <p>{plugin.plugin_source.description}</p>

              <div className="plugin__list__actions">
                {JSON.stringify(plugin.scopes) !== JSON.stringify(plugin.plugin_source.current_scopes) && (
                  <button
                    className="plugin__button-update"
                    onClick={(): void => onAddPluginSource(plugin.plugin_source)}
                  >
                    Upgrade
                  </button>
                )}
                {(plugin.plugin_source.identifier == "litify" && plugin.refresh_token == null) && (
                  <button
                    className="plugin__button-update"
                    onClick={(): void => loginToLitify(plugin)}
                  >
                    Login
                  </button>
                )}
                {(plugin.plugin_source.identifier == "leaddocket") && (
                  <button
                    className="plugin__button-update"
                    onClick={(): void => editLeaddocket(plugin)}
                  >
                    Edit
                  </button>
                )}
                <button onClick={(): void => removePlugin(plugin)}>Remove</button>
              </div>
            </li>
          );
        })}
      </ul>

      <PluginSources
        onAddPluginSource={(pluginSource): void => onAddPluginSource(pluginSource)}
        setVisible={(visible): void => togglePluginSources(visible)}
        visible={showPluginSourceModal}
        setClioGrowVisible={setClioGrowVisible}
      />

      <ClioGrowSettings
        setVisible={(visible): void => setClioGrowVisible(visible)}
        visible={clioGrowVisible}
        savePluginSource={savePluginSourceFactory("clio_grow")}
      />

      <LitifyDomain
        setVisible={setLitifyDomainVisible}
        visible={litifyDomainVisible}
        savePluginSource={savePluginSourceFactory("litify")}
      />

      <FilevineSettings
        setVisible={(visible): void => setFilevineModalVisibility(visible)}
        visible={filevineModalVisible}
        savePluginSource={savePluginSourceFactory("filevine")}
      />

      <LeadDocketSettings
        setVisible={(visible): void => setLeadDocketVisible(visible)}
        setDisabled={(disabled): void => setLeadDocketDisabled(disabled)}
        setEditing={(editing): void => setLeadDocketEditing(editing)}
        visible={leadDocketVisible}
        disabled={leadDocketDisabled}
        editing={leadDocketEditing}
        savePluginSource={savePluginSourceFactory("leaddocket")}
        plugin={pizza}
        plugin_sources={pluginSources}
      />
    </div>
  );
};

export default connect(
  (state: RootStore) => ({
    plugins: state.plugins.plugins,
    pluginSources: state.plugins.plugin_sources,
  }),
  { fetchPlugins }
)(Plugins);

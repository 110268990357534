import api from "lib/api";

export const inviteLimit = 20;

type iniviteParams = {
    name?: string;
    email: string;
}

export const fetchInvites = (page:number) => {
    return api.get(`/invites?limit=${inviteLimit}&page=${page}`, null)
}

export const sendInvite = (inviteUser:iniviteParams) => {
    return api.post(`/invites`, inviteUser)
}

export const resendInvite = (inviteUser:iniviteParams) => {
    return api.post(`/invites/resend`, inviteUser)
}

export const rescindInvite = (id:string) => {
    return api.deleteReq(`/invites/${id}`, null);
}

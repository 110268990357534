import * as React from "react";

// Sass
// import "sass/components/cmpName.scss";

// Cmps
import Dialog from "app/components/global/dialog";

type State = {

}

type Props = {
    title: String;
    message: String;
    actionTitle: String;
    cancelTitle: String | null;
    confirmation: Function;
    visible: Boolean;
    setVisible: Function;
    cancel: Function | null;
};

/**
 * Confirmation Window
 */
class Confirmation extends React.Component<Props, State> {

    render() {
        const { title, message, actionTitle, cancelTitle, confirmation, setVisible } = this.props;

        return (
            <Dialog className="confirmation" {...this.props}>
                <h3 className="type-30-bold">{title}</h3>

                <p className="type-16-regular">{message}</p>

                <div className="confirmation__buttonWrap">
                    <button 
                        onClick={(e) => confirmation(e)} 
                        className="confirmation__action"
                    >{actionTitle}</button>
                    
                    <button 
                        onClick={() => setVisible(false)}
                        className="confirmation__cancel"
                    >
                    {cancelTitle || 'Cancel'}
                    </button>
                </div>
            </Dialog>
        );
    }
}

export default Confirmation;
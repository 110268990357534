import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import { updateBotParams, editBot } from "lib/bots";
import { Checkbox } from "@material-ui/core";
import { AttorneyStore } from "reducers/attorneys";
import { toggleSnackbar } from "actions/ui";

// Sass
import "sass/global/colors.scss";
import "sass/components/editDocumentGeneration.scss";

// Cmps
import Dialog from "app/components/global/dialog";
import FormControl from "app/components/global/formControl";
import { Bot } from "actions/bot";

const initialState = {
  loading: false,
  botParams: undefined,
  emails: [],
};

type Props = {
  refresh: Function;
  bot: Bot;
  visible: boolean;
  setVisible: Function;
  attorneys: AttorneyStore;
  getAttorneys: Function;
} & RouteComponentProps;

const EditDocumentGenerationEmails: React.FC<Props> = ({ refresh, bot, visible, setVisible, attorneys }: Props) => {
  const [state, setState] = React.useState<{ emails: string[] }>(initialState);
  React.useEffect(() => {
    if (bot && bot.document_generation_emails && bot.document_generation_emails !== state.emails) {
      setState({ emails: bot.document_generation_emails });
    }
  }, [bot]);

  const handleEdit = (e): void => {
    editBot({ id: bot.id, document_generation_emails: state.emails })
      .then((response) => {
        setVisible(false);
        refresh();
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong updating document generation emails!");
      });
  };

  const handleEmailChange = (e, email): void => {
    if (e.target.checked) {
      setState({ emails: [...state.emails, email] });
    } else {
      const index = state.emails.indexOf(email);
      const array = [...state.emails];
      if (index !== -1) {
        array.splice(index, 1);
        setState({ emails: array });
      }
    }
  };

  const userList =
    attorneys &&
    attorneys.data &&
    attorneys.data.map((attorney) => {
      return (
        <div key={attorney.id}>
          <span>{attorney.name}</span>
          <Checkbox
            checked={state.emails.includes(attorney.email)}
            onChange={(e): void => handleEmailChange(e, attorney.email)}
          ></Checkbox>
        </div>
      );
    });

  return (
    <Dialog className="document_generation_emails" setVisible={setVisible} visible={visible}>
      <h3>Select users to receive documents</h3>
      {userList}
      <button onClick={(e): void => handleEdit(e)}>Save</button>
    </Dialog>
  );
};

export default connect()(EditDocumentGenerationEmails);

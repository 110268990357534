import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CueType } from "lib/cue"

import Date from  "assets/icons/datetime.inline.svg";
import Contact from "src/assets/icons/email.inline.svg";
import File from "assets/icons/file.inline.svg";
import Address from "assets/icons/address.inline.svg";
import Message from "assets/icons/message.inline.svg";
import Number from "assets/icons/number.inline.svg";
import Open from "assets/icons/open.inline.svg";
import Option from "assets/icons/option.inline.svg";
import Prompt from "assets/icons/prompt.inline.svg"
import Dialog from "assets/icons/dialog.inline.svg";

type Props = {
    cueType
} & RouteComponentProps;

/**
 * a cue icon for the individual cue types
 */
class CueIcon extends React.Component<Props> {
    render() {
        const { cueType, ...rest } = this.props

        switch (cueType) {
            case CueType.MESSAGE:
                return <Prompt { ...rest } />
            case CueType.OPEN_RESPONSE:
                return <Open { ...rest } />
            case CueType.CONTACT:
                return <Contact { ...rest } />
            case CueType.NUMBER_RESPONSE:
                return <Number { ...rest } />
            case CueType.FILE_UPLOAD:
                return <File { ...rest } />
            case CueType.MULTIPLE_CHOICE:
                return <Option { ...rest } />
            case CueType.DATE:
                return <Date { ...rest } />
            case CueType.HANDOFF:
                return <Message { ...rest } />
            case CueType.DIALOG:
                return <Dialog { ...rest } />
            case CueType.ADDRESS:
                return <Address { ...rest } />
            default:
                return <Message { ...rest } />
        }

    }
}

export default CueIcon;

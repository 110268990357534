import * as React from "react";
import connect from "app/connect";
import { NodeConnection as NodeConn, fetchNSetNode } from "actions/bot";
import moment from "moment";
import { RootStore } from "reducers";
import { withRouter, RouteComponentProps } from "react-router";
import {
  createNodeConnection,
  deleteNodeConnection,
  updateNodeConnection,
  updateNodeConnectionParams,
  createNodeConnectionParams,
} from "lib/nodes";
import { CueType, OperatorDetails, nodeTypeToAllowedPattern } from "lib/cue";
import Plus from "assets/icons/ui/plus.inline.svg";
import { userIsAdmin } from "lib/utilities/global";

// Sass
import "sass/components/nodeConnections.scss";

// Cmps
import NodeTags from "app/components/botDetail/nodeTags";
import NodeConnectionPattern from "app/components/botDetail/nodeConnectionPattern";

type State = {
  patterns: Array<any>;
  withRange: boolean;
  allowedPattern: OperatorDetails;
};

type Props = {
  isNew: boolean;
  isActive: boolean;
  index?: number;
  nodeId: number;
  nextReferenceNodeId?: number | null;
  nodeType: string;
  isFollowUpNode: boolean;
  addFollowUpToPattern: () => {};
  makeConnectionActive: () => {};
  nodeConnection?: NodeConn;
  checkBothFileFollowup: (filePatternToCheck: string) => {};
  selected: boolean;
  editing: boolean;
} & RouteComponentProps;

export const SKIP = "skip";
export const SUBMITTED = "submitted";

/**
 * Node connection related component with followup to add.
 */
class NodeConnection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const stateDefaultData = this.getCurrentOperator();
    this.state = stateDefaultData;
  }
  /** To take child input ref */
  childInputRef: any;

  /**
   * Create Connection
   */
  createNodeConnection = (params: createNodeConnectionParams) => {
    const { nodeId } = this.props;
    return createNodeConnection(params)
      .then((response) => {
        this.props.fetchNSetNode(nodeId);
        if (this.childInputRef) this.childInputRef.focus();
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error creating this connection`);
      });
  };

  /**
   * Update Connection
   */
  updateNodeConnectionSoft = (params: updateNodeConnectionParams) => {
    return updateNodeConnection(params).catch((err) => {
      this.props.toggleSnackbar(`We ran into an error updating this pattern`);
      throw err;
    });
  };

  /**
   * Delete Connection
   */
  deleteNodeConnection = (id: number) => {
    const { nodeId } = this.props;
    return deleteNodeConnection(id)
      .then((res) => {
        this.props.fetchNSetNode(nodeId);
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error deleting option ${id}`);
      });
  };
  /**
   * set the ref of child input
   */
  setInputBoxRef = (ref) => {
    this.childInputRef = ref;
  };

  /**
   * Set the initial state based on the pattern we got
   */
  getCurrentOperator = () => {
    const { nodeType, nodeConnection } = this.props;

    /**
     * Each node type has a set of possible "patterns" for their connections
     * These patterns determine the behavior of the connection
     */
    const { allowed_pattern } = nodeTypeToAllowedPattern(nodeType.type);
    
    /** State defaults when we doesn't have node connections */
    const stateDefaultData = {
      patterns: nodeConnection && nodeConnection.patterns ? nodeConnection.patterns : [],
      withRange: nodeConnection && nodeConnection.patterns && nodeConnection.patterns.length > 1 ? true : false,
      allowedPattern: allowed_pattern[0],
    };
    if (!nodeConnection) {
      if (stateDefaultData.allowedPattern.value === SUBMITTED || stateDefaultData.allowedPattern.value === SKIP) {
        stateDefaultData.patterns = [stateDefaultData.allowedPattern.value];
      }
      return stateDefaultData;
    }
    const currentPattern = nodeConnection.patterns;
    /** Deciding the operator which is associated with the pattern  */
    for (let j = 0; j < currentPattern.length; j++) {
      for (let i = 0; i < allowed_pattern.length; i++) {
        const opIndex = currentPattern[j].indexOf(allowed_pattern[i].value.toLowerCase());
        if (opIndex > -1) {
          stateDefaultData.allowedPattern = allowed_pattern[i];
          if (currentPattern.length > 1) {
            stateDefaultData.allowedPattern = allowed_pattern[allowed_pattern.length - 1];
          }
          break;
        }
      }
    }

    return stateDefaultData;
  };

  /**
   * Setting the state patterns when user changes values in input box
   */
  onChangeHandler = ({ target }) => {
    const { nodeType } = this.props;
    const { withRange, allowedPattern, patterns } = this.state;
    let currentPattern = [...patterns];
    let patternVal = target.value;

    if (nodeType.type === CueType.DATE) {
      patternVal = patternVal.length > 0 ? moment(patternVal).format("x") : "";
    }

    if (target.id === "rangeDate") {
      currentPattern[1] = patternVal;
    } else if (allowedPattern.value === "[]" && withRange) {
      currentPattern[0] = patternVal;
    } else {
      currentPattern = [patternVal];
    }

    currentPattern = this.setPatternValues(currentPattern, allowedPattern.value);

    this.setState({ patterns: currentPattern });
  };

  /**
   * Adding the corresponding operator to the pattern and
   * make pattern value ready to submit
   */
  setPatternValues = (updatedPatterns, opValue) => {
    const nUpdatedPatterns = updatedPatterns.filter((value) => {
      return value !== "";
    });

    if (nUpdatedPatterns.length === 0) {
      return nUpdatedPatterns;
    }

    updatedPatterns.forEach((value, key) => {
      if (opValue === SUBMITTED || opValue === SKIP) {
        updatedPatterns[key] = opValue;
      } else if (value.length > 0) {
        value = value.replace(/[<>=]/g, "");
        if (opValue == "[]") {
          if (key === 0) {
            updatedPatterns[key] = ">=" + value;
          } else {
            updatedPatterns[key] = "<=" + value;
          }
        } else {
          updatedPatterns[key] = opValue + value;
        }
      }
    });
    return updatedPatterns;
  };

  /**
   * Setting the operator in state when user changes the operator in selectbox
   */
  changeOperatorHandler = (opValue) => {
    const { withRange, patterns } = this.state;
    const updatedPatterns = [...patterns];
    let currentRange = false;

    if (opValue.value === "[]") {
      currentRange = true;
      if (patterns.length == 1) {
        if (opValue.type === "date") {
          updatedPatterns.push(
            moment(parseInt(patterns[0].replace(/[<>=]/g, "")))
              .add(1, "day")
              .format("x")
          );
        } else {
          updatedPatterns.push((parseInt(patterns[0].replace(/[<>=]/g, "")) + 1).toString());
        }
      }
    } else if (withRange && patterns.length > 1) {
      updatedPatterns.splice(-1, 1);
    }

    this.setPatternValues(updatedPatterns, opValue.value);
    this.setState({ allowedPattern: opValue, patterns: updatedPatterns, withRange: currentRange });
  };

  /**
   * Submitting patterns with node connection to the API for ADD/EDIT/DELETE
   */
  setConnPatternHandler = () => {
    const { isNew, nodeConnection, nodeId, nodeType, checkBothFileFollowup } = this.props;

    const { patterns } = this.state;

    if (isNew && patterns.length > 0) {
      if (nodeType.type === CueType.FILE_UPLOAD && checkBothFileFollowup(patterns[0])) {
        this.props.toggleSnackbar(`Followup for '${patterns[0].toUpperCase()}' already exist.`);
        return false;
      }
      this.createNodeConnection({ node_id: nodeId, patterns: patterns });

      if (nodeType.type !== CueType.FILE_UPLOAD) this.setState({ patterns: [] });
    } else if (!isNew && patterns.length > 0) {
      // Update an existing pattern if it has new text
      this.updateNodeConnectionSoft({ id: nodeConnection.id, patterns: patterns }).then((response) => {
        this.setState({
          patterns: response.data.patterns,
        });
      });
    } else if (!isNew && patterns.length == 0) {
      this.deleteNodeConnection(nodeConnection.id);
    }
  };

  /**
   * For FileUpload type node setting the two strict patterns only
   */
  setValueForFileType = () => {
    const opValue = this.state.allowedPattern;
    if (opValue.value === SUBMITTED || opValue.value === SKIP) {
      this.setState({ patterns: [opValue.value] });
      this.setConnPatternHandler();
    }
  };

  patternInterface = () => {
    const { patterns, allowedPattern, withRange } = this.state;
    const {
      detailBot,
      nodeId,
      isNew,
      nodeConnection,
      nodeType,
      addFollowUpToPattern,
      makeConnectionActive,
      nextReferenceNodeId,
      user,
      editing,
    } = this.props;

    return (
      <NodeConnectionPattern
        isNew={isNew}
        nextReferenceNodeId={nextReferenceNodeId}
        nodeType={nodeType}
        addFollowUpToPattern={addFollowUpToPattern}
        makeConnectionActive={makeConnectionActive}
        nodeConnection={nodeConnection}
        patterns={patterns}
        allowedPattern={allowedPattern}
        withRange={withRange}
        changeOperatorHandler={this.changeOperatorHandler}
        setConnPatternHandler={this.setConnPatternHandler}
        onChangeHandler={this.onChangeHandler}
        setInputBoxRef={this.setInputBoxRef}
        user={user}
        editing={editing}
      />
    );
  };

  render() {
    const {
      isNew,
      isActive,
      nodeConnection,
      isFollowUp,
      nextReferenceNodeId,
      isFollowUpNode,
      addFollowUpToPattern,
      user,
      selected,
      editing,
    } = this.props;
    const patternItem = this.patternInterface();
    if (isNew) {
      return <div className="addFollowUpNode">{patternItem}</div>;
    } else {
      return (
        <div className="node__body__pattern">
          {patternItem}
          <div className="node__body__pattern-actions active">
            {/*nodeConnection.referencing_node_id === nextReferenceNodeId && !isFollowUp && !isFollowUpNode &&*/ editing && (
              <span
                className={`node__body__pattern-add ${userIsAdmin(this.props.user) ? "" : "disabled"}`}
                onClick={userIsAdmin(this.props.user) && addFollowUpToPattern}
              >
                <Plus />
              </span>
            )}
            {(nodeConnection.tags.length > 0 || editing) && <NodeTags
              user={user}
              tagData={{ node_connection_id:  nodeConnection ? nodeConnection.id : null }}
              tags={nodeConnection.tags}
              selected={selected}
              editing={editing}
            />}
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect((state: RootStore) => ({ detailBot: state.detailBot }), { fetchNSetNode })(NodeConnection)
);

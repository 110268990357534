import { fetchBot } from "lib/bots";
import { fetchNode } from "lib/nodes";
import { deleteDialog as deleteDialogRequest } from "lib/dialogs";
import { toggleSnackbar } from "actions/ui";
import { Tag } from "actions/classifiers";
import { UpdateMarkdownDocumentParams } from "lib/markdownDocuments";
import { getLeaddocketFields, LeadDocketField, getLeaddocketCaseTypes, LeadDocketCaseType, getLitifyCaseTypes, LitifyCaseType } from "lib/plugins";

// Action types
export const FETCH_BOT = "FETCH_BOT";
export const SET_BOT = "SET_BOT";
export const SELECT_NODE = "SELECT_NODE";
export const SET_NODE = "SET_NODE";
export const ADD_NODE = "ADD_NODE";
export const SELECT_DIALOG = "SELECT_DIALOG";
export const ADD_DIALOG = "ADD_DIALOG";
export const SET_DIALOG = "SET_DIALOG";
export const SET_DIALOGS = "SET_DIALOGS";
export const FETCH_DIALOGS = "FETCH_DIALOGS";
export const DELETE_DIALOG = "DELETE_DIALOG";
export const FETCH_LEADDOCKET_FIELDS = "FETCH_LEADDOCKET_FIELDS";
export const SET_LEADDOCKET_FIELDS = "SET_LEADDOCKET_FIELDS";
export const FETCH_LEADDOCKET_CASE_TYPES = "FETCH_LEADDOCKET_CASE_TYPES"
export const SET_LEADDOCKET_CASE_TYPES = "SET_LEADDOCKET_CASE_TYPES"
export const FETCH_LITIFY_CASE_TYPES = "FETCH_LITIFY_CASE_TYPES"
export const SET_LITIFY_CASE_TYPES = "SET_LITIFY_CASE_TYPES"

export type Dialog = {
  nodes: [];
  id: any;
  first_node_id: number;
  bot_id: number;
  title?: string;
};

export type NodeMeta = {
  success_message?: string; //deprecated
  fallback_message?: string; //deprecated
  dialog_to_link_to?: any;
  pre_schedule_message?: string;
  post_scheduled_message?: string;
};

export type Node = {
  id: number;
  message: string;
  node_type: string;
  meta: NodeMeta;
  node_connections: NodeConnection[];
  title: string | null;
  dialog_id: number;
  validation?: string;
};

export type NodeConnection = {
  id: number;
  node_id: number;
  connection_type: string;
  referencing_node_id: number;
  patterns: Array<string>;
  tags: Array<Tag>;
  option_id?: number;
  root: boolean;
};

export type Bot = {
  id: number;
  name: string;
  dialogs: Dialog[];
  initial_dialog_id?: number;
  site?: any;
  title: string;
  active: boolean;
  display_options: any;
  markdown_documents: UpdateMarkdownDocumentParams[];
  document_generation_emails: string[];
  leaddocket_sync_enabled: boolean;
  leaddocket_case_type_id?: number;
  file_format: string;
  hubspot_sync_enabled: boolean;

};

export const getDialogs = (id: string) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_DIALOGS,
  });

  // Actually fetch bots
  fetchBot(id)
    .then((res) => {
      dispatch({
        type: SET_DIALOGS,
        dialogs: res.dialogs,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch this bot! Please try again", true)(dispatch);
    });
};

export const fetchLeadDocketFields = (caseTypeId: number) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_LEADDOCKET_FIELDS,
    loading: true,
  });
  // Actually fetch leaddocket case types
  getLeaddocketFields(caseTypeId)
    .then((fields) => {
      dispatch({
        type: SET_LEADDOCKET_FIELDS,
        leaddocketFields: fields,
        caseTypeId,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch leaddocket fields! Please try again", true);
    });
};

export const fetchLeadDocketCaseTypes = () => (dispatch) => {
  dispatch({
    type: FETCH_LEADDOCKET_CASE_TYPES,
    loading: true,
  });
  //fetch casetypes
  
  getLeaddocketCaseTypes()
  .then((case_types) => {
    dispatch({
      type: SET_LEADDOCKET_CASE_TYPES,
      leaddocketCaseTypes: case_types,
    });
  }).catch((err) => {
    toggleSnackbar("We couldn't fetch leaddocket case types! please try again", true)(dispatch);
  })
}

export const fetchLitifyCaseTypes = () => (dispatch) => {
  dispatch({
    type: FETCH_LITIFY_CASE_TYPES,
    loading: true,
  })

  getLitifyCaseTypes()
  .then((case_types) => {
    dispatch({
      type: SET_LITIFY_CASE_TYPES,
      litifyCaseTypes: case_types,
    });
  }).catch((err) => {
    toggleSnackbar("We couldn't fetch litify case types! please try again", true)(dispatch);
  })
}

export const getBot = (id: string) => (dispatch) => {
  // Tell reducer we're fetching
  dispatch({
    type: FETCH_BOT,
  });

  // Actually fetch bots
  fetchBot(id)
    .then((bot) => {
      dispatch({
        type: SET_BOT,
        bot: bot,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch this bot! Please try again", true)(dispatch);
    });
};

export const setBot = (id: string) => (dispatch) => {
  // Actually fetch bots
  fetchBot(id)
    .then((res) => {
      dispatch({
        type: SET_BOT,
        bot: res,
      });
    })
    .catch((err) => {
      toggleSnackbar("We couldn't fetch this bot! Please try again", true)(dispatch);
    });
};

export const addDialog = (dialog: Dialog) => (dispatch) => {
  dispatch({
    type: ADD_DIALOG,
    dialog,
  });
};

export const setDialog = (dialog: Dialog) => (dispatch) => {
  dispatch({
    type: SET_DIALOG,
    dialog,
  });
};

export const setNode = (node: Node) => (dispatch) => {
  dispatch({
    type: SET_NODE,
    node,
  });
};

export const selectNode = (node: Node) => (dispatch) => {
  dispatch({
    type: SELECT_NODE,
    node,
  });
};

export const selectedDialogId = (dialogId: string) => (dispatch) => {
  dispatch({
    type: SELECT_DIALOG,
    dialogId,
  });
};

export const fetchNSetNode = (id: string) => (dispatch) => {
  fetchNode(id).then((res) => {
    dispatch({
      type: SET_NODE,
      node: res,
    });
  });
};

export const deleteDialog = (dialogId: string) => (dispatch) => {
  dispatch({
    type: DELETE_DIALOG,
    dialogId: dialogId,
  });
};

export type BotAction =
  | {
      type: "FETCH_BOT" | "FETCH_DIALOGS" | "FETCH_LEADDOCKET_FIELDS" | "FETCH_LEADDOCKET_CASE_TYPES" | "FETCH_LITIFY_CASE_TYPES";
      loading: boolean;
    }
  | {
      type: "SET_BOT";
      bot: Bot;
    }
  | {
      type: "SELECT_NODE";
      node: Node;
    }
  | {
      type: "SET_NODE";
      node: Node;
    }
  | {
      type: "ADD_NODE";
      node: Node;
      dialog_id: string;
    }
  | {
      type: "SELECT_DIALOG";
      dialogId: string;
    }
  | {
      type: "ADD_DIALOG";
      dialog: Dialog;
    }
  | {
      type: "SET_DIALOG";
      dialog: Dialog;
    }
  | {
      type: "SET_DIALOGS";
      dialogs: Dialog[];
    }
  | {
      type: "DELETE_DIALOG";
      dialogId: number;
    }
  | {
      type: "SET_LEADDOCKET_FIELDS";
      leaddocketFields: LeadDocketField[];
      caseTypeId: number;
    }
  | {
    type: "SET_LEADDOCKET_CASE_TYPES";
    leaddocketCaseTypes: LeadDocketCaseType[];
  }
  | {
    type: "SET_LITIFY_CASE_TYPES";
    litifyCaseTypes: LitifyCaseType[];
  };

import * as React from "react";
import connect from "app/connect";
import { getClassifiers, deleteClassifier, Classifier } from "actions/classifiers";
import { RootStore } from "reducers";
import { User } from "reducers/user";

// Sass
import "sass/components/classifiers.scss";

// Cmps
import ClassifierItem from "app/components/leadRouting/classifierItem";
import Confirmation from "app/components/global/confirmation";

type Props = {
  getClassifiers: Function;
  deleteClassifier: Function;
  classifiers: Classifier[];
  selectedAttorney: User;
  currentUser: User;
};

type State = {
  deleteConfirm: boolean;
  classifierToDelete?: number | null;
};

/**
 * Classifiers list Component
 */
class Classifiers extends React.Component<Props, State> {
  state: State = {
    deleteConfirm: false,
  };

  componentDidMount() {
    this.props.getClassifiers();
  }

  delete = () => {
    if (this.state.classifierToDelete && this.state.deleteConfirm) {
      this.props.deleteClassifier(this.state.classifierToDelete);

      this.setState({
        deleteConfirm: false,
        classifierToDelete: null,
      });
    }
  };

  render() {
    const { classifiers, selectedAttorney, currentUser } = this.props;

    return (
      <div className="classifiers">
        <h4 className="class classifications__header">CLASSIFICATIONS</h4>

        <div className="classifier__row classifier__row--first">
          <span className="classifier__row__title">Title</span>
          <span className="classifier__row__title classifier__row__active">Active</span>
          {(currentUser.id === selectedAttorney.id || currentUser.role === 'admin' || currentUser.role === 'superadmin') && (
            <span className="classifier__row__title classifier__row__scheduled">Notify</span>
          )}
          <span className="classifier__row__title classifier__row__scheduled">E-Sign</span>
          <span className="classifier__row__title classifier__row__scheduled">Schedule</span>
          <span className="classifier__row__title classifier__row__appointment__type">Type</span>
          <span className="classifier__row__title classifier__row__tags">Tags</span>
        </div>

        <div className="classifier__table">
          {classifiers.map((classifier) => (
            <ClassifierItem
              classifier={classifier}
              key={classifier.id}
              selectedAttorney={selectedAttorney}
              setDeletion={(classifierToDelete) => {
                this.setState({ deleteConfirm: true, classifierToDelete });
              }}
            />
          ))}
        </div>

        <ClassifierItem className="classifier__row classifier__row--last" />
        <Confirmation
          title="Delete classifier"
          message="Are you sure you want to permanently delete this classifier?
                    This action cannot be undone."
          actionTitle="Delete"
          cancelTitle={null}
          setVisible={(visible) => this.setState({ deleteConfirm: visible })}
          cancel={null}
          visible={this.state.deleteConfirm}
          confirmation={this.delete}
        />
      </div>
    );
  }
}

export default connect(
  (state: RootStore) => ({
    classifiers: state.classifiers.classifiers,
    selectedAttorney: state.attorneys.selectedAttorney,
    currentUser: state.user,
  }),
  { getClassifiers, deleteClassifier }
)(Classifiers);

import * as React from "react";
import connect from "app/connect";
import { getQueryParam } from "lib/utilities/url";

type Props = {
  toggleSnackbar: Function;
};

const StripeListener: React.FC<Props> = ({ toggleSnackbar }) => {
  const checkoutSuccess = getQueryParam(location.href, "checkout_success");
  const checkoutCancelled = getQueryParam(location.href, "checkout_cancelled");

  React.useEffect(() => {
    if (checkoutSuccess) toggleSnackbar("Subscription successfully created!", true, "success");
    if (checkoutCancelled) toggleSnackbar("Subscription checkout cancelled.", true, "failure");
  }, [checkoutSuccess, checkoutCancelled]);

  return null;
};

export default connect()(StripeListener);

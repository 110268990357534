import * as React from "react";
import { Select, MenuItem, InputBase, InputLabel, FormControl, withStyles } from "@material-ui/core";
import { updateUser, getTimezones as getTimezonesRequest } from "lib/user";
import connect from "app/connect";
import { getAttorneys } from "actions/attorneys";

import "sass/components/timezoneSelector.scss";
import { RootStore } from "reducers";

type Props = {
  selectedAttorneyUser: { id: string; time_zone: string };
  disabled: boolean;
  getAttorneys: Function;
};

const updateUserTimezone = async (timeZone, id, getAttorneys, setTimezone): Promise<void> => {
  updateUser({ id, time_zone: timeZone }).then(() => {
    setTimezone(timeZone);
    getAttorneys();
  });
};

const getTimezones = async (setTimezoneOptions): Promise<void> => {
  try {
    const timezones = await getTimezonesRequest();
    const timezoneOptions = [...timezones.usaMajor, ...timezones.all].map((tZ, index) => (
      <MenuItem key={index} value={tZ.value}>
        {tZ.name}
      </MenuItem>
    ));

    setTimezoneOptions(timezoneOptions);
  } catch (err) {
    console.log("timezones err", err);
  }
};

const TimezoneSelector = ({ disabled, selectedAttorneyUser, getAttorneys }: Props): JSX.Element => {
  const { time_zone, id } = selectedAttorneyUser;
  const [timezone, setTimezone] = React.useState("");
  const [timezoneOptions, setTimezoneOptions] = React.useState([]);

  React.useEffect(() => {
    getTimezones(setTimezoneOptions);
  }, []);

  React.useEffect(() => {
    setTimezone(time_zone);
  }, [time_zone]);

  return (
    <div className="TimezoneSelector">
      <FormControl>
        <InputLabel id="TimezoneSelectorLabel">Timezone Preference</InputLabel>
        <Select
          disabled={disabled}
          value={timezone}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
            updateUserTimezone(e.target.value, id, getAttorneys, setTimezone);
          }}
        >
          {timezoneOptions}
        </Select>
      </FormControl>
    </div>
  );
};

export default connect((state: RootStore) => ({}), { getAttorneys })(TimezoneSelector);

import * as React from "react";
import { useToggle } from "react-use";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Settings,
  DashboardOutlined,
  CallSplitRounded,
  PersonOutlined,
  Schedule,
  Today,
  ExitToAppRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap, Divider } from "@material-ui/core";
import {
  LEADS,
  BOTS,
  BOT,
  SITES,
  APPOINTMENT_TYPE,
  LEAD_ROUTING,
  CALENDAR,
  DASHBOARD,
  SETTINGS,
  LOGOUT,
} from "app/router";
import connect from "app/connect";
import Message from "assets/icons/message.inline.svg";
import "sass/components/sidebar.scss";
import HornLogo from "assets/horn-logo.jpg";
import Logo from "assets/logo.png";

type SidebarItemProps = {
  text: string;
  icon: React.SVGFactory | OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  link: string[];
  expanded: boolean;
};

type Props = {
  toggleable?: boolean;
};

/**
 * Sidebar shared cross internal site
 */
const Sidebar = ({ toggleable = true }: Props) => {
  const [expanded, toggleExpanded] = useToggle(true);

  return (
    <div className={`sidebar ${expanded && "expanded"}`}>
      <div className="flex align-center logoWrapper">
        <img src={HornLogo} className="hornLogo" />
        {expanded && <img src={Logo} className="gideonLogo" />}
      </div>

      <ul style={{ height: "100%", flexDirection: "column", display: "flex" }}>
        <Divider style={{ marginTop: 0 }} />
        <SidebarItem expanded={expanded} text="Dashboard" icon={DashboardOutlined} link={[DASHBOARD]} />
        <SidebarItem expanded={expanded} text="Leads" icon={PersonOutlined} link={[LEADS]} />
        <SidebarItem expanded={expanded} text="Bots" icon={Message} link={[BOTS, BOT]} />
        {/* <SidebarItem expanded={expanded} text="Sites" icon={Message} link={[SITES]} /> */}
        <SidebarItem expanded={expanded} text="Lead Routing" icon={CallSplitRounded} link={[LEAD_ROUTING]} />
        <SidebarItem expanded={expanded} text="Appointment Types" icon={Schedule} link={[APPOINTMENT_TYPE]} />
        <SidebarItem expanded={expanded} text="Calendar" icon={Today} link={[CALENDAR]} />
        <SidebarItem expanded={expanded} text="Settings" icon={Settings} link={[SETTINGS]} />
        <Divider />
        <SidebarItem expanded={expanded} text="Logout" icon={ExitToAppRounded} link={[LOGOUT]} />
        {toggleable && (
          <li className="flex align-center collapseToggle" onClick={toggleExpanded}>
            <span className="icon">{expanded ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</span>
            {expanded && <span>Collapse</span>}
          </li>
        )}
      </ul>
    </div>
  );
};

const SidebarItem = ({ text, icon, link, expanded }: SidebarItemProps) => {
  const Icon = icon;
  const isCurrent = link.some((l) => window.location.pathname.indexOf(l) > -1);
  return (
    <li className={isCurrent ? "current" : ""}>
      <Link to={link[0]}>
        <span className="icon">
          <Icon />
        </span>
        {expanded && <span style={{ paddingTop: 7 }}>{text}</span>}
      </Link>
    </li>
  );
};

export default withRouter(connect()(Sidebar));

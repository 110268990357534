import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import Select from "react-select";
import { customSelectStyles } from "lib/utilities/ui";
import {
  updateMarkdownDocument,
  UpdateMarkdownDocumentParams,
  getPresignedUrlForMarkdownDocument,
  deleteMarkdownDocument,
  TemplateDocassembleParams,
} from "lib/markdownDocuments";
import { fetchMarkdownVariables, UpdateMarkdownVariableParams } from "lib/markdownVariables";
import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { toggleSnackbar } from "actions/ui";
import { BotsStore } from "reducers/bots";
import { RootStore } from "reducers";
import { getBots } from "actions/bots";
import { Bot } from "actions/bot";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CreateMarkdownDocument from "app/components/settings/markdown_documents/createMarkdownDocument";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import HotInput from "app/components/global/hotInput";

import "sass/components/markdownDocuments.scss";
import { useSetState } from "react-use";
import bot from "app/pages/bot";

type Props = {
  bots: BotsStore;
} & RouteComponentProps;

interface MarkdownDocumentState {
  bots: BotsStore;
  documents: UpdateMarkdownDocumentParams[];
  selectedDocument: UpdateMarkdownDocumentParams;
  selectedBot: Bot;
  markdownVariables: UpdateMarkdownVariableParams[];
  createVisible: boolean;
  loading: boolean;
}

const initialState = {
  bots: null,
  selectedBot: null,
  documents: [],
  markdownVariables: [],
  selectedDocument: null,
  createVisible: false,
  loading: false,
};

type DocumentDropzoneProps = {
  setDocumentUrl: Function;
  onS3UploadError: Function;
} & RouteComponentProps;

const DocumentDropzone = (
  props: {
    setDocumentUrl?: (doc_url: string, base_64: string) => void;
    onS3UploadError: (any) => void;
  } & RouteComponentProps
): DocumentDropzoneProps => {
  const onDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    let s3URL = undefined;
    let base64 = undefined;
    let to_split = '';
    const reader = new FileReader();
    reader.onload  = (event) => {
      to_split = event.target.result.toString();
    };
    reader.readAsDataURL(file);
    getPresignedUrlForMarkdownDocument(file.type)
      .then((res) => {
        s3URL = res.data.presigned_logo_url.split("?")[0];
        base64 = to_split.split(",")[1]
        //axios.put()
        return axios.put(res.data.presigned_logo_url, file, { headers: { "Content-Type": file.type } });
      })
      .then((res) => {
        props.setDocumentUrl(s3URL, base64);
      })
      .catch((err) => {
        console.log(err);
        props.onS3UploadError(err);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className="markdown__documents__options__document__upload__drop" {...getRootProps()}>
      <span>Drag and Drop .docx here</span>
      <input {...getInputProps()} />
    </div>
  );
};

const MarkdownDocuments: React.FC<Props> = ({ bots, getBots }: Props) => {
  const [state, setState] = React.useState<MarkdownDocumentState>(initialState);
  const [mde, setMDE] = React.useState<{ mde: EasyMDE }>({ mde: undefined });
  const [documentURL, setDocumentURL] = React.useState({ document_url: undefined, base_64: undefined });
  const [conditionalVariable, setConditionalVariable] = React.useState("");

  const loadVariables = (document: UpdateMarkdownDocumentParams): void => {
    if (!document || !document.bot_id) return;
    fetchMarkdownVariables(document.bot_id)
      .then((res) => {
        setState({
          ...state,
          selectedDocument: document,
          markdownVariables: res,
        });
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong loading variables! Please try again.");
      });
  };

  const handleSelectDocument = (option): void => {
    const selectedDoc = state.documents.find((doc) => doc.id === option.value);
    if (selectedDoc) {
      if (selectedDoc && selectedDoc.bot_id) {
        loadVariables(selectedDoc);
      } else {
        setState({
          ...state,
          selectedDocument: selectedDoc,
          markdownVariables: [],
        });
      }
    } else {
      setState({
        ...state,
        createVisible: true,
      });
    }
  };

  const handleSelectBot = (option): void => {
    const bot = bots.data.find((bot) => bot.id === option.value);
    setState({
      ...state,
      selectedBot: bot,
      documents: bot.markdown_documents,
      selectedDocument: null,
      markdownVariables: [],
    });
  };

  const documentOptions = (): { value: number; label: string }[] => {
    const baseOptions = state.documents.map((document) => ({
      value: document.id,
      label: document.title,
    }));
    baseOptions.push({ value: 0, label: "Create new document" });
    return baseOptions;
  };

  const documentCreated = (document): void => {
    setState({
      ...state,
      documents: [...state.documents, document],
      selectedDocument: document,
      createVisible: false,
    });
  };

  const findSelectedBot = (): { value: number; label: string } | undefined => {
    if (!state.selectedBot) return undefined;
    const bot = bots.data.find((bot) => bot.id === state.selectedBot.id);
    return bot ? { value: bot.id, label: bot.title } : undefined;
  };

  const updateText = (): void => {
    updateMarkdownDocument({ ...state.selectedDocument, ...documentURL})
      .then((res) => {
        const info = {
          base64: documentURL.base_64,
          bot_name: state.selectedBot.title,
          doc_name: state.selectedDocument.title
        }     

        setState({
          ...state,
          selectedDocument: res.data,
          documents: [...state.documents.filter((doc) => doc.id !== res.data.id), res.data],
        });
      })
      .catch((err) => {
        console.log(err);
        toggleSnackbar("Something went wrong saving this document!");
      });
  };

  const deleteSelectedDocument = (): void => {
    if (!state.selectedDocument.id) return;
    deleteMarkdownDocument(state.selectedDocument.id)
      .then((res) => {
        getBots();
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong deleting this document!");
      });
  };

  const handleTextChanged = (value: string): void => {
    setState({ ...state, selectedDocument: { ...state.selectedDocument, text: value } });
  };

  React.useEffect(() => {
    if (!bots.loading && bots.data.length === 0) {
      getBots();
    } else if (state.bots !== bots) {
      setState({
        ...state,
        bots: bots,
      });
    }

    if (state.selectedDocument && state.selectedDocument.document_url !== documentURL.document_url) {
      setState({
        ...state,
        selectedDocument: {
          ...state.selectedDocument,
          document_url: documentURL.document_url,
        },
      });
    }
  }, [bots, documentURL]);

  const insertVariable = (variable: UpdateMarkdownVariableParams, conditional_variable: string = null): void => {
    if (variable.conditional) {
      const selection = mde.mde.codemirror.getSelection();
      mde.mde.codemirror.replaceSelection(`{{#${conditional_variable}}}${selection}{{/${conditional_variable}}}`);
    } else {
      mde.mde.codemirror.replaceSelection(`{{${variable.label}}}`);
    }
  };

  const variableListItems = (): JSX.Element[] => {
    const items: JSX.Element[] = [];
    state.markdownVariables.forEach((mv) => {
      if (mv.conditional) {
        mv.conditional_variables.forEach((conditional_variable) => {
          items.push(
            <ListItem
              key={mv.id.toString() + conditional_variable}
              onClick={(): void => insertVariable(mv, conditional_variable)}
              button
            >
              <ListItemText primary={`${conditional_variable}`} />
            </ListItem>
          );
        });
      } else {
        items.push(
          <ListItem key={mv.id.toString()} onClick={(): void => insertVariable(mv)} button>
            <ListItemText primary={mv.label} />
          </ListItem>
        );
      }
    });
    return items;
  };

  const addConditionalVariableToSelectedDocument = (e): void => {
    if (e.keyCode !== 13) return;

    setState({
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        conditional_variables: [...state.selectedDocument.conditional_variables, conditionalVariable],
      },
    });

    setConditionalVariable("");
  };

  return (
    <div className="markdown__documents">
      <div className="markdown__documents__options">
        <div className="markdown__documents__options__select markdown__documents__options__select__bots">
          <span>Bots:</span>
          <Select
            placeholder="Select Bot"
            onChange={(option): void => handleSelectBot(option)}
            options={bots.data.map((bot) => ({
              value: bot.id,
              label: bot.title,
            }))}
            className="bot__selector"
            styles={customSelectStyles}
            value={findSelectedBot()}
          />
        </div>
        <div className="markdown__documents__options__select markdown__documents__options__select__documents">
          <span>Documents:</span>
          <Select
            placeholder="Select Document"
            onChange={(option): void => handleSelectDocument(option)}
            options={documentOptions()}
            className="document__selector"
            styles={customSelectStyles}
            isDisabled={!state.selectedBot}
            value={
              state.selectedDocument ? { value: state.selectedDocument.id, label: state.selectedDocument.title } : null
            }
          />
        </div>
        <div className="markdown__documents__options__document__upload">
          <DocumentDropzone setDocumentUrl={(documentURL, base64): void => setDocumentURL({ document_url: documentURL, base_64: base64 })} />
          {state.selectedDocument && state.selectedDocument.document_url && (
            <a href={state.selectedDocument.document_url}>Uploaded Document</a>
          )}
        </div>
      </div>
      <div className="markdown__documents__editor__container">
        <SimpleMDEReact
          className="markdown__documents__editor__container__editor"
          value={state.selectedDocument ? state.selectedDocument.text : ""}
          onChange={(value): void => handleTextChanged(value)}
          getMdeInstance={(instance): void => {
            setMDE({ mde: instance });
          }}
        />
        <div className="markdown__documents__editor__container__variables">
          <span>Available Variables</span>
          <List aria-label="Variables">{variableListItems()}</List>
          <span>Conditional Variables</span>
          {state.selectedDocument &&
            state.selectedDocument.conditional_variables &&
            state.selectedDocument.conditional_variables.map((c_v, i) => <li key={`doc-cv-${i}`}>{c_v}</li>)}
          <HotInput
            type="text"
            className="markdown__documents__editor__container__variables__create__new"
            initialValue={conditionalVariable}
            onChange={(value): void => {
              setConditionalVariable(value);
            }}
            onKeyUp={(e: any): void => addConditionalVariableToSelectedDocument(e)}
            noDelay={true}
            placeholder={"Add new conditional variable"}
          />
        </div>
      </div>
      <div className="markdown__documents__final__buttons">
        <button onClick={(): void => updateText()}>Save Document</button>
        <button onClick={(): void => deleteSelectedDocument()}>Delete Document</button>
      </div>
      <CreateMarkdownDocument
        visible={state.createVisible}
        setVisible={(createVisible): void => setState({ ...state, createVisible })}
        selectedBot={state.selectedBot}
        documentCreated={documentCreated}
      />
    </div>
  );
};

export default connect(
  (state: RootStore) => ({
    bots: state.bots,
  }),
  { getBots }
)(MarkdownDocuments);

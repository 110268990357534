import * as React from "react";
import { RouteComponentProps } from "react-router";
import connect from "app/connect";
import { LeadsStore, Order } from "reducers/leads";
import { getLeads, setSortAndOrder, setFilter, Filter, Sort, countAllLeads, getExportLeads } from "actions/leads";
import Layout from "app/components/global/layout";
import { RootStore } from "reducers";
import { leadLimit } from "lib/leads";
import Active from "assets/icons/handoff_active.inline.svg";
import LeadSearchBar from "app/components/leads/leadSearchBar";
import { useToggle } from "react-use";

// Sass
import "sass/pages/leads.scss";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField, Button } from "@material-ui/core";
import LeadItem from "app/components/leads/leadItem";
import SortingLabel from "app/components/leads/sortingHeader/sortingLabel";

type Props = {
  getLeads: Function;
  setFilter: Function;
  countAllLeads: Function;
  leads: LeadsStore;
  getExportedLeads: Function;
} & RouteComponentProps;

type State = {
  activeContext: number | null;
  filter: Filter;
  sort: Sort;
};

/**
 * Leads Container Component
 */
class Leads extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeContext: null,
      filter: {
        field: null,
        value: null,
      },
      sort: {
        field: null,
        order: null,
      }
    };
  }

  componentDidMount() {
    this.props.getLeads();
    this.props.countAllLeads();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter.field !== this.state.filter.field || prevState.filter.value !== this.state.filter.value) {
      // Update filter if it changes, this will refresh the list
      this.props.setFilter(this.state.filter);
    }
  }

  onScroll = (e: any) => {
    const endReached = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;

    // Check if scroll hits bottom
    if (endReached && this.props.leads.hasMore && !this.props.leads.loading) {
     this.fetchMore();
    }
  };

  fetchMore = () => {
    this.props.getLeads(parseInt(this.props.leads.offset) + leadLimit, false);
  };

  updateSort = (field, order) => {
    this.props.setSortAndOrder(field, order);
  };

  /**
   * Creates a function that will sort the leads by the given field
   * @param title - the title of the column in the table
   * @param field - the name of the field in the db
   * @returns a function that will take a sorting direction and sort by the set
   *  field
   */
   setSortFunc = (title: string, field: string) => {
    return ((order: Order) => {
      this.setState({
        sort: {
          field: title,
          order: order
        }
      })

      if(order == null)
        this.props.setSortAndOrder("last_active", "DESC")
      else
        this.props.setSortAndOrder(field, order)
    })
  }

  handleExport = (sort: {field, order}, filter: {field, value}) => {
    let formatedSortField;
    if(sort.field === "Name") {
      formatedSortField = "name";
    } else if(sort.field === "Date Created"){
      formatedSortField = "created_at";
    } else if(sort.field === "Classification"){
      formatedSortField = "classifier";
    } else if(sort.field === "Assignee"){
      formatedSortField = "assignee";
    } /* else if(sort.field === "Marketing Source"){
      formatedSortField = "source";
    } else if(sort.field === "Campaign"){
      formatedSortField = "campaign";
    } */ else {
      formatedSortField = null;
    }
    this.props.getExportLeads({ field: formatedSortField, order: sort.order }, filter)
  }


  render() {
    const rows = this.props.leads.leads;
    const totalLeads = this.props.leads.totalLeads;
    return (
      <Layout appClassName="platform">
        <div className="top__menu">
          <div className="lead_headers" style={{display: "inline-flex", flexDirection: "row", alignItems: "center"}}>
            <h2 className="leads__header type-24-bold">Leads Inbox</h2> 
            <text className="leads__header2 type-30">{`(${totalLeads})`}</text>
          </div>
          <LeadSearchBar updateFilter={(field, value) => this.setState({ filter: { field, value } })} />
          <Button size="large" className="button__export" onClick={() => this.handleExport(this.state.sort, this.state.filter)}>Export</Button>
          {/* <Button size="large" className="button__newLead">+ New Lead</Button> */}
        </div>
        <TableContainer className="table__container" component={Paper} style={{ height: "calc(100vh - 120px)" }} onScroll={this.onScroll}>
          {/* Todo: Fix this magic number */}
          <div className="search__results">Total search result: {rows.length}/{totalLeads}</div>
          <Table aria-label="Leads Table" size="small" className="leads__table">
            <colgroup>
              <col width="300px" />
              <col width="230px" />
              <col width="230px" />
              <col width="230px" />
              <col width="230px" />
              <col width="150px" />
              <col width="150px" />
            </colgroup>
            <TableHead>
              <TableRow className="lead__item lead__item--first">
                <TableCell className="header">
                  <SortingLabel title="Name" currentSort={this.state.sort} setSort={this.setSortFunc("Name", "name")} />
                </TableCell>
                <TableCell className="header">
                  <SortingLabel title="Date Created" currentSort={this.state.sort} setSort={this.setSortFunc("Date Created", "created_at")} reverseArrow={true}/>
                </TableCell>
                <TableCell className="header">
                  <SortingLabel title="Classification" currentSort={this.state.sort} setSort={this.setSortFunc("Classification", "classifier")} />
                </TableCell>
                <TableCell className="header">
                <SortingLabel title="Marketing Source" currentSort={this.state.sort} setSort={this.setSortFunc("Marketing Source", "source")} />
                </TableCell>
                <TableCell className="header">
                <SortingLabel title="Campaign" currentSort={this.state.sort} setSort={this.setSortFunc("Campaign", "campaign")} />
                </TableCell>
                <TableCell align="center" className="header">
                  <SortingLabel title="Scheduled" currentSort={this.state.sort} setSort={this.setSortFunc("Scheduled", "appointment")} />
                </TableCell>
                <TableCell align="center" className="header">
                  <SortingLabel title="Assignee" currentSort={this.state.sort} setSort={this.setSortFunc("Assignee", "assignee")} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <LeadItem key={row.id} lead={row} />
              ))}
            </TableBody>
          </Table>
          {this.props.leads.hasMore && (
            <div className="lead__list__loadMore" onClick={() => this.fetchMore()}>
              <div>{this.props.leads.loading ? "Loading..." : "Load more"}</div>
            </div>
          )}
        </TableContainer>
      </Layout>
    );
  }
}

type LeadColumnHeaderProps = {
  className: string;
  updateSort?: Function;
  logo?: any;
  title?: string;
  field?: string;
  leads?: LeadsStore;
};

class LeadColumnHeader extends React.Component<LeadColumnHeaderProps> {
  render() {
    const { className, title, field, updateSort, leads, logo } = this.props;
    const currentlySorted = leads && leads.sort === field;
    const sortToSetTo = leads && (currentlySorted && leads.order) === "asc" ? "desc" : "asc";

    return (
      <div
        className={`${className} ${leads && currentlySorted && `active ${leads.order}`}`}
        onClick={updateSort ? () => updateSort(field, sortToSetTo) : undefined}
      >
        {logo || title}
      </div>
    );
  }
}

export default connect((state: RootStore) => ({ leads: state.leads }), {
  getLeads,
  setSortAndOrder,
  setFilter,
  getExportLeads,
  countAllLeads
})(Leads);

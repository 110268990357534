import React from "react";
import ReactDom from "react-dom";
import { AxiosError } from "axios";
import { Button, FormControl, Select, MenuItem, InputLabel, Typography, makeStyles } from "@material-ui/core";

import { LeadDocketField, getLeaddocketMappingForNode, upsertLeaddocketMappingForNode, upsertLeaddocketCaseTypeMappingForNode, LeadDocketCaseType, getLeaddocketCaseTypes, getDialogLDCaseType } from "lib/plugins";

type Props = {
  leadDocketCaseType: LeadDocketCaseType[];
  node: any;
  dialogActionArea: React.MutableRefObject<HTMLDivElement>;
  onRequestClose: Function;
};

const useStyles = makeStyles({
  acceptableTitle: {
    margin: "20px 0 5px",
  },
  acceptableBody: {
    margin: "5px 0 20px",
  },
});


const LEADDOCKET_FIELD_VALUE_ATTRIBUTE = "FieldNameUnderscore";

const LeadDocketCaseSetting: React.FC<Props> = ({ node, onRequestClose, leadDocketCaseType = [], dialogActionArea }) => {
    const [ignored, forceUpdate] = React.useReducer((x: number) => x + 1, 0);
  
    const [deleteEnabled, setDeleteEnabled] = React.useState(false);
    const [selected, setSelected] = React.useState<string>(null);
    const [selectedId, setSelectedId] = React.useState<number>(null);
    const classes = useStyles();
    var selectedCaseType = null;
    const handleSave = () => {
      const payload = {
        node_id: node.id,
        case_type_id: selectedId,
        dialog_id: node.meta.dialog_to_link_to
      };
  
      upsertLeaddocketCaseTypeMappingForNode(payload)
        .catch((err) => alert(err.toString()))
        .finally(() => onRequestClose());
    };

    React.useEffect(() => {
      getDialogLDCaseType(node.meta.dialog_to_link_to)
        .then((dialog) => {
          if(dialog) {
            const selectedField = leadDocketCaseType.find(
              (field) => field.Id === dialog.case_type
            );
            if(selectedField) {
              setSelected(selectedField.TypeName)
              setSelectedId(selectedField.Id);
            } else {
              setSelected(null);
              setSelectedId(null);
            }
          }
          /*if (ldMapping.leaddocket_field && ldMapping.active) {
            const selectedLDField = leadDocketCaseType.find(
              (ldField) => ldField[LEADDOCKET_FIELD_VALUE_ATTRIBUTE] === ldMapping.leaddocket_field
            );
            if (selectedLDField) {
              setSelected(selectedLDField[LEADDOCKET_FIELD_VALUE_ATTRIBUTE]);
              setDeleteEnabled(true);
            } else {
              setSelected(null);
              setDeleteEnabled(false);
            }
          }*/
        })
        .catch(({ response }: AxiosError) => response.status !== 404 && console.error(response));
    }, [node.id]);
  
    const handleChange = (event) => {
      setSelectedId(leadDocketCaseType.find(k => k.TypeName == event.target.value).Id)
      setSelected(event.target.value);   
    }
  
    if (!dialogActionArea.current) { // hack: dialogActionArea.current might not be available at initial render
      setTimeout(() => {
        forceUpdate();
      }, 10);
    }
  
    return (
      <>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="ld-field-select-label">Case Type</InputLabel>
          <Select
            labelId="ld-field-select-label"
            id="ld-field-select"
            value={selected === null ? "" : selected}
            label="Case Type"
            onChange={handleChange}
          >
            {leadDocketCaseType.map((field) => (
              <MenuItem key={field.Id} value={field.TypeName}>
                {field.TypeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {dialogActionArea.current &&
          ReactDom.createPortal(
            <>
              <Button onClick={handleSave} color="primary" variant="contained" className="settingsSaveButton">
                Save
              </Button>
            </>,
            dialogActionArea.current
          )}
      </>
    );
  };
  
  export default LeadDocketCaseSetting;
  
/**
 * The label at the top of a sortable field in a table
 * 
 * Includes caret arrows displaying which direction (ascending/descending) the
 * column is being sorted in, if it is being sorted
 * 
 * Click the label to cycle through sorting directions
 */

import * as React from "react";
import SortingArrows from "app/components/leads/sortingHeader/sortingArrows";
import { SortDirection, sortDirectionToString } from "app/components/leads/sortingHeader/sortingDirection";
import { Sort } from "actions/leads";

type State = {
    direction: SortDirection;
}

type Props = {
    title: string;
    currentSort: Sort;  // the current sorting of the table
    setSort: Function;  // the function to set the sorting of the table
    activeColor?: string;   // optional fields to set the color of the label when it is selected...
    defaultColor?: string;  // ...and not selected
    reverseArrow?: boolean; // optional field to have the arrow point the "opposite" direction of the sort (useful for sorting by "newest" date)
}

class SortingLabel extends React.Component<Props, State> {

    state = {
        direction: SortDirection.OFF    // on initial load, don't sort
    }

    activeColor: string = this.props.activeColor || "#3F6DE1";
    defaultColor: string = this.props.defaultColor || "#262626";
    firstSort: SortDirection = this.props.reverseArrow ? SortDirection.DESC : SortDirection.ASC;

    /**
     * Before calling render, check if another field is being sorted
     * Turn off sorting for this field if another is in use
     */
    componentDidUpdate(prevProps, prevState) {
        if(this.props.currentSort.field != this.props.title && this.state.direction != SortDirection.OFF)
            this.setState({
                direction: SortDirection.OFF
            })
    }

    /**
     * -- DEPRECATED, REPLACED WITH toggleDirection --
     * Triggered on click
     * Sets the sorting direction stored in state to the next possible value
     * Cycles back to ascending if the current direction is the last possible value
     */
    cycleDirection = () => {
        const next_dir = (this.state.direction == SortDirection.OFF ? 
            SortDirection.ASC : this.state.direction + 1)

        const next_dir_str = sortDirectionToString(next_dir);

        this.setState({
            direction: next_dir
        })

        // call to api to retrieve sorted data
        this.props.setSort(next_dir_str);
    }

    /**
     * Triggered on click
     * Sets the sorting direction stored in state to the opposite direction
     *  ASC -> DESC, DESC -> ASC
     * If no sort direction was selected, ASC is selected
     */
    toggleDirection = () => {
        // Is this better than a nested ternary? Maybe not, but it makes me happier
        const next_dir = (() => {
            switch(this.state.direction) {
                case SortDirection.OFF:
                default:
                    return this.firstSort;
                case SortDirection.ASC:
                    return SortDirection.DESC;
                case SortDirection.DESC:
                    return SortDirection.ASC;
            }
        })();

        const next_dir_str = sortDirectionToString(next_dir);

        this.setState({
            direction: next_dir
        })

        // call to api to retrieve sorted data
        this.props.setSort(next_dir_str);
    }

    getArrowDirection = () => {
        if(this.props.reverseArrow && this.state.direction != SortDirection.OFF) {
            return (this.state.direction == SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC)
        }

        return this.state.direction;
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}
                onClick={this.toggleDirection}
                className="sorting__label">
                <span style={{color: this.state.direction != SortDirection.OFF ? this.activeColor : this.defaultColor}}>{this.props.title}</span>
                <SortingArrows direction={this.getArrowDirection()} />
            </div>
        );
    }
}

export default SortingLabel;
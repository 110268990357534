import * as React from "react";
import App from "app/components/app";
import { RouteComponentProps, withRouter } from "react-router";

// Cmps
import Nav from "app/components/navbar"

// Sass
import "sass/pages/404.scss"

type Props = {
    message: string;
} & RouteComponentProps;

/**
 * 404 Container component
 */
class NoMatch extends React.PureComponent<Props> {
    static defaultProps:Props = {
        message: 'Page not found!'
    }

    render() {
        const { location } = this.props;
        const message = location.state && location.state.message ? location.state.message : this.props.message;

        return (
            <App className="public NoMatch">
                <Nav />

                <h1>{message}</h1>
            </App>
        );
    }
}

export default withRouter(NoMatch);

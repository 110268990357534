import { HandoffAction, FETCH_ATTORNEYS, SET_ATTORNEYS } from "actions/attorneys";
import { User } from "reducers/user";
import { AttorneyAction, SET_ATTORNEY } from "actions/attorney";
import {
  AvailabilitiesAction,
  SET_AVAILABILITIES,
  FETCH_AVAILABILITIES,
  SET_CALENDAR_DATES,
  FETCH_PLUGIN_EVENTS,
  SET_PLUGIN_EVENTS,
  REMOVE_AVAILABILITY,
} from "actions/availabilities";

import moment from "moment";
import { assignLead } from "lib/leads";

export type AttorneyStore = {
  data: User[];
  loading: boolean;
  selectedAttorney: SelectedAttorney | null;
  pageStartDate: string;
  pageEndDate: string;
  seats_remaining: number;
};

export type SelectedAttorney = {
  availabilities?: any;
  plugin_events?: any;
};

const initialState = {
  data: [],
  loading: false,
  selectedAttorney: { plugin_events: [], availabilities: [] },
  pageStartDate: moment().day(0).format("YYYY-MM-DD"),
  pageEndDate: moment().day(6).format("YYYY-MM-DD"),
  seats_remaining: 0,
};

export default function (
  state: AttorneyStore = initialState,
  action: HandoffAction | AttorneyAction | AvailabilitiesAction
): AttorneyStore {
  switch (action.type) {
    case FETCH_ATTORNEYS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_ATTORNEYS: {
      return {
        ...state,
        loading: false,
        data: action.attorneys,
        seats_remaining: action.seats_remaining,
      };
    }
    case SET_ATTORNEY: {
      return {
        ...state,
        loading: false,
        selectedAttorney: {
          ...state.selectedAttorney,
          ...action.selectedAttorney,
        },
      };
    }
    case FETCH_AVAILABILITIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_AVAILABILITIES: {
      const selectedAttorney = state.selectedAttorney
        ? { ...state.selectedAttorney, availabilities: mapAvailabilitiesAsPerCmp(action.availabilities) }
        : state.selectedAttorney;

      return {
        ...state,
        loading: false,
        selectedAttorney: selectedAttorney,
      };
    }

    case FETCH_PLUGIN_EVENTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_PLUGIN_EVENTS: {
      const selectedAttorney = state.selectedAttorney
        ? { ...state.selectedAttorney, plugin_events: mapAvailabilitiesAsPerCmp(action.plugin_events) }
        : state.selectedAttorney;

      return {
        ...state,
        loading: false,
        selectedAttorney: selectedAttorney,
      };
    }

    case SET_CALENDAR_DATES: {
      return {
        ...state,
        pageStartDate: action.startDate,
        pageEndDate: action.endDate,
      };
    }
    case REMOVE_AVAILABILITY: {
      const selectedAttorney = state.selectedAttorney
        ? {
            ...state.selectedAttorney,
            availabilities: state.selectedAttorney.availabilities.filter((a) => a.id !== action.availabilityId),
          }
        : state.selectedAttorney;

      return {
        ...state,
        loading: false,
        selectedAttorney: selectedAttorney,
      };
    }
    default: {
      return state;
    }
  }
}

export const mapAvailabilitiesAsPerCmp = (availabilities) => {
  const allAvailabilities = availabilities.reduce((prev, curr) => {
    const date = curr.date;

    const mappedAvailablities = curr.availabilities.map((avail, index) => {
      const startTime = new Date(avail.start_time);
      const endTime = new Date(avail.end_time);
      return {
        availability: avail,
        name: avail.type,
        id: avail.id,
        title: avail.title || avail.type,
        start: startTime,
        end: endTime,
        _id: date + "_" + avail.id,
        is_repeat: avail.is_repeat,
        type: avail.type,
      };
    });

    return prev.concat(mappedAvailablities);
  }, []);
  return allAvailabilities;
};

import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";

type Props = {
  children?: any,
  value?: any,
  index: number,
  other?: any
}

class TabPanel extends React.Component<Props> {
  render() {
    return (
      <div
        role="tabpanel"
        hidden={this.props.value !== this.props.index}
        id={`simple-tabpanel-${this.props.index}`}
        aria-labelledby={`simple-tab-${this.props.index}`}
        {...this.props.other}
      >
        {this.props.value === this.props.index && <Box p={3}>{this.props.children}</Box>}
      </div>
    );
  }
};

export default TabPanel;

import * as React from "react";

type Props = {
    type: string;
    initialValue: any;
    placeholder?: any;
    className?: string;
    noDelay?: boolean;
    onKeyUp?: (e) => any;
    onBlur?: boolean;
    delayWindow?: number;
    onChange?: (string: string) => any;
    disabled?: boolean;
};

/**
 * Hot Input
 * Tracks live changes on input and sends new value for 
 * saving after a delay
 */
class HotInput extends React.Component<Props> {

    timeout: any;

    static defaultProps = {
        delayWindow: 2000
    }

    state = {
        value: this.props.initialValue,
        timeout: null
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({ value: this.props.initialValue })
        }
    }
    
    componentWillUnmount() {
        // Call on umount so we trigger a change
        // event in case the user exits the page the cmp. is in within 
        // the delay window
        if (this.props.onChange) {
            this.props.onChange(this.state.value)
        }
    }

    /**
     * Only sends updates to parent if the update persists for
     * longer than 2 seconds
     */
    onChange = (e) => {
        this.setState({ value: e.target.value })
    
        if (this.props.noDelay && this.props.onChange) {
            return this.props.onChange(e.target.value)
        }

        setTimeout((setValue) => {
            if (this.state.value == setValue) {
                if (this.props.onChange) this.props.onChange(setValue)
            }
        }, this.props.delayWindow, e.target.value)
    }
    
    render() {
        return (
            <input 
                className={`hotInput ${this.props.className || ''}`}
                placeholder={this.props.placeholder}
                type={this.props.type}
                value={this.state.value} 
                onBlur={this.props.onBlur ? (e) => this.onChange(e) : undefined}
                onChange={this.onChange} 
                onKeyUp={this.props.onKeyUp && this.props.onKeyUp}
                disabled={this.props.disabled}
            />
        );
    }
}

export default HotInput;
import * as React from "react";
import App from "app/components/app";
import "sass/pages/lead.scss";
import Sidebar from "../sidebar";
import DesktopOnly from "./desktopOnly";
import { Drawer, IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useToggle, useWindowSize } from "react-use";

type Props = {
  appClassName: string;
  pageTitle?: string;
  miniTitle?: string;
  mobileBreakPoint?: number;
  children: React.ReactNode;
  desktopOnly?: boolean;
  mobileTitle?: string;
};

const breakpoints = {
  sm: 900,
};

const Layout = ({
  appClassName,
  pageTitle,
  miniTitle,
  mobileBreakPoint = breakpoints.sm,
  children,
  desktopOnly,
  mobileTitle,
}: Props) => {
  const [open, toggleOpen] = useToggle(false);
  const { width } = useWindowSize();
  const isDesktop = width > mobileBreakPoint;

  return (
    <App className={appClassName}>
      {isDesktop ? (
        <Sidebar />
      ) : (
        <Drawer anchor="right" open={open} onClose={toggleOpen}>
          <Sidebar toggleable={false} />
        </Drawer>
      )}
      <div className="flex full-width" style={{ flexDirection: "column" }}>
        <div className="flex align-center">
          {isDesktop ? (
            pageTitle && <div className="lead_headers" style={{display: "inline-flex", flexDirection: "row", alignItems: "center"}}><h2 className="leads__header type-24-bold-nexa">{pageTitle}</h2> <text className="leads_header2 type-30-nexa" style={{marginLeft: '20px', fontSize: '12px'}} >{miniTitle}</text></div>          
          ) : (
            <>
              <img src={`${process.env.REACT_STATIC_CDN}/logo.svg`} alt="logo" style={{ margin: 15 }} />
              <IconButton onClick={toggleOpen} style={{ marginLeft: "auto" }}>
                <Menu />
              </IconButton>
            </>
          )}
        </div>
        {desktopOnly && !isDesktop ? <DesktopOnly mobileTitle={mobileTitle} /> : children}
      </div>
    </App>
  );
};

export default Layout;

import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import { Plugin, PluginSource } from "actions/plugins";

// Cmps
import Dialog from "app/components/global/dialog";
import TextField from "@material-ui/core/TextField";

// Sass
import "sass/components/clioGrow.scss";

type Props = {
  setVisible: Function;
  visible: boolean;
  plugin: Plugin;
  savePluginSource: Function;
} & RouteComponentProps;

const initialState = {
  inboxToken: "",
  errorInboxToken: false,
  errorInboxTokenText: "Inbox Token cannot be blank.",
};

const ClioGrowSettings: React.FC<Props> = ({ visible, setVisible, plugin, savePluginSource }: Props) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    if (plugin && plugin.token) {
      setState({
        ...state,
        inboxToken: plugin.token,
      });
    } else {
      setState({ ...initialState });
    }
  }, [plugin]);

  const saveInboxToken = (): void => {
    // validate access token
    if (state.inboxToken === "") {
      setState({
        ...state,
        errorInboxToken: true,
      });
      return;
    }

    savePluginSource({ authorization_code: state.inboxToken });
    setVisible(false);
  };

  const integrationsLink = (
    <a
      onClick={(e): void => {
        e.preventDefault();
        window.open("https://grow.clio.com/settings/integrations");
      }}
    >
      here
    </a>
  );

  return (
    <Dialog className="clio__grow__settings" setVisible={setVisible} visible={visible}>
      <h3 className="type-30-bold-nexa">{plugin && plugin.token ? "Edit Clio Grow Settings" : "Create Clio Grow"}</h3>
      <div className="clio__grow__settings__help">
        <span>To find your Inbox Token, login {integrationsLink}.</span>
        <br></br>
        <span>Under Clio Grow Inbox, copy your Inbox Token and paste below.</span>
      </div>
      <span className="clio__grow__settings__inbox__token__label">Inbox Token</span>
      <TextField
        type="text"
        placeholder="Inbox Token"
        value={state.inboxToken}
        error={state.errorInboxToken}
        helperText={state.errorInboxToken ? state.errorInboxTokenText : ""}
        onChange={(e): void => setState({ ...state, inboxToken: e.target.value, errorInboxToken: false })}
      />
      <button
        onClick={(e): void => {
          e.preventDefault();
          saveInboxToken();
        }}
      >
        Add Plugin
      </button>
    </Dialog>
  );
};

export default connect()(ClioGrowSettings);
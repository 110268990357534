import {
  PluginSource,
  Plugin,
  SET_PLUGIN_SOURCES,
  FETCH_PLUGIN_SOURCES,
  FETCH_PLUGINS,
  SET_PLUGINS,
  PluginActions,
} from "actions/plugins";

export type PluginsStore = {
  plugin_sources: PluginSource[];
  plugins: Plugin[];
  loading_plugins: boolean;
  loading_plugin_sources: boolean;
};

const initialState = {
  plugin_sources: [],
  plugins: [],
  loading_plugin_sources: false,
  loading_plugins: false,
};

export default function (state: PluginsStore = initialState, action: PluginActions): PluginsStore {
  switch (action.type) {
    case FETCH_PLUGINS: {
      return {
        ...state,
        loading_plugins: true,
      };
    }
    case FETCH_PLUGIN_SOURCES: {
      return {
        ...state,
        loading_plugin_sources: true,
      };
    }
    case SET_PLUGIN_SOURCES: {
      return {
        ...state,
        loading_plugin_sources: false,
        plugin_sources: action.plugin_sources,
      };
    }
    case SET_PLUGINS: {
      return {
        ...state,
        loading_plugins: false,
        plugins: action.plugins,
      };
    }
    default: {
      return state;
    }
  }
}

import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import connect from "app/connect";
import Container from "app/components/global/container";

import { getStripeBillingPortalSession } from "lib/stripe";

type Props = {} & RouteComponentProps;

const Account: React.FC<Props> = ({ toggleSnackbar }: Props) => {
  const [redirectUrl, setRedirectUrl] = React.useState();

  const getRedirectUrl = async (): Promise<void> => {
    try {
      const response = await getStripeBillingPortalSession();

      setRedirectUrl(response.data.url);
    } catch (err) {
      toggleSnackbar("Unable to create Stripe Billing Portal Session, please contact support.", undefined, "error");
    }
  };

  React.useEffect(() => {
    getRedirectUrl();
  }, []);

  if (redirectUrl) window.location = redirectUrl;

  return (
    <div className="billing">
      <Container name="lander">
        <h1>Redirecting to Stripe Billing Portal...</h1>
      </Container>
    </div>
  );
};

export default connect()(Account);

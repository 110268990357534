import * as React from "react";
import connect from "app/connect";
import {
    updateNodeParams
} from 'lib/nodes';
import { RootStore } from "reducers";
import { BotStore } from "reducers/detailBot";
import { createDialog } from "lib/dialogs";
import { Bot, Node, Dialog, getBot, selectedDialogId, addDialog } from "actions/bot";
import BotDialog from 'app/components/botDetail/botDialog';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { userIsAdmin } from "lib/utilities/global";

//scss
import "sass/components/dialogLink.scss";

type Props = {
    bot: Bot;
    node: Node;
    dialog?: Dialog;
    detailBot: BotStore;
    getBot: Function;
    selectedDialogId: Function;
    updateNode: (params: updateNodeParams) => {};
    addDialog: Function;
} & RouteComponentProps;

type State = {
    dialogs: Dialog[],
    inputDialog: string,
    prevInputDialog?: string | null,
    title?: string
}

/**
 * Dialog Link type node that appears to link a dialog to node
 */
class DialogLink extends React.Component<Props, State> {

    state: State = {
        dialogs: [],
        inputDialog: this.props.dialog && this.props.dialog.title ? this.props.dialog.title : '',
        prevInputDialog: null
    }
    
    /**
     * Clearing the list of dialogs
     */
    clearSuggestions = () => {
        this.setState({
            inputDialog: '',
            prevInputDialog: null,
            dialogs: []
        })
    }

    /**
     * Updating a node with dialog id to link with.
     */
    linkDialog = (dialog: Dialog) => {
        const { node, updateNode } = this.props;
        this.setState({
            inputDialog: dialog.title || '',
            dialogs: []
        })
        
        updateNode({ id: node.id, meta: { dialog_to_link_to: dialog.id } })
    }

    /**
     * input change handler
     */
    handleChange = (key: any, e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            [key]: e.target.value
        } as any);
    }

    /**
     * Providing the suggestion of unlinked dialogs as
     * user types
     */
    onKeyUp = (e) => {
        const { dialog, bot } = this.props;
        const { dialogs } = this.props.detailBot.bot;
        const inputDialogTitle = e.target.value;

        if (e.keyCode === 13 && inputDialogTitle.length > 0) {
            const matchingDialog: any = dialogs.find((t: Dialog) => t.title === inputDialogTitle);
            if (matchingDialog) {
                this.linkDialog(matchingDialog)
            } else {
                if (dialog && dialog.title === inputDialogTitle) {
                    return;
                }
                createDialog({ title: inputDialogTitle, bot_id: bot.id })
                .then(res => {
                    this.props.addDialog(res.data);
                    this.linkDialog(res.data);
                })
                .catch(err => {
                    const errData = err.response.data;
                    let errorMsg = 'Sorry, but we ran into an issue creating this dialog!';
                    if(errData && errData.code && errData.error){
                        errorMsg = errData.error;
                    }
                    this.props.toggleSnackbar(errorMsg);
                });
            }
        } else if (this.state.prevInputDialog !== inputDialogTitle) {
            const res = dialogs.filter((t: Dialog) => {
                return t.title ? t.title.indexOf(inputDialogTitle) > -1 : false;
            })

            this.setState({
                dialogs: res
            })
        } else if (inputDialogTitle === '') {
            this.setState({ dialogs: [] })
        }

        this.setState({
            inputDialog: inputDialogTitle,
            prevInputDialog: this.state.inputDialog
        })
    }

    render() {
        const { dialog, bot, detailBot, selectedDialogId } = this.props;
        const { dialogs } = this.state;
        
        return (
            <div className={`dialogLink`}>
                <div className="dialogLink__details">
                    Link to dialog:
                    <input
                        type="text"
                        value={this.state.inputDialog}
                        onChange={(e) => this.handleChange('inputDialog', e)}
                        onKeyUp={this.onKeyUp}
                        placeholder="type to add Dialog"
                        disabled={!userIsAdmin(this.props.user)}
                    />

                    <ul className={`dialogs__suggestions ${dialogs.length > 0 && 'visible'}`}>
                        <span>Suggestions:</span>
                        {dialogs.map((dialog: Dialog, i) => (
                            <li
                                key={i}
                                onClick={() => this.linkDialog(dialog)}
                            >
                                {dialog.title}
                            </li>
                        ))}
                    </ul>

                    {dialog && (
                        <div
                            className="dialogLink__botDialog"
                            onClick={() => selectedDialogId(dialog.id)}>
                            <Link to={`${dialog.id}`}>
                                <span className="dialogLink__title">
                                    {dialog.title}
                                </span>
                                
                                <BotDialog
                                    bot={bot}
                                    dialog={dialog}
                                    limit={1}
                                    refresh={() => this.props.getBot(bot.id)}
                                    dialogsById={detailBot.dialogsById}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
export default withRouter(connect(
    (state: RootStore) => ({ detailBot: state.detailBot }),
    { getBot, selectedDialogId, addDialog }
)(DialogLink));
import React from "react";
import { PhonelinkErase } from "@material-ui/icons";
import { Paper } from "@material-ui/core";

interface Props {
  mobileTitle: string;
}

const DesktopOnly = ({ mobileTitle }: Props) => {
  return (
    <div className="container" style={{ padding: 20, height: "100%" }}>
      <Paper
        className="flex align-center"
        style={{ flexDirection: "column", padding: 40, justifyContent: "space-around" }}
      >
        <PhonelinkErase style={{ fontSize: 75 }} />
        <h2 style={{ fontSize: 24, marginTop: 40, textAlign: "center" }}>
          The {mobileTitle} feature is not currently supported on this screen size.
        </h2>
      </Paper>
    </div>
  );
};

export default DesktopOnly;

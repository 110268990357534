import {
  AppointmentType,
  FETCH_APPOINTMENT_TYPES,
  SET_APPOINTMENT_TYPES,
  AppointmentTypesAction
} from "actions/appointmentTypes";

export type AppointmentTypesStore = {
  data: AppointmentType[],
  page?: number;
  loading: Boolean
};

const initialState = {
  data: [],
  number: null,
  loading: false
}

export default function(
  state: AppointmentTypesStore = initialState,
  action: AppointmentTypesAction,

): AppointmentTypesStore {
  switch (action.type) {
    case FETCH_APPOINTMENT_TYPES: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_APPOINTMENT_TYPES: {
      return {
        ...state,
        loading: false,
        data: action.appointmentTypes,
        page: action.page
      };
    }
    default: {
      return state;
    }
  }
}
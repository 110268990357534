/**
 * The caret arrows displayed with SortingLabel that denote which direction
 * the field is being sorted in
 */

import * as React from "react";
import UpArrow from "assets/icons/ui/up.inline.svg";
import DownArrow from "assets/icons/ui/down.inline.svg";
import { SortDirection } from "app/components/leads/sortingHeader/sortingDirection";

type Props = {
    direction: SortDirection;   // gets sorting direction from SortingLabel
    activeColor?: string;   // optional fields to set the color of the label when it is selected...
    defaultColor?: string;  // ...and not selected
}

class SortingArrows extends React.Component<Props, {}> {
    activeColor: string = this.props.activeColor || "#3F6DE1";
    defaultColor: string = this.props.defaultColor || "#979797";

    render() {
        return (
            <div style={{display: "flex", marginLeft: "14px", alignItems: "center"}}>
                {this.props.direction == SortDirection.ASC && 
                    <UpArrow fill={this.activeColor} width="17px" height="12px"/>
                }
                
                {this.props.direction != SortDirection.ASC &&
                    <DownArrow fill={this.props.direction == SortDirection.DESC ? this.activeColor : this.defaultColor} width="17px" height="12px"/>
                }
            </div>
        );
    }
}

export default SortingArrows;
import api from "lib/api";

export type createClassifierParams = {
    tag: number[];
    title?: string;
}

export type classifierUserParams = {
    classifier_id: number;
    enabled: boolean;
    user_id: number;
}

export type updateClassifierParams = {
    id: number;
} & createClassifierParams

export const fetchClassifiers = () => {
    return api.get('/classifiers?order_by=asc', null)
}

export const factoryCreateClassifier = (params: createClassifierParams) => {
    return api.post(`/classifiers`, params)
}

export const classifierUser = (params: classifierUserParams) => {
    return api.post(`/classifiers/user`, params)
}

export const factoryUpdateClassifier = (params: updateClassifierParams) => {
    return api.put(`/classifiers/${params.id}`, params)
}

export const factoryDeleteClassifier = (classifierId: number) => {
    return api.deleteReq(`/classifiers/${classifierId}`, null)
}

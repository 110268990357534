/**
 * Styles for the drop-down
 */
export const customSelectStyles = {
  control: (styles, state) => ({
      ...styles,
      backgroundColor: '#f6f7f8',
      borderColor: state.isFocused ? '#cedcdb' : '#cedcdb'
  }),
  option: (styles, state) => ({
      ...styles,
      color: '#9b9b9b',
      backgroundColor: state.isSelected ? '#cccccc' : state.isFocused ? '#cedcdb' : '#f6f7f8',
  }),
  menu: styles => ({ ...styles, backgroundColor: '#f6f7f8' }),
}

export const customSelectIntegrationStyles = {
  control: (styles, state) => ({
      ...styles,
      backgroundColor: '#f6f7f8',
      borderColor: state.isFocused ? '#cedcdb' : '#cedcdb',
      minHeight: '50px',
      height: '50px'
  }),
  option: (styles, state) => ({
      ...styles,
      color: '#9b9b9b',
      backgroundColor: state.isSelected ? '#cccccc' : state.isFocused ? '#cedcdb' : '#f6f7f8',
  }),
  menu: styles => ({ ...styles, backgroundColor: '#f6f7f8' }),
}
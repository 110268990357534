import React from "react";
import { LOGOUT } from 'app/router';
import connect from "app/connect";
// import LoadingCmp from "app/components/global/loading";

/**
 * Wrapper route cmp. that manages a loading state
 * until we load our user from the cache
 */
class Protected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: this.shouldBeLoading(props)
        };
    }

    componentWillReceiveProps(nextProps) {
        const { data = {}, history } = nextProps;
        const { loading } = this.state;

        if (this.shouldBeLoading(nextProps)) {
            // Check if Apollo has loaded our user
            if (!data.loading && data.loggedInUser) {
                // Cache into redux store
                this.props.cacheUser(data.loggedInUser);
                this.setState({ loading: false });
            } else if(data.loggedInUser == null && !data.loading) {
                //Still not loading, but no user is returned. Best we can do is log the user out
                history.push(LOGOUT);
            }
        } else {
            if (loading) this.setState({ loading: false });
        }
    }

    /**
     * Tells us if the state should be loading or not
     * @returns {Boolean} true/false
     */
    shouldBeLoading = (props) => {
        const { user = {} } = props;
        // If we don't have a user's email yet from the API,
        // we assume the user is still being fetched
        if (!user.loggedIn || user.shouldRefresh) {
            return true
        } else {
           return false;
        }
    };

    render() {
        const { loading } = this.state;
        const { Component, ...rest } = this.props;
        return loading ? <div>Loading</div> : <Component {...rest} />;
    }
}

export default connect()(Protected);

import {
  fetchConversationStats,
} from "lib/dashboard";

import { toggleSnackbar } from "actions/ui";
import { LineComputedSerieData, LineComputedSerieDatum } from "@nivo/line";

// Action types
export const FETCH_CONVERSATION_STATS = 'FETCH_CONVERSATION_STATS';
export const SET_CONVERSATION_STATS = 'SET_CONVERSATION_STATS';

export type ConversationStatParams = {
  start: string;
  end: string;
  period: string;
}

export const getConversationStats = (params: ConversationStatParams) => (dispatch) => {
  dispatch({
    type: FETCH_CONVERSATION_STATS,
  });

  fetchConversationStats(params)
    .then((res) => {
      const stats = {
        Conversations: 0,
        Classified: 0,
        Scheduled: 0,
        Documents: 0,
        eSign_Documents: 0,
        Signed_Documents: 0,
        yMax: 0,
      };

      dispatch({
        type: SET_CONVERSATION_STATS,
        conversations: res.conversations.map(e => { stats.Conversations += e.y; if(e.y > stats.yMax) { stats.yMax = e.y }; return { x: new Date(e.x), y: e.y } }),
        classified: res.classified.map(e => { stats.Classified += e.y; return { x: new Date(e.x), y: e.y } }),
        scheduled: res.scheduled.map(e =>  { stats.Scheduled += e.y; return { x:  new Date(e.x), y: e.y }; }),
        documents: res.documents.map(e => { stats.Documents += e.y ; return { x: new Date(e.x), y: e.y }; }),
        esign_documents: res.esign_docs,
        signed_documents: res.signed_docs,
        stats,
      });
    })
    .catch(() => {
      toggleSnackbar("Unable to fetch Conversation Analytics.", true, "error")(dispatch);
    });
};

export type DashboardAction =
  | {
    type: "FETCH_CONVERSATION_STATS",
  }
  | {
    type: "SET_CONVERSATION_STATS",
    conversations: LineComputedSerieDatum[],
    classified: LineComputedSerieDatum[],
    scheduled: LineComputedSerieDatum[],
    documents: LineComputedSerieDatum[],
    esign_documents: number,
    signed_documents: number,
    stats: any,
  }

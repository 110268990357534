import * as React from "react";
import { resetPassword } from "lib/user";
import { Link } from "react-router-dom";
import connect from "app/connect";
import {withRouter, RouteComponentProps } from "react-router";
import { INDEX } from 'app/router';

// Cmps.
import FormControl from "app/components/global/formControl";

type Props = {
    email: string;
    resetToken: string;
} & RouteComponentProps;

type State = {
    loading: Boolean;
    newPassword: string;
    newPasswordConfirmation: string;
    error?: string;
}

class ResetPasswordForm extends React.Component<Props, State> {
    state = {
        loading: false,
        newPassword: '',
        newPasswordConfirmation: '',
    };

    resetPassword = e => {
        e.preventDefault()

        const { newPassword, newPasswordConfirmation } = this.state;

        if (newPassword !== newPasswordConfirmation) {
            this.props.toggleSnackbar("password and password confirmation must match!");
            return;
        }

        resetPassword(this.props.email, this.props.resetToken, this.state.newPassword)
        .then(response => {
            this.props.toggleSnackbar("Password successfully reset.")
            this.props.history.push(INDEX);
        })
        .catch(err => {
            console.log(err);
            this.props.toggleSnackbar("Invalid password reset token.", true, 'error');
        })
    }

    render() {
        return (
            <div className="loginForm window-form">
                <h2>Set a new Password</h2>

                <form onSubmit={this.resetPassword}>
                    <FormControl 
                        type="password" 
                        value={this.state.newPassword} 
                        title
                        placeholder="New Password"
                        onChange={ (e) => this.setState({ newPassword: e.target.value }) } 
                    />
                    <FormControl 
                        type="password" 
                        value={this.state.newPasswordConfirmation} 
                        title
                        placeholder="Password Confirmation"
                        onChange={ (e) => this.setState({ newPasswordConfirmation: e.target.value }) } 
                    />
                    <button onClick={(e) => this.resetPassword(e)}>Update Password</button>
                </form>

                <span>
                    <Link to='/signup' className="light-blue create-account">Create account</Link>

                    &nbsp;or&nbsp;

                    <Link to='/' className="light-blue align-right">Sign in</Link>
                </span>
            </div>
        )
    }
}

export default withRouter(connect()(ResetPasswordForm));
import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";
import { getQueryParam } from "lib/utilities/url";
 
// Cmps
import Nav from "app/components/navbar"
import Container from "app/components/global/container";
import ResetPasswordForm from "app/components/lander/resetPasswordForm";

/**
 * Reset Password Page Component
 */
class ResetPassword extends React.PureComponent {
    render() {
        const email = getQueryParam(window.location.href, 'email');
        const token = getQueryParam(window.location.href, 'token');
        //console.log('ResetPassword', this.props);

        return (
            <App className="public">
                <Nav />
                <Container name="lander">
                    <ResetPasswordForm
                        email={email}
                        resetToken={token}
                    />
                </Container>
            </App>
        );
    }
}

export default connect()(ResetPassword);
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { userIsAdmin } from "lib/utilities/global";
import { CompactPicker } from "react-color";
import FormControl from "app/components/global/formControl";
import ImageManager from "./image_manager";
import {
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useToggle } from "react-use";

type OptionsFormProps = {
  display_title: string;
  color: string;
  orgId: number;
  logo_url: string;
  widget_url: string;
  fullscreen_url: string;
  display_name: string;
  widget_same_as_logo: boolean;
  onSubmit: Function;
  toggleSnackbar: Function;
  isNew: boolean;
  isEditing: boolean;
  presetId: number;
  handleDeletePreset: (presetId: number) => null;
} & RouteComponentProps;

const Form = ({
  logo_url,
  display_title,
  color,
  orgId,
  onSubmit,
  toggleSnackbar,
  display_name,
  widget_url,
  widget_same_as_logo,
  fullscreen_url,
  user,
  isNew,
  presetTitle,
  presetId,
  handleDeletePreset,
}: OptionsFormProps) => {
  const [formBotTitle, updateBotTitle] = React.useState(display_title || "");
  const [formBotColor, updateBotColor] = React.useState(color || "");
  const [formBotLogoURL, updateBotLogoURL] = React.useState(logo_url || "");
  const [formBotWidgetURL, updateBotWidgetURL] = React.useState(widget_url || "");
  const [formBotFullscreenImageURL, updateBotFullscreenImageURL] = React.useState(fullscreen_url || "");
  const [formBotName, updateBotName] = React.useState(display_name || "");
  const [sameAsLogo, setSameAsLogo] = React.useState(widget_same_as_logo || false);
  const [formPresetTitle, updatePresetTitle] = React.useState(presetTitle || "");
  const [isOpen, toggleOpen] = useToggle(false);

  React.useEffect(() => {
    updateBotTitle(display_title);
  }, [display_title]);

  React.useEffect(() => {
    updateBotColor(color);
  }, [color]);

  React.useEffect(() => {
    updateBotLogoURL(logo_url);
  }, [logo_url]);

  React.useEffect(() => {
    updateBotWidgetURL(widget_url);
  }, [widget_url]);

  React.useEffect(() => {
    updateBotFullscreenImageURL(fullscreen_url);
  }, [fullscreen_url]);

  React.useEffect(() => {
    updateBotName(display_name);
  }, [display_name]);

  React.useEffect(() => {
    setSameAsLogo(widget_same_as_logo || false);
  }, [widget_same_as_logo]);

  React.useEffect(() => {
    updatePresetTitle(presetTitle);
  }, [presetTitle]);

  const clearFields = (): void => {
    updateBotTitle("");
    updateBotColor("");
    updateBotLogoURL("");
    updateBotWidgetURL("");
    updateBotFullscreenImageURL("");
    updateBotName("");
    setSameAsLogo(false);
    updatePresetTitle("");
  };

  const toggleSameAsLogo = (): void => setSameAsLogo((prevState) => !prevState);

  const onDelete = () => {
    toggleOpen();
    handleDeletePreset(presetId);
  };

  return (
    <>
      <div className="customizationsForm">
        {(isNew || presetId) && (
          <div className="title">
            <FormControl
              title
              placeholder="Preset Title"
              type="text"
              secondPlaceholder="Custom Preset Title"
              value={formPresetTitle}
              onChange={(e): void => updatePresetTitle(e.target.value)}
              disabled={!userIsAdmin(user)}
            />
          </div>
        )}

        <span>Header Logo</span>
        <ImageManager
          orgId={orgId}
          imageUrl={formBotLogoURL}
          setLogoURL={(s3Url): void => updateBotLogoURL(s3Url)}
          onS3UploadError={(): void => toggleSnackbar("Error with logo image, please try again.", undefined, "error")}
          user={user}
          height={400}
          width={400}
        />

        <span style={{ marginTop: 10, marginBottom: 0 }}>Widget Image</span>
        <FormControlLabel
          className="same_image_checkbox"
          control={
            <Checkbox
              checked={sameAsLogo}
              onChange={toggleSameAsLogo}
              name="same_image_checkbox"
              color="primary"
              disabled={!userIsAdmin(user)}
            />
          }
          label="Same as Header Logo"
        />
        {!sameAsLogo ? (
          <ImageManager
            orgId={orgId}
            imageUrl={formBotWidgetURL}
            onS3UploadError={(): void => toggleSnackbar("Error with logo image, please try again.", undefined, "error")}
            setLogoURL={(s3Url): void => updateBotWidgetURL(s3Url)}
            user={user}
            height={400}
            width={400}
          />
        ) : (
          <div
            className="flex"
            style={{ flexDirection: "column", alignItems: "center", marginRight: "auto", marginLeft: 4 }}
          >
            <div className="image_preview_label">Preview</div>
            {formBotLogoURL ? (
              <img className="image_preview" src={formBotLogoURL} alt="cropped image" />
            ) : (
              <div className="image_preview_alternate">Add a Header Logo</div>
            )}
          </div>
        )}

      <span style={{ marginTop: 10 }}>Fullscreen Chatbot Image</span>
        <ImageManager
          orgId={orgId}
          imageUrl={formBotFullscreenImageURL}
          setLogoURL={(s3Url): void => updateBotFullscreenImageURL(s3Url)}
          onS3UploadError={(): void => toggleSnackbar("Error with logo image, please try again.", undefined, "error")}
          user={user}
          height={800}
          width={400}
          fullscreenChatbotImage
        />

        <FormControl
          title
          placeholder="Chatbot Header Text"
          type="text"
          secondPlaceholder="Gideon Bot"
          value={formBotTitle}
          onChange={(e): void => updateBotTitle(e.target.value)}
          disabled={!userIsAdmin(user)}
        />

        <FormControl
          title
          placeholder="Chatbot Avatar Name"
          type="text"
          secondPlaceholder="Bot"
          value={formBotName}
          onChange={(e): void => updateBotName(e.target.value)}
          disabled={!userIsAdmin(user)}
        />

        <div className="colorPicker">
          <span>Chatbot Theme Color</span>
          <div className="colorPicker__Wrapper">
            {userIsAdmin(user) ? (
              <CompactPicker color={formBotColor} onChangeComplete={(color): void => updateBotColor(color.hex)} />
            ) : (
              <div className="colorPicker__nonadmin__display" style={{ backgroundColor: formBotColor }} />
            )}
          </div>
        </div>

        <div className="flex" style={{ width: "100%", marginTop: 10 }}>
          <button
            className="save__customizations"
            disabled={!userIsAdmin(user)}
            onClick={(): void => {
              const params = {
                botColor: formBotColor,
                botTitle: formBotTitle,
                botLogoURL: formBotLogoURL,
                botWidgetURL: formBotWidgetURL,
                botWidgetSameAsLogo: sameAsLogo,
                botFullscreenImageURL: formBotFullscreenImageURL,
                botName: formBotName,
                presetTitle: formPresetTitle,
              };

              presetId ? onSubmit(params, presetId) : onSubmit(params);
              if (isNew) {
                clearFields();
              }
            }}
          >
            {isNew ? "Create" : "Save"}
          </button>

          {presetId && (
            <button className="delete_preset" onClick={toggleOpen} disabled={!userIsAdmin(user)}>
              Delete
            </button>
          )}
        </div>
      </div>
      <Dialog open={isOpen}>
        <DialogTitle>Delete Preset? This cannot be undone.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting this preset will remove it from all bots that it is currently assigned to.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Cancel</Button>
          <Button onClick={onDelete} style={{ color: "red" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Form;

import * as React from "react";
import connect from "app/connect";
import { withRouter, RouteComponentProps } from "react-router";
import { ContextMenuTrigger, ContextMenu, connectMenu, MenuItem } from "react-contextmenu";
import { deleteBot, editBot, exportBot, exportBotYml, deleteBotYml } from "lib/bots";
import { BOT } from "app/router";
import * as FileSaver from "file-saver";
import { userIsAdmin } from "lib/utilities/global";

// Cmps
import Confirmation from "app/components/global/confirmation";

// Sass
import "sass/components/botListItem.scss";
import { Bot } from "actions/bot";
import { IconButton } from "@material-ui/core";
import { Settings } from "@material-ui/icons";

type State = {
  deleteConfirm: Boolean;
};

type Props = {
  bot: Bot;
  redundantKey: Number;
  edit: Function;
  editDocumentGenerationEmails: Function;
  contextMenu: Boolean;
  active: Boolean;
} & RouteComponentProps;

const BOT_ITEM_MENU = "BOT_ITEM_MENU";

function collect(props) {
  return props;
}

const gideonNumberRed = "#ff7d8d";
const gideonOpenResponseBlue = "#86d4fc";
const unassignedGrey = "#d8d8d8";

/**
 * Bot List Item Component
 * Manages the tile and context menu. See parent cmp. for insertion of context menu
 */
class BotListItem extends React.Component<Props, State> {
  state = {
    deleteConfirm: false,
  };
  contextTrigger = null;

  onClick = () => {
    this.props.history.push(`${BOT}/${this.props.bot.id}`);
  };

  /**
   * Toggles the `active` property of the bot
   */
  activateToggle = () => {
    const { bot } = this.props;

    editBot({ active: !bot.active, id: bot.id })
      .then((res) => {
        this.props.refresh();
      })
      .catch((err) => {
        this.props.toggleSnackbar(`We ran into an error ${bot.active ? "de-activating" : "activating"} your bot!`);
      });
  };

  toggleMenu = (e) => {
    if (this.contextTrigger) {
      this.contextTrigger.handleContextClick(e);
    }
  };

  /**
   * Sends delete request and refreshes accordingly
   */
  delete = (e) => {
    e.stopPropagation();

    deleteBot(this.props.bot.id)
      .then((res) => {
        this.setState({
          deleteConfirm: false,
        });

        this.props.refresh();
      })
      .catch((err) => {
        this.props.toggleSnackbar("We ran into an error deleting this bot!");
      });
  };

  export = (e) => {
    e.stopPropagation();

    exportBot(this.props.bot.id)
      .then((res) => {
        const blob = new Blob([JSON.stringify(res)], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, `${this.props.bot.title.split(" ").join("_").toLowerCase()}_export.json`);
      })
      .catch((err) => {
        this.props.toggleSnackbar("We ran into an error exporting this bot!");
      });
  };


  export_yml = (e) => {
    e.stopPropagation();

    exportBotYml(this.props.bot.id).then((res) => {
      const blob = new Blob([JSON.stringify(res)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, `${this.props.bot.title.split(" ").join("_").toLowerCase()}_export.yml`)
    }).catch((err) => {
      this.props.toggleSnackbar("We ran into an error exporting this bot's yml!")
    })
  }

  delete_yml = (e) => {
    e.stopPropagation();

    deleteBotYml(this.props.bot.id).then((res) => {
      this.props.refresh();
    }).catch((err) => {
      this.props.toggleSnackbar("We ran into an error removing this bot's yml!")
    })
  }

  render() {
    const { bot } = this.props;

    return (
      <>
        <div className="botList__item">
          <ContextMenuTrigger
            id={BOT_ITEM_MENU}
            ref={(c) => (this.contextTrigger = c)}
            holdToDisplay={1000}
            collect={(props) => {
              return {
                ...props,
                bot,
                edit: () => this.props.edit(bot),
                export: (e) => this.export(e),
                activate: this.activateToggle,
                delete: () => this.setState({ deleteConfirm: true }),
                export_yml: (e) => this.export_yml(e),
                delete_yml: (e) => this.delete_yml(e),
                editDocumentGenerationEmails: (): void => this.props.editDocumentGenerationEmails(bot),
              };
            }}
          >
            <div onClick={() => this.onClick()}>
              <div
                className="botList__item__preview"
                style={{
                  backgroundColor: !bot.site ? unassignedGrey : bot.active ? gideonOpenResponseBlue : gideonNumberRed,
                }}
              >
                <span className="type-16-regular">{!bot.site ? "Unassigned" : bot.active ? "Active" : "Inactive"}</span>
              </div>

              <div className="botList__item__bottomBar">
                <span className="botList__item__bottomBar__title type-16-regular">{bot.title}</span>

                <span className="botList__item__bottomBar__site">
                  {!bot.active ? "Inactive" : bot.site ? bot.site.domain : "No site"}
                </span>
              </div>
            </div>
            <IconButton style={{ position: "absolute", top: 0, right: 0 }} onClick={(e) => this.toggleMenu(e)}>
              <Settings />
            </IconButton>
          </ContextMenuTrigger>
        </div>
        <Confirmation
          title="Delete bot"
          message="Are you sure you want to permanently delete this bot?
                    This action cannot be undone."
          actionTitle="Delete"
          cancelTitle={null}
          setVisible={(visible) => this.setState({ deleteConfirm: visible })}
          cancel={null}
          visible={this.state.deleteConfirm}
          confirmation={this.delete}
        />
      </>
    );
  }
}

/**
 * Context menu for the bot list item
 */
const BotListItemContextMenu: React.FC<any> = ({ id, trigger, user }) => {
  const bot = trigger ? trigger.bot : null;

  const hasMarkdownDocuments = bot && bot.markdown_documents && bot.markdown_documents.length > 0;

  return (
    <ContextMenu id={id} className={`botList__item__contextMenu ${trigger && "visible"}`}>
      {userIsAdmin(user) && trigger && bot ? (
        <ul className="type-16-regular">
          <MenuItem onClick={trigger.edit}>Edit</MenuItem>
          <MenuItem onClick={trigger.activate}>{bot.active ? "Deactivate" : "Activate"}</MenuItem>
          {hasMarkdownDocuments && <MenuItem onClick={trigger.editDocumentGenerationEmails}>Document Routing</MenuItem>}
          <MenuItem onClick={trigger.export}>Export</MenuItem>

          {<MenuItem onClick={trigger.export_yml}>Export Yml</MenuItem>}

          {<MenuItem onClick={trigger.delete_yml}>Delete Yml</MenuItem>}

          <MenuItem onClick={trigger.delete}>Delete</MenuItem>
          {/* TODO: Permissions needed here */}
        </ul>
      ) : (
        <div />
      )}
    </ContextMenu>
  );
};

export const ConnectedMenu = connectMenu<{ user: any }>(BOT_ITEM_MENU)(BotListItemContextMenu);

export default withRouter(connect()(BotListItem));

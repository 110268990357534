import { User } from "reducers/user";

// Action types
export const FETCH_ATTORNEY = 'FETCH_ATTORNEY';
export const SET_ATTORNEY = 'SET_ATTORNEY';
export const FETCH_ME = 'FETCH_ME';
export const SET_ME = 'SET_ME';


export const setAttorney = (selectedUser:User) => dispatch => {
    dispatch({
        type: SET_ATTORNEY,
        selectedAttorney: selectedUser
    });
};

export type AttorneyAction =
    | {
          type: "SET_ATTORNEY";
          selectedAttorney: User
      };

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce, useWindowSize, useToggle } from "react-use";
import connect from "app/connect";
import { getLead, Conversation, getConversations, getLeadConversationDocuments, getConversationTranscripts, archiveLead, getLeadAppointments } from "actions/leads";
import { fetchPlugins } from "actions/plugins";
import { PluginsStore } from "reducers/plugins";
import { RootStore } from "reducers";
import { LeadsStore, detailLeadConversations, leadDocassembleLinks, chatTranscripts} from "reducers/leads";
import { LEADS } from "app/router";
import { Button } from "@material-ui/core";


// Cmps
import Layout from "app/components/global/layout";
import LeadProfile from "app/components/lead/leadProfile";
import LeadConversation from "app/components/lead/leadConversation";
import LeadConversations from "app/components/lead/leadConversations";
import NoMatch from "app/pages/404";

// Sass
import "sass/pages/lead.scss";
import { fetchLeadsConversationDocuments, fetchLeadAppointments } from "lib/leads";
import { CodeSharp } from "@material-ui/icons";

type Props = {
  getLead: Function;
  getInitialConversation: Function;
  getConversationTranscripts: Function;
  archiveLead: Function,
  detailLeadConversations: detailLeadConversations;
  leadDocassembleLinks: leadDocassembleLinks;
  leads: LeadsStore;
  plugins: PluginsStore;
  chatTranscripts: chatTranscripts;
} & RouteComponentProps;

const breakpoints = {
  xs: 800,
};

/**
 * Lead Detail Container Component
 */
const LeadDetail = ({ leads, plugins, fetchPlugins, getLead, getConversations, detailLeadConversations, getLeadConversationDocuments, getConversationTranscripts, getLeadAppointments, chatTranscripts, archiveLead, toggleSnackbar }: Props) => {
  const history = useHistory();
  const { id } = useParams();
  const { width } = useWindowSize();
  const xsScreen = width <= breakpoints.xs;
  const [view, toggleView] = useToggle(true);
  const [currentConversation, setCurrentConversation] = React.useState<Conversation | null | undefined>(undefined);
  var spinner = false;
  const goToLeads = () => {
    history.push(LEADS);
  };

  useEffectOnce(() => {
    if (id) {
      spinner = true;
      getLead(id);
      getConversations(id, 1, "DESC", 1);
      getLeadAppointments(id);
      getLeadConversationDocuments(id)
      getConversationTranscripts(id);
    } else {
      goToLeads();
    }
    fetchPlugins();
    spinner = false
  });

  const onClickArchiveLead = (e) => {
    archiveLead(id, () => {
      toggleSnackbar("Lead successfully archived.");
      history.push(LEADS);
    });
  }

  React.useEffect(() => {
    if (currentConversation !== null) {
      spinner = true
      let convo = detailLeadConversations.conversations.find(i => i.lead_count !== 0 && i.lead_id == id);
      convo ? setCurrentConversation(convo) : setCurrentConversation(undefined);
      spinner = false
    }
  }, [detailLeadConversations]);

  let campaign = null;
  if (currentConversation) {
    campaign = currentConversation.campaign;
  } else {
    campaign = detailLeadConversations.conversations.find((c) => c.campaign)?.campaign;
  }

  if (!leads.loading && !leads.detailLead) {
    return <NoMatch message="Lead does not exist!" />;
  } else if (leads.loading) {
    return <div className="leadDetail__app__spinner">
      <div id="loading-bar-spinner" className="spinner"><div className="spinner-icon"></div></div>
    </div>;
  }

  let bot = null;
  if(currentConversation) {
    bot = currentConversation.bot;
  } 

  const conversationsController = (xsScreen: boolean) =>
    currentConversation ? (
      <LeadConversation
        lead={leads.detailLead}
        currentConversation={currentConversation}
        setCurrentConversation={setCurrentConversation}
      />
    ) : (
      <LeadConversations
        lead={leads.detailLead}
        setCurrentConversation={setCurrentConversation}
        toggleView={xsScreen ? toggleView : null}
      />
    );

  return (
    <Layout appClassName="platform" mobileBreakPoint={1000}>
      <div className="leadDetail container container--unStyled">
        <div className="lead__headers">
          <h2 className="type-24-bold" style={{marginLeft:"40px"}}>Lead Profile</h2>
          <Button className="button__archive" size="large" onClick={(e) => onClickArchiveLead(e)}>Archive</Button>
        </div>
        <div className="leadDetail__container">
          {xsScreen ? (
            view ? (
              <LeadProfile
                lead={leads.detailLead}
                loading={leads.loading}
                plugins={plugins}
                toggleView={toggleView}
                campaign={campaign}
                bot={bot}
                leadDocassembleLinks={leads.leadDocassembleLinks}
                leadAppointments={leads.appointments}
                transcripts={chatTranscripts.transcripts}
                currentConvo={currentConversation}
              />
            ) : (
              conversationsController(true)
            )
          ) : (
            <>
              <LeadProfile 
                lead={leads.detailLead} 
                loading={leads.loading} 
                plugins={plugins} 
                campaign={campaign} 
                leadDocassembleLinks={leads.leadDocassembleLinks} 
                bot={bot} 
                leadAppointments={leads.appointments}
                transcripts={chatTranscripts.transcripts}
                currentConvo={currentConversation}
              />
              {conversationsController(false)}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default connect(
  (state: RootStore) => ({
    leads: state.leads,
    plugins: state.plugins.plugins,
    detailLeadConversations: state.leads.detailLeadConversations,
    leadDocassembleLinks: state.leads.leadDocassembleLinks,
    chatTranscripts: state.leads.chatTranscripts
  }),
  {
    getLead,
    fetchPlugins,
    getConversations,
    getLeadConversationDocuments,
    getLeadAppointments,
    getConversationTranscripts, 
    archiveLead
  }
)(LeadDetail);

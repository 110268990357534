import * as React from "react";
import connect from "app/connect";
import { RootStore } from "reducers";

//Cmps
import X from "assets/icons/ui/x.inline.svg";
import { isEmptyString } from "lib/utilities/global";
import HotInput from "../global/hotInput";
import Checkbox from "@material-ui/core/Checkbox";
import { RouteComponentProps } from "react-router-dom";
import { userIsAdmin } from "lib/utilities/global";

type Props = {
  className?: string;
  appointmentType?: any;
  setDeletion?: Function;
  handleEdit?: Function;
  handleEmailCustomization?: Function;
} & RouteComponentProps;

type State = {
  title?: string;
  duration: string;
  description?: string;
}

/**
 * Appointment Type Row Item that appears in appointmentTypes component
 */
class AppointmentTypeItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      duration: "00:00",
    };
  }

  formatDuration = (duration) => {
    const hours = parseInt(duration.split(":")[0]);
    const minutes = parseInt(duration.split(":")[1]);
    return (hours * 60 + minutes) + " minutes"
  }

  render(): JSX.Element {
    const { className, appointmentType, setDeletion, handleEdit, handleEmailCustomization } = this.props;

    return (
      <div className={`appointment__type__row ${className || ""}`}>
        <X onClick={(): void => userIsAdmin(this.props.user) && setDeletion(appointmentType.id)} className={`appointment__type__row__indicator icon-delete ${userIsAdmin(this.props.user) ? '' : 'disabled'}`}/>
        <span className="appointment__type__row__title">
          {appointmentType.title}
        </span>
        <span className="appointment__type__row__description">
          {appointmentType.description}
        </span>
        <span className="appointment__type__row__duration">
          {this.formatDuration(appointmentType.duration)}
        </span>
        <span className="appointment__type__row__buffer">
          {`${appointmentType.buffer} minutes`}
        </span>
        {userIsAdmin(this.props.user) && <span className="appointment__type__row__edit" onClick={() => handleEdit(appointmentType)}>
          EDIT
        </span>}
        {userIsAdmin(this.props.user) && <span className="appointment__type__row__edit" onClick={() => handleEmailCustomization(appointmentType)}>
          Meeting Invites
        </span>}
      </div>
    )
  }
}

export default connect(
  (state: RootStore) => ( { currentUser: state.user})
)(AppointmentTypeItem);
import * as React from "react";
import connect from "app/connect";
import { PUBLIC, SIGNUP, LOGIN } from "app/router";
import { withRouter, RouteComponentProps } from "react-router";

// Cmps

type State = {
    scrollHeight: number;
}

type Props = {
    children: (arg: {
        recordScroll: (event: React.UIEvent<HTMLDivElement>) => void;
        registerContainer: ((instance: HTMLDivElement | null) => any);
        scrollTop: number;
    }) => React.ReactNode;
};

/**
 * Sample cmp.
 */
class RetainScroll extends React.Component<Props, State> {

    scrollTop: number = -1
    scrollContainer: HTMLDivElement | null = null

    constructor(props) {
        super(props)

        this.scrollTop = -1;
    }

    recordScroll = (event) => {
        const { target } = event
        /*
         * HACKED TOGETHER.  NEEDS BETTER SOLUTION
         */
        if (target.className && target.scrollTop > 0) {
            this.scrollTop = target.scrollTop;
        }
    }

    registerContainer = (target: any) => {
        if (target === null || !target.className || target.className === "") return;
        this.scrollContainer = target;
        this.scrollTop = target.scrollTop;
    }

    componentDidUpdate = () =>  {
        if (this.scrollContainer) {
            this.scrollContainer.scrollTop = this.scrollTop
        }
    }

    render() {
        return this.props.children({
            recordScroll: this.recordScroll,
            registerContainer: this.registerContainer,
            scrollTop: this.scrollTop
        })
    }
}

export default RetainScroll;

import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import connect from "app/connect";
import { RootStore } from "reducers";
import { getInvites } from "actions/invites";
import { Invite } from "reducers/invites";
import { resendInvite, rescindInvite } from 'lib/invites';

// Cmps
import Confirmation from "app/components/global/confirmation";

type Props = {
    invite: Invite,
    isActive: Boolean;
    makeInviteItemActive: Function;
} & RouteComponentProps;

type State = {
    deleteConfirm: Boolean;
}

/**
 * Invite List Item
 */
class InviteItem extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        
        this.state = {
            deleteConfirm: false
        }
    }

    /**
     * Deleting the invite
     */
    deleteInvite = () => {
        const { invite } = this.props;
        rescindInvite(invite.id)
        .then(res => {
            this.setState({
                deleteConfirm: false
            })
            this.props.getInvites();
        })
        .catch(err => {
            const errData = err.response.data;
            let errorMsg = 'We ran into an error deleting this invite!';
            if(errData && errData.code && errData.error){
                errorMsg = errData.error;
            }
            this.props.toggleSnackbar(errorMsg);
        })
        
    }

    /**
     * Resending the invite
     */
     resendInvite = () => {
        const {invite} = this.props;
        resendInvite({ email: invite.recipient_email })
        .then(res => {
            this.props.toggleSnackbar('Invitation re-sent successfully.');
        })
        .catch(err => {
            this.props.toggleSnackbar('Sorry, but we ran into an issue sending this invite!')
        })    
     }


    render() {
        const { invite, makeInviteItemActive } = this.props;       

        return (
            <li className="user__item" onClick={makeInviteItemActive}>

                <div className="user__item__title">{invite.name || 'No name'}</div>              
               
                <div className="user__item__email"><span>{invite.recipient_email || 'No Email'}</span></div>

                <div className="user__item__options">                   
                    <button 
                        className="delete"
                        onClick={() => this.setState({ deleteConfirm: true })}
                    >
                        Cancel
                    </button>                                 
                    <button
                        onClick={this.resendInvite}
                    >
                        Resend
                    </button>
                </div>

                <Confirmation
                    title="Delete Invite"
                    message="Are you sure you want to permanently delete this invite? This action cannot be undone."
                    actionTitle="Delete"
                    cancelTitle={null}
                    setVisible={(visible) => this.setState({ deleteConfirm: visible })}
                    cancel={null}
                    visible={this.state.deleteConfirm}
                    confirmation={this.deleteInvite}
                />
            </li>
        );
    }
}

export default withRouter(connect(
    (state: RootStore) => ({ invites: state.invites }), 
    { getInvites  }
)(InviteItem));
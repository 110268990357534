import { InviteAction, FETCH_INVITES , SET_INVITES , ADD_INVITES } from "actions/invites";

export type Organization = {
    id: number;
    owner_id: number;
    plan: string;
    seats_remaining: number;
    seats_used: number;
    plan_seats: number;
    title: string;
};

export type Invite = {
    id: string;
    recipient_email: string;
    name: string;
    is_expire: Boolean;
    organization?: Organization;
};

export type InviteStore = {
    invites: Invite[],
    loading: Boolean,
    page: number,
    seats_remaining: number,
};

const initialState = {
    invites: [],
    loading: false,
    page: 1,
    seats_remaining: 0,
};

export default function(
    state: InviteStore = initialState,
    action: InviteAction,
): InviteStore {
    switch (action.type) {
        case FETCH_INVITES: {
            return {
                ...state,
                loading: true,
            };
        }
        case ADD_INVITES:
        case SET_INVITES: {
            return {
                ...state,
                loading: false,
                invites: action.type == ADD_INVITES ? state.invites.concat(action.invites) : action.invites,
                page: action.page,
                seats_remaining: action.seats_remaining,
            };
        }       
        default: {
            return state;
        }
    }
}

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { ControlPoint } from "@material-ui/icons";
import { twitterTabsStylesHook } from "@mui-treasury/styles/tabs";
import { updateBotCustomizations } from "lib/user";
import { deleteDisplayPreset, fetchDisplayPresets, newDisplayPreset, updateDisplayPreset } from "lib/bots";
import connect from "app/connect";
import { RootStore } from "reducers";
import { getBotCustomizations, BotDisplayOptions } from "actions/botCustomizations";
import TabPanel from "./tab_panel";
import Form from "./form";

type Props = {} & RouteComponentProps;

const BotCustomizations = ({ toggleSnackbar, user, getBotCustomizations, botCustomizations }: Props) => {
  const [presets, setPresets] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState({ id: 0 });
  const { botDisplayOptions, loading } = botCustomizations;
  const orgId = user.organization_id;
  const tabsStyles = twitterTabsStylesHook.useTabs();
  const tabItemStyles = twitterTabsStylesHook.useTabItem();

  const updateBot = (params: BotDisplayOptions): void => {
    updateBotCustomizations({
      orgId,
      ...params,
    })
      .then(() => {
        toggleSnackbar("Bot Customizations saved!");
      })
      .catch(() => {
        toggleSnackbar("Unable to save bot customizations, please try again.", undefined, "error");
      });
  };

  const newPreset = (params: BotDisplayOptions): void => {
    newDisplayPreset({ orgId, ...params })
      .then(({ data }) => {
        setPresets((prevState) => [...prevState, { ...data, display_options: JSON.parse(data.display_options) }]);
        toggleSnackbar("New display preset created!");
      })
      .catch(() => {
        toggleSnackbar("Unable to create new display preset, please try again.", undefined, "error");
      });
  };

  const editPreset = (params: BotDisplayOptions, presetId: number): void => {
    updateDisplayPreset({ presetId, ...params })
      .then(({ data }) => {
        setPresets((prevState) =>
          prevState.map((preset) =>
            preset.id === presetId ? { ...data, display_options: JSON.parse(data.display_options) } : preset
          )
        );
        toggleSnackbar("Display preset updated!");
      })
      .catch(() => {
        toggleSnackbar("Unable to update display preset, please try again.", undefined, "error");
      });
  };

  const handleDeletePreset = (presetId: number): void => {
    deleteDisplayPreset(presetId)
      .then(() => {
        setSelectedTab({ id: 0 });
        setPresets((prevState) => prevState.filter(({ id }) => id !== presetId));
        toggleSnackbar("Display preset deleted!");
      })
      .catch(() => {
        toggleSnackbar("Display preset could not be deleted", undefined, "error");
      });
  };

  React.useEffect(() => {
    if (orgId) {
      getBotCustomizations(orgId);
      fetchDisplayPresets(orgId).then((res) => {
        const displayPresets = res.map((preset) => {
          return { ...preset, display_options: JSON.parse(preset.display_options) };
        });
        setPresets(displayPresets);
      });
    }
  }, [orgId]);

  const handleChange = (_e, newValue): void => {
    setSelectedTab({ id: newValue });
  };

  return (
    <div className="bot_customization">
      <div className="bot_customization__header">
        <h3 className="type-24-bold">Bot Customizations</h3>
      </div>

      <AppBar position="static" style={{ backgroundColor: "unset" }}>
        <Tabs
          value={selectedTab.id}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={tabsStyles}
        >
          <Tab label="Default Customization" classes={tabItemStyles} style={{ margin: 0 }} />
          {presets.map(({ id, title }) => (
            <Tab label={title} key={`tab-${id}`} classes={tabItemStyles} style={{ margin: 0 }} />
          ))}
          <Tab icon={<ControlPoint />} classes={tabItemStyles} style={{ margin: 0, minWidth: 0, width: 50 }} />
        </Tabs>
      </AppBar>
      <div className="bot_customization__body">
        <TabPanel value={selectedTab.id} index={0}>
          <Form
            {...botDisplayOptions}
            orgId={orgId}
            loading={loading}
            onSubmit={updateBot}
            toggleSnackbar={toggleSnackbar}
            user={user}
          />
        </TabPanel>
        {presets.map(({ id, display_options, title }, index) => {
          return (
            <TabPanel value={selectedTab.id} index={index + 1} key={id}>
              <Form
                {...display_options}
                presetTitle={title}
                orgId={orgId}
                loading={loading}
                onSubmit={editPreset}
                toggleSnackbar={toggleSnackbar}
                user={user}
                presetId={id}
                handleDeletePreset={handleDeletePreset}
              />
            </TabPanel>
          );
        })}
        <TabPanel value={selectedTab.id} index={presets.length + 1}>
          <Form
            orgId={orgId}
            loading={loading}
            onSubmit={newPreset}
            toggleSnackbar={toggleSnackbar}
            user={user}
            isNew
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default connect(
  (state: RootStore) => ({
    botCustomizations: state.botCustomizations,
  }),
  {
    getBotCustomizations,
  }
)(BotCustomizations);

import * as React from "react";

// Cmps
import Classifiers from "app/components/leadRouting/classifiers";

/**
 * HandoffPanel Component
 * Selected Attorny info will be shown here
 */
class HandoffPanel extends React.PureComponent {
  render(): React.ReactElement {
    return (
      <div className="handoff__panel container container--unStyled">
        <Classifiers />
      </div>
    );
  }
}

export default HandoffPanel;

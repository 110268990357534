import { 
    createAvailabilityParams,
    fetchAvailabilities,
    fetchPluginEvents,
    putAvailability,
    deleteAvailability as _deleteAvailability,
    createAvailability as _createAvailability
} from "lib/availabilities";

import { toggleSnackbar } from "actions/ui";
import { axiosErr } from "lib/api";

// Action types
export const FETCH_AVAILABILITIES = 'FETCH_AVAILABILITIES';
export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const SET_CALENDAR_DATES = 'SET_CALENDAR_DATES';
export const FETCH_PLUGIN_EVENTS = 'FETCH_PLUGIN_EVENTS';
export const SET_PLUGIN_EVENTS = 'SET_PLUGIN_EVENTS';
export const EDIT_AVAILABILITY = 'EDIT_AVAILABILITY';
export const DELETE_AVAILABILITY = 'DELETE_AVAILABILITY';
export const REMOVE_AVAILABILITY = 'REMOVE_AVAILABILITY';
export const CREATE_AVAILABILITY = 'CREATE_AVAILABILITY';

export type Availability = {
    id: number,
    start_time: string,
    end_time: string,
    is_repeat: string,
    type: string
}

export type AvailabilityDateWise = {
    date: String;
    avaliability: Availability[]
}

export type PluginEvent = {
    id: number,
    start_time: string,
    end_time: string,
    is_repeat: string,
    type: string,
    title: string,
}

export type PluginEventDatewise = {
    date: String;
    plugin_events: PluginEvent[]
}

export const getAvailabilities = (id: string, startDate: string, endDate: string) => dispatch => {
    // Tell reducer we're fetching
    dispatch({
        type: FETCH_AVAILABILITIES
    });

    // Actually fetch attorneys
    fetchAvailabilities(id, startDate, endDate)
    .then(res => {
        dispatch({
            type: SET_AVAILABILITIES,
            availabilities: res
        }) 
        setPaginationDates(startDate,endDate)(dispatch);      
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your user's availabilities! Please try again", true)(dispatch);
    })
}

export const setPaginationDates = (startDate: string, endDate: string) => dispatch => {
    dispatch({
        type: SET_CALENDAR_DATES,
        startDate: startDate,
        endDate: endDate,
    })
}

export const createAvailability = (availabilityParams: createAvailabilityParams) => dispatch => {
    dispatch({
        type: CREATE_AVAILABILITY,
    })

    _createAvailability(availabilityParams).then((response) => {
        dispatch({
            type: SET_AVAILABILITIES,
            availabilities: response.data.availabilities
        })
    }).catch((error) =>{
        const err = axiosErr(error)
        let snackbar = null;
        if (err && err.error.indexOf('already exist') > -1) {
            toggleSnackbar(`An availability with this timing already exists!`)(dispatch);
        } else if (err && err.error.indexOf('greater than') > -1) {
            toggleSnackbar(`An availability can't have Start time greater then End time!`)(dispatch);
        } else {
            toggleSnackbar(`We ran into an error creating your availability!`)(dispatch);
        }
    })
}

export const editAvailability = (availability) => dispatch => {
    dispatch({
        type: EDIT_AVAILABILITY,
    })

    putAvailability({...availability, availability_type: availability.type}).then((response) => {
        dispatch({
            type: SET_AVAILABILITIES,
            availabilities: response.data.availabilities
        });
    }).catch(e => {
        const err = axiosErr(e)
        if (err && err.error.indexOf('already exist') > -1) {
            toggleSnackbar(`An availability with this timing already exists!`);
        } else if (err && err.error.indexOf('greater than') > -1) {
            toggleSnackbar(`An availability can't have Start time greater then End time!`);
        } else {
            toggleSnackbar(`We ran into an error saving your availability!`);
        }
    });
}

export const deleteAvailability = (availability) => dispatch => {
    dispatch({
        type: DELETE_AVAILABILITY,
    })

    _deleteAvailability(availability).then((response) => {
        dispatch({
            type: REMOVE_AVAILABILITY,
            availabilityId: availability
        })
    }).catch(error => {
        toggleSnackbar('We ran into an error deleting this availability!');
    })
}

export const getPluginEvents = (id: string, startDate: string, endDate: string) => dispatch => {
    dispatch({
        type: FETCH_PLUGIN_EVENTS
    });

    fetchPluginEvents(id, startDate, endDate)
    .then(response => {
        dispatch({
            type: SET_PLUGIN_EVENTS,
            plugin_events: response
        });
        setPaginationDates(startDate,endDate)(dispatch);
    })
    .catch(err => {
        toggleSnackbar("We couldn't fetch your Google Calendar / Outlook Events", true)(dispatch);
    })
}

export type AvailabilitiesAction =
    | {
          type: "FETCH_AVAILABILITIES",
          loading: Boolean
      } 
    | {
        type: "SET_AVAILABILITIES",
        availabilities: AvailabilityDateWise[]
      }
    | {
          type: 'FETCH_PLUGIN_EVENTS',
          loading: Boolean
      }
    | {
        type: "SET_PLUGIN_EVENTS",
        plugin_events: PluginEventDatewise[]
      }
    | {
        type: "SET_CALENDAR_DATES",
        startDate: string,
        endDate: string
      } 
    | {
        type: "CREATE_AVAILABILITY",
        loading: Boolean,
      }
    | {
        type: "EDIT_AVAILABILITY",
        loading: Boolean,
      }
    | {
        type: "DELETE_AVAILABILITY",
        loading: Boolean,
      }
    | {
        type: "REMOVE_AVAILABILITY",
        availabilityId: Number,
    }
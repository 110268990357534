import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";

// Cmps
import Nav from "app/components/navbar";
import Container from "app/components/global/container";
import LoginForm from "app/components/lander/loginForm";

// Sass
import "sass/pages/login.scss";

/**
 * Login Container Component
 */
class Login extends React.PureComponent {
  render(): JSX.Element {
    return (
      <App className="public">
        <Nav />

        <Container name="lander">
          <LoginForm />
        </Container>
      </App>
    );
  }
}

export default connect()(Login);

import { User } from "../reducers/user"
import { AdminAction, ADMIN_SET_USERS } from "actions/admin";

export type AdminStore = {
    users: User[],
};

const initialState = {
    users: [],
};

export default function(
    state: AdminStore = initialState,
    action: AdminAction,
): AdminStore {
    switch (action.type) {
        case ADMIN_SET_USERS: {
            return {
                ...state,
                users: action.users,
            };
        }
        default: {
            return state;
        }
    }
}
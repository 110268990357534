import * as React from "react";
import { RouteComponentProps } from "react-router";
import { getAttorneys } from "actions/attorneys";
import { AttorneyStore } from "reducers/attorneys";
import { RootStore } from "reducers";
import connect from "app/connect";

import Invitelist from "../invites/inviteList";
import UserList from "./userList";
import CreateInvite from "app/components/settings/invites/createInvite";
import { getInvites } from "actions/invites";
import { Organization, InviteStore } from "reducers/invites";
import { toCapitalized } from "lib/utilities/capitalize";

type Props = {
    organization: Organization;
    invites: InviteStore;
    attorneys: AttorneyStore;
    getAttorneys: Function;
} & RouteComponentProps;

const Users: React.FC<Props> = ({
    attorneys,
    getAttorneys,
    getInvites,
    organization,
    invites 
}: Props): JSX.Element => {
    const [showInviteModal, toggleShowInviteModal] = React.useState(false);

    React.useEffect(() => {
        getInvites();
        if (!!attorneys) getAttorneys();
    }, []);

    const users = attorneys.data;
    //console.log(attorneys.data)
    return (
        <div className="users">
            <div className="users__header">
                <div className="users__org">
                    <h3 className="type-24-bold">Users</h3>
                    <h4>{ organization && organization.title }</h4>
                    <h4>Plan: { organization && toCapitalized(organization.plan) }</h4>
                </div>
                <div className="users__action">
                    { organization && <>
                        <div className="users__org-details">
                            <span>
                                <h3>Seats Utilized:</h3>
                                <h3>{`${organization.seats_used} / ${organization.plan_seats}`}</h3>
                            </span>
                        </div>
                        <div className="users__action-cta">
                        { attorneys.seats_remaining > 0 ?
                            <button 
                                className="users__add__button" 
                                onClick={() => toggleShowInviteModal(true)}
                                disabled={attorneys.seats_remaining > 0 ? false : true }
                            >Add User</button>
                            : <a className="users__contact-us" href="mailto:support@gideon.legal">Contact Support to Upgrade</a>

                        }
                        </div>
                        </>
                    }
                </div>
            </div>               

            <Invitelist
                fetchMore={() => getInvites(parseInt(invites.page) + 1)}
                loading={false}
                invites={invites.invites}
            />

            <UserList
                loading={false}
                users={users} />

            <CreateInvite 
                setModalVisibility={(visible) => toggleShowInviteModal(visible)}
                visible={showInviteModal} 
            />
        </div>
    );
};

export default connect((state: RootStore) => ({ attorneys: state.attorneys, invites: state.invites, organization: state.user.organization }),
{
    getAttorneys,
    getInvites,
}
)(Users);

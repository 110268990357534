import * as React from "react";
import connect from "app/connect";
import { RouteComponentProps } from "react-router";
import { createAvailabilityParams, editAvailabilityParams } from "lib/availabilities";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import { KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// Sass
import "sass/components/createAvailability.scss";

// Cmps
import Dialog from "app/components/global/dialog";
import moment from "moment";
import momentTz from "moment-timezone";

type State = {
  loading: boolean;
  availabilityParams: createAvailabilityParams;
  is_repeat: boolean;
  selectedRecurrence: Recurrence;
};

const initialState = {
  loading: false,
  availabilityParams: {
    id: null,
    start_date: "",
    start_time: "",
    end_time: "",
    available_for_user_id: "",
    list_start_date: "",
    list_end_date: "",
    is_repeat: "norepeat",
    type: "busy",
  },
  is_repeat: false,
  selectedRecurrence: { value: "daily", label: "Daily" },
  errors: {
    start_date: true,
    start_time: true,
    end_time: true,
  },
};

type Recurrence = {
  value: string;
  label: string;
};

type Props = {
  selectedUserId: string;
  selectedAvailability: editAvailabilityParams;
  refresh: Function;
  setVisible: Function;
  visible: boolean;
  listStartDate: string;
  listEndDate: string;
} & RouteComponentProps;

export const recurringOptions: Array<Recurrence> = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "weekdays", label: "Weekdays" },
  { value: "weekends", label: "Weekends" },
  { value: "monthly", label: "Monthly" },
];

// /**
//  * Styles for the drop-down
//  */
const customSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#f6f7f8",
    borderColor: state.isFocused ? "#cedcdb" : "#cedcdb",
  }),
  option: (styles, state) => ({
    ...styles,
    color: "#9b9b9b",
    backgroundColor: state.isSelected ? "#cccccc" : state.isFocused ? "#cedcdb" : "#f6f7f8",
  }),
  menu: (styles) => ({ ...styles, backgroundColor: "#f6f7f8" }),
};

/**
 * Create Availablity Cmp.
 * Doubles as creating/editing if a availability is passed
 * Uses the <Dialog /> cmp.
 */
const CreateAvailability: React.FC<Props> = ({
  selectedUserId,
  selectedAvailability,
  refresh,
  setVisible,
  visible,
  listStartDate,
  listEndDate,
  createAvailability,
  editAvailability,
  timeZone,
}: Props) => {
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    if (selectedAvailability) {
      const currRrecurrence = selectedAvailability.is_repeat || "null";
      const recurrenceOption = recurringOptions.find((s: Recurrence) => {
        return s && s.value && s.value === currRrecurrence;
      });

      setState({
        loading: false,
        availabilityParams: {
          start_date: selectedAvailability.start_date,
          start_time: moment(selectedAvailability.start_time).format("hh:mm A"),
          end_time: moment(selectedAvailability.end_time).format("hh:mm A"),
          available_for_user_id: selectedUserId,
          is_repeat: selectedAvailability.is_repeat ? selectedAvailability.is_repeat : "norepeat",
          id: selectedAvailability.id ? selectedAvailability.id : null,
          list_start_date: listStartDate,
          list_end_date: listEndDate,
          type: selectedAvailability.type,
        },
        is_repeat: recurrenceOption ? true : false,
        selectedRecurrence: recurrenceOption ? recurrenceOption : initialState.selectedRecurrence,
        errors: {
          start_date: false,
          start_time: false,
          end_time: false,
        },
      });
    } else {
      setState({
        ...initialState,
        availabilityParams: {
          ...initialState.availabilityParams,
          available_for_user_id: selectedUserId,
          list_start_date: listStartDate,
          list_end_date: listEndDate,
        },
      });
    }
  }, [selectedAvailability, selectedUserId]);

  const handleChange = (key: string, m: Date): void => {
    if (!m) return;
    let formatValue = key === "start_date" ? moment(m).format("YYYY-MM-DD") : moment(m).format("hh:mm A");
    if (typeof m === "string") {
      formatValue = "";
    }
    setState({
      ...state,
      availabilityParams: {
        ...state.availabilityParams,
        [key]: formatValue,
      },
      errors: {
        ...state.errors,
        [key]: isNaN(m.getTime()),
      },
    });
  };

  const handleRepeatCheck = (e): void => {
    const { selectedRecurrence } = state;
    const isRepeat = e.target.checked;
    const defaultRecurrence = selectedRecurrence ? selectedRecurrence : initialState.selectedRecurrence;
    setState({
      ...state,
      is_repeat: isRepeat,
      selectedRecurrence: defaultRecurrence,
      availabilityParams: {
        ...state.availabilityParams,
        is_repeat: isRepeat ? defaultRecurrence.value : "norepeat",
      },
    });
  };

  const handleRecurringChange = (recurrence): void => {
    if (state.is_repeat && recurrence) {
      setState({
        ...state,
        availabilityParams: {
          ...state.availabilityParams,
          is_repeat: recurrence.value,
        },
        selectedRecurrence: recurrence,
      });
    }
  };

  const validateCreateAvailability = (): boolean => {
    const { errors } = state;
    const { start_date, start_time, end_time } = errors;
    return !start_date && !start_time && !end_time;
  };

  const createAvailabilityLocal = (e): void => {
    e.preventDefault();
    if (!validateCreateAvailability()) return;
    const methodToUse: Function =
      selectedAvailability && selectedAvailability.id ? editAvailability : createAvailability;
    setState({ ...state, loading: true });
    methodToUse(state.availabilityParams);
    refresh();
    setState({
      ...initialState,
      loading: false,
      availabilityParams: {
        ...initialState.availabilityParams,
        available_for_user_id: selectedUserId,
        list_start_date: listStartDate,
        list_end_date: listEndDate,
      },
    });
  };

  const availabilityType =
    (selectedAvailability && selectedAvailability.type === "busy") || !selectedAvailability
      ? "Unavailability"
      : "Availability";

  const startDate =
    state.availabilityParams.start_date === ""
      ? ""
      : timeZone
      ? momentTz(state.availabilityParams.start_date).tz(timeZone).toDate()
      : state.availabilityParams.start_date;
  return (
    <Dialog
      className="create__new__availability"
      setVisible={setVisible}
      visible={visible}
      excludedTargetRegex={"Mui*"}
    >
      <h3 className="type-24-bold">
        {selectedAvailability && selectedAvailability.id ? `Edit ${availabilityType}` : `Create ${availabilityType}`}
      </h3>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="timePicker">
          <div className="create__new__availability__title">Start Date</div>
          <KeyboardDatePicker
            value={startDate}
            onChange={(e): boolean => {
              handleChange("start_date", e);
              return false;
            }}
            style={{ backgroundColor: "#f6f7f8", border: "none", width: "100%" }}
          />
        </div>

        <div className="formField-container">
          <div className="timePicker">
            <span className="create__new__availability__title">Start Time</span>
            <KeyboardTimePicker
              value={""}
              inputValue={state.availabilityParams.start_time}
              onChange={(e): boolean => {
                handleChange("start_time", e);
                return false;
              }}
              style={{ backgroundColor: "#f6f7f8", border: "none" }}
            />
          </div>
          <div className="timePicker">
            <span className="create__new__availability__title">End Time</span>
            <KeyboardTimePicker
              value={""}
              inputValue={state.availabilityParams.end_time}
              onChange={(e): boolean => {
                handleChange("end_time", e);
                return false;
              }}
              style={{ backgroundColor: "#f6f7f8", border: "none" }}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>

      <div className="form-control create__new__availability-is_repeat">
        <Checkbox
          defaultChecked={false}
          checked={state.is_repeat}
          className={`availability__checkbox ${state.is_repeat ? "active" : ""}`}
          onChange={(e): void => handleRepeatCheck(e)}
        />
        <span>Repeat</span>
      </div>

      {state.is_repeat && (
        <div className="form-control">
          <Select
            placeholder="Choose Recurrence"
            onChange={(optionValue): void => handleRecurringChange(optionValue)}
            options={recurringOptions}
            value={state.selectedRecurrence}
            className="recurrence__selector"
            styles={customSelectStyles}
          />
        </div>
      )}

      <button onClick={(e): void => !state.loading && visible && createAvailabilityLocal(e)} style={{ marginTop:"25px" }}>
        {selectedAvailability ? "Save" : "Create"}
      </button>
    </Dialog>
  );
};

export default connect()(CreateAvailability);

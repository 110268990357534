import * as React from "react";
import { NODE_TYPES } from "lib/nodes";
import CueIcon from "app/components/botDetail/cueIcon";
import { CueType, cueTitle, CueTypeString } from "lib/cue";

// Sass
import "sass/components/cueTypeSelection.scss";

type Props = {
  onSelect: Function;
  visible: boolean;
  isNodeAllowed: boolean;
};

/**
 * Cue Type Selection box
 */
class CueTypeSelection extends React.Component<Props> {
  render() {
    return (
      <ul className="cue_type_selection">
        <CueSelector type={CueType.MESSAGE} onClick={this.props.onSelect} />
        <CueSelector type={CueType.MULTIPLE_CHOICE} onClick={this.props.onSelect} title="Choice" />
        <CueSelector type={CueType.DATE} onClick={this.props.onSelect} title="Date" />
        <CueSelector type={CueType.CONTACT} onClick={this.props.onSelect} title="Contact" />
        <CueSelector type={CueType.OPEN_RESPONSE} onClick={this.props.onSelect} title="Text" />
        <CueSelector type={CueType.NUMBER_RESPONSE} onClick={this.props.onSelect} title="Number" />
        <CueSelector type={CueType.FILE_UPLOAD} onClick={this.props.onSelect} title="File" />
        <CueSelector type={CueType.DISCLAIMER} onClick={this.props.onSelect} title="Disclaimer" />
        <CueSelector type={CueType.REPEAT} onClick={this.props.onSelect} title="Repeat" />
        <CueSelector type={CueType.ADDRESS} onClick={this.props.onSelect} title="Address" />
        {this.props.isNodeAllowed && (
          <>
            <CueSelector type={CueType.DIALOG} onClick={this.props.onSelect} title="Dialog" />
            <CueSelector type={CueType.HANDOFF} onClick={this.props.onSelect} title="Completion Message" />
          </>
        )}
      </ul>
    );
  }
}

class CueSelector extends React.PureComponent<{
  type: CueType;
  onClick: Function;
  title?: string;
}> {
  render() {
    const { type, onClick } = this.props;
    const title = this.props.title || cueTitle(type);

    return (
      <li className={`cue_selector cue_selector--${CueTypeString[type]}`} onClick={() => onClick(type)}>
        <div>
          <CueIcon cueType={type} className="cue_selector--icon" />
        </div>

        <span>{title}</span>
      </li>
    );
  }
}

export default CueTypeSelection;

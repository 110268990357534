import React from "react";
import { Route, Redirect } from "react-router-dom";
import connect from "app/connect";
import ProtectedCmp from "app/pages/protected";
import { storeRedirectLocation } from "actions/redirect";

/**
 * Custom react-router route that ensures there's a user
 * before rendering the route
 */
const Protected = ({ component, user, ...rest }) => {
    
    if (!user.loggedIn) {
        storeRedirectLocation(rest.location.pathname);
    }

    return (
        <Route
            {...rest}
            render={props =>
                user.loggedIn ? (
                    <ProtectedCmp
                        {...rest}
                        {...props}
                        user={user}
                        Component={component}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default connect(null, { storeRedirectLocation })(Protected)
import api from "lib/api";

export type CreatePluginPayload = {
  plugin_source_id: number;
  authorization_code?: string;
  redirect_uri?: string;
  api_key?: string;
  api_secret?: string;
  endpoint?: string;
  oauthState?: string;
};

export type createClioMappingParams = {
  node_id: number;
  active: boolean;
  clio_field: string;
};

export type updateClioMappingParams = {
  id: number;
  active?: boolean;
  clio_field?: string;
};

export type createClioMatterParams = {
  plugin_id: number;
  lead_id: number;
  practice_area: number;
  description: string;
  create_new_matter: boolean;
};


export const redirect_uri_for_plugin_source = (plugin_source_id: number) => {
  return location.protocol + "//" + location.host + `/settings/plugins/${plugin_source_id}`;
};

export const getPluginSources = () => {
  return api.get(`/plugin_sources`, null);
};

export const getPlugins = () => {
  return api.get(`/plugins`, null);
};

export const createPlugin = (params: CreatePluginPayload) => {
  //console.log("createPlugin passes params:");
  //console.log(params);
  return api.post(`/plugins`, params);
};

export const removePlugin = (plugin_id: number) => {
  return api.deleteReq(`/plugins/${plugin_id}`, null);
};

// Clio
export const getClioMappingForNode = (node_id) => {
  return api.get(`/clio_mappings?node_id=${node_id}`, null);
};

export const createClioMappingForNode = (params: createClioMappingParams) => {
  return api.post(`/clio_mappings`, params);
};

export const updateClioMapping = (params: updateClioMappingParams) => {
  return api.put(`/clio_mappings/${params.id}`, params);
};

export const getClioFields = () => {
  return api.get(`/clio_mappings/fields`, null);
};

export const createClioMatter = (params: createClioMatterParams) => {
  return api.post(`/clio/create_matter`, params);
};

export const getPracticeAreas = (plugin_id: number) => {
  return api.get(`/clio/practice_areas?plugin_id=${plugin_id}`);
};

// LeadDocket
export interface LeadDocketCaseType {
  Id: number;
  TypeName: string;
}

export interface LitifyCaseType {
  Name: string;
}
// Leaddocket
export const getLeaddocketCaseTypes = () => {
  return api.get(`/leaddocket/case_types`, null) as Promise<LeadDocketCaseType[]>;
};

export const getLeaddocketIntegrationURLS = () => {
  return api.get(`/leaddocket_mappings/integrations`, null);
};

export const getLitifyCaseTypes = () => {
  return api.get(`/litify/case_types`, null) as Promise<LitifyCaseType[]>;
}

export const getDialogLDCaseType = (dialog_id: number) => {
  return api.get(`/leaddocket/case_type_mappings?dialog_id=${dialog_id}`, null)
}

export interface LeadDocketField {
  Id: number;
  FieldName: string;
  DefaultValues: string;
  FieldNameUnderscore: string;
}

export interface LeadDocketMapping {
  id: number;
  leaddocket_field: string;
  active: boolean;
  node_id: number;
}

export interface UpsertLeadDocketMappingParams {
  node_id: number;
  leaddocket_field?: string;
  active?: boolean;
}

export interface UpsertLeadDocketCaseTypeMappingParams {
  node_id: number;
  case_type_id: number;
}

export const getLeaddocketFields = (caseTypeId: number | string) => {
  return api.get(`/leaddocket/lead_fields?case_type_id=${caseTypeId}`, null) as Promise<LeadDocketField[]>;
};

export const getLeaddocketMappingForNode = (node_id: number): Promise<LeadDocketMapping> => {
    return api.get(`/leaddocket_mappings?node_id=${node_id}`, null);
};

export const upsertLeaddocketMappingForNode = (params: UpsertLeadDocketMappingParams): Promise<LeadDocketMapping> => {
  return api.post(`/leaddocket_mappings`, params);
};

export const upsertLeaddocketCaseTypeMappingForNode = (params: UpsertLeadDocketCaseTypeMappingParams): Promise<LeadDocketCaseType> => {
  return api.post(`/leaddocket/case_type_mappings`, params)
}

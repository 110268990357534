import * as React from "react";
import moment from "moment";
import { Lead, Campaign, Bot, Lead_Appointments, getPDF, Conversation } from "actions/leads";
import get from "lodash.get";
import { formatTime, fromNow } from "lib/utilities/time";
import { Plugin } from "actions/plugins";
import Collapsible from "react-collapsible";
import { withRouter, RouteComponentProps } from "react-router";
import { SETTINGS, LEADS } from "app/router";
import connect from "app/connect";
import { userIsAdmin } from "lib/utilities/global";
import { IconButton, Button, Tabs, Tab, Box } from "@material-ui/core";
import { BorderBottom, Chat } from "@material-ui/icons";
import { previewDocuments, generateDocuments } from "lib/markdownDocuments";
import clioCheckmark from "assets/clio-checkmark.png";
import * as FileSaver from "file-saver";

// Cmps.
import Avatar from "app/components/global/avatar";
import LeadClioSync from "app/components/lead/leadClioSync";
import TabPanel from "app/components/global/tabPanel";
import PreviewMarkownDocument from "app/components/lead/previewMarkdownDocument";
import lead from "app/pages/lead";
import { createAvailability, createAvailabilityParams } from "lib/availabilities";
import { fetchLeadsConversationDocuments, checkConversationDocuments, fetchPDF, fetchCSV, fetchPresignedDocument } from "lib/leads";
import { chatTranscripts, leadDocassembleLinks } from "reducers/leads";
import DownArrow from "assets/icons/ui/down.inline.svg";
import Download from "assets/icons/download.inline.svg";

type Props = {
  loading: boolean;
  lead: Lead | null;
  plugins: Plugin[];
  toggleView: Function;
  campaign?: Campaign;
  conversation_docs: null;
  leadDocassembleLinks: leadDocassembleLinks;
  leadAppointments: Lead_Appointments;
  bot: Bot;
  file_arr: [];
  transcripts: chatTranscripts;
  currentConvo: Conversation;
} & RouteComponentProps;

type State = {
  clioSyncVisible: boolean;
  isValidClioToken: boolean;
  previewMarkdownDocumentVisible: boolean;
  markdownDocumentBody: string;
  docassembleDocuments: any;
  tabValue: number;
};

/**
 * Lead Profile component
 * Shows lead information
 */
class LeadProfile extends React.Component<Props> {
  static initialState = {
    clioSyncVisible: false,
    isValidClioToken: true,
    previewMarkdownDocumentVisible: false,
    markdownDocumentBody: "",
    docassembleDocuments: null,
    transcripts: [],
    tabValue: 0,
  };

  state = LeadProfile.initialState;

  onClickClioSync(e) {
    const { lead, toggleSnackbar } = this.props;

    if (lead) {
      this.setState({ clioSyncVisible: true });
    } else {
      toggleSnackbar("Error: Lead doesn't exist", true, "error");
    }
  }

  onClickPreviewDocuments(e) {
    const { lead, toggleSnackbar } = this.props;
    previewDocuments({ lead_id: lead.id })
      .then((res) => {
        this.setState({
          ...this.state,
          previewMarkdownDocumentVisible: true,
          markdownDocumentBody: res.document,
        });
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong generating documents!");
      });
  }

  onClickGenerateDocuments(e) {
    const { lead, toggleSnackbar } = this.props;
    generateDocuments({ lead_id: lead.id })
      .then((res) => {
        toggleSnackbar("Success!");
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong generating documents!");
      });
  }

  

  onClickFetchDocassembleDocs(e) {
    const { lead, toggleSnackbar } = this.props;
    fetchLeadsConversationDocuments(lead.id)
      .then((res) => {
        toggleSnackbar("Success!");
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong getting docassemble documents");
      });
  }

  onClickFetchPDF(lead_id, conversation_id) {
    const { lead, toggleSnackbar } = this.props;
    fetchPDF(lead_id, conversation_id)
      .then((res) => {
        FileSaver.saveAs(res, `transcript.pdf`);
      })
      .catch((err) => {
        console.log(err);
        toggleSnackbar("Could not fetch document");
      });
  }

  onClickFetchCSV(lead_id, conversation_id) {
    const { lead, toggleSnackbar } = this.props;
    fetchCSV(lead_id, conversation_id)
      .then((res) => {
        FileSaver.saveAs(res, `transcript.csv`);
      })
      .catch((err) => {
        console.log(err);
        toggleSnackbar("Could not fetch document");
      });
  }

  onClickFetchDocument(url) {
    const { lead, toggleSnackbar } = this.props;
    fetchPresignedDocument(url)
    .then((res) => {
      console.log(res.url)
      var link = document.createElement("a");
      link.href = res.url;
      link.target = "_blank"
      link.click();
    })
    .catch((err) => {
      console.log(err);
      toggleSnackbar("Could not fetch document")
    })
  }

  renderClioIntegration() {
    const { lead, plugins } = this.props;

    if (!this.state.isValidClioToken) {
      return (
        <>
          <div className="leadDetail__profile__field__plugin">
            <button onClick={(): void => this.props.history.push(SETTINGS)} className="addPlugins">
              Edit Plugins
            </button>
            <p>Clio Token invalid. Please re-add plugin.</p>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="leadDetail__profile__field__plugin">
          {/* <img src={`${process.env.REACT_STATIC_CDN}/clio.png`}></img> */}
          {lead && lead.clio ? (
            <Button
              onClick={() => {
                window.location.href = `https://app.clio.com/contacts/${lead.clio.contact.id}`;
              }}
            >
              View in Clio
            </Button>
          ) : (
            <Button onClick={(e) => this.onClickClioSync(e)}>Sync Lead to Clio</Button>
          )}
          {lead && lead.clio ? <hr style={{ opacity: "100%" }} /> : <hr style={{ opacity: "30%" }} />}
          {lead && lead.clio ? (
            <img src={clioCheckmark} style={{ opacity: "100%" }} />
          ) : (
            <img src={clioCheckmark} style={{ opacity: "30%" }} />
          )}
        </div>
        <LeadClioSync
          visible={this.state.clioSyncVisible}
          setIsClioTokenValid={(isValidClioToken): void => this.setState({ isValidClioToken })}
          setVisible={(e): void => {
            this.setState({ clioSyncVisible: e });
          }}
          lead={lead}
          clioPlugin={plugins.find((e) => e.plugin_source.identifier === "clio")}
        />
      </>
    );
  }

  sortLeadFiles(lead) {
    const files = lead.files;
    const files_arr = [];
    files.map(function (node_progress, i) {
      const check = node_progress.response;
      const arr = check.split(",");
      for (let i = 0; i < arr.length; i++) {
        let text = arr[i];
        if (text[2] == "h") {
          text = text.slice(2, -2);
        } else {
          text = text.slice(1, -2);
        }
        arr[i] = text;
      }
      {
        arr.map(function (file, f) {
          files_arr.push({ url: file, title: node_progress.title });
        });
      }
    });

    return files_arr;
  }

  convertDate(date) {
    const d = new Date(date);
    return d.toLocaleString("en-us", { weekday: "long", year: "numeric", month: "long", day: "numeric" });
  }

  convertTime(time) {
    const t = new Date(time);
    return t.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  }

  getApptEnd(time) {
    const t = new Date(time);
    t.setMinutes(t.getMinutes() + 30);
    return t.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  }

  getTimezone(date) {
    const d = new Date(date);
    return d.toLocaleDateString("en-US", { day: "2-digit", timeZoneName: "short" }).split(",")[1];
  }

  onClickAddToCalendar(e, start_time, end_time, date) {
    const { lead, toggleSnackbar } = this.props;
    const d = date.slice(0, 10);
    const avail = {
      id: "",
      start_date: d,
      start_time: start_time,
      end_time: end_time,
      available_for_user_id: lead.assignee.id,
      is_repeat: "norepeat",
      type: "busy",
      list_start_date: d,
      list_end_date: d,
    };
    createAvailability(avail)
      .then((res) => {
        toggleSnackbar("Success!");
      })
      .catch((err) => {
        toggleSnackbar("Something went wrong adding appointment to calendar");
      });
  }

  // gets name for file -> created at - bot name - lead name
  getDocassembleName(docassemble_file, bot, lead) {
    if (docassemble_file && lead && lead.name) {
      if (this.isJson(lead.name)) {
        var created = docassemble_file.updated_at.split("T")[0];
        var bot_name = docassemble_file.bot_title;
        var name_json = JSON.parse(lead.name);
        var first = name_json["first_name"] ? name_json["first_name"] : "";
        var middle = name_json["middle_name"] ? name_json["middle_name"] : "";
        var last = name_json["last_name"] ? name_json["last_name"] : "";
        var full = "";
        if (first != "") {
          full += first + " ";
        }
        if (middle != "") {
          full += middle + " ";
        }
        if (last != "") {
          full += last;
        }
        return created + " - " + bot_name + " - " + full;
      } else {
        var created = docassemble_file.updated_at.split("T")[0];
        var bot_name = docassemble_file.bot_title;
        var first_name = lead.name.split(" ")[0];
        var last_name = lead.name.split(" ")[1];
        return created + " - " + bot_name + " - " + first_name + " " + last_name;
      }
    } else {
      return "docassemble file";
    }
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    const {
      campaign,
      loading,
      lead,
      plugins,
      toggleView,
      user,
      leadDocassembleLinks,
      bot,
      transcripts,
      leadAppointments,
      currentConvo,
    } = this.props;
    const files_arr = this.sortLeadFiles(lead);
    const clioPlugin = plugins.find((e) => e.plugin_source.identifier === "clio");
    const documentGenerationEnabled = user.organization?.document_generation_enabled;
    let address = lead.address;
    console.log("transcripts: ")
    console.log(transcripts)
    //const [firstname, lastname] = lead.Name.split(' ')

    if (lead.address != null && lead.address.includes("address")) {
      const splitAddr = lead.address.replace(/['"]+/g, "").split(":");
      address = splitAddr[1].replace(",apartment", "");
    }

    var is_json = false;
    var first_name = "No first name";
    var middle_name = "No middle name";
    var last_name = "No last name";
    if (lead.name != null && lead.name.includes("{")) {
      var json_name = JSON.parse(lead.name);
      if (json_name && typeof json_name === "object") {
        is_json = true;
        if (json_name.first_name) {
          first_name = json_name.first_name;
        }
        if (json_name.middle_name) {
          middle_name = json_name.middle_name;
        }
        if (json_name.last_name) {
          last_name = json_name.last_name;
        }
      }
    }

    return (
      <div className="leadDetail__profile">
        <Collapsible trigger="General Information" className="leadDetail__profile__collapse" open>
          {!loading && lead != null && is_json == false && (
            <div className="leadDetail__profile__section leadDetail__profile__status">
              <div className="leadDetail__profile__fields">
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>First Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">{lead.name.split(" ")[0] || "No first name"}</h4>
                  </div>
                </div>
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Middle Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">
                      {lead.name.split(" ").length >= 3 ? lead.name.split(" ")[1] : "No middle name"}
                    </h4>
                  </div>
                </div>
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Last Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">
                      {lead.name.split(" ").length >= 3
                        ? lead.name.split(" ")[2]
                        : lead.name.split(" ")[1] || "No last name"}
                    </h4>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Email:</span>
                  </div>
                  <div className="colB">
                    <a className="info" href={"mailto:" + lead.email}>
                      {lead.email || "No email"}
                    </a>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Phone:</span>
                  </div>
                  <div className="colB">
                    <a className="info" href={"tel:" + lead.phone_number}>
                      {lead.phone_number || "No phone number"}
                    </a>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Address:</span>
                  </div>
                  <div className="colB">
                    <h4>{address || "No address"}</h4>
                  </div>
                </div>

                <div className="leadDetail__profile__field__last">
                  <div className="colA">
                    <span>Date Created:</span>
                  </div>
                  <div className="colB">
                    <h4>{formatTime(lead.created_at, "MM/D/YY, hh:mma")}</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && lead != null && is_json == true && (
            <div className="leadDetail__profile__section leadDetail__profile__status">
              <div className="leadDetail__profile__fields">
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>First Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">{first_name}</h4>
                  </div>
                </div>
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Middle Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">{middle_name}</h4>
                  </div>
                </div>
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Last Name:</span>
                  </div>
                  <div className="colB">
                    <h4 className="name">{last_name}</h4>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Email:</span>
                  </div>
                  <div className="colB">
                    <a className="info" href={"mailto:" + lead.email}>
                      {lead.email || "No email"}
                    </a>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Phone:</span>
                  </div>
                  <div className="colB">
                    <a className="info" href={"tel:" + lead.phone_number}>
                      {lead.phone_number || "No phone number"}
                    </a>
                  </div>
                </div>

                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Address:</span>
                  </div>
                  <div className="colB">
                    <h4>{address || "No address"}</h4>
                  </div>
                </div>

                <div className="leadDetail__profile__field__last">
                  <div className="colA">
                    <span>Date Created:</span>
                  </div>
                  <div className="colB">
                    <h4>{formatTime(lead.created_at, "MM/D/YY, hh:mma")}</h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Collapsible>

        {toggleView && (
          <Button className="viewConversationButton" onClick={() => toggleView()} variant="contained" color="primary">
            View Conversations
          </Button>
        )}

        <Collapsible trigger="Lead Type/Analytics" open>
          {!loading && lead != null && (
            <div className="leadDetail__profile__section leadDetail__profile__status">
              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Classification:</span>
                </div>
                <div className="colB">
                  <h4 className="leadDetail__profile__field__classifiers">
                    {currentConvo
                      ? currentConvo.classifier
                        ? currentConvo.classifier?.title
                        : "Unclassified"
                      : "No conversation selected"}
                    {console.log(currentConvo)}
                  </h4>
                </div>
              </div>

              {!loading && lead != null && (
                <div className="leadDetail__profile__field">
                  <div className="colA">
                    <span>Tags: </span>
                  </div>
                  <div className="colB">
                    {lead.tags.map((t, i) => (
                      <span className="tag" key={i}>
                        {t.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Assignee:</span>
                </div>
                <div className="colB">
                  <h4>{lead.assignee ? lead.assignee.name : "Unassigned"}</h4>
                </div>
              </div>

              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Conversation Start:</span>
                </div>
                <div className="colB">
                  <h4>
                    {currentConvo
                      ? `${moment(currentConvo.created_at).format("MM/DD/YYYY")} at ${moment(
                          currentConvo.created_at
                        ).format("h:mm a")}`
                      : "-"}
                  </h4>
                </div>
              </div>

              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Conversation End:</span>
                </div>
                <div className="colB">
                  <h4>
                    {currentConvo
                      ? `${moment(currentConvo.updated_at).format("MM/DD/YYYY")} at ${moment(
                          currentConvo.updated_at
                        ).format("h:mm a")}`
                      : "-"}
                  </h4>
                </div>
              </div>

              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Source:</span>
                </div>
                <div className="colB">
                  <h4>
                    {currentConvo?.marketing_setting?.source_name !== undefined
                      ? currentConvo.marketing_setting.source_name
                      : "-"}
                  </h4>
                </div>
              </div>

              <div className="leadDetail__profile__field">
                <div className="colA">
                  <span>Campaign:</span>
                </div>
                <div className="colB">
                  <h4>
                    {currentConvo?.marketing_setting?.campaign_name !== undefined
                      ? currentConvo.marketing_setting.campaign_name
                      : "-"}
                  </h4>
                </div>
              </div>

              <div className="leadDetail__profile__field__last">
                <div className="colA">
                  <span>Bot Name:</span>
                </div>
                <div className="colB">
                  <h4>{bot ? bot.title : "-"}</h4>
                </div>
              </div>
            </div>
          )}
        </Collapsible>

        {/* <h3>Other Information</h3>

                {!loading && lead != null && (
                    <div className="leadDetail__profile__section leadDetail__profile__otherInfo">
                        {lead.node_progresses.map((node_progress, i) => (
                            <div className="leadDetail__profile__field" key={i}>
                                <span>{node_progress.title}</span>
                                <h4>{node_progress.response}</h4>
                            </div>
                        ))}
                    </div>
                )} */}

        {/* <div className="leadDetail__profile__header">
          <h3>Files</h3>
        </div> */}

        <PreviewMarkownDocument
          body={this.state.markdownDocumentBody}
          visible={this.state.previewMarkdownDocumentVisible}
          setVisible={(previewMarkdownDocumentVisible): void =>
            this.setState({ ...this.state, previewMarkdownDocumentVisible })
          }
        />

        {/* download tab card */}
        <Box className="tabs__container">
          <Box style={{ borderBottom: "1px solid #E9E9E9" }}>
            <Tabs
              className="leadDetail__tab__section"
              value={this.state.tabValue}
              onChange={(event, newValue) => this.setState({ tabValue: newValue })}
              //if scrollable for mobile
              // variant="scrollable"
              // scrollButtons="on"
              aria-label="download tabs"
            >
              <Tab label="File Uploaded" />
              <Tab label="Documents" />
              <Tab label="Chat Transcript" />
              <Tab label="Appointment" />
            </Tabs>
          </Box>

          <TabPanel value={this.state.tabValue} index={0}>
            {!loading && lead != null && (
              <div className="leadDetail__tab__section">
                {files_arr.length > 0 ? (
                  <div className="leadDetail__tab__section__label">File Name</div>
                ) : (
                  <div className="leadDetail__tab__section__label">No files yet.</div>
                )}
                <ul>
                  {files_arr.map((file, f) => (
                    <li key={f}>
                      <a className="leadDetail__profile__file" href={file.url} target="_blank">
                        {file.title}
                      </a>
                      <Download />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            {!loading && lead != null && (
              <div className="leadDetail__tab__section">
                {leadDocassembleLinks.doc_files.length > 0 ? (
                  <div className="leadDetail__tab__section__label">File Name</div>
                ) : (
                  <div className="leadDetail__tab__section__label">No documents yet.</div>
                )}
                <ul>
                  {leadDocassembleLinks.doc_files.map((doc, i) => (
                    <li key={i} onClick={() => this.onClickFetchDocument(doc.document_link)}>
                      <a className="leadDetail__profile__file" target="_blank">
                        {this.getDocassembleName(doc, bot, lead)} ({doc.file_type})
                      </a>
                      <Download className="leadDetail__tab__section__download" />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={2}>
            {!loading && lead != null && (
              <div className="leadDetail__tab__section">
                {transcripts.conversations && transcripts.conversations.length > 0 ? (
                  <div className="leadDetail__tab__section__label">File Name</div>
                ) : (
                  <div className="leadDetail__tab__section__label">No transcripts yet.</div>
                )}
                <ul>
                  {transcripts.conversations &&
                    transcripts.conversations.map((file, f) => (
                      <li key={f} onClick={() => this.onClickFetchPDF(lead.id, file.id)}>
                        <a className="leadDetail__profile__file" target="_blank">
                          {file.bot.title}: {moment(file.created_at).format("MM/DD/YY - h:mm a")} - PDF
                        </a>
                        <Download />
                      </li>
                    ))}
                </ul>
                <ul>
                  {transcripts.conversations &&
                    transcripts.conversations.map((file, f) => (
                      <li key={f} onClick={() => this.onClickFetchCSV(lead.id, file.id)}>
                        <a className="leadDetail__profile__file" target="_blank">
                          {file.bot.title}: {moment(file.created_at).format("MM/DD/YY - h:mm a")} - CSV
                        </a>
                        <Download />
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={3}>
            {!loading && lead != null && (
              <div className="leadDetail__tab__section">
                {leadAppointments && leadAppointments.length > 0 ? (
                  <div className="leadDetail__tab__section__label"></div>
                ) : (
                  <div className="leadDetail__tab__section__label">No appointments yet.</div>
                )}
                <ul>
                  {leadAppointments &&
                    leadAppointments.map((app, a) => (
                      <li key={a}>
                        <table>
                          <tr>
                            <td className="leadDetail__app__date">
                              {this.convertDate(app.requested_time)} <span className="leadDetail__app__space"></span>
                            </td>
                            <td className="leadDetail__app__attendee">
                              {" "}
                              Attendee:
                              <span className="leadDetail__app__attendee2">
                                {" "}
                                {lead.assignee ? lead.assignee.name : ""}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="leadDetail__app__time">
                              {this.convertTime(app.requested_time)} - {this.getApptEnd(app.requested_time)}{" "}
                              {this.getTimezone(app.requested_time)}
                            </td>
                            <td className="leadDetail__app__attendee">
                              {" "}
                              Appointment Type:
                              <span className="leadDetail__app__attendee2"> {app.appointment_title}</span>{" "}
                            </td>
                            <td className="leadDetail__app__calendar">
                              {" "}
                              <span className="leadDetail__app__space"></span>{" "}
                              <input
                                className="leadDetail__app__calendar"
                                type="submit"
                                value="Add to Calendar"
                                onClick={(e) =>
                                  this.onClickAddToCalendar(
                                    e,
                                    this.convertTime(app.requested_time),
                                    this.getApptEnd(app.requested_time),
                                    app.requested_time
                                  )
                                }
                              ></input>{" "}
                            </td>
                          </tr>
                        </table>
                        <hr className="leadDetail__app__line"></hr>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </TabPanel>
        </Box>

        {/* integration card */}
        <div className="leadDetail__profile__section integration">
          <div className="integration__label">Integrations</div>
          {plugins && clioPlugin ? (
            this.renderClioIntegration()
          ) : (
            <div className="leadDetail__profile__field__plugin">
              <Button size="large" onClick={() => this.props.history.push(SETTINGS)} className="addPlugins">
                Add Plugins
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// export default connect()(CmpName);
export default withRouter(connect(() => {}, {})(LeadProfile));

import api from "lib/api";

export type createAvailabilityParams = {
  id?: string;
  start_date: string;
  start_time: string;
  end_time: string;
  available_for_user_id: string;
  is_repeat?: string;
  type?: string;
  list_start_date: string;
  list_end_date: string;
};

export type editAvailabilityParams = {
  id: string;
  start_date: string;
  start_time: string;
  end_time: string;
  available_for_user_id: string;
  is_repeat?: string;
  type?: string;
  list_start_date: string;
  list_end_date: string;
};

export const createAvailability = (params: createAvailabilityParams) => {
  return api.post(`/availabilities`, params);
};

export const putAvailability = (params: editAvailabilityParams) => {
  return api.put(`/availabilities/${params.id}`, params);
};

export const deleteAvailability = (id: string) => {
  return api.deleteReq(`/availabilities/${id}`, null);
};

export const fetchAvailabilities = (id, startDate, endDate, startTime = null, endTime = null) => {
  let endpoint = `/availabilities?available_for_user_id=${id}&start_date=${startDate}`;
  if (endDate != null) endpoint += `&end_date=${endDate}`;
  if (startTime != null) endpoint += `&start_time=${startTime}`;
  if (endTime != null) endpoint += `&end_date=${endTime}`;

  return api.get(endpoint, null);
};

export const fetchPluginEvents = (id, startDate, endDate) => {
  const endpoint = `/availabilities/plugin_events?available_for_user_id=${id}&start_date=${startDate}&end_date=${endDate}`;
  return api.get(endpoint, null);
};

/**
 * The component representation of a node within a dialog
 * Note that one DialogNode tag (typically representing the root node) 
 * will recursively render the rest of the nodes in the dialog (see determineNextNode)
 */

import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Draggable } from "react-beautiful-dnd";

import { RootStore } from "reducers";
import { BotStore } from "reducers/detailBot";

import connect from "app/connect";
import { Node, Dialog, selectNode, selectedDialogId, fetchNSetNode } from "actions/bot";

import { CueType, defaultCueValues, cueTypeToNodeSpecs } from "lib/cue";
import { NODE_TYPES, createNodeParams, updateNodeParams, updateOption, createOption,
  deleteOption, updateOptionParams, createNodeConnection, createNodeConnectionParams } from "lib/nodes";
import { createTag, createTagParams, deleteTag, deleteTagParams } from "lib/tags";
import { userIsAdmin } from "lib/utilities/global";

import { hasLeaddocketPlugin } from "selectors/plugins";
import { leadDocketFieldsPerCaseType, leadDocketCaseTypeId, leadDocketCaseTypes, leadDocketCaseTypesLoading } from "selectors/detailbot";

import X from "assets/icons/ui/x.inline.svg";

// Cmps
import CueTypeSelection from "./cueTypeSelection";
import DialogNodeHeader from "./dialogNodeHeader";
import DialogNodeBody from "./dialogNodeBody";
import DialogSettings from "./dialogSettings";
import DialogNodeFooter from "./dialogNodeFooter";

// Sass
import "sass/components/dialogNode.scss";
import nodeConnection from "./nodeConnection";
import { create } from "domain";
import es from "date-fns/esm/locale/es/index.js";


// TODO: break this up into separate types?
/**
 * Object representation of changes to be made to new or existing options
 * 
 * Adding a new option:
 *  required: label, node_id
 *  optional: tag
 * 
 * Updating an existing option:
 *  required: id
 *  optional: label tag, remove_tag
 * 
 * Removing an existing option:
 *  required: id
 */
type Option = {
  id?: number;    // option id
  label?: string; // text to be displayed for the new or existing Option
  node_id?: number;
  tag?: any;
  remove_tag?: boolean;
} & updateOptionParams;

/**
 * Object representation of a Tag and its relation to either an Option or 
 * a Node Connection
 */
type TagData = {
  node_connection_id?: number;
  option_id?: number;
  tag_id: number;
} & createTagParams;

/**
 * Stores the changes made to the node that haven't been saved to the db yet
 */
type NodeState = {
  title: string;
  message: string;
  meta: any;
  documentGenerated: boolean;
  esign: boolean;
  user_download: boolean;
  pre_sched: string;
  post_sched: string;
  sign_message: string;
  postsign_message: string;
  userdl_message: string;
  initSched: boolean;

  // keep track of the changes to be made to the db
  new_options: Option[];
  removed_options: Option[];
  updated_options: Option[];

  // the list of the node's options with the above changes applied
  current_options: any[];
  
  // keep track of the changes to be made to the db
  new_tags: TagData[];
  removed_tags: TagData[];

  // the list of the node's tags with the above changes applied
  current_tags: any[];

  // patterns: any[];
}

type Props = {
  detailBot: BotStore;
  node: Node;
  dialog: Dialog;
  root?: boolean;
  nodesById: Record<string, any>;
  deleteNode: Function;
  selectNode: Function;
  selectedDialogId: Function;
  createNode: (params: createNodeParams) => {};
  updateNode: (params: updateNodeParams) => {};
  index: number;
  clickable: boolean;
  terminusId?: number;
  draggable: boolean;
  limit?: number;
  isFollowUpNode?: boolean;
  fetchNSetNode: Function;
} & RouteComponentProps;

type State = {
  showNodeCreate: boolean;
  showAddButton: boolean;
  showDeleteButton: boolean;
  settingsOpen: boolean;
  collapsed: boolean;
  editing: boolean;
  currentDialog: number;
} & NodeState;

/**
 * Dialog Node wrapper cmp.
 */
class DialogNode extends React.Component<Props, State> {
  static defaultProps = {
    index: 0,
    draggable: true,
    clickable: true,
  };

  /**
   * Object representation of the node as it is currently stored in the db
   */
  initState: NodeState = {
    title: this.props.node.title,
    message: this.props.node.message,
    meta: this.props.node.meta,
    documentGenerated: this.props.node.generate_documents,
    esign: this.props.node.esign,
    user_download: this.props.node.user_download,

    pre_sched: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['pre_schedule_message'] : '',
    post_sched: this.props.node.title == 'Completion Message' && this.props.node.meta  ? this.props.node.meta['post_scheduled_message'] : '',
    sign_message: this.props.node.title == 'Completion Message' && this.props.node.meta  ? this.props.node.meta['esign_message'] : '',
    postsign_message: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['postesign_message'] : '',
    userdl_message: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['userdownload_message'] : '',
    initSched: !this.props.node.meta || (this.props.node.meta['pre_schedule_message'] == '' && this.props.node.meta['post_scheduled_message'] == '') || (!this.props.node.meta.hasOwnProperty('pre_schedule_message') && !this.props.node.meta.hasOwnProperty('post_scheduled_message')) ? false : true,

    new_options: [],
    removed_options: [],
    updated_options: [],
    current_options: this.props.node.options,
    
    new_tags: [],
    removed_tags: [],
    current_tags: this.props.node.node_connections[0].tags,

    // patterns: this.props.node.node_connections[0].patterns
  }

  state = {
    showNodeCreate: false,
    showAddButton: false,
    showDeleteButton: false,
    settingsOpen: false,
    collapsed: false,
    editing: false,
    currentDialog: this.props.node.dialog_id,
    ...this.initState
  };


  // EDITING / SAVE STATE

  /**
   * Updates title in the state
   * @param title 
   */
  updateHeader = (title) => {
    this.setState({
      title: title
    })
  }

  /**
   * Updates message, tags, options in the state
   * @param message 
   */
  updateBody = (message) => {
    this.setState({
      message: message
    })
  }

  updateDocGen = (docGen) => {
    this.setState({
      documentGenerated: docGen
    })
  }

  updateEsign = (esign) => {
    this.setState({
      esign: esign
    })
  }

  updateUserDownload = (user_download) => {
    this.setState({
      user_download: user_download
    })
  }

  updateInitSched = (initSched) => {
    this.setState({
      initSched: initSched
    })
  }

  /**
   * Updates the meta object in the state
   * @param key 
   * @param value 
   */
  updateMeta = (key: string, value: any) => {
    const current_meta = this.state.meta;

    this.setState({
      meta: {
        ...current_meta,
        [key]: value
      }
    })
  }

  // TODO: come back to this when you know what custom attributes are used for
  /**
   * 
   * @param value 
   * @param remove 
   */
  // updateCustomAttributes = (value: string, remove: boolean = false) => {
  //   const attrs = this.state.custom_attributes.slice();

  //   if(remove) {
  //     this.setState({
  //       custom_attributes: attrs.filter(attr => attr != value)
  //     })
  //   }

  //   if(attrs.indexOf(value) == -1) {
  //     this.setState({
  //       custom_attributes: attrs.concat(value)
  //     })
  //   }
  //   else {
  //     // TODO: warning that attribute already exists
  //   }
  // }

  /**
   * 
   * @param tag 
   * @param label 
   */
  addTag = (tag: TagData, label: string) => {
    // Both Option and Node Connection-related Tags will need to be added to
    // new_tags so they are created in the db when the user clicks "Save"
    let new_tags = this.state.new_tags;
    let current_tags = this.state.current_tags;

    let updates = {
      new_tags: new_tags.concat({...tag, name: label})
    }
    if(tag.option_id) {
      // Tags attached to options are stored within the options themselves
      this.updateOptions({
        id: tag.option_id, 
        tag: {...tag, name: label}
      })
      
      let current_tags = this.state.current_tags.slice();
      updates["current_tags"] = current_tags.filter(current_tag => {current_tag.id != tag.tag_id})
    }
    else if(tag.node_connection_id) {
      // create a copy of the current tags array so we are not changing 
      // the tags array in the node prop
      let current_tags = this.state.current_tags.slice();
      updates["current_tags"] = current_tags.concat({...tag, name: label})
      this.setState({
        current_tags: updates["current_tags"]
      })
    }

    this.setState(updates);
  }

  /**
   * 
   * @param tag 
   */
  removeTag = (tag: TagData) => {
    let removed_tags = this.state.removed_tags;
    let updates = {
      removed_tags: removed_tags.concat({...tag})
    }

    if(tag.option_id) {
      this.updateOptions({
        id: tag.option_id, 
        remove_tag: true
      })
    }
    else if(tag.node_connection_id) {
      // create a copy of the current tags array so we are not changing 
      // the tags array in the node prop
      let current_tags = this.state.current_tags.slice();
      updates["current_tags"] = current_tags.filter(current_tag => {current_tag.id != tag.tag_id})
    }

    this.setState(updates);
  }

  /**
   * 
   * @param tag 
   * @param label 
   * @param remove 
   */
  updateTags = (tag: TagData, label: string, remove: boolean = false) => {
    if(remove) {
      this.removeTag(tag);
    }
    else {
      this.addTag(tag, label);
    }
  }

  updatePre = (pre: string) => {
    this.setState({
      pre_sched: pre
    })
  }

  updatePost = (post: string) => {
    this.setState({
      post_sched: post
    })
  }

  updateSignMeta = (sign: string) => {
    this.setState({
      sign_message: sign
    })
  }

  updatePostSignMeta = (sign: string) => {
    this.setState({
      postsign_message: sign
    })
  }

  updateUserDownloadMeta = (user_download: string) => {
    this.setState({
      userdl_message: user_download
    })
  }

  /**
   * 
   * @param option 
   * @param add 
   * @param remove 
   */
  updateOptions = (option: Option, add: boolean = false, remove: boolean = false, index: number = null) => {
    // create a copy of the current options array so we are not changing the options array in the node prop
    let current_options = this.state.current_options.slice();
    if(remove) {
      let removed_options = this.state.removed_options;
      this.setState({
        current_options: current_options.filter(o => o.id != option.id),
        removed_options: removed_options.concat(option)
      })
    }
    else if(add) {
      let new_options = this.state.new_options;
      this.setState({
        current_options: current_options.concat(option),
        new_options: new_options.concat(option)
      })
    }
    // update an existing option
    else {
      // find the existing option we want to update in current_options
      if(option.id) {
        let option_to_update_i = current_options.findIndex(o => o.id == option.id);
        let option_to_update = current_options[option_to_update_i];

        // create a new option to replace the old one with that includes the updates to be made
        let new_option = {
          id: option_to_update.id,
          label: option.label,
          node_id: option_to_update.node_id,
          tag: option.tag ? option.tag : (option.remove_tag ? null : option_to_update.tag)
        }
        
        current_options[option_to_update_i] = new_option;
      } else {
        let option_to_update = current_options[index];

        //option_to

        let new_option = {
          label: option.label,
          node_id: option_to_update.node_id,
          tag: option.tag ? option.tag : (option.remove_tag ? null : option_to_update.tag)
        }

        current_options[index] = new_option;
        let new_index = index - this.props.node.options.length

        let new_option_two: Option = {
          id: null,
          label: option.label,
          node_id: option_to_update.node_id,
          tag: option.tag ? option.tag : (option.remove_tag ? null : option_to_update.tag)
        }
        
        let new_options = this.state.new_options
        new_options[new_index] = new_option_two
      }

      
      // update the state of the node
      let updated_options = this.state.updated_options

      let toAdd = true;
      updated_options.forEach(uo => {
        if(uo.id == option.id) {
          uo.label = option.label;
          toAdd = false
        }
      })

      if(toAdd) {
        updated_options.push(option)
      }

      this.setState({
        current_options: current_options,
        updated_options: updated_options
      })
    }
  }



  /**
   * Resets node state to what's stored in props
   * Turns editing mode off
   */
  discardChanges = () => {
    
    this.setState({
      ...this.initState,
      editing: false
    })
  }
  

  /**
   * Sends changes to db and reloads the page
   * Turns editing mode off
   */
  saveChanges = async () => {
    const node_id = this.props.node.id;

    this.setState({
      editing: false
    })

    this.props.updateNode({
      id: node_id,
      title: this.state.title,
      message: this.state.message,
      meta: this.state.meta,
      generate_documents: this.state.documentGenerated,
      esign: this.state.esign,
      user_download: this.state.user_download
    })
    
    /*const newOptions = (this.state.new_options.map(new_option => createOption({ nodeId: this.props.node.id, label: new_option.label}).then(res => {
      const data = res.data;
      const option_id = data.id;
      const label = data.label;
      let current_options = this.state.current_options

      current_options.forEach(co => {
        if(co.label == label) {
          co['id'] = option_id
        }
      })

      this.setState({
        current_options: current_options
      })

    })));*/

    let new_options = this.state.new_options
    let promise_arr = []
    for(let i=0; i < new_options.length; i++) {
      await createOption({ nodeId: this.props.node.id, label: new_options[i].label}).then(res => {
        
        const data = res.data;
        const option_id = data.id;
        const label = data.label;
        let current_options = this.state.current_options

        current_options.forEach(co => {
          if(co.label == label) {
            co['id'] = option_id
          }
        })

        this.setState({
          current_options: current_options
        })
      })
    }


    this.state.removed_options.map(removed_option => deleteOption(removed_option.id));
    this.state.updated_options.map(updated_option => updateOption({id: updated_option.id, label: updated_option.label}))

    this.state.new_tags.map(new_tag => createTag(new_tag).then(res => {
      const data = res.data;
      const tag_id = data.id;
      const name = data.name;
      let current_tags = this.state.current_tags

      current_tags.forEach(ct => {
        if(ct.name == name) {
          ct['id'] = tag_id
        }
      })

      this.setState({
        current_tags: current_tags
      })

    }))  // NOTE: no call to updateOption necessary for new tags

    this.state.removed_tags.map(removed_tag => deleteTag(removed_tag["id"], removed_tag))

    // createNodeConnection({node_id, patterns: });
    this.initState.title = this.state.title;
    this.initState.message = this.state.message;
    this.initState.meta = this.state.meta;
    this.state.documentGenerated = this.state.documentGenerated;
    this.state.esign = this.state.esign;
    this.state.user_download = this.state.user_download;
    this.initState.current_options = this.state.current_options;
    this.initState.current_tags = this.state.current_tags;
    
    this.setState({
      removed_options: [],
      removed_tags: [],
      new_tags: [],
      new_options: []
    })
  }



  /**
   * Not totally sure what this does? I think it selects a dialog but I don't know where "match" is coming from
   */
  componentWillMount() {
    const { match } = this.props;
    const dialogId = match.params.id;
    this.props.selectedDialogId(dialogId);
  }

  componentDidUpdate() {
    this.dialogSwitch()
  }

  /**
   * Proxy function that processes logic
   * for which node the new node will link to
   */
  createSelectedCueType = (type: CueType) => {
    const { node } = this.props;

    // Should link to this node
    this.props.createNode({
      dialog_id: node.dialog_id,
      link_from: node.id,
      ...defaultCueValues(type),
      ...cueTypeToNodeSpecs(type),
    });
  };

  /**
   * Click handler to set the node properties in Editor
   * Handles collapsed toggle and node selection
   */
  clickHandler = (e) => {
    const { node, clickable } = this.props; // I don't know if clickable is ever false?
    clickable && this.props.selectNode(node);
    e.stopPropagation();
    if (this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  };

  /**
   * 
   * @returns 
   */
  defaultConnectionNodeId = () => {
    const { node_connections } = this.props.node;

    // I have zero clue what this does
    const defaultNext = node_connections.find(
      (connection) => connection.connection_type === "direct" && connection.root === true
    );
    return defaultNext ? defaultNext.referencing_node_id : undefined;
  };

  dialogSwitch = () => {
    const { node } = this.props;
    if(this.props.node.title == 'Completion Node') {
    }
    if(node.dialog_id != this.state.currentDialog) {
      
      
      let newState: NodeState = {
        title: this.props.node.title,
        message: this.props.node.message,
        meta: this.props.node.meta,
        documentGenerated: this.props.node.generate_documents,
        esign: this.props.node.esign,
        user_download: this.props.user_download,
    
        pre_sched: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['pre_schedule_message'] : '',
        post_sched: this.props.node.title == 'Completion Message' && this.props.node.meta  ? this.props.node.meta['post_scheduled_message'] : '',
        sign_message: this.props.node.title == 'Completion Message' && this.props.node.meta  ? this.props.node.meta['esign_message'] : '',
        postsign_message: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['post_esign_message'] : '',
        userdl_message: this.props.node.title == 'Completion Message' && this.props.node.meta ? this.props.node.meta['userdownload_message'] : '',
        initSched: !this.props.node.meta || (this.props.node.meta['pre_schedule_message'] == '' && this.props.node.meta['post_scheduled_message'] == '') || (!this.props.node.meta.hasOwnProperty('pre_schedule_message') && !this.props.node.meta.hasOwnProperty('post_scheduled_message')) ? false : true,
    
        new_options: [],
        removed_options: [],
        updated_options: [],
        current_options: this.props.node.options,
        
        new_tags: [],
        removed_tags: [],
        current_tags: this.props.node.node_connections[0].tags
      }
      this.setState({
        ...newState,
        currentDialog: node.dialog_id
      })

    }
  }

  /**
   * Determines if there is a next node to render in the node hierarchy.
   * Acts as a recursive render
   */
  determineNextNode = () => {
    const { nodesById, updateNode, index, terminusId, ...rest } = this.props;


    const defaultNextId = this.defaultConnectionNodeId();

    if (defaultNextId && terminusId != defaultNextId) {
      const nextNode = nodesById[`${defaultNextId}`];
      // If the node has reached the terminus set by it's parent,
      // dont render the terminus node
      if (terminusId === defaultNextId) return;
      // Return the respective node
      return (
        <DialogNode
          {...rest}
          node={nextNode}
          index={index + 1}
          root={false}
          nodesById={nodesById}
          updateNode={updateNode}
          terminusId={terminusId}
        />
      );
    }
  };

  /**
   * 
   * @param optionId 
   * @param isOption 
   * @returns 
   */
  getFollowUp = (optionId, isOption = true) => {
    const { node, nodesById, ...rest } = this.props;
    const { node_connections } = node;

    const followUpConnection = node_connections.find((connection) =>
      isOption ? connection.option_id === optionId : connection.id === optionId
    );
    // Ensure that there is a follow up connection
    if (followUpConnection == undefined) return null;

    const followUpId = followUpConnection.referencing_node_id;
    // Ensure that follow up id exists
    if (followUpId === null) return null;
    // Ensure that follow up connection doesn't point to the default
    if (followUpId === this.defaultConnectionNodeId()) return null;

    return (
      <DialogNode
        {...rest}
        node={nodesById[`${followUpId}`]}
        draggable={false}
        clickable={true}
        isFollowUpNode={true}
        nodesById={nodesById}
        terminusId={this.defaultConnectionNodeId()}
      />
    );
  };


  // TOGGLE FUNCTIONS

  toggleAddButton = (show) => {
    this.setState({
      showAddButton: show !== null ? show : !this.state.showAddButton,
    });
  };

  toggleSettingsOpen = () => {
    this.setState({ settingsOpen: !this.state.settingsOpen });
  };

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  toggleEditing = () => {
    this.setState({ editing: !this.state.editing })
  }

  /**
   * Renders body of Dialog Node
   */
  renderContent = () => {
    const {
      detailBot,
      node,
      root,
      updateNode,
      createNode,
      deleteNode,
      nodesById,
      isFollowUpNode,
      terminusId,
      user,
      limit,
    } = this.props;
    
    const { collapsed, editing } = this.state;
    const hasNextNode = this.defaultConnectionNodeId();
    const selectedNodeId = detailBot.selectedNode ? detailBot.selectedNode.id : null;
    const showNextNodeAddButton = !(node.node_type === NODE_TYPES.HANDOFF || node.node_type === NODE_TYPES.DIALOG_LINK);
    const dialogId = node.node_type == NODE_TYPES.DIALOG_LINK && node.meta ? node.meta.dialog_to_link_to : undefined;
    const selected = selectedNodeId === node.id;
    const branchedDialog = limit === 1;
    //this.dialogSwitch();

    const className = `node node--${node.node_type} ${selected ? "selected" : "unselected"} ${root ? "node--root" : "node--regular"} ${
      selected ? "selected" : ""
    }`;

    return (
      <>
        <div
          className={className}
          onMouseEnter={() => this.setState({ showDeleteButton: true })}
          onMouseLeave={() => this.setState({ showDeleteButton: false })}
        >
          {!branchedDialog && (
            <span
              className={`node__delete icon-delete ${
                userIsAdmin(this.props.user) && (this.state.showDeleteButton || selected) ? "visible" : ""
              }`}
              onClick={() => this.props.deleteNode(node.id, dialogId)}
            >
              <X className="node__delete-x" />
            </span>
          )}

          <DialogNodeHeader
            node={node}
            toggleSettingsOpen={this.toggleSettingsOpen}
            toggleCollapsed={this.toggleCollapsed}
            toggleEditing={this.toggleEditing}
            collapsed={collapsed}
            editing={editing}
            title={this.state.title}
            updateHeader={this.updateHeader}
          />

          {!collapsed &&
            (!branchedDialog ? (
              <DialogNodeBody
                user={user}
                bot={detailBot.bot}
                dialogsById={detailBot.dialogsById}
                node={node}
                selected={selected}

                editing={editing}
                updateNode={updateNode}
                createNode={createNode}
                getFollowUp={this.getFollowUp}
                nodesById={nodesById}
                deleteNode={deleteNode}
                isFollowUpNode={isFollowUpNode}
                message={this.state.message}
                post_sched={this.state.post_sched}
                pre_sched={this.state.pre_sched}
                sign_message={this.state.sign_message}
                postsign_message={this.state.postsign_message}
                userdl_message={this.state.userdl_message}
                title={this.state.title}
                initSched={this.state.initSched}
                updateInitSched={this.updateInitSched}
                updatePre={this.updatePre}
                updatePost={this.updatePost}
                updateSignMeta={this.updateSignMeta}
                updatePostSignMeta={this.updatePostSignMeta}
                updateUserDownloadMeta={this.updateUserDownloadMeta}
                updateBody={this.updateBody}
                updateTags={this.updateTags}
                updateMeta={this.updateMeta}
                updateDocGen={this.updateDocGen}
                updateEsign={this.updateEsign}
                updateUserDownload={this.updateUserDownload}
                metaState={this.state.meta}
                docGenState={this.state.documentGenerated}
                user_download={this.state.user_download}
                esign={this.state.esign}
                optionState={this.handleYesNoOrder(this.state.current_options)}
                tagState={this.state.current_tags}
                updateOptions={this.updateOptions}
              />
            ) : (
              <div style={{ padding: "20px 30px 30px", color: "black" }}>{node.message}</div>
            ))}

          {editing && <DialogNodeFooter 
            saveChanges={this.saveChanges}
            discardChanges={this.discardChanges}
          />}

        </div>

        {showNextNodeAddButton && !branchedDialog && (
          <div
            onMouseOver={() => this.toggleAddButton(true)}
            onMouseLeave={() => this.toggleAddButton(false)}
            className="node__connector--bottom"
          >
            <div className="node__connector">
              <img
                className={`plus ${
                  userIsAdmin(this.props.user) && (this.state.showAddButton || !hasNextNode) && "visible"
                }`}
                src={`${process.env.REACT_STATIC_CDN}/botbuilder/add-item.svg`}
                onClick={() => this.setState({ showNodeCreate: !this.state.showNodeCreate })}
              />
            </div>
          </div>
        )}

        {this.state.showNodeCreate && (
          <CueTypeSelection
            onSelect={this.createSelectedCueType}
            visible={this.state.showNodeCreate}
            isNodeAllowed={!hasNextNode || (terminusId && hasNextNode === terminusId)}
          />
        )}
      </>
    );
  };

  handleYesNoOrder = (options) => {

    if(options.length == 2) {
      if(options[0].label == 'Yes' && options[1].label == 'No') {
        return this.state.current_options
      }
      if(options[1].label == 'Yes' && options[0].label == 'No') {
        const yes = options[1]
        const no = options[0]
        this.state.current_options[0] = yes
        this.state.current_options[1] = no
        return this.state.current_options

      }
    }

    return this.state.current_options.sort((a,b) => a.id - b.id)
  }

  handleMultipleSelect = (e) => {
    const { node, updateNode } = this.props;
    updateNode({
      id: node.id,
      multiple_selection: e.target.checked,
    });
  };

  handleCaseTypeChange = (e) => {
    const { node, updateNode } = this.props;
    updateNode({
      id: node.id,
      multi_case_type: e.target.checked,
    });
  };

  render() {
    const { node, dialog, index, limit, leadDocketFieldsPerCaseType, leadDocketCaseTypes, leadDocketCaseTypeId } = this.props;
    const { settingsOpen } = this.state;
    const draggable = userIsAdmin(this.props.user);

    const case_id = leadDocketCaseTypeId ? leadDocketCaseTypeId : dialog.case_type;


    const leadDocketFields = case_id ? leadDocketFieldsPerCaseType[case_id] : [];
    const isCaseSelect = leadDocketCaseTypeId == 0 && !dialog.case_type ? true : false
    const ldCaseTypes = leadDocketCaseTypes;
    //this.fetchLDCaseTypes();

    return (
      <>
        <DialogSettings
          settingsOpen={settingsOpen}
          toggleSettingsOpen={this.toggleSettingsOpen}
          node={node}
          dialog={dialog}
          handleMultipleSelect={this.handleMultipleSelect}
          handleCaseTypeChange={this.handleCaseTypeChange}
          leadDocketFields={leadDocketFields}
          leadDocketCaseType={ldCaseTypes}
          isCaseSelect={isCaseSelect}
        />
        {draggable ? (
          <Draggable key={node.id} draggableId={node.id.toString()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={provided.draggableStyle}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onClick={this.clickHandler}
                className="node__container"
              >
                {this.renderContent()}
              </div>
            )}
          </Draggable>
        ) : (
          <div className="node__container" onClick={this.clickHandler}>
            {this.renderContent()}
          </div>
        )}

        {(limit !== 1 || limit == undefined) && this.determineNextNode()}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state: RootStore) => ({
      detailBot: state.detailBot,
      hasLeaddocketPlugin: hasLeaddocketPlugin(state),
      leadDocketFieldsPerCaseType: leadDocketFieldsPerCaseType(state),
      leadDocketCaseTypeId: leadDocketCaseTypeId(state),
      leadDocketCaseTypes: leadDocketCaseTypes(state),
    }),
    { selectNode, selectedDialogId, fetchNSetNode }
  )(DialogNode)
);

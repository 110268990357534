import * as React from "react";
import App from "app/components/app";
import connect from "app/connect";

// Cmps
import Nav from "app/components/navbar"
import Container from "app/components/global/container";
import ForgotPassword from "app/components/lander/forgotPasswordForm";

/**
 * Forgot Password Page Component
 */
class Forgot extends React.PureComponent {
    render() {
        return (
            <App className="public">
                <Nav />

                <Container name="lander">
                    <ForgotPassword />
                </Container>
            </App>
        );
    }
}

export default connect()(Forgot);

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { NodeConnection as NodeConn } from "actions/bot";
import Select from 'react-select';
import  moment from "moment";
import TextField from '@material-ui/core/TextField';
import {
    OperatorDetails,
    nodeTypeToAllowedPattern,
    CueTypeString
} from "lib/cue"
import Plus from "assets/icons/ui/plus.inline.svg";
import { userIsAdmin } from "lib/utilities/global";

// Cmps
type State = {
    label: string;
}

type Props = {   
    isNew:Boolean; 
    nextReferenceNodeId?: Number| null;
    nodeType: String;
    addFollowUpToPattern: () => {};
    makeConnectionActive: () => {};
    nodeConnection?: NodeConn;
    patterns: Array<any>;
    allowedPattern: OperatorDetails;
    withRange: boolean;
    changeOperatorHandler: Function;
    setConnPatternHandler: Function;
    onChangeHandler: Function;
    setInputBoxRef: Function;
    editing: boolean;
} & RouteComponentProps;

/**
 * Pattern compontent for each node connection
 */
class NodeConnectionPattern extends React.Component<Props, State> {

    /**
     * Capturing the when user pressed ENTER
     */
    captureEnter = (e) => {
        const { isNew, patterns } = this.props;
        if (e.key === 'Enter') {
            e.target.blur();
            this.props.setConnPatternHandler();
        }else if((e.key === 'Delete' || e.keyCode == 8) && !isNew && patterns.length == 0){
            e.target.blur();
            if (e.keyCode == 8) e.preventDefault();      
        }
    }

    /**
     * Setting the pattern element properties for both new/existing node connections
     */
    
    patternElement = () =>{
        const {
            isNew,
            nodeConnection,
            nodeType,
            makeConnectionActive,
            patterns,
            allowedPattern,
            withRange,
            changeOperatorHandler,
            setConnPatternHandler,
            onChangeHandler,
            setInputBoxRef,
            editing,
        } = this.props;
        if ((!nodeConnection || nodeConnection.root) && !isNew) {
            return null;
        }
        const { allowed_pattern } = nodeTypeToAllowedPattern(nodeType.type);
        const patternsArr = patterns.map((value) =>{
            let newValue = value.replace(/[<>=]/g, '');
            if (allowedPattern.type === 'date') {
                newValue = moment(parseInt(newValue)).format("YYYY-MM-DD");
            }
            return newValue;
        });

        let connectionElement = (
            <>
                <Select
                    onChange={changeOperatorHandler}
                    options={allowed_pattern}
                    onBlur={(!isNew) ? setConnPatternHandler : undefined}
                    defaultValue={allowedPattern}
                    className="node__body__pattern__selector"
                    classNamePrefix="node__body__pattern__selector"
                    isDisabled={!userIsAdmin(this.props.user) || !editing}
                />                  
                <div>
                    {(allowedPattern.type !== 'file') && 
                        <TextField
                            type={allowedPattern.type}
                            className="type-14-regular node__body__pattern-label"
                            onChange={onChangeHandler}
                            placeholder={(!isNew) ? "Delete Response?" : "type to add rule"}
                            onFocus={makeConnectionActive}
                            onBlur={(!isNew) ? setConnPatternHandler : undefined}
                            onKeyDown={this.captureEnter}
                            value={(patterns.length > 0) ? patternsArr[0] : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputRef={setInputBoxRef}
                            disabled={!userIsAdmin(this.props.user) || !editing}
                        />
                    }
                </div>
                {withRange && (
                    <div className="node__body__pattern__range">
                        <span>{allowedPattern.joinedText}</span>
                        <TextField
                            type={allowedPattern.type}
                            className="type-14-regular node__body__pattern-label"
                            onChange={onChangeHandler}
                            onFocus={makeConnectionActive}
                            onBlur={(!isNew) ? setConnPatternHandler : undefined}
                            onKeyDown={this.captureEnter}
                            placeholder={(!isNew) ? "Delete Response?" : "type to add rule"}
                            id="rangeDate"
                            value={(patterns.length > 1)  ? patternsArr[1] : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={!userIsAdmin(this.props.user) || !editing}
                        />                    
                    </div>
                )}
            </>
        )

        if (allowedPattern.type === 'file' && (!isNew)) {
            connectionElement = (
                <span 
                    className="node__body__pattern-label node__body__pattern__type-file" 
                    onClick={makeConnectionActive}>
                    {allowedPattern.initialText}
                </span>
            )
        }
        
        return connectionElement;
    }
    
    render() {       
        const {
            isNew,
            nodeType,
            allowedPattern,
            setConnPatternHandler,
            editing,
        } = this.props;
        const patternElement = this.patternElement();
        if(isNew && editing){            
            return (
                <div className="node__body__pattern node__body__pattern--new">
                    <span className={`node__body__pattern__new-icon ${userIsAdmin(this.props.user) ? '' : 'disabled'}`}
                        onClick={userIsAdmin(this.props.user) && setConnPatternHandler}
                    >
                        <Plus />
                    </span>
                    {patternElement}
                </div>           
            )
        }
        else if(isNew && !editing) {
            return null;
        }

        return(
            <React.Fragment>
                <span className={`node__body__pattern-icon node__body__pattern-icon--${CueTypeString[nodeType.type]} ${userIsAdmin(this.props.user) ? '' : 'disabled'}`} >
                    <span>
                        <span className={`node__body__pattern--iconText`}>
                            {allowedPattern.iconText}
                        </span>
                    </span>
                </span>

                {patternElement}                    
            </React.Fragment>
        );
    }
}

export default NodeConnectionPattern;
import {
  fetchAppointmentTypes,
  createAppointmentType as factoryCreateAppointmentType,
  updateAppointmentType as factoryUpdateAppointmentType,
  deleteAppointmentType as factoryDeleteAppointmentType
} from "lib/appointmentTypes";
import { toggleSnackbar } from "actions/ui";

// Action types
export const FETCH_APPOINTMENT_TYPES = 'FETCH_APPOINTMENT_TYPES';
export const SET_APPOINTMENT_TYPES = 'SET_APPOINTMENT_TYPES';

export type AppointmentType = {
  id: number;
  title: string;
  description: string;
  duration: string;
  organization_id: number;
  buffer: number;
  email_customizations: EmailOptions;
}

export type EmailOptions = {
  title?: string;
  description?: string;
  location?: string;
}

export const getAppointmentTypes = () => dispatch => {
  dispatch({
    type: FETCH_APPOINTMENT_TYPES
  });

  fetchAppointmentTypes()
  .then(res => {
    dispatch({
      type: SET_APPOINTMENT_TYPES,
      appointmentTypes: res.appointment_types,
      page: res.page,
    })
  })
  .catch(err => {
    toggleSnackbar("We couldn't fetch your appointment types! Please try again", true)(dispatch);
  })
}

export const updateAppointmentType = (params) => dispatch => {
  factoryUpdateAppointmentType(params)
  .then(res => {
    getAppointmentTypes()(dispatch)
  })
  .catch(err => {
    toggleSnackbar("We ran into an error updating this appointment type!", true)(dispatch)
  })
}

export const createAppointmentType = (params) => dispatch => {
  factoryCreateAppointmentType(params)
  .then(res => {
    getAppointmentTypes()(dispatch)
  })
  .catch(err => {
    toggleSnackbar("We ran into an error creating a new appointment type!", true)(dispatch)
  })
}

export const deleteAppointmentType = (appointmentTypeId) => dispatch => {
  factoryDeleteAppointmentType(appointmentTypeId)
  .then(res => {
    getAppointmentTypes()(dispatch)
  })
  .catch(err => {
    toggleSnackbar("We ran into an error deleting this appointment type!", true)(dispatch)
  })
}

export type AppointmentTypesAction = 
  | {
      type: "FETCH_APPOINTMENT_TYPES";
      loading: Boolean
    }
  | {
      type: "SET_APPOINTMENT_TYPES";
      appointmentTypes: AppointmentType[],
      page: number
    };